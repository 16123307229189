import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButtonSlide from "../../common/radioButtonSlide";
import BoxText from "../../common/BoxText";
import ProductList from "./List";
import RadioButtonIcon from "../../common/RadioButtonIcon";
import RadioButtonSlideIcon from "../../common/radioButtonSlideIcon";
import { Link } from "react-router-dom";

import {
  fetchCurrentConfigTheme,
  fetchProductListListData,
  updateProductListListData,
} from "../../../api";

function ProductLists({ storeKey }) {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isColor, setIsColor] = useState("#f9a1bc");
  const [isGrid, setIsGrid] = useState(false);
  const [isGrid3D, setIsGrid3D] = useState(false);
  const [isTinder, setIsTinder] = useState(false);
  const [isSlider, setIsSlider] = useState(false);
  const [isSliderOverlap, setIsSliderOverlap] = useState(false);
  const [isSliderFlip3D, setIsSliderFlip3D] = useState(false);
  const [isSliderZoomout, setIsSliderZoomout] = useState(false);
  const [isRounded, setIsRounded] = useState(false);
  const [isThemeSelected, setIsThemeSelected] = useState("theme1");
  const [selected, setSelected] = useState("");
  const [isActive, setActive] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [color1, setColor1] = useState();
  const [templateID, setTemplateID] = useState();

  /**
   * Theme is a property for all but grid and grid3d
   * anymationType only for slider types (type = 2)
   *
   */
  const [selectedConfig, setSelectedConfig] = useState({
    grid: {
      isRounded: false,
      hasShadow: true,
      type: 0,
      isCustom: false,
      mainColor: "#f9a1bc",
    },
    grid3d: {
      isRounded: false,
      hasShadow: true,
      type: 1,
      isCustom: false,
      mainColor: "#f9a1bc",
    },
    linear: {
      isRounded: false,
      hasShadow: true,
      theme: 1,
      type: 2,
      animationType: 0,
      isCustom: false,
      mainColor: "#f9a1bc",
    },
    flip3d: {
      isRounded: false,
      hasShadow: true,
      theme: 1,
      type: 2,
      animationType: 2,
      isCustom: false,
      mainColor: "#f9a1bc",
    },
    overlap: {
      isRounded: false,
      hasShadow: true,
      theme: 1,
      type: 2,
      animationType: 1,
      isCustom: false,
      mainColor: "#f9a1bc",
    },
    zoomout: {
      isRounded: false,
      hasShadow: true,
      theme: 1,
      type: 2,
      animationType: 3,
      isCustom: false,
      mainColor: "#f9a1bc",
    },
    tinder: {
      isRounded: false,
      hasShadow: true,
      theme: 0,
      type: 3,
      isCustom: false,
      mainColor: "#f9a1bc",
    },
  });
  const [configToSave, setConfigToSave] = useState([]);

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  useEffect(() => {
    // This function is run whenever `isActive` changes
    console.log("isActive has changed:", isActive);
    updateColorComponent();
  }, [isActive]); // Dependency array. Only re-run the effect if `isActive` changes.

  useEffect(() => {
    updateSavedData();
  }, [isRounded, isThemeSelected, selected, isCustom]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);

    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchProductListListData(storeKey, templateID)
      .then((response) => {
        // Here's the change
        const data = response.data;
        const colorData = data.color;
        const listData = data.listData;

        console.log("colorData", colorData);
        console.log("listData", listData);

        if (colorData) {
          setColor1(colorData.mainColor);
        }
        if (listData) {
          mapSavedDataToCurrentState(listData);
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching navBar data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const updateConfig = async () => {
    setIsSaving(true);

    // API
    updateProductListListData(storeKey, templateID, configToSave)
      .then((response) => {
        console.log("response", response);
        updateColorComponent();
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateProductListListData:", error);
      });
  };

  const updateColorComponent = () => {
    if (selectedConfig && selectedConfig[selected]) {
      const config = { ...selectedConfig[selected] }; // Create a copy of config

      if (isActive === false) {
        setIsColor(color1);
        config.mainColor = color1; // Change copy of config
        config.isCustom = false;
        setSelectedConfig((prevConfig) => ({
          ...prevConfig, // Keep rest of the object
          [selected]: config, // Update only selected config
        }));
      } else {
        config.mainColor = isColor;
        config.isCustom = true;
        setSelectedConfig((prevConfig) => ({
          ...prevConfig, // Keep rest of the object
          [selected]: config, // Update only selected config
        }));
      }
    }
  };

  const updateSavedData = () => {
    const savedData = configToSave;
    savedData.forEach((element) => {
      // console.log("element", element)
      switch (element.type) {
        case 0:
          addConfig("grid");
          break;
        case 1:
          addConfig("grid3d");
          break;
        case 2:
          switch (element.animationType) {
            case 0:
              addConfig("linear");
              break;
            case 1:
              addConfig("overlap");
              break;
            case 2:
              addConfig("flip3d");
              break;
            case 3:
              addConfig("zoomout");
              break;
            default:
              break;
          }
          break;
        case 3:
          addConfig("tinder");
          break;
        default:
          break;
      }
    });
    console.log("updateSavedData configToSave:", configToSave);
  };

  const mapSavedDataToCurrentState = (savedData) => {
    const tempSelectedConfig = selectedConfig;
    let lastSavedItem = "";
    savedData.forEach((element) => {
      console.log("element", element);
      switch (element.type) {
        case 0:
          // console.log("grid");
          addConfig("grid");
          setIsGrid(true);
          lastSavedItem = "grid";
          tempSelectedConfig.grid.isRounded = element.isRounded;
          tempSelectedConfig.grid.mainColor = element.mainColor;
          tempSelectedConfig.grid.isCustom = element.isCustom;
          break;
        case 1:
          // console.log("grid3d");
          addConfig("grid3d");
          setIsGrid3D(true);
          lastSavedItem = "grid3d";
          tempSelectedConfig.grid3d.isRounded = element.isRounded;
          tempSelectedConfig.grid3d.mainColor = element.mainColor;
          tempSelectedConfig.grid3d.isCustom = element.isCustom;
          break;
        case 2:
          switch (element.animationType) {
            case 0:
              // console.log("linear");
              addConfig("linear");
              setIsSlider(true);
              lastSavedItem = "linear";
              tempSelectedConfig.linear.isRounded = element.isRounded;
              tempSelectedConfig.linear.mainColor = element.mainColor;
              tempSelectedConfig.linear.theme = element.theme;
              tempSelectedConfig.linear.isCustom = element.isCustom;
              break;
            case 1:
              // console.log("overlap");
              addConfig("overlap");
              setIsSliderOverlap(true);
              lastSavedItem = "overlap";
              tempSelectedConfig.overlap.isRounded = element.isRounded;
              tempSelectedConfig.overlap.mainColor = element.mainColor;
              tempSelectedConfig.overlap.theme = element.theme;
              tempSelectedConfig.overlap.isCustom = element.isCustom;
              break;
            case 2:
              // console.log("flip3d");
              addConfig("flip3d");
              setIsSliderFlip3D(true);
              lastSavedItem = "flip3d";
              tempSelectedConfig.flip3d.isRounded = element.isRounded;
              tempSelectedConfig.flip3d.mainColor = element.mainColor;
              tempSelectedConfig.flip3d.theme = element.theme;
              tempSelectedConfig.flip3d.isCustom = element.isCustom;
              break;
            case 3:
              // console.log("zoomout");
              addConfig("zoomout");
              setIsSliderZoomout(true);
              lastSavedItem = "zoomout";
              tempSelectedConfig.zoomout.isRounded = element.isRounded;
              tempSelectedConfig.zoomout.mainColor = element.mainColor;
              tempSelectedConfig.zoomout.theme = element.theme;
              tempSelectedConfig.zoomout.isCustom = element.isCustom;
              break;
            default:
              break;
          }
          break;
        case 3:
          // console.log("tinder");
          // console.log("element.mainColor", element.mainColor);
          // console.log("element.isCustom", element.isCustom);
          addConfig("tinder");
          setIsTinder(true);
          lastSavedItem = "tinder";
          tempSelectedConfig.tinder.isRounded = element.isRounded;
          tempSelectedConfig.tinder.mainColor = element.mainColor;
          tempSelectedConfig.tinder.theme = element.theme;
          tempSelectedConfig.tinder.isCustom = element.isCustom;
          break;
        default:
          break;
      }
    });
    setSelectedConfig(tempSelectedConfig);
    handleOnClickListThemeItem(lastSavedItem);
  };

  const getTheme = () => {
    const rounded = isRounded ? "rounded" : "square";
    if (selected === "grid" || selected === "grid3d") {
      return selected + "_" + rounded;
    } else {
      return selected + "_" + rounded + "_" + isThemeSelected;
    }
  };

  const getSelectedTheme = (config) => {
    if (config.theme) {
      return "theme" + config.theme;
    }
    return null;
  };

  const addConfig = (type) => {
    const tempConfig = configToSave;
    const configToAdd = selectedConfig[type];
    const foundConfigIndex = tempConfig.findIndex((c) => {
      if (configToAdd.type === 2) {
        return (
          c.type === configToAdd.type &&
          c.animationType === configToAdd.animationType
        );
      }
      return c.type === configToAdd.type;
    });
    if (foundConfigIndex !== -1) {
      tempConfig[foundConfigIndex] = configToAdd;
    } else {
      tempConfig.push(configToAdd);
    }
    setConfigToSave(tempConfig);
  };

  const removeConfig = (type) => {
    const tempConfig = configToSave;
    const configToRemove = selectedConfig[type];
    const foundConfigIndex = tempConfig.findIndex((c) => {
      if (configToRemove.type === 2) {
        return (
          c.type === configToRemove.type &&
          c.animationType === configToRemove.animationType
        );
      }
      return c.type === configToRemove.type;
    });
    configToSave.splice(foundConfigIndex, 1);
    setConfigToSave(configToSave);
  };

  const handleOnEnableDisable = (e) => {
    console.log("handleOnEnableDisable");
    switch (e.target.id) {
      case "grid":
        if (!isGrid) {
          addConfig("grid");
        } else {
          removeConfig("grid");
        }
        setIsGrid(!isGrid);
        break;
      case "grid3d":
        if (!isGrid3D) {
          addConfig("grid3d");
        } else {
          removeConfig("grid3d");
        }
        setIsGrid3D(!isGrid3D);
        break;
      case "tinder":
        if (!isTinder) {
          addConfig("tinder");
        } else {
          removeConfig("tinder");
        }
        setIsTinder(!isTinder);
        break;
      case "slider":
        if (!isSlider) {
          addConfig("linear");
        } else {
          removeConfig("linear");
        }
        setIsSlider(!isSlider);
        break;
      case "sliderFlip":
        if (!isSliderFlip3D) {
          addConfig("flip3d");
        } else {
          removeConfig("flip3d");
        }
        setIsSliderFlip3D(!isSliderFlip3D);
        break;
      case "sliderOverlap":
        if (!isSliderOverlap) {
          addConfig("overlap");
        } else {
          removeConfig("overlap");
        }
        setIsSliderOverlap(!isSliderOverlap);
        break;
      case "sliderZoomOut":
        if (!isSliderZoomout) {
          addConfig("zoomout");
        } else {
          removeConfig("zoomout");
        }
        setIsSliderZoomout(!isSliderZoomout);
        break;
      default:
        break;
    }
  };

  const handleOnClickListThemeItem = (id) => {
    console.log("handleOnClickListThemeItem");

    const config = selectedConfig[id];
    setIsRounded(config.isRounded);

    if (config.isCustom === true) {
      setIsColor(config.mainColor);
      setActive(true);
    } else {
      if (color1) {
        setIsColor(color1);
      }
      setActive(false);
    }
    setSelected(id);

    const theme = getSelectedTheme(config);
    if (theme) {
      setIsThemeSelected(theme);
    }
  };

  const handleOnRounded = () => {
    const config = selectedConfig[selected];
    config.isRounded = !isRounded;
    selectedConfig[selected] = config;
    setSelectedConfig(selectedConfig);
    setIsRounded(!isRounded);
  };

  const handleOnSelectTheme = (e) => {
    const config = selectedConfig[selected];
    config.theme = Number(e.target.value.slice(-1));
    selectedConfig[selected] = config;
    setSelectedConfig(selectedConfig);
    setIsThemeSelected(e.target.value);
  };

  const handleToggle = (e) => {
    const config = selectedConfig[selected];
    config.isCustom = !isActive;
    selectedConfig[selected] = config;
    setSelectedConfig(selectedConfig);
    setIsCustom(!isActive);
    setActive(!isActive);
  };

  const colorChange = (e) => {
    const config = selectedConfig[selected];
    config.mainColor = e.target.value;
    config.isCustom = true;
    selectedConfig[selected] = config;
    setSelectedConfig(selectedConfig);
    setIsCustom(true);
    setIsColor(e.target.value);
    setActive(true);
  };

  const themeVideos = {
    tinder: {
      theme1: "./sidevideos/producListTinder1.mp4",
      theme2: "./sidevideos/producListTinder2.mp4",
      theme3: "./sidevideos/producListTinder3.mp4",
      // ... add other themes as needed
    },
    linear: {
      theme1: "./sidevideos/productListLinear1.mp4",
      theme2: "./sidevideos/productListLinear2.mp4",
      theme3: "./sidevideos/productListLinear3.mp4",
      // ... add other themes as needed
    },
    flip3d: {
      theme1: "./sidevideos/productListflip1.mp4",
      theme2: "./sidevideos/productListflip2.mp4",
      theme3: "./sidevideos/productListflip3.mp4",
    },
    overlap: {
      theme1: "./sidevideos/productListOverlap1.mp4",
      theme2: "./sidevideos/productListOverlap2.mp4",
      theme3: "./sidevideos/productListOverlap3.mp4",
    },
    zoomout: {
      theme1: "./sidevideos/productListZoomout1.mp4",
      theme2: "./sidevideos/productListZoomout2.mp4",
      theme3: "./sidevideos/productListZoomout3.mp4",
    },
  };

  const themes = ["theme1", "theme2", "theme3"];

  return (
    <section className="--list productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Loading...</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Saving...</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">Product lists:</h2>
          <nav
            className="componentNav"
            style={{ position: "absolute", top: "8px", left: "235px" }}
          >
            <ul className="componentList">
              <li className="itemList">
                <Link to="/lists" style={{ color: "#6D7A8B" }}>
                  HEADER
                </Link>
              </li>
              <li className="itemList">
                <Link to="/lists-navbar" style={{ color: "#6D7A8B" }}>
                  MENU
                </Link>
              </li>
              <li className="itemList active">
                LISTS
                <BoxText handleSaveButton={updateConfig}>
                Activate one or more product views and adjust them to your brand’s identity. Your users can choose the view they like the most.
                </BoxText>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Lists’s Themes:</h2>
          <div
            className="row settings radioCustom"
            style={{ marginLeft: "10px" }}
          >
            <RadioButtonSlideIcon
              label="GRID"
              id="grid"
              isActive={isGrid}
              onClick={handleOnEnableDisable}
              isSelected={selected === "grid"}
              onSelect={() => handleOnClickListThemeItem("grid")}
              title="Product List - Grid"
              description=""
              video="./sidevideos/producListGrid.mp4"
            />
            <RadioButtonSlideIcon
              label="GRID 3D"
              id="grid3d"
              isActive={isGrid3D}
              onClick={handleOnEnableDisable}
              isSelected={selected === "grid3d"}
              onSelect={() => handleOnClickListThemeItem("grid3d")}
              title="Product List - Grid 3D"
              description=""
              video="./sidevideos/producListGrid3D.mp4"
            />
            <RadioButtonSlideIcon
              label="TINDER"
              id="tinder"
              isActive={isTinder}
              onClick={handleOnEnableDisable}
              isSelected={selected === "tinder"}
              onSelect={() => handleOnClickListThemeItem("tinder")}
              title="Tinde Theme"
              description=""
              video="./sidevideos/producListTinder2.mp4"
            />
            <RadioButtonSlideIcon
              label="SLIDER"
              id="slider"
              isActive={isSlider}
              onClick={handleOnEnableDisable}
              isSelected={selected === "linear"}
              onSelect={() => handleOnClickListThemeItem("linear")}
              title="Slider"
              description="description"
              video="./sidevideos/productListLinear2.mp4"
            />
            <RadioButtonSlideIcon
              label="SLIDER FLIP 3D"
              id="sliderFlip"
              isActive={isSliderFlip3D}
              onClick={handleOnEnableDisable}
              isSelected={selected === "flip3d"}
              onSelect={() => handleOnClickListThemeItem("flip3d")}
              title="Slider Flip 3D"
              description="description"
              video="./sidevideos/productListflip2.mp4"
            />
            <RadioButtonSlideIcon
              label="SLIDER OVERLAP"
              id="sliderOverlap"
              isActive={isSliderOverlap}
              onClick={handleOnEnableDisable}
              isSelected={selected === "overlap"}
              onSelect={() => handleOnClickListThemeItem("overlap")}
              title="Slider Overlap"
              description="description"
              video="./sidevideos/productListOverlap2.mp4"
            />
            <RadioButtonSlideIcon
              label="SLIDER ZOOM OUT"
              id="sliderZoomOut"
              isActive={isSliderZoomout}
              onClick={handleOnEnableDisable}
              isSelected={selected === "zoomout"}
              onSelect={() => handleOnClickListThemeItem("zoomout")}
              title="Slider Zoom Out"
              description="description"
              video="./sidevideos/productListZoomout2.mp4"
            />
          </div>
          <div
            className="row settings radioCustom --line"
            style={{ width: "46%" }}
          >
            <h2 className="title">Container Settings:</h2>
            <RadioButtonSlide
              label="ROUNDED"
              id="rounded"
              isActive={isRounded}
              onClick={handleOnRounded}
              style={{ marginLeft: "10px" }}
            />

            {selected.length > 0 && !selected.includes("grid") && (
              <div style={{ width: "285px" }}>
                <h2 className="title">Select theme</h2>
                {themes.map((theme, index) => {
                  const themeNumber = index + 1;
                  const videoForTheme =
                    themeVideos[selected] && themeVideos[selected][theme];

                  return (
                    <RadioButtonIcon
                      key={theme}
                      label={`THEME ${themeNumber}`}
                      id={theme}
                      value={theme}
                      onChange={handleOnSelectTheme}
                      check={isThemeSelected}
                      title={theme} // modify if you have specific titles
                      description="" // modify if you have descriptions
                      video={videoForTheme}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="settingsGroup row">
          <h2 className="title">Colors</h2>
          <div
            className="row settings radioCustom --colors"
            style={{ marginTop: "-68px", marginLeft: "135px", width: "70%" }}
          >
            <RadioButtonSlide
              label="CUSTOM COLORS"
              id="customColors"
              isActive={isActive}
              onClick={handleToggle}
            />
            <div className="contentColors">
              <input
                type="text"
                name="textColors"
                className="textColor"
                value={isColor}
                onChange={colorChange}
              />
              <input
                type="color"
                name="colors"
                className="pickColor"
                value={isColor}
                onChange={colorChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <ProductList color={isColor} theme={getTheme()} />
      </div>
    </section>
  );
}

export default ProductLists;
