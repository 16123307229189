import React from "react";

const ProductList = (props) => {
  switch (props.theme) {
    case "grid_square": // GRID
      return <ProductListGridSquare color={props.color} />;
    case "grid_rounded": // GRID
      return <ProductListGridRounded color={props.color} />;
    case "grid3d_square": // GRID 3D
      return <ProductListGrid3DSquare color={props.color} />;
    case "grid3d_rounded": // GRID 3D
      return <ProductListGrid3DRounded color={props.color} />;
    case "flip3d_rounded_theme1": // SLIDER FLIP 3D
      return <ProductListSliderFlip3DRoundedTheme1 color={props.color} />;
    case "flip3d_rounded_theme2": // SLIDER FLIP 3D
      return <ProductListSliderFlip3DRoundedTheme2 color={props.color} />;
    case "flip3d_rounded_theme3": // SLIDER FLIP 3D
      return <ProductListSliderFlip3DRoundedTheme3 color={props.color} />;
    case "flip3d_square_theme1": // SLIDER FLIP 3D
      return <ProductListSliderFlip3DSquareTheme1 color={props.color} />;
    case "flip3d_square_theme2": // SLIDER FLIP 3D
      return <ProductListSliderFlip3DSquareTheme2 color={props.color} />;
    case "flip3d_square_theme3": // SLIDER FLIP 3D
      return <ProductListSliderFlip3DSquareTheme3 color={props.color} />;
    case "linear_rounded_theme1": // SLIDER
      return <ProductListSliderLinearRoundedTheme1 color={props.color} />;
    case "linear_rounded_theme2": // SLIDER
      return <ProductListSliderLinearRoundedTheme2 color={props.color} />;
    case "linear_rounded_theme3": // SLIDER
      return <ProductListSliderLinearRoundedTheme3 color={props.color} />;
    case "linear_square_theme1": // SLIDER
      return <ProductListSliderLinearSquareTheme1 color={props.color} />;
    case "linear_square_theme2": // SLIDER
      return <ProductListSliderLinearSquareTheme2 color={props.color} />;
    case "linear_square_theme3": // SLIDER
      return <ProductListSliderLinearSquareTheme3 color={props.color} />;
    case "overlap_rounded_theme1": // SLIDER OVERLAP
      return <ProductListSliderOverlapRoundedTheme1 color={props.color} />;
    case "overlap_rounded_theme2": // SLIDER OVERLAP
      return <ProductListSliderOverlapRoundedTheme2 color={props.color} />;
    case "overlap_rounded_theme3": // SLIDER OVERLAP
      return <ProductListSliderOverlapRoundedTheme3 color={props.color} />;
    case "overlap_square_theme1": // SLIDER OVERLAP
      return <ProductListSliderOverlapSquareTheme1 color={props.color} />;
    case "overlap_square_theme2": // SLIDER OVERLAP
      return <ProductListSliderOverlapSquareTheme2 color={props.color} />;
    case "overlap_square_theme3": // SLIDER OVERLAP
      return <ProductListSliderOverlapSquareTheme3 color={props.color} />;
    case "zoomout_rounded_theme1": // SLIDER ZOOMOUT
      return <ProductListSliderZoomOutRoundedTheme1 color={props.color} />;
    case "zoomout_rounded_theme2": // SLIDER ZOOMOUT
      return <ProductListSliderZoomOutRoundedTheme2 color={props.color} />;
    case "zoomout_rounded_theme3": // SLIDER ZOOMOUT
      return <ProductListSliderZoomOutRoundedTheme3 color={props.color} />;
    case "zoomout_square_theme1": // SLIDER ZOOMOUT
      return <ProductListSliderZoomOutSquareTheme1 color={props.color} />;
    case "zoomout_square_theme2": // SLIDER ZOOMOUT
      return <ProductListSliderZoomOutSquareTheme2 color={props.color} />;
    case "zoomout_square_theme3": // SLIDER ZOOMOUT
      return <ProductListSliderZoomOutSquareTheme3 color={props.color} />;
    case "tinder_rounded_theme1": // TINDER
      return <ProductListTinderRoundedTheme1 color={props.color} />;
    case "tinder_rounded_theme2": // TINDER
      return <ProductListTinderRoundedTheme2 color={props.color} />;
    case "tinder_rounded_theme3": // TINDER
      return <ProductListTinderRoundedTheme3 color={props.color} />;
    case "tinder_square_theme1": // TINDER
      return <ProductListTinderSquareTheme1 color={props.color} />;
    case "tinder_square_theme2": // TINDER
      return <ProductListTinderSquareTheme2 color={props.color} />;
    case "tinder_square_theme3": // TINDER
      return <ProductListTinderSquareTheme3 color={props.color} />;
    default:
      return <ProductListGridSquare color={props.color} />;
  }
};

// GRID
const ProductListGridSquare = (props) => {
  return (
    <div className="ProductListGridSquare">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 28 product list grid sq" clipPath="url(#clip0_76_7556)">
          <rect width="379" height="664" fill="white" />
          <g id="prev comp card vertical">
            <g id="prev comp bg1">
              <g id="Rectangle 1" filter="url(#filter0_d_76_7556)">
                <rect x="20" y="19" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7556)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M152.315 111.705C151.871 110.943 151.224 110.295 150.424 109.838L105.591 88.6857C103.989 87.7714 102.021 87.7714 100.419 88.6857L55.5862 109.838C54.7852 110.295 54.1387 110.943 53.6948 111.705L103.005 139.8L152.315 111.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M103.005 196C103.902 196 104.79 195.771 105.591 195.314L146.863 169.771C148.465 168.857 149.449 167.171 149.449 165.343L153.01 114.257C153.01 113.343 152.759 112.467 152.315 111.705L103.005 139.8V196Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M100.419 195.314C101.22 195.771 102.117 196 103.005 196V139.809L53.6948 111.714C53.2509 112.467 53 113.352 53 114.257L56.3388 165.343C56.3388 167.171 57.3231 168.857 58.9249 169.771L100.419 195.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M125.614 98.0572L81.0807 124.257L103.005 139.8L53.6948 111.705L78.9771 122.733L123.858 97.3143L125.614 98.0572Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49"
              x="34"
              y="258"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1"
              x="34"
              y="283"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_2">
            <g id="prev comp bg1_2">
              <g id="Rectangle 1_2" filter="url(#filter1_d_76_7556)">
                <rect x="20" y="336" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product_2" clipPath="url(#clip2_76_7556)">
              <g id="Comp icon - Product_2">
                <g id="Group_2">
                  <path
                    id="Vector_5"
                    d="M152.315 428.705C151.871 427.943 151.224 427.295 150.424 426.838L105.591 405.686C103.989 404.771 102.021 404.771 100.419 405.686L55.5862 426.838C54.7852 427.295 54.1387 427.943 53.6948 428.705L103.005 456.8L152.315 428.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_6"
                    d="M103.005 513C103.902 513 104.79 512.771 105.591 512.314L146.863 486.771C148.465 485.857 149.449 484.171 149.449 482.343L153.01 431.257C153.01 430.343 152.759 429.467 152.315 428.705L103.005 456.8V513Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_7"
                    d="M100.419 512.314C101.22 512.771 102.117 513 103.005 513V456.81L53.6948 428.714C53.2509 429.467 53 430.352 53 431.257L56.3388 482.343C56.3388 484.171 57.3231 485.857 58.9249 486.771L100.419 512.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_8"
                    d="M125.614 415.057L81.0807 441.257L103.005 456.8L53.6948 428.705L78.9771 439.733L123.858 414.314L125.614 415.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_2"
              x="34"
              y="575"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_2"
              x="34"
              y="600"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_3">
            <g id="prev comp bg1_3">
              <g id="Rectangle 1_3" filter="url(#filter2_d_76_7556)">
                <rect x="196" y="19" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product_3" clipPath="url(#clip3_76_7556)">
              <g id="Comp icon - Product_3">
                <g id="Group_3">
                  <path
                    id="Vector_9"
                    d="M328.315 111.705C327.871 110.943 327.224 110.295 326.424 109.838L281.591 88.6857C279.989 87.7714 278.021 87.7714 276.419 88.6857L231.586 109.838C230.785 110.295 230.139 110.943 229.695 111.705L279.005 139.8L328.315 111.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_10"
                    d="M279.005 196C279.902 196 280.79 195.771 281.591 195.314L322.863 169.771C324.465 168.857 325.449 167.171 325.449 165.343L329.01 114.257C329.01 113.343 328.759 112.467 328.315 111.705L279.005 139.8V196Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_11"
                    d="M276.419 195.314C277.22 195.771 278.117 196 279.005 196V139.809L229.695 111.714C229.251 112.467 229 113.352 229 114.257L232.339 165.343C232.339 167.171 233.323 168.857 234.925 169.771L276.419 195.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_12"
                    d="M301.614 98.0572L257.081 124.257L279.005 139.8L229.695 111.705L254.977 122.733L299.858 97.3143L301.614 98.0572Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_3"
              x="210"
              y="258"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_3"
              x="210"
              y="283"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_4">
            <g id="prev comp bg1_4">
              <g id="Rectangle 1_4" filter="url(#filter3_d_76_7556)">
                <rect x="196" y="336" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product_4" clipPath="url(#clip4_76_7556)">
              <g id="Comp icon - Product_4">
                <g id="Group_4">
                  <path
                    id="Vector_13"
                    d="M328.315 428.705C327.871 427.943 327.224 427.295 326.424 426.838L281.591 405.686C279.989 404.771 278.021 404.771 276.419 405.686L231.586 426.838C230.785 427.295 230.139 427.943 229.695 428.705L279.005 456.8L328.315 428.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_14"
                    d="M279.005 513C279.902 513 280.79 512.771 281.591 512.314L322.863 486.771C324.465 485.857 325.449 484.171 325.449 482.343L329.01 431.257C329.01 430.343 328.759 429.467 328.315 428.705L279.005 456.8V513Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_15"
                    d="M276.419 512.314C277.22 512.771 278.117 513 279.005 513V456.81L229.695 428.714C229.251 429.467 229 430.352 229 431.257L232.339 482.343C232.339 484.171 233.323 485.857 234.925 486.771L276.419 512.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_16"
                    d="M301.614 415.057L257.081 441.257L279.005 456.8L229.695 428.705L254.977 439.733L299.858 414.314L301.614 415.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_4"
              x="210"
              y="575"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_4"
              x="210"
              y="600"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7556"
            x="1"
            y="0"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7556"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7556"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7556"
            x="1"
            y="317"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7556"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7556"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7556"
            x="177"
            y="0"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7556"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7556"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7556"
            x="177"
            y="317"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7556"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7556"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7556">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7556">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 88)"
            />
          </clipPath>
          <clipPath id="clip2_76_7556">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 405)"
            />
          </clipPath>
          <clipPath id="clip3_76_7556">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 88)"
            />
          </clipPath>
          <clipPath id="clip4_76_7556">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 405)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListGridRounded = (props) => {
  return (
    <div className="ProductListGridRounded">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 29 product list grid rd" clipPath="url(#clip0_76_7557)">
          <rect width="379" height="664" fill="white" />
          <g id="prev comp card vertical">
            <g id="prev comp bg1">
              <g id="Rectangle 1" filter="url(#filter0_d_76_7557)">
                <rect
                  x="20"
                  y="19"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7557)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M152.315 111.705C151.871 110.943 151.224 110.295 150.424 109.838L105.591 88.6857C103.989 87.7714 102.021 87.7714 100.419 88.6857L55.5862 109.838C54.7852 110.295 54.1387 110.943 53.6948 111.705L103.005 139.8L152.315 111.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M103.005 196C103.902 196 104.79 195.771 105.591 195.314L146.863 169.771C148.465 168.857 149.449 167.171 149.449 165.343L153.01 114.257C153.01 113.343 152.759 112.467 152.315 111.705L103.005 139.8V196Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M100.419 195.314C101.22 195.771 102.117 196 103.005 196V139.809L53.6948 111.714C53.2509 112.467 53 113.352 53 114.257L56.3388 165.343C56.3388 167.171 57.3231 168.857 58.9249 169.771L100.419 195.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M125.614 98.0572L81.0807 124.257L103.005 139.8L53.6948 111.705L78.9771 122.733L123.858 97.3143L125.614 98.0572Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49"
              x="34"
              y="258"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1"
              x="34"
              y="283"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_2">
            <g id="prev comp bg1_2">
              <g id="Rectangle 1_2" filter="url(#filter1_d_76_7557)">
                <rect
                  x="20"
                  y="336"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product_2" clipPath="url(#clip2_76_7557)">
              <g id="Comp icon - Product_2">
                <g id="Group_2">
                  <path
                    id="Vector_5"
                    d="M152.315 428.705C151.871 427.943 151.224 427.295 150.424 426.838L105.591 405.686C103.989 404.771 102.021 404.771 100.419 405.686L55.5862 426.838C54.7852 427.295 54.1387 427.943 53.6948 428.705L103.005 456.8L152.315 428.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_6"
                    d="M103.005 513C103.902 513 104.79 512.771 105.591 512.314L146.863 486.771C148.465 485.857 149.449 484.171 149.449 482.343L153.01 431.257C153.01 430.343 152.759 429.467 152.315 428.705L103.005 456.8V513Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_7"
                    d="M100.419 512.314C101.22 512.771 102.117 513 103.005 513V456.81L53.6948 428.714C53.2509 429.467 53 430.352 53 431.257L56.3388 482.343C56.3388 484.171 57.3231 485.857 58.9249 486.771L100.419 512.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_8"
                    d="M125.614 415.057L81.0807 441.257L103.005 456.8L53.6948 428.705L78.9771 439.733L123.858 414.314L125.614 415.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_2"
              x="34"
              y="575"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_2"
              x="34"
              y="600"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_3">
            <g id="prev comp bg1_3">
              <g id="Rectangle 1_3" filter="url(#filter2_d_76_7557)">
                <rect
                  x="196"
                  y="19"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product_3" clipPath="url(#clip3_76_7557)">
              <g id="Comp icon - Product_3">
                <g id="Group_3">
                  <path
                    id="Vector_9"
                    d="M328.315 111.705C327.871 110.943 327.224 110.295 326.424 109.838L281.591 88.6857C279.989 87.7714 278.021 87.7714 276.419 88.6857L231.586 109.838C230.785 110.295 230.139 110.943 229.695 111.705L279.005 139.8L328.315 111.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_10"
                    d="M279.005 196C279.902 196 280.79 195.771 281.591 195.314L322.863 169.771C324.465 168.857 325.449 167.171 325.449 165.343L329.01 114.257C329.01 113.343 328.759 112.467 328.315 111.705L279.005 139.8V196Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_11"
                    d="M276.419 195.314C277.22 195.771 278.117 196 279.005 196V139.809L229.695 111.714C229.251 112.467 229 113.352 229 114.257L232.339 165.343C232.339 167.171 233.323 168.857 234.925 169.771L276.419 195.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_12"
                    d="M301.614 98.0572L257.081 124.257L279.005 139.8L229.695 111.705L254.977 122.733L299.858 97.3143L301.614 98.0572Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_3"
              x="210"
              y="258"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_3"
              x="210"
              y="283"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_4">
            <g id="prev comp bg1_4">
              <g id="Rectangle 1_4" filter="url(#filter3_d_76_7557)">
                <rect
                  x="196"
                  y="336"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product_4" clipPath="url(#clip4_76_7557)">
              <g id="Comp icon - Product_4">
                <g id="Group_4">
                  <path
                    id="Vector_13"
                    d="M328.315 428.705C327.871 427.943 327.224 427.295 326.424 426.838L281.591 405.686C279.989 404.771 278.021 404.771 276.419 405.686L231.586 426.838C230.785 427.295 230.139 427.943 229.695 428.705L279.005 456.8L328.315 428.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_14"
                    d="M279.005 513C279.902 513 280.79 512.771 281.591 512.314L322.863 486.771C324.465 485.857 325.449 484.171 325.449 482.343L329.01 431.257C329.01 430.343 328.759 429.467 328.315 428.705L279.005 456.8V513Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_15"
                    d="M276.419 512.314C277.22 512.771 278.117 513 279.005 513V456.81L229.695 428.714C229.251 429.467 229 430.352 229 431.257L232.339 482.343C232.339 484.171 233.323 485.857 234.925 486.771L276.419 512.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_16"
                    d="M301.614 415.057L257.081 441.257L279.005 456.8L229.695 428.705L254.977 439.733L299.858 414.314L301.614 415.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_4"
              x="210"
              y="575"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_4"
              x="210"
              y="600"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7557"
            x="1"
            y="0"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7557"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7557"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7557"
            x="1"
            y="317"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7557"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7557"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7557"
            x="177"
            y="0"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7557"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7557"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7557"
            x="177"
            y="317"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7557"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7557"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7557">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7557">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 88)"
            />
          </clipPath>
          <clipPath id="clip2_76_7557">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 405)"
            />
          </clipPath>
          <clipPath id="clip3_76_7557">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 88)"
            />
          </clipPath>
          <clipPath id="clip4_76_7557">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 405)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// GRID 3D
const ProductListGrid3DSquare = (props) => {
  return (
    <div className="ProductListGridSquare">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 30 product list grid sq 3d"
          clipPath="url(#clip0_76_7558)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp card vertical">
            <g id="prev comp bg1">
              <g id="Rectangle 1" filter="url(#filter0_d_76_7558)">
                <rect x="40" y="17" width="128" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_2">
              <g id="Rectangle 1_2" filter="url(#filter1_d_76_7558)">
                <rect x="30" y="26" width="148" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_3">
              <g id="Rectangle 1_3" filter="url(#filter2_d_76_7558)">
                <rect x="20" y="36" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7558)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M152.315 128.705C151.871 127.943 151.224 127.295 150.424 126.838L105.591 105.686C103.989 104.771 102.021 104.771 100.419 105.686L55.5862 126.838C54.7852 127.295 54.1387 127.943 53.6948 128.705L103.005 156.8L152.315 128.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M103.005 213C103.902 213 104.79 212.771 105.591 212.314L146.863 186.771C148.465 185.857 149.449 184.171 149.449 182.343L153.01 131.257C153.01 130.343 152.759 129.467 152.315 128.705L103.005 156.8V213Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M100.419 212.314C101.22 212.771 102.117 213 103.005 213V156.809L53.6948 128.714C53.2509 129.467 53 130.352 53 131.257L56.3388 182.343C56.3388 184.171 57.3231 185.857 58.9249 186.771L100.419 212.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M125.614 115.057L81.0807 141.257L103.005 156.8L53.6948 128.705L78.9771 139.733L123.858 114.314L125.614 115.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49"
              x="34"
              y="275"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1"
              x="34"
              y="300"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_2">
            <g id="prev comp bg1_4">
              <g id="Rectangle 1_4" filter="url(#filter3_d_76_7558)">
                <rect x="40" y="363" width="128" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_5">
              <g id="Rectangle 1_5" filter="url(#filter4_d_76_7558)">
                <rect x="30" y="372" width="148" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_6">
              <g id="Rectangle 1_6" filter="url(#filter5_d_76_7558)">
                <rect x="20" y="382" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product_2" clipPath="url(#clip2_76_7558)">
              <g id="Comp icon - Product_2">
                <g id="Group_2">
                  <path
                    id="Vector_5"
                    d="M152.315 474.705C151.871 473.943 151.224 473.295 150.424 472.838L105.591 451.686C103.989 450.771 102.021 450.771 100.419 451.686L55.5862 472.838C54.7852 473.295 54.1387 473.943 53.6948 474.705L103.005 502.8L152.315 474.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_6"
                    d="M103.005 559C103.902 559 104.79 558.771 105.591 558.314L146.863 532.771C148.465 531.857 149.449 530.171 149.449 528.343L153.01 477.257C153.01 476.343 152.759 475.467 152.315 474.705L103.005 502.8V559Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_7"
                    d="M100.419 558.314C101.22 558.771 102.117 559 103.005 559V502.81L53.6948 474.714C53.2509 475.467 53 476.352 53 477.257L56.3388 528.343C56.3388 530.171 57.3231 531.857 58.9249 532.771L100.419 558.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_8"
                    d="M125.614 461.057L81.0807 487.257L103.005 502.8L53.6948 474.705L78.9771 485.733L123.858 460.314L125.614 461.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_2"
              x="34"
              y="621"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_2"
              x="34"
              y="646"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_3">
            <g id="prev comp bg1_7">
              <g id="Rectangle 1_7" filter="url(#filter6_d_76_7558)">
                <rect x="216" y="17" width="128" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_8">
              <g id="Rectangle 1_8" filter="url(#filter7_d_76_7558)">
                <rect x="206" y="26" width="148" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_9">
              <g id="Rectangle 1_9" filter="url(#filter8_d_76_7558)">
                <rect x="196" y="36" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product_3" clipPath="url(#clip3_76_7558)">
              <g id="Comp icon - Product_3">
                <g id="Group_3">
                  <path
                    id="Vector_9"
                    d="M328.315 128.705C327.871 127.943 327.224 127.295 326.424 126.838L281.591 105.686C279.989 104.771 278.021 104.771 276.419 105.686L231.586 126.838C230.785 127.295 230.139 127.943 229.695 128.705L279.005 156.8L328.315 128.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_10"
                    d="M279.005 213C279.902 213 280.79 212.771 281.591 212.314L322.863 186.771C324.465 185.857 325.449 184.171 325.449 182.343L329.01 131.257C329.01 130.343 328.759 129.467 328.315 128.705L279.005 156.8V213Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_11"
                    d="M276.419 212.314C277.22 212.771 278.117 213 279.005 213V156.809L229.695 128.714C229.251 129.467 229 130.352 229 131.257L232.339 182.343C232.339 184.171 233.323 185.857 234.925 186.771L276.419 212.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_12"
                    d="M301.614 115.057L257.081 141.257L279.005 156.8L229.695 128.705L254.977 139.733L299.858 114.314L301.614 115.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_3"
              x="210"
              y="275"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_3"
              x="210"
              y="300"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_4">
            <g id="prev comp bg1_10">
              <g id="Rectangle 1_10" filter="url(#filter9_d_76_7558)">
                <rect x="216" y="363" width="128" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_11">
              <g id="Rectangle 1_11" filter="url(#filter10_d_76_7558)">
                <rect x="206" y="372" width="148" height="44" fill="white" />
              </g>
            </g>
            <g id="prev comp bg1_12">
              <g id="Rectangle 1_12" filter="url(#filter11_d_76_7558)">
                <rect x="196" y="382" width="166" height="308" fill="white" />
              </g>
            </g>
            <g id="Comp icon product_4" clipPath="url(#clip4_76_7558)">
              <g id="Comp icon - Product_4">
                <g id="Group_4">
                  <path
                    id="Vector_13"
                    d="M328.315 474.705C327.871 473.943 327.224 473.295 326.424 472.838L281.591 451.686C279.989 450.771 278.021 450.771 276.419 451.686L231.586 472.838C230.785 473.295 230.139 473.943 229.695 474.705L279.005 502.8L328.315 474.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_14"
                    d="M279.005 559C279.902 559 280.79 558.771 281.591 558.314L322.863 532.771C324.465 531.857 325.449 530.171 325.449 528.343L329.01 477.257C329.01 476.343 328.759 475.467 328.315 474.705L279.005 502.8V559Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_15"
                    d="M276.419 558.314C277.22 558.771 278.117 559 279.005 559V502.81L229.695 474.714C229.251 475.467 229 476.352 229 477.257L232.339 528.343C232.339 530.171 233.323 531.857 234.925 532.771L276.419 558.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_16"
                    d="M301.614 461.057L257.081 487.257L279.005 502.8L229.695 474.705L254.977 485.733L299.858 460.314L301.614 461.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_4"
              x="210"
              y="621"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_4"
              x="210"
              y="646"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7558"
            x="21"
            y="-2"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7558"
            x="11"
            y="7"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7558"
            x="1"
            y="17"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7558"
            x="21"
            y="344"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_76_7558"
            x="11"
            y="353"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_d_76_7558"
            x="1"
            y="363"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter6_d_76_7558"
            x="197"
            y="-2"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter7_d_76_7558"
            x="187"
            y="7"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter8_d_76_7558"
            x="177"
            y="17"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter9_d_76_7558"
            x="197"
            y="344"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter10_d_76_7558"
            x="187"
            y="353"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <filter
            id="filter11_d_76_7558"
            x="177"
            y="363"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7558"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7558"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7558">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7558">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 105)"
            />
          </clipPath>
          <clipPath id="clip2_76_7558">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 451)"
            />
          </clipPath>
          <clipPath id="clip3_76_7558">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 105)"
            />
          </clipPath>
          <clipPath id="clip4_76_7558">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 451)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListGrid3DRounded = (props) => {
  return (
    <div className="ProductListGridRounded">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 31 product list grid rd 3d"
          clipPath="url(#clip0_76_7559)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp card vertical">
            <g id="prev comp bg1">
              <g id="Rectangle 1" filter="url(#filter0_d_76_7559)">
                <rect
                  x="40"
                  y="17"
                  width="128"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_2">
              <g id="Rectangle 1_2" filter="url(#filter1_d_76_7559)">
                <rect
                  x="30"
                  y="26"
                  width="148"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_3">
              <g id="Rectangle 1_3" filter="url(#filter2_d_76_7559)">
                <rect
                  x="20"
                  y="36"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7559)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M152.315 128.705C151.871 127.943 151.224 127.295 150.424 126.838L105.591 105.686C103.989 104.771 102.021 104.771 100.419 105.686L55.5862 126.838C54.7852 127.295 54.1387 127.943 53.6948 128.705L103.005 156.8L152.315 128.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M103.005 213C103.902 213 104.79 212.771 105.591 212.314L146.863 186.771C148.465 185.857 149.449 184.171 149.449 182.343L153.01 131.257C153.01 130.343 152.759 129.467 152.315 128.705L103.005 156.8V213Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M100.419 212.314C101.22 212.771 102.117 213 103.005 213V156.809L53.6948 128.714C53.2509 129.467 53 130.352 53 131.257L56.3388 182.343C56.3388 184.171 57.3231 185.857 58.9249 186.771L100.419 212.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M125.614 115.057L81.0807 141.257L103.005 156.8L53.6948 128.705L78.9771 139.733L123.858 114.314L125.614 115.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49"
              x="34"
              y="275"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1"
              x="34"
              y="300"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_2">
            <g id="prev comp bg1_4">
              <g id="Rectangle 1_4" filter="url(#filter3_d_76_7559)">
                <rect
                  x="40"
                  y="363"
                  width="128"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_5">
              <g id="Rectangle 1_5" filter="url(#filter4_d_76_7559)">
                <rect
                  x="30"
                  y="372"
                  width="148"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_6">
              <g id="Rectangle 1_6" filter="url(#filter5_d_76_7559)">
                <rect
                  x="20"
                  y="382"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product_2" clipPath="url(#clip2_76_7559)">
              <g id="Comp icon - Product_2">
                <g id="Group_2">
                  <path
                    id="Vector_5"
                    d="M152.315 474.705C151.871 473.943 151.224 473.295 150.424 472.838L105.591 451.686C103.989 450.771 102.021 450.771 100.419 451.686L55.5862 472.838C54.7852 473.295 54.1387 473.943 53.6948 474.705L103.005 502.8L152.315 474.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_6"
                    d="M103.005 559C103.902 559 104.79 558.771 105.591 558.314L146.863 532.771C148.465 531.857 149.449 530.171 149.449 528.343L153.01 477.257C153.01 476.343 152.759 475.467 152.315 474.705L103.005 502.8V559Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_7"
                    d="M100.419 558.314C101.22 558.771 102.117 559 103.005 559V502.81L53.6948 474.714C53.2509 475.467 53 476.352 53 477.257L56.3388 528.343C56.3388 530.171 57.3231 531.857 58.9249 532.771L100.419 558.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_8"
                    d="M125.614 461.057L81.0807 487.257L103.005 502.8L53.6948 474.705L78.9771 485.733L123.858 460.314L125.614 461.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_2"
              x="34"
              y="621"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_2"
              x="34"
              y="646"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_3">
            <g id="prev comp bg1_7">
              <g id="Rectangle 1_7" filter="url(#filter6_d_76_7559)">
                <rect
                  x="216"
                  y="17"
                  width="128"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_8">
              <g id="Rectangle 1_8" filter="url(#filter7_d_76_7559)">
                <rect
                  x="206"
                  y="26"
                  width="148"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_9">
              <g id="Rectangle 1_9" filter="url(#filter8_d_76_7559)">
                <rect
                  x="196"
                  y="36"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product_3" clipPath="url(#clip3_76_7559)">
              <g id="Comp icon - Product_3">
                <g id="Group_3">
                  <path
                    id="Vector_9"
                    d="M328.315 128.705C327.871 127.943 327.224 127.295 326.424 126.838L281.591 105.686C279.989 104.771 278.021 104.771 276.419 105.686L231.586 126.838C230.785 127.295 230.139 127.943 229.695 128.705L279.005 156.8L328.315 128.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_10"
                    d="M279.005 213C279.902 213 280.79 212.771 281.591 212.314L322.863 186.771C324.465 185.857 325.449 184.171 325.449 182.343L329.01 131.257C329.01 130.343 328.759 129.467 328.315 128.705L279.005 156.8V213Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_11"
                    d="M276.419 212.314C277.22 212.771 278.117 213 279.005 213V156.809L229.695 128.714C229.251 129.467 229 130.352 229 131.257L232.339 182.343C232.339 184.171 233.323 185.857 234.925 186.771L276.419 212.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_12"
                    d="M301.614 115.057L257.081 141.257L279.005 156.8L229.695 128.705L254.977 139.733L299.858 114.314L301.614 115.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_3"
              x="210"
              y="275"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_3"
              x="210"
              y="300"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
          <g id="prev comp card vertical_4">
            <g id="prev comp bg1_10">
              <g id="Rectangle 1_10" filter="url(#filter9_d_76_7559)">
                <rect
                  x="216"
                  y="363"
                  width="128"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_11">
              <g id="Rectangle 1_11" filter="url(#filter10_d_76_7559)">
                <rect
                  x="206"
                  y="372"
                  width="148"
                  height="44"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="prev comp bg1_12">
              <g id="Rectangle 1_12" filter="url(#filter11_d_76_7559)">
                <rect
                  x="196"
                  y="382"
                  width="166"
                  height="308"
                  rx="14"
                  fill="white"
                />
              </g>
            </g>
            <g id="Comp icon product_4" clipPath="url(#clip4_76_7559)">
              <g id="Comp icon - Product_4">
                <g id="Group_4">
                  <path
                    id="Vector_13"
                    d="M328.315 474.705C327.871 473.943 327.224 473.295 326.424 472.838L281.591 451.686C279.989 450.771 278.021 450.771 276.419 451.686L231.586 472.838C230.785 473.295 230.139 473.943 229.695 474.705L279.005 502.8L328.315 474.705Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_14"
                    d="M279.005 559C279.902 559 280.79 558.771 281.591 558.314L322.863 532.771C324.465 531.857 325.449 530.171 325.449 528.343L329.01 477.257C329.01 476.343 328.759 475.467 328.315 474.705L279.005 502.8V559Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_15"
                    d="M276.419 558.314C277.22 558.771 278.117 559 279.005 559V502.81L229.695 474.714C229.251 475.467 229 476.352 229 477.257L232.339 528.343C232.339 530.171 233.323 531.857 234.925 532.771L276.419 558.314Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_16"
                    d="M301.614 461.057L257.081 487.257L279.005 502.8L229.695 474.705L254.977 485.733L299.858 460.314L301.614 461.057Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle 49_4"
              x="210"
              y="621"
              width="137"
              height="20"
              rx="5"
              fill="#9694A0"
            />
            <rect
              id="themecolor1_4"
              x="210"
              y="646"
              width="55"
              height="28"
              rx="5"
              fill={props.color}
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7559"
            x="21"
            y="-2"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7559"
            x="11"
            y="7"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7559"
            x="1"
            y="17"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7559"
            x="21"
            y="344"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_76_7559"
            x="11"
            y="353"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_d_76_7559"
            x="1"
            y="363"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter6_d_76_7559"
            x="197"
            y="-2"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter7_d_76_7559"
            x="187"
            y="7"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter8_d_76_7559"
            x="177"
            y="17"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter9_d_76_7559"
            x="197"
            y="344"
            width="166"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter10_d_76_7559"
            x="187"
            y="353"
            width="186"
            height="82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <filter
            id="filter11_d_76_7559"
            x="177"
            y="363"
            width="204"
            height="346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7559"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7559"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7559">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7559">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 105)"
            />
          </clipPath>
          <clipPath id="clip2_76_7559">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(53 451)"
            />
          </clipPath>
          <clipPath id="clip3_76_7559">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 105)"
            />
          </clipPath>
          <clipPath id="clip4_76_7559">
            <rect
              width="100"
              height="108"
              fill="white"
              transform="translate(229 451)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// SLIDER FLIP 3D
const ProductListSliderFlip3DRoundedTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderFlip3DRoundedTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 53 product list 3dflip rd v1"
          clipPath="url(#clip0_76_7581)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7581)">
            <path d="M-10 94L53 114V539L-10 559V94Z" fill="#FBFBFC" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7581)">
            <path d="M319 114L388 94V559L319 539V114Z" fill="#FBFBFC" />
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7581)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 155C68 150.582 71.5817 147 76 147H302C306.418 147 310 150.582 310 155V459C310 463.418 306.418 467 302 467H76C71.5817 467 68 463.418 68 459V155Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7581)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 541C68 538.239 70.2386 536 73 536H305C307.761 536 310 538.239 310 541V565C310 567.761 307.761 570 305 570H73C70.2386 570 68 567.761 68 565V541Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 483C68 480.239 70.2386 478 73 478H305C307.761 478 310 480.239 310 483V496C310 498.761 307.761 501 305 501H73C70.2386 501 68 498.761 68 496V483Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7581"
            x="-29"
            y="75"
            width="101"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7581"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7581"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7581"
            x="300"
            y="75"
            width="107"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7581"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7581"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7581"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7581"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7581"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7581">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7581">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderFlip3DRoundedTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderFlip3DRoundedTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 54 product list 3dflip rd v2"
          clipPath="url(#clip0_76_7582)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7582)">
            <path d="M-10 94L56 114V539L-10 559V94Z" fill="#FBFBFC" />
          </g>
          <path
            id="prev comp fullcard bg_2"
            d="M-10 469L56 459V539L-10 559V469Z"
            fill="#C0BFCA"
          />
          <g id="prev comp fullcard bg_3" filter="url(#filter1_d_76_7582)">
            <path d="M323 114L388 94V559L323 539V114Z" fill="#FBFBFC" />
          </g>
          <path
            id="prev comp fullcard bg_4"
            d="M323 459L388 469V559L323 539V459Z"
            fill="#C0BFCA"
          />
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7582)">
            <rect
              id="prev comp fullcard bg_5"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7582)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 491H335V585C335 589.418 331.418 593 327 593H50C45.5817 593 42 589.418 42 585V491Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 517C129 514.239 131.239 512 134 512H244C246.761 512 249 514.239 249 517V527C249 529.761 246.761 532 244 532H134C131.239 532 129 529.761 129 527V517Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V545Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7582"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7582"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7582"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7582"
            x="304"
            y="75"
            width="103"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7582"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7582"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7582"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7582"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7582"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7582">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7582">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderFlip3DRoundedTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderFlip3DRoundedTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 55 product list 3dflip rd v3"
          clipPath="url(#clip0_76_7583)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7583)">
            <path d="M-10 94L56 114V539L-10 559V94Z" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7583)">
            <path d="M320 114L388 94V163H320V114Z" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_3" filter="url(#filter2_d_76_7583)">
            <path d="M320 480L388 490V559L320 539V480Z" fill="#FBFBFC" />
          </g>
          <path
            id="prev comp fullcard bg_4"
            d="M-10 247L56 257V539L-10 559V247Z"
            fill="#FBFBFC"
          />
          <path
            id="prev comp fullcard bg_5"
            d="M320 147L388 137V490L320 480V147Z"
            fill="#EAE8EE"
          />
          <g id="comp elem card theme 5" filter="url(#filter3_d_76_7583)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="59"
                width="293"
                height="534"
                rx="10"
                fill={props.color}
              />
            </g>
            <path
              id="Rectangle 6"
              d="M42 232H335V583C335 588.523 330.523 593 325 593H52C46.4771 593 42 588.523 42 583V232Z"
              fill="#FBFBFC"
            />
            <g id="themecolor2">
              <path
                d="M68 535C68 532.239 70.2386 530 73 530H305C307.761 530 310 532.239 310 535V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V535Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 122H282C286.418 122 290 125.582 290 130V499C290 503.418 286.418 507 282 507H42V122Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 84C255 81.2386 257.239 79 260 79H305C307.761 79 310 81.2386 310 84V94C310 96.7614 307.761 99 305 99H260C257.239 99 255 96.7614 255 94V84Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 84C68 81.2386 70.2386 79 73 79H176C178.761 79 181 81.2386 181 84V94C181 96.7614 178.761 99 176 99H73C70.2386 99 68 96.7614 68 94V84Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="176.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 163C286.8 163 285.05 163.587 283.523 164.73C282.059 165.842 281.073 167.248 280.5 168.268C279.927 167.248 278.941 165.842 277.477 164.73C275.934 163.587 274.2 163 272.291 163C266.993 163 263 167.202 263 172.794C263 178.819 267.98 182.943 275.536 189.2C276.825 190.266 278.273 191.455 279.784 192.737C279.991 192.907 280.245 193 280.5 193C280.755 193 281.025 192.907 281.216 192.737C282.727 191.455 284.175 190.25 285.464 189.184C293.005 182.943 298 178.819 298 172.794C298 167.202 293.991 163 288.693 163Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7583)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 256.972C248.182 255.751 247.141 254.714 245.852 253.981L173.671 220.098C171.092 218.634 167.923 218.634 165.344 220.098L93.1637 253.981C91.8742 254.714 90.8333 255.751 90.1187 256.972L169.508 301.976L248.897 256.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 392C170.953 392 172.382 391.634 173.671 390.902L240.119 349.986C242.698 348.521 244.283 345.821 244.283 342.892L250.016 261.06C250.016 259.596 249.612 258.192 248.897 256.972L169.508 301.976V392Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 390.902C166.634 391.634 168.078 392 169.508 392V301.991L90.1186 256.987C89.4039 258.192 89 259.611 89 261.06L94.3755 342.892C94.3755 345.821 95.9601 348.521 98.5391 349.986L165.344 390.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 235.11L134.21 277.078L169.508 301.976L90.1187 256.971L130.823 274.637L203.081 233.92L205.909 235.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7583"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7583"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7583"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7583"
            x="301"
            y="75"
            width="106"
            height="107"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7583"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7583"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7583"
            x="301"
            y="461"
            width="106"
            height="117"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7583"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7583"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7583"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7583"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7583"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7583">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7583">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderFlip3DSquareTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderFlip3DSquareTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 50 product list 3dflip sq v1"
          clipPath="url(#clip0_76_7578)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7578)">
            <path d="M-10 94L53 114V539L-10 559V94Z" fill="#FBFBFC" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7578)">
            <path d="M319 114L388 94V559L319 539V114Z" fill="#FBFBFC" />
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7578)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="59"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 155C68 150.582 71.5817 147 76 147H302C306.418 147 310 150.582 310 155V459C310 463.418 306.418 467 302 467H76C71.5817 467 68 463.418 68 459V155Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7578)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 541C68 538.239 70.2386 536 73 536H305C307.761 536 310 538.239 310 541V565C310 567.761 307.761 570 305 570H73C70.2386 570 68 567.761 68 565V541Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 483C68 480.239 70.2386 478 73 478H305C307.761 478 310 480.239 310 483V496C310 498.761 307.761 501 305 501H73C70.2386 501 68 498.761 68 496V483Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7578"
            x="-29"
            y="75"
            width="101"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7578"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7578"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7578"
            x="300"
            y="75"
            width="107"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7578"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7578"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7578"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7578"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7578"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7578">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7578">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderFlip3DSquareTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderFlip3DSquareTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 51 product list 3dflip sq v2"
          clipPath="url(#clip0_76_7579)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7579)">
            <path d="M-10 94L56 114V539L-10 559V94Z" fill="#FBFBFC" />
          </g>
          <path
            id="prev comp fullcard bg_2"
            d="M-10 469L56 459V539L-10 559V469Z"
            fill="#C0BFCA"
          />
          <g id="prev comp fullcard bg_3" filter="url(#filter1_d_76_7579)">
            <path d="M323 114L388 94V559L323 539V114Z" fill="#FBFBFC" />
          </g>
          <path
            id="prev comp fullcard bg_4"
            d="M323 459L388 469V559L323 539V459Z"
            fill="#C0BFCA"
          />
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7579)">
            <rect
              id="prev comp fullcard bg_5"
              x="42"
              y="59"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7579)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 491H335V593H42V491Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 517C129 514.239 131.239 512 134 512H244C246.761 512 249 514.239 249 517V527C249 529.761 246.761 532 244 532H134C131.239 532 129 529.761 129 527V517Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V545Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7579"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7579"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7579"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7579"
            x="304"
            y="75"
            width="103"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7579"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7579"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7579"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7579"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7579"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7579">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7579">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderFlip3DSquareTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderFlip3DSquareTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 52 product list 3dflip sq v3"
          clipPath="url(#clip0_76_7580)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7580)">
            <path d="M-10 94L56 114V539L-10 559V94Z" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7580)">
            <path d="M320 114L388 94V163H320V114Z" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_3" filter="url(#filter2_d_76_7580)">
            <path d="M320 480L388 490V559L320 539V480Z" fill="#FBFBFC" />
          </g>
          <path
            id="prev comp fullcard bg_4"
            d="M-10 247L56 257V539L-10 559V247Z"
            fill="#FBFBFC"
          />
          <path
            id="prev comp fullcard bg_5"
            d="M320 147L388 137V490L320 480V147Z"
            fill="#EAE8EE"
          />
          <g id="comp elem card theme 5" filter="url(#filter3_d_76_7580)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="59"
                width="293"
                height="534"
                fill={props.color}
              />
            </g>
            <path id="Rectangle 6" d="M42 232H335V593H42V232Z" fill="#FBFBFC" />
            <g id="themecolor2">
              <path
                d="M68 535C68 532.239 70.2386 530 73 530H305C307.761 530 310 532.239 310 535V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V535Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 122H282C286.418 122 290 125.582 290 130V499C290 503.418 286.418 507 282 507H42V122Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 84C255 81.2386 257.239 79 260 79H305C307.761 79 310 81.2386 310 84V94C310 96.7614 307.761 99 305 99H260C257.239 99 255 96.7614 255 94V84Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 84C68 81.2386 70.2386 79 73 79H176C178.761 79 181 81.2386 181 84V94C181 96.7614 178.761 99 176 99H73C70.2386 99 68 96.7614 68 94V84Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="176.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 163C286.8 163 285.05 163.587 283.523 164.73C282.059 165.842 281.073 167.248 280.5 168.268C279.927 167.248 278.941 165.842 277.477 164.73C275.934 163.587 274.2 163 272.291 163C266.993 163 263 167.202 263 172.794C263 178.819 267.98 182.943 275.536 189.2C276.825 190.266 278.273 191.455 279.784 192.737C279.991 192.907 280.245 193 280.5 193C280.755 193 281.025 192.907 281.216 192.737C282.727 191.455 284.175 190.25 285.464 189.184C293.005 182.943 298 178.819 298 172.794C298 167.202 293.991 163 288.693 163Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7580)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 256.972C248.182 255.751 247.141 254.714 245.852 253.981L173.671 220.098C171.092 218.634 167.923 218.634 165.344 220.098L93.1637 253.981C91.8742 254.714 90.8333 255.751 90.1187 256.972L169.508 301.976L248.897 256.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 392C170.953 392 172.382 391.634 173.671 390.902L240.119 349.986C242.698 348.521 244.283 345.821 244.283 342.892L250.016 261.06C250.016 259.596 249.612 258.192 248.897 256.972L169.508 301.976V392Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 390.902C166.634 391.634 168.078 392 169.508 392V301.991L90.1186 256.987C89.4039 258.192 89 259.611 89 261.06L94.3755 342.892C94.3755 345.821 95.9601 348.521 98.5391 349.986L165.344 390.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 235.11L134.21 277.078L169.508 301.976L90.1187 256.971L130.823 274.637L203.081 233.92L205.909 235.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7580"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7580"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7580"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7580"
            x="301"
            y="75"
            width="106"
            height="107"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7580"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7580"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7580"
            x="301"
            y="461"
            width="106"
            height="117"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7580"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7580"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7580"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7580"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7580"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7580">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7580">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// SLIDER LINEAR
const ProductListSliderLinearRoundedTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderLinearRoundedTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 41 product list slider rd v1"
          clipPath="url(#clip0_76_7569)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_76_7569)">
            <rect
              id="prev comp fullcard bg"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 155C68 150.582 71.5817 147 76 147H302C306.418 147 310 150.582 310 155V459C310 463.418 306.418 467 302 467H76C71.5817 467 68 463.418 68 459V155Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7569)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 541C68 538.239 70.2386 536 73 536H305C307.761 536 310 538.239 310 541V565C310 567.761 307.761 570 305 570H73C70.2386 570 68 567.761 68 565V541Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 483C68 480.239 70.2386 478 73 478H305C307.761 478 310 480.239 310 483V496C310 498.761 307.761 501 305 501H73C70.2386 501 68 498.761 68 496V483Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7569)">
            <path
              d="M-10 59H14C19.5228 59 24 63.4772 24 69V583C24 588.523 19.5228 593 14 593H-10V59Z"
              fill="#FBFBFC"
            />
          </g>
          <g id="prev comp fullcard bg_3" filter="url(#filter2_d_76_7569)">
            <path
              d="M354 69C354 63.4772 358.477 59 364 59H388V593H364C358.477 593 354 588.523 354 583V69Z"
              fill="#FBFBFC"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7569"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7569"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7569"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7569"
            x="-29"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7569"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7569"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7569"
            x="335"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7569"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7569"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7569">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7569">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderLinearRoundedTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderLinearRoundedTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 42 product list slider rd v2"
          clipPath="url(#clip0_76_7570)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_76_7570)">
            <rect
              id="prev comp fullcard bg"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7570)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 491H335V585C335 589.418 331.418 593 327 593H50C45.5817 593 42 589.418 42 585V491Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 517C129 514.239 131.239 512 134 512H244C246.761 512 249 514.239 249 517V527C249 529.761 246.761 532 244 532H134C131.239 532 129 529.761 129 527V517Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V545Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7570)">
            <path
              d="M-10 59H14C19.5228 59 24 63.4772 24 69V583C24 588.523 19.5228 593 14 593H-10V59Z"
              fill="#FBFBFC"
            />
          </g>
          <g id="prev comp fullcard bg_3" filter="url(#filter2_d_76_7570)">
            <path
              d="M354 69C354 63.4772 358.477 59 364 59H388V593H364C358.477 593 354 588.523 354 583V69Z"
              fill="#FBFBFC"
            />
          </g>
          <path
            id="prev comp fullcard bg_4"
            d="M-10 491H24V583C24 588.523 19.5228 593 14 593H-10V491Z"
            fill="#C0BFCA"
          />
          <path
            id="prev comp fullcard bg_5"
            d="M354 491H388V593H364C358.477 593 354 588.523 354 583V491Z"
            fill="#C0BFCA"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7570"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7570"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7570"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7570"
            x="-29"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7570"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7570"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7570"
            x="335"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7570"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7570"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7570">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7570">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderLinearRoundedTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderLinearRoundedTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 43 product list slider rd v3"
          clipPath="url(#clip0_76_7571)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7571)">
            <path
              d="M354 69C354 63.4772 358.477 59 364 59H388V593H364C358.477 593 354 588.523 354 583V69Z"
              fill="#9694A0"
            />
          </g>
          <path
            id="prev comp fullcard bg_2"
            d="M354 505C354 499.477 358.477 495 364 495H388V593H364C358.477 593 354 588.523 354 583V505Z"
            fill="#FBFBFC"
          />
          <g id="comp elem card theme 5" filter="url(#filter1_d_76_7571)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="59"
                width="293"
                height="534"
                rx="10"
                fill={props.color}
              />
            </g>
            <path
              id="Rectangle 6"
              d="M42 232H335V583C335 588.523 330.523 593 325 593H52C46.4771 593 42 588.523 42 583V232Z"
              fill="#FBFBFC"
            />
            <g id="themecolor2">
              <path
                d="M68 535C68 532.239 70.2386 530 73 530H305C307.761 530 310 532.239 310 535V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V535Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 122H282C286.418 122 290 125.582 290 130V499C290 503.418 286.418 507 282 507H42V122Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 84C255 81.2386 257.239 79 260 79H305C307.761 79 310 81.2386 310 84V94C310 96.7614 307.761 99 305 99H260C257.239 99 255 96.7614 255 94V84Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 84C68 81.2386 70.2386 79 73 79H176C178.761 79 181 81.2386 181 84V94C181 96.7614 178.761 99 176 99H73C70.2386 99 68 96.7614 68 94V84Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="176.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 163C286.8 163 285.05 163.587 283.523 164.73C282.059 165.842 281.073 167.248 280.5 168.268C279.927 167.248 278.941 165.842 277.477 164.73C275.934 163.587 274.2 163 272.291 163C266.993 163 263 167.202 263 172.794C263 178.819 267.98 182.943 275.536 189.2C276.825 190.266 278.273 191.455 279.784 192.737C279.991 192.907 280.245 193 280.5 193C280.755 193 281.025 192.907 281.216 192.737C282.727 191.455 284.175 190.25 285.464 189.184C293.005 182.943 298 178.819 298 172.794C298 167.202 293.991 163 288.693 163Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7571)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 256.972C248.182 255.751 247.141 254.714 245.852 253.981L173.671 220.098C171.092 218.634 167.923 218.634 165.344 220.098L93.1637 253.981C91.8742 254.714 90.8333 255.751 90.1187 256.972L169.508 301.976L248.897 256.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 392C170.953 392 172.382 391.634 173.671 390.902L240.119 349.986C242.698 348.521 244.283 345.821 244.283 342.892L250.016 261.06C250.016 259.596 249.612 258.192 248.897 256.972L169.508 301.976V392Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 390.902C166.634 391.634 168.078 392 169.508 392V301.991L90.1186 256.987C89.4039 258.192 89 259.611 89 261.06L94.3755 342.892C94.3755 345.821 95.9601 348.521 98.5391 349.986L165.344 390.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 235.11L134.21 277.078L169.508 301.976L90.1187 256.971L130.823 274.637L203.081 233.92L205.909 235.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prev comp fullcard bg_3" filter="url(#filter2_d_76_7571)">
            <path
              d="M-10 59H14C19.5228 59 24 63.4772 24 69V583C24 588.523 19.5228 593 14 593H-10V59Z"
              fill="#9694A0"
            />
          </g>
          <path
            id="prev comp fullcard bg_4"
            d="M-10 232H24V583C24 588.523 19.5228 593 14 593H-10V232Z"
            fill="#C0BFCA"
          />
          <rect
            id="prev comp fullcard bg_5"
            x="354"
            y="121"
            width="34"
            height="386"
            fill="#EAE8EE"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7571"
            x="335"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7571"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7571"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7571"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7571"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7571"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7571"
            x="-29"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7571"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7571"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7571">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7571">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderLinearSquareTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderLinearSquareTheme1">
 <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
<g id="preview 38 product list slider sq v1" clip-path="url(#clip0_137_17601)">
<rect width="379" height="664" fill="white"/>
<g id="comp elem card theme 5" filter="url(#filter0_d_137_17601)">
<rect id="prev comp fullcard bg" x="42" y="59" width="293" height="534" fill="#FBFBFC"/>
<path id="Rectangle 3" d="M68 155C68 150.582 71.5817 147 76 147H302C306.418 147 310 150.582 310 155V459C310 463.418 306.418 467 302 467H76C71.5817 467 68 463.418 68 459V155Z" fill="#EAE8EE"/>
<g id="Comp icon product" clip-path="url(#clip1_137_17601)">
<g id="Comp icon - Product">
<g id="Group">
<path id="Vector" d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z" fill="#DCDAE2"/>
<path id="Vector_2" d="M185.508 393C186.953 393 188.382 392.634 189.671 391.901L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.595 265.612 259.192 264.897 257.971L185.508 302.976V393Z" fill="#9694A0"/>
<path id="Vector_3" d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z" fill="#66646F"/>
<path id="Vector_4" d="M221.909 236.11L150.21 278.079L185.508 302.976L106.119 257.972L146.823 275.638L219.081 234.92L221.909 236.11Z" fill="#66646F"/>
</g>
</g>
</g>
<g id="themecolor1">
<rect x="68" y="536" width="242" height="34" rx="5" fill={props.color}/>
</g>
<g id="comp elem rect 4">
<rect x="68" y="478" width="242" height="23" rx="5" fill="#9694A0"/>
</g>
<g id="comp elem heart" clip-path="url(#clip2_137_17601)">
<g id="Group_2">
<g id="Group_3">
<path id="Vector_5" d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z" fill="#9694A0"/>
</g>
</g>
</g>
<g id="themecolor2">
<rect x="236" y="81" width="75" height="33" rx="5" fill={selectedColor}/>
</g>
</g>
<g id="prev comp fullcard bg_2" filter="url(#filter1_d_137_17601)">
<rect x="-10" y="59" width="34" height="534" fill="#FBFBFC"/>
</g>
<g id="prev comp fullcard bg_3" filter="url(#filter2_d_137_17601)">
<rect x="354" y="59" width="34" height="534" fill="#FBFBFC"/>
</g>
</g>
<defs>
<filter id="filter0_d_137_17601" x="23" y="40" width="331" height="572" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="9.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_137_17601"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_137_17601" result="shape"/>
</filter>
<filter id="filter1_d_137_17601" x="-29" y="40" width="72" height="572" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="9.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_137_17601"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_137_17601" result="shape"/>
</filter>
<filter id="filter2_d_137_17601" x="335" y="40" width="72" height="572" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="9.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_137_17601"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_137_17601" result="shape"/>
</filter>
<clipPath id="clip0_137_17601">
<rect width="379" height="664" fill="white"/>
</clipPath>
<clipPath id="clip1_137_17601">
<rect width="161" height="173" fill="white" transform="translate(105 220)"/>
</clipPath>
<clipPath id="clip2_137_17601">
<rect width="39" height="34" fill="white" transform="translate(68 82)"/>
</clipPath>
</defs>
</svg>
    </div>
  );
};

const ProductListSliderLinearSquareTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderLinearSquareTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 39 product list slider sq v2"
          clipPath="url(#clip0_76_7567)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_76_7567)">
            <rect
              id="prev comp fullcard bg"
              x="42"
              y="59"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7567)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 491H335V593H42V491Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 517C129 514.239 131.239 512 134 512H244C246.761 512 249 514.239 249 517V527C249 529.761 246.761 532 244 532H134C131.239 532 129 529.761 129 527V517Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V545Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7567)">
            <rect x="-10" y="59" width="34" height="534" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_3"
            x="-10"
            y="491"
            width="34"
            height="102"
            fill="#C0BFCA"
          />
          <g id="prev comp fullcard bg_4" filter="url(#filter2_d_76_7567)">
            <rect x="354" y="59" width="34" height="534" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_5"
            x="354"
            y="491"
            width="34"
            height="102"
            fill="#C0BFCA"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7567"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7567"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7567"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7567"
            x="-29"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7567"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7567"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7567"
            x="335"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7567"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7567"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7567">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7567">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderLinearSquareTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderLinearSquareTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 40 product list slider sq v3"
          clipPath="url(#clip0_76_7568)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_76_7568)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="59"
                width="293"
                height="534"
                fill={props.color}
              />
            </g>
            <path id="Rectangle 6" d="M42 232H335V593H42V232Z" fill="#FBFBFC" />
            <g id="themecolor2">
              <path
                d="M68 535C68 532.239 70.2386 530 73 530H305C307.761 530 310 532.239 310 535V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V535Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 122H282C286.418 122 290 125.582 290 130V499C290 503.418 286.418 507 282 507H42V122Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 84C255 81.2386 257.239 79 260 79H305C307.761 79 310 81.2386 310 84V94C310 96.7614 307.761 99 305 99H260C257.239 99 255 96.7614 255 94V84Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 84C68 81.2386 70.2386 79 73 79H176C178.761 79 181 81.2386 181 84V94C181 96.7614 178.761 99 176 99H73C70.2386 99 68 96.7614 68 94V84Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="176.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 163C286.8 163 285.05 163.587 283.523 164.73C282.059 165.842 281.073 167.248 280.5 168.268C279.927 167.248 278.941 165.842 277.477 164.73C275.934 163.587 274.2 163 272.291 163C266.993 163 263 167.202 263 172.794C263 178.819 267.98 182.943 275.536 189.2C276.825 190.266 278.273 191.455 279.784 192.737C279.991 192.907 280.245 193 280.5 193C280.755 193 281.025 192.907 281.216 192.737C282.727 191.455 284.175 190.25 285.464 189.184C293.005 182.943 298 178.819 298 172.794C298 167.202 293.991 163 288.693 163Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7568)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 256.972C248.182 255.751 247.141 254.714 245.852 253.981L173.671 220.098C171.092 218.634 167.923 218.634 165.344 220.098L93.1637 253.981C91.8742 254.714 90.8333 255.751 90.1187 256.972L169.508 301.976L248.897 256.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 392C170.953 392 172.382 391.634 173.671 390.902L240.119 349.986C242.698 348.521 244.283 345.821 244.283 342.892L250.016 261.06C250.016 259.596 249.612 258.192 248.897 256.972L169.508 301.976V392Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 390.902C166.634 391.634 168.078 392 169.508 392V301.991L90.1186 256.987C89.4039 258.192 89 259.611 89 261.06L94.3755 342.892C94.3755 345.821 95.9601 348.521 98.5391 349.986L165.344 390.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 235.11L134.21 277.078L169.508 301.976L90.1187 256.971L130.823 274.637L203.081 233.92L205.909 235.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prev comp fullcard bg" filter="url(#filter1_d_76_7568)">
            <rect x="-10" y="59" width="34" height="534" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter2_d_76_7568)">
            <rect x="354" y="59" width="34" height="534" fill="#9694A0" />
          </g>
          <rect
            id="prev comp fullcard bg_3"
            x="-10"
            y="232"
            width="34"
            height="361"
            fill="#FBFBFC"
          />
          <rect
            id="prev comp fullcard bg_4"
            x="354"
            y="121"
            width="34"
            height="386"
            fill="#EAE8EE"
          />
          <rect
            id="prev comp fullcard bg_5"
            width="34"
            height="86"
            transform="matrix(1 0 0 -1 354 593)"
            fill="#FBFBFC"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7568"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7568"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7568"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7568"
            x="-29"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7568"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7568"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7568"
            x="335"
            y="40"
            width="72"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7568"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7568"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7568">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7568">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// SLIDER OVERLAP
const ProductListSliderOverlapRoundedTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderOverlapRoundedTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 47 product list 3dslider rd v1"
          clipPath="url(#clip0_76_7575)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7575)">
            <rect x="-10" y="94" width="63" height="465" fill="#FBFBFC" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7575)">
            <rect x="319" y="94" width="69" height="465" fill="#FBFBFC" />
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7575)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 155C68 150.582 71.5817 147 76 147H302C306.418 147 310 150.582 310 155V459C310 463.418 306.418 467 302 467H76C71.5817 467 68 463.418 68 459V155Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7575)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 541C68 538.239 70.2386 536 73 536H305C307.761 536 310 538.239 310 541V565C310 567.761 307.761 570 305 570H73C70.2386 570 68 567.761 68 565V541Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 483C68 480.239 70.2386 478 73 478H305C307.761 478 310 480.239 310 483V496C310 498.761 307.761 501 305 501H73C70.2386 501 68 498.761 68 496V483Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7575"
            x="-29"
            y="75"
            width="101"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7575"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7575"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7575"
            x="300"
            y="75"
            width="107"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7575"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7575"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7575"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7575"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7575"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7575">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7575">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderOverlapRoundedTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderOverlapRoundedTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 48 product list 3dslider rd v2"
          clipPath="url(#clip0_76_7576)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7576)">
            <rect x="-10" y="94" width="66" height="465" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_2"
            x="-10"
            y="469"
            width="66"
            height="90"
            fill="#C0BFCA"
          />
          <g id="prev comp fullcard bg_3" filter="url(#filter1_d_76_7576)">
            <rect x="323" y="94" width="65" height="465" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_4"
            x="323"
            y="469"
            width="65"
            height="90"
            fill="#C0BFCA"
          />
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7576)">
            <rect
              id="prev comp fullcard bg_5"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7576)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 491H335V585C335 589.418 331.418 593 327 593H50C45.5817 593 42 589.418 42 585V491Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 517C129 514.239 131.239 512 134 512H244C246.761 512 249 514.239 249 517V527C249 529.761 246.761 532 244 532H134C131.239 532 129 529.761 129 527V517Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V545Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7576"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7576"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7576"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7576"
            x="304"
            y="75"
            width="103"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7576"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7576"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7576"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7576"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7576"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7576">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7576">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderOverlapRoundedTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderOverlapRoundedTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 49 product list 3dslider rd v3"
          clipPath="url(#clip0_76_7577)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7577)">
            <rect x="320" y="94" width="68" height="69" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7577)">
            <rect x="320" y="490" width="68" height="69" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_3"
            x="320"
            y="137"
            width="68"
            height="353"
            fill="#EAE8EE"
          />
          <g id="prev comp fullcard bg_4" filter="url(#filter2_d_76_7577)">
            <rect x="-10" y="94" width="66" height="465" fill="#9694A0" />
          </g>
          <rect
            id="prev comp fullcard bg_5"
            x="-10"
            y="247"
            width="66"
            height="312"
            fill="#FBFBFC"
          />
          <g id="comp elem card theme 5" filter="url(#filter3_d_76_7577)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="59"
                width="293"
                height="534"
                rx="10"
                fill={props.color}
              />
            </g>
            <path
              id="Rectangle 6"
              d="M42 232H335V583C335 588.523 330.523 593 325 593H52C46.4771 593 42 588.523 42 583V232Z"
              fill="#FBFBFC"
            />
            <g id="themecolor2">
              <path
                d="M68 535C68 532.239 70.2386 530 73 530H305C307.761 530 310 532.239 310 535V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V535Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 122H282C286.418 122 290 125.582 290 130V499C290 503.418 286.418 507 282 507H42V122Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 84C255 81.2386 257.239 79 260 79H305C307.761 79 310 81.2386 310 84V94C310 96.7614 307.761 99 305 99H260C257.239 99 255 96.7614 255 94V84Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 84C68 81.2386 70.2386 79 73 79H176C178.761 79 181 81.2386 181 84V94C181 96.7614 178.761 99 176 99H73C70.2386 99 68 96.7614 68 94V84Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="176.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 163C286.8 163 285.05 163.587 283.523 164.73C282.059 165.842 281.073 167.248 280.5 168.268C279.927 167.248 278.941 165.842 277.477 164.73C275.934 163.587 274.2 163 272.291 163C266.993 163 263 167.202 263 172.794C263 178.819 267.98 182.943 275.536 189.2C276.825 190.266 278.273 191.455 279.784 192.737C279.991 192.907 280.245 193 280.5 193C280.755 193 281.025 192.907 281.216 192.737C282.727 191.455 284.175 190.25 285.464 189.184C293.005 182.943 298 178.819 298 172.794C298 167.202 293.991 163 288.693 163Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7577)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 256.972C248.182 255.751 247.141 254.714 245.852 253.981L173.671 220.098C171.092 218.634 167.923 218.634 165.344 220.098L93.1637 253.981C91.8742 254.714 90.8333 255.751 90.1187 256.972L169.508 301.976L248.897 256.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 392C170.953 392 172.382 391.634 173.671 390.902L240.119 349.986C242.698 348.521 244.283 345.821 244.283 342.892L250.016 261.06C250.016 259.596 249.612 258.192 248.897 256.972L169.508 301.976V392Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 390.902C166.634 391.634 168.078 392 169.508 392V301.991L90.1186 256.987C89.4039 258.192 89 259.611 89 261.06L94.3755 342.892C94.3755 345.821 95.9601 348.521 98.5391 349.986L165.344 390.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 235.11L134.21 277.078L169.508 301.976L90.1187 256.971L130.823 274.637L203.081 233.92L205.909 235.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7577"
            x="301"
            y="75"
            width="106"
            height="107"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7577"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7577"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7577"
            x="301"
            y="471"
            width="106"
            height="107"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7577"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7577"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7577"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7577"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7577"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7577"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7577"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7577"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7577">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7577">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderOverlapSquareTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderOverlapSquareTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 44 product list 3dslider sq v1"
          clipPath="url(#clip0_76_7572)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7572)">
            <rect x="-10" y="94" width="63" height="465" fill="#FBFBFC" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7572)">
            <rect x="319" y="94" width="69" height="465" fill="#FBFBFC" />
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7572)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="59"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 155C68 150.582 71.5817 147 76 147H302C306.418 147 310 150.582 310 155V459C310 463.418 306.418 467 302 467H76C71.5817 467 68 463.418 68 459V155Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7572)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 541C68 538.239 70.2386 536 73 536H305C307.761 536 310 538.239 310 541V565C310 567.761 307.761 570 305 570H73C70.2386 570 68 567.761 68 565V541Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 483C68 480.239 70.2386 478 73 478H305C307.761 478 310 480.239 310 483V496C310 498.761 307.761 501 305 501H73C70.2386 501 68 498.761 68 496V483Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7572"
            x="-29"
            y="75"
            width="101"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7572"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7572"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7572"
            x="300"
            y="75"
            width="107"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7572"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7572"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7572"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7572"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7572"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7572">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7572">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderOverlapSquareTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderOverlapSquareTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 45 product list 3dslider sq v2"
          clipPath="url(#clip0_76_7573)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7573)">
            <rect x="-10" y="94" width="66" height="465" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_2"
            x="-10"
            y="469"
            width="66"
            height="90"
            fill="#C0BFCA"
          />
          <g id="prev comp fullcard bg_3" filter="url(#filter1_d_76_7573)">
            <rect x="323" y="94" width="65" height="465" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_4"
            x="323"
            y="469"
            width="65"
            height="90"
            fill="#C0BFCA"
          />
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7573)">
            <rect
              id="prev comp fullcard bg_5"
              x="42"
              y="59"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip1_76_7573)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.902L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.596 265.612 259.192 264.897 257.972L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.078L185.508 302.976L106.119 257.971L146.823 275.637L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 491H335V593H42V491Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 517C129 514.239 131.239 512 134 512H244C246.761 512 249 514.239 249 517V527C249 529.761 246.761 532 244 532H134C131.239 532 129 529.761 129 527V517Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V545Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7573"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7573"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7573"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7573"
            x="304"
            y="75"
            width="103"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7573"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7573"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7573"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7573"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7573"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7573">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7573">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderOverlapSquareTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderOverlapSquareTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 46 product list 3dslider sq v3"
          clipPath="url(#clip0_76_7574)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7574)">
            <rect x="-10" y="94" width="66" height="465" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7574)">
            <rect x="320" y="94" width="68" height="69" fill="#9694A0" />
          </g>
          <g id="prev comp fullcard bg_3" filter="url(#filter2_d_76_7574)">
            <rect x="320" y="490" width="68" height="69" fill="#FBFBFC" />
          </g>
          <rect
            id="prev comp fullcard bg_4"
            x="-10"
            y="247"
            width="66"
            height="312"
            fill="#FBFBFC"
          />
          <rect
            id="prev comp fullcard bg_5"
            x="320"
            y="137"
            width="68"
            height="353"
            fill="#EAE8EE"
          />
          <g id="comp elem card theme 5" filter="url(#filter3_d_76_7574)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="59"
                width="293"
                height="534"
                fill={props.color}
              />
            </g>
            <path id="Rectangle 6" d="M42 232H335V593H42V232Z" fill="#FBFBFC" />
            <g id="themecolor2">
              <path
                d="M68 535C68 532.239 70.2386 530 73 530H305C307.761 530 310 532.239 310 535V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V535Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 122H282C286.418 122 290 125.582 290 130V499C290 503.418 286.418 507 282 507H42V122Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 84C255 81.2386 257.239 79 260 79H305C307.761 79 310 81.2386 310 84V94C310 96.7614 307.761 99 305 99H260C257.239 99 255 96.7614 255 94V84Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 84C68 81.2386 70.2386 79 73 79H176C178.761 79 181 81.2386 181 84V94C181 96.7614 178.761 99 176 99H73C70.2386 99 68 96.7614 68 94V84Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="176.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 163C286.8 163 285.05 163.587 283.523 164.73C282.059 165.842 281.073 167.248 280.5 168.268C279.927 167.248 278.941 165.842 277.477 164.73C275.934 163.587 274.2 163 272.291 163C266.993 163 263 167.202 263 172.794C263 178.819 267.98 182.943 275.536 189.2C276.825 190.266 278.273 191.455 279.784 192.737C279.991 192.907 280.245 193 280.5 193C280.755 193 281.025 192.907 281.216 192.737C282.727 191.455 284.175 190.25 285.464 189.184C293.005 182.943 298 178.819 298 172.794C298 167.202 293.991 163 288.693 163Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip1_76_7574)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 256.972C248.182 255.751 247.141 254.714 245.852 253.981L173.671 220.098C171.092 218.634 167.923 218.634 165.344 220.098L93.1637 253.981C91.8742 254.714 90.8333 255.751 90.1187 256.972L169.508 301.976L248.897 256.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 392C170.953 392 172.382 391.634 173.671 390.902L240.119 349.986C242.698 348.521 244.283 345.821 244.283 342.892L250.016 261.06C250.016 259.596 249.612 258.192 248.897 256.972L169.508 301.976V392Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 390.902C166.634 391.634 168.078 392 169.508 392V301.991L90.1186 256.987C89.4039 258.192 89 259.611 89 261.06L94.3755 342.892C94.3755 345.821 95.9601 348.521 98.5391 349.986L165.344 390.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 235.11L134.21 277.078L169.508 301.976L90.1187 256.971L130.823 274.637L203.081 233.92L205.909 235.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7574"
            x="-29"
            y="75"
            width="104"
            height="503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7574"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7574"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7574"
            x="301"
            y="75"
            width="106"
            height="107"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7574"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7574"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7574"
            x="301"
            y="471"
            width="106"
            height="107"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7574"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7574"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7574"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7574"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7574"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7574">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7574">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// SLIDER ZOOM OUT
const ProductListSliderZoomOutRoundedTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderZoomOutRoundedTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 100 product list clean">
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_2016_19912)">
            <rect
              id="prev comp fullcard bg"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 155C68 150.582 71.5817 147 76 147H302C306.418 147 310 150.582 310 155V459C310 463.418 306.418 467 302 467H76C71.5817 467 68 463.418 68 459V155Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip0_2016_19912)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.901L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.595 265.612 259.192 264.897 257.971L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.079L185.508 302.976L106.119 257.972L146.823 275.638L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 541C68 538.239 70.2386 536 73 536H305C307.761 536 310 538.239 310 541V565C310 567.761 307.761 570 305 570H73C70.2386 570 68 567.761 68 565V541Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 483C68 480.239 70.2386 478 73 478H305C307.761 478 310 480.239 310 483V496C310 498.761 307.761 501 305 501H73C70.2386 501 68 498.761 68 496V483Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2016_19912"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2016_19912"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2016_19912"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2016_19912">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderZoomOutRoundedTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderZoomOutRoundedTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 101 product list clean">
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_2016_19913)">
            <rect
              id="prev comp fullcard bg"
              x="42"
              y="59"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip0_2016_19913)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 257.972C264.182 256.751 263.141 255.714 261.852 254.981L189.671 221.098C187.092 219.634 183.923 219.634 181.344 221.098L109.164 254.981C107.874 255.714 106.833 256.751 106.119 257.972L185.508 302.976L264.897 257.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 393C186.953 393 188.382 392.634 189.671 391.901L256.119 350.986C258.698 349.521 260.283 346.821 260.283 343.892L266.016 262.06C266.016 260.595 265.612 259.192 264.897 257.971L185.508 302.976V393Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 391.902C182.634 392.634 184.078 393 185.508 393V302.991L106.119 257.987C105.404 259.192 105 260.611 105 262.06L110.375 343.892C110.375 346.821 111.96 349.521 114.539 350.986L181.344 391.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 236.11L150.21 278.079L185.508 302.976L106.119 257.972L146.823 275.638L219.081 234.92L221.909 236.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 82C94.52 82 92.57 82.6653 90.8682 83.9609C89.2373 85.2214 88.1382 86.8146 87.5 87.9701C86.8618 86.8146 85.7627 85.2214 84.1318 83.9609C82.4123 82.6653 80.48 82 78.3527 82C72.4495 82 68 86.7621 68 93.0999C68 99.9279 73.5486 104.602 81.9691 111.693C83.405 112.901 85.0182 114.249 86.7023 115.702C86.9327 115.895 87.2164 116 87.5 116C87.7836 116 88.085 115.895 88.2977 115.702C89.9818 114.249 91.595 112.884 93.0309 111.676C101.434 104.602 107 99.9279 107 93.0999C107 86.7621 102.533 82 96.6295 82Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 491H335V585C335 589.418 331.418 593 327 593H50C45.5817 593 42 589.418 42 585V491Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 517C129 514.239 131.239 512 134 512H244C246.761 512 249 514.239 249 517V527C249 529.761 246.761 532 244 532H134C131.239 532 129 529.761 129 527V517Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V545Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 86C236 83.2386 238.239 81 241 81H306C308.761 81 311 83.2386 311 86V109C311 111.761 308.761 114 306 114H241C238.239 114 236 111.761 236 109V86Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2016_19913"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2016_19913"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2016_19913"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2016_19913">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 220)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderZoomOutRoundedTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderZoomOutRoundedTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 102 product list clean">
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_2016_19914)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="59"
                width="293"
                height="534"
                rx="10"
                fill={props.color}
              />
            </g>
            <path
              id="Rectangle 6"
              d="M42 232H335V583C335 588.523 330.523 593 325 593H52C46.4771 593 42 588.523 42 583V232Z"
              fill="#FBFBFC"
            />
            <g id="themecolor2">
              <path
                d="M68 535C68 532.239 70.2386 530 73 530H305C307.761 530 310 532.239 310 535V564C310 566.761 307.761 569 305 569H73C70.2386 569 68 566.761 68 564V535Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 122H282C286.418 122 290 125.582 290 130V499C290 503.418 286.418 507 282 507H42V122Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 84C255 81.2386 257.239 79 260 79H305C307.761 79 310 81.2386 310 84V94C310 96.7614 307.761 99 305 99H260C257.239 99 255 96.7614 255 94V84Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 84C68 81.2386 70.2386 79 73 79H176C178.761 79 181 81.2386 181 84V94C181 96.7614 178.761 99 176 99H73C70.2386 99 68 96.7614 68 94V84Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="176.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 163C286.8 163 285.05 163.587 283.523 164.73C282.059 165.842 281.073 167.248 280.5 168.268C279.927 167.248 278.941 165.842 277.477 164.73C275.934 163.587 274.2 163 272.291 163C266.993 163 263 167.202 263 172.794C263 178.819 267.98 182.943 275.536 189.2C276.825 190.266 278.273 191.455 279.784 192.737C279.991 192.907 280.245 193 280.5 193C280.755 193 281.025 192.907 281.216 192.737C282.727 191.455 284.175 190.25 285.464 189.184C293.005 182.943 298 178.819 298 172.794C298 167.202 293.991 163 288.693 163Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip0_2016_19914)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 256.972C248.182 255.751 247.141 254.714 245.852 253.981L173.671 220.098C171.092 218.634 167.923 218.634 165.344 220.098L93.1637 253.981C91.8742 254.714 90.8333 255.751 90.1187 256.972L169.508 301.976L248.897 256.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 392C170.953 392 172.382 391.634 173.671 390.901L240.119 349.986C242.698 348.521 244.283 345.821 244.283 342.892L250.016 261.06C250.016 259.595 249.612 258.192 248.897 256.971L169.508 301.976V392Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 390.902C166.634 391.634 168.078 392 169.508 392V301.991L90.1186 256.987C89.4039 258.192 89 259.611 89 261.06L94.3755 342.892C94.3755 345.821 95.9601 348.521 98.5391 349.986L165.344 390.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 235.11L134.21 277.079L169.508 301.976L90.1187 256.972L130.823 274.638L203.081 233.92L205.909 235.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2016_19914"
            x="23"
            y="40"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2016_19914"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2016_19914"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2016_19914">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 219)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderZoomOutSquareTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderZoomOutSquareTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 104 product list clean">
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_2019_19896)">
            <rect
              id="prev comp fullcard bg"
              x="42"
              y="69"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 165C68 160.582 71.5817 157 76 157H302C306.418 157 310 160.582 310 165V469C310 473.418 306.418 477 302 477H76C71.5817 477 68 473.418 68 469V165Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip0_2019_19896)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 267.972C264.182 266.751 263.141 265.714 261.852 264.981L189.671 231.098C187.092 229.634 183.923 229.634 181.344 231.098L109.164 264.981C107.874 265.714 106.833 266.751 106.119 267.972L185.508 312.976L264.897 267.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 403C186.953 403 188.382 402.634 189.671 401.901L256.119 360.986C258.698 359.521 260.283 356.821 260.283 353.892L266.016 272.06C266.016 270.595 265.612 269.192 264.897 267.971L185.508 312.976V403Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 401.902C182.634 402.634 184.078 403 185.508 403V312.991L106.119 267.987C105.404 269.192 105 270.611 105 272.06L110.375 353.892C110.375 356.821 111.96 359.521 114.539 360.986L181.344 401.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 246.11L150.21 288.079L185.508 312.976L106.119 267.972L146.823 285.638L219.081 244.92L221.909 246.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 551C68 548.239 70.2386 546 73 546H305C307.761 546 310 548.239 310 551V575C310 577.761 307.761 580 305 580H73C70.2386 580 68 577.761 68 575V551Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 493C68 490.239 70.2386 488 73 488H305C307.761 488 310 490.239 310 493V506C310 508.761 307.761 511 305 511H73C70.2386 511 68 508.761 68 506V493Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 92C94.52 92 92.57 92.6653 90.8682 93.9609C89.2373 95.2214 88.1382 96.8146 87.5 97.9701C86.8618 96.8146 85.7627 95.2214 84.1318 93.9609C82.4123 92.6653 80.48 92 78.3527 92C72.4495 92 68 96.7621 68 103.1C68 109.928 73.5486 114.602 81.9691 121.693C83.405 122.901 85.0182 124.249 86.7023 125.702C86.9327 125.895 87.2164 126 87.5 126C87.7836 126 88.085 125.895 88.2977 125.702C89.9818 124.249 91.595 122.884 93.0309 121.676C101.434 114.602 107 109.928 107 103.1C107 96.7621 102.533 92 96.6295 92Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 96C236 93.2386 238.239 91 241 91H306C308.761 91 311 93.2386 311 96V119C311 121.761 308.761 124 306 124H241C238.239 124 236 121.761 236 119V96Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2019_19896"
            x="23"
            y="50"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2019_19896"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2019_19896"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2019_19896">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 230)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderZoomOutSquareTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderZoomOutSquareTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 105 product list clean">
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_2019_19918)">
            <rect
              id="prev comp fullcard bg"
              x="42"
              y="69"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip0_2019_19918)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 267.972C264.182 266.751 263.141 265.714 261.852 264.981L189.671 231.098C187.092 229.634 183.923 229.634 181.344 231.098L109.164 264.981C107.874 265.714 106.833 266.751 106.119 267.972L185.508 312.976L264.897 267.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 403C186.953 403 188.382 402.634 189.671 401.901L256.119 360.986C258.698 359.521 260.283 356.821 260.283 353.892L266.016 272.06C266.016 270.595 265.612 269.192 264.897 267.971L185.508 312.976V403Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 401.902C182.634 402.634 184.078 403 185.508 403V312.991L106.119 267.987C105.404 269.192 105 270.611 105 272.06L110.375 353.892C110.375 356.821 111.96 359.521 114.539 360.986L181.344 401.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 246.11L150.21 288.079L185.508 312.976L106.119 267.972L146.823 285.638L219.081 244.92L221.909 246.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 92C94.52 92 92.57 92.6653 90.8682 93.9609C89.2373 95.2214 88.1382 96.8146 87.5 97.9701C86.8618 96.8146 85.7627 95.2214 84.1318 93.9609C82.4123 92.6653 80.48 92 78.3527 92C72.4495 92 68 96.7621 68 103.1C68 109.928 73.5486 114.602 81.9691 121.693C83.405 122.901 85.0182 124.249 86.7023 125.702C86.9327 125.895 87.2164 126 87.5 126C87.7836 126 88.085 125.895 88.2977 125.702C89.9818 124.249 91.595 122.884 93.0309 121.676C101.434 114.602 107 109.928 107 103.1C107 96.7621 102.533 92 96.6295 92Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 501H335V603H42V501Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 527C129 524.239 131.239 522 134 522H244C246.761 522 249 524.239 249 527V537C249 539.761 246.761 542 244 542H134C131.239 542 129 539.761 129 537V527Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 555C68 552.239 70.2386 550 73 550H305C307.761 550 310 552.239 310 555V574C310 576.761 307.761 579 305 579H73C70.2386 579 68 576.761 68 574V555Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 96C236 93.2386 238.239 91 241 91H306C308.761 91 311 93.2386 311 96V119C311 121.761 308.761 124 306 124H241C238.239 124 236 121.761 236 119V96Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2019_19918"
            x="23"
            y="50"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2019_19918"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2019_19918"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2019_19918">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 230)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListSliderZoomOutSquareTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListSliderZoomOutSquareTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 106 product list clean">
          <rect width="379" height="664" fill="white" />
          <g id="comp elem card theme 5" filter="url(#filter0_d_2019_19940)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="69"
                width="293"
                height="534"
                fill={props.color}
              />
            </g>
            <path id="Rectangle 6" d="M42 242H335V603H42V242Z" fill="#FBFBFC" />
            <g id="themecolor2">
              <path
                d="M68 545C68 542.239 70.2386 540 73 540H305C307.761 540 310 542.239 310 545V574C310 576.761 307.761 579 305 579H73C70.2386 579 68 576.761 68 574V545Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 132H282C286.418 132 290 135.582 290 140V509C290 513.418 286.418 517 282 517H42V132Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 94C255 91.2386 257.239 89 260 89H305C307.761 89 310 91.2386 310 94V104C310 106.761 307.761 109 305 109H260C257.239 109 255 106.761 255 104V94Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 94C68 91.2386 70.2386 89 73 89H176C178.761 89 181 91.2386 181 94V104C181 106.761 178.761 109 176 109H73C70.2386 109 68 106.761 68 104V94Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="186.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 173C286.8 173 285.05 173.587 283.523 174.73C282.059 175.842 281.073 177.248 280.5 178.268C279.927 177.248 278.941 175.842 277.477 174.73C275.934 173.587 274.2 173 272.291 173C266.993 173 263 177.202 263 182.794C263 188.819 267.98 192.943 275.536 199.2C276.825 200.266 278.273 201.455 279.784 202.737C279.991 202.907 280.245 203 280.5 203C280.755 203 281.025 202.907 281.216 202.737C282.727 201.455 284.175 200.25 285.464 199.184C293.005 192.943 298 188.819 298 182.794C298 177.202 293.991 173 288.693 173Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip0_2019_19940)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 266.972C248.182 265.751 247.141 264.714 245.852 263.981L173.671 230.098C171.092 228.634 167.923 228.634 165.344 230.098L93.1637 263.981C91.8742 264.714 90.8333 265.751 90.1187 266.972L169.508 311.976L248.897 266.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 402C170.953 402 172.382 401.634 173.671 400.901L240.119 359.986C242.698 358.521 244.283 355.821 244.283 352.892L250.016 271.06C250.016 269.595 249.612 268.192 248.897 266.971L169.508 311.976V402Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 400.902C166.634 401.634 168.078 402 169.508 402V311.991L90.1186 266.987C89.4039 268.192 89 269.611 89 271.06L94.3755 352.892C94.3755 355.821 95.9601 358.521 98.5391 359.986L165.344 400.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 245.11L134.21 287.079L169.508 311.976L90.1187 266.972L130.823 284.638L203.081 243.92L205.909 245.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2019_19940"
            x="23"
            y="50"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2019_19940"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2019_19940"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2019_19940">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 229)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// TINDER
const ProductListTinderRoundedTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListTinderRoundedTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 35 product list tinder rd v1">
          <rect width="379" height="664" fill="white" />
          <g id="prev elem card pile">
            <mask
              id="mask0_76_7563"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="43"
              y="21"
              width="293"
              height="58"
            >
              <rect
                id="Rectangle 51"
                x="43"
                y="21"
                width="293"
                height="58"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_76_7563)">
              <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7563)">
                <rect
                  x="88"
                  y="41"
                  width="203"
                  height="89"
                  rx="8"
                  fill="#FBFBFC"
                />
              </g>
              <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7563)">
                <rect
                  x="65"
                  y="59"
                  width="249"
                  height="89"
                  rx="8"
                  fill="#FBFBFC"
                />
              </g>
            </g>
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7563)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="79"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 175C68 170.582 71.5817 167 76 167H302C306.418 167 310 170.582 310 175V479C310 483.418 306.418 487 302 487H76C71.5817 487 68 483.418 68 479V175Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip0_76_7563)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 277.972C264.182 276.751 263.141 275.714 261.852 274.981L189.671 241.098C187.092 239.634 183.923 239.634 181.344 241.098L109.164 274.981C107.874 275.714 106.833 276.751 106.119 277.972L185.508 322.976L264.897 277.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 413C186.953 413 188.382 412.634 189.671 411.901L256.119 370.986C258.698 369.521 260.283 366.821 260.283 363.892L266.016 282.06C266.016 280.595 265.612 279.192 264.897 277.971L185.508 322.976V413Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 411.902C182.634 412.634 184.078 413 185.508 413V322.991L106.119 277.987C105.404 279.192 105 280.611 105 282.06L110.375 363.892C110.375 366.821 111.96 369.521 114.539 370.986L181.344 411.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 256.11L150.21 298.079L185.508 322.976L106.119 277.972L146.823 295.638L219.081 254.92L221.909 256.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 561C68 558.239 70.2386 556 73 556H305C307.761 556 310 558.239 310 561V585C310 587.761 307.761 590 305 590H73C70.2386 590 68 587.761 68 585V561Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 503C68 500.239 70.2386 498 73 498H305C307.761 498 310 500.239 310 503V516C310 518.761 307.761 521 305 521H73C70.2386 521 68 518.761 68 516V503Z"
                fill="#9694A0"
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 102C94.52 102 92.57 102.665 90.8682 103.961C89.2373 105.221 88.1382 106.815 87.5 107.97C86.8618 106.815 85.7627 105.221 84.1318 103.961C82.4123 102.665 80.48 102 78.3527 102C72.4495 102 68 106.762 68 113.1C68 119.928 73.5486 124.602 81.9691 131.693C83.405 132.901 85.0182 134.249 86.7023 135.702C86.9327 135.895 87.2164 136 87.5 136C87.7836 136 88.085 135.895 88.2977 135.702C89.9818 134.249 91.595 132.884 93.0309 131.676C101.434 124.602 107 119.928 107 113.1C107 106.762 102.533 102 96.6295 102Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 106C236 103.239 238.239 101 241 101H306C308.761 101 311 103.239 311 106V129C311 131.761 308.761 134 306 134H241C238.239 134 236 131.761 236 129V106Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7563"
            x="69"
            y="22"
            width="241"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7563"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7563"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7563"
            x="46"
            y="40"
            width="287"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7563"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7563"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7563"
            x="23"
            y="60"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7563"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7563"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7563">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 240)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListTinderRoundedTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListTinderRoundedTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 36 product list tinder rd v2">
          <rect width="379" height="664" fill="white" />
          <g id="prev elem card pile">
            <mask
              id="mask0_76_7564"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="43"
              y="21"
              width="293"
              height="58"
            >
              <rect
                id="Rectangle 51"
                x="43"
                y="21"
                width="293"
                height="58"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_76_7564)">
              <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7564)">
                <rect
                  x="88"
                  y="41"
                  width="203"
                  height="89"
                  rx="8"
                  fill="#FBFBFC"
                />
              </g>
              <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7564)">
                <rect
                  x="65"
                  y="59"
                  width="249"
                  height="89"
                  rx="8"
                  fill="#FBFBFC"
                />
              </g>
            </g>
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7564)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="79"
              width="293"
              height="534"
              rx="10"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip0_76_7564)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 277.972C264.182 276.751 263.141 275.714 261.852 274.981L189.671 241.098C187.092 239.634 183.923 239.634 181.344 241.098L109.164 274.981C107.874 275.714 106.833 276.751 106.119 277.972L185.508 322.976L264.897 277.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 413C186.953 413 188.382 412.634 189.671 411.901L256.119 370.986C258.698 369.521 260.283 366.821 260.283 363.892L266.016 282.06C266.016 280.595 265.612 279.192 264.897 277.971L185.508 322.976V413Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 411.902C182.634 412.634 184.078 413 185.508 413V322.991L106.119 277.987C105.404 279.192 105 280.611 105 282.06L110.375 363.892C110.375 366.821 111.96 369.521 114.539 370.986L181.344 411.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 256.11L150.21 298.079L185.508 322.976L106.119 277.972L146.823 295.638L219.081 254.92L221.909 256.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 102C94.52 102 92.57 102.665 90.8682 103.961C89.2373 105.221 88.1382 106.815 87.5 107.97C86.8618 106.815 85.7627 105.221 84.1318 103.961C82.4123 102.665 80.48 102 78.3527 102C72.4495 102 68 106.762 68 113.1C68 119.928 73.5486 124.602 81.9691 131.693C83.405 132.901 85.0182 134.249 86.7023 135.702C86.9327 135.895 87.2164 136 87.5 136C87.7836 136 88.085 135.895 88.2977 135.702C89.9818 134.249 91.595 132.884 93.0309 131.676C101.434 124.602 107 119.928 107 113.1C107 106.762 102.533 102 96.6295 102Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 511H335V605C335 609.418 331.418 613 327 613H50C45.5817 613 42 609.418 42 605V511Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 537C129 534.239 131.239 532 134 532H244C246.761 532 249 534.239 249 537V547C249 549.761 246.761 552 244 552H134C131.239 552 129 549.761 129 547V537Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 565C68 562.239 70.2386 560 73 560H305C307.761 560 310 562.239 310 565V584C310 586.761 307.761 589 305 589H73C70.2386 589 68 586.761 68 584V565Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 106C236 103.239 238.239 101 241 101H306C308.761 101 311 103.239 311 106V129C311 131.761 308.761 134 306 134H241C238.239 134 236 131.761 236 129V106Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7564"
            x="69"
            y="22"
            width="241"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7564"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7564"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7564"
            x="46"
            y="40"
            width="287"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7564"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7564"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7564"
            x="23"
            y="60"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7564"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7564"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7564">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 240)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListTinderRoundedTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListTinderRoundedTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 37 product list tinder rd v3">
          <rect width="379" height="664" fill="white" />
          <g id="prev elem card pile">
            <mask
              id="mask0_76_7565"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="42"
              y="21"
              width="293"
              height="58"
            >
              <rect
                id="Rectangle 51"
                x="42"
                y="21"
                width="293"
                height="58"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_76_7565)">
              <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7565)">
                <rect
                  x="87"
                  y="41"
                  width="203"
                  height="89"
                  rx="8"
                  fill="#9694A0"
                />
              </g>
              <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7565)">
                <rect
                  x="64"
                  y="59"
                  width="249"
                  height="89"
                  rx="8"
                  fill="#9694A0"
                />
              </g>
            </g>
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7565)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="79"
                width="293"
                height="534"
                rx="10"
                fill={props.color}
              />
            </g>
            <path
              id="Rectangle 6"
              d="M42 252H335V603C335 608.523 330.523 613 325 613H52C46.4771 613 42 608.523 42 603V252Z"
              fill="#FBFBFC"
            />
            <g id="themecolor2">
              <path
                d="M68 555C68 552.239 70.2386 550 73 550H305C307.761 550 310 552.239 310 555V584C310 586.761 307.761 589 305 589H73C70.2386 589 68 586.761 68 584V555Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 142H282C286.418 142 290 145.582 290 150V519C290 523.418 286.418 527 282 527H42V142Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 104C255 101.239 257.239 99 260 99H305C307.761 99 310 101.239 310 104V114C310 116.761 307.761 119 305 119H260C257.239 119 255 116.761 255 114V104Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 104C68 101.239 70.2386 99 73 99H176C178.761 99 181 101.239 181 104V114C181 116.761 178.761 119 176 119H73C70.2386 119 68 116.761 68 114V104Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="196.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 183C286.8 183 285.05 183.587 283.523 184.73C282.059 185.842 281.073 187.248 280.5 188.268C279.927 187.248 278.941 185.842 277.477 184.73C275.934 183.587 274.2 183 272.291 183C266.993 183 263 187.202 263 192.794C263 198.819 267.98 202.943 275.536 209.2C276.825 210.266 278.273 211.455 279.784 212.737C279.991 212.907 280.245 213 280.5 213C280.755 213 281.025 212.907 281.216 212.737C282.727 211.455 284.175 210.25 285.464 209.184C293.005 202.943 298 198.819 298 192.794C298 187.202 293.991 183 288.693 183Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip0_76_7565)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 276.972C248.182 275.751 247.141 274.714 245.852 273.981L173.671 240.098C171.092 238.634 167.923 238.634 165.344 240.098L93.1637 273.981C91.8742 274.714 90.8333 275.751 90.1187 276.972L169.508 321.976L248.897 276.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 412C170.953 412 172.382 411.634 173.671 410.901L240.119 369.986C242.698 368.521 244.283 365.821 244.283 362.892L250.016 281.06C250.016 279.595 249.612 278.192 248.897 276.971L169.508 321.976V412Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 410.902C166.634 411.634 168.078 412 169.508 412V321.991L90.1186 276.987C89.4039 278.192 89 279.611 89 281.06L94.3755 362.892C94.3755 365.821 95.9601 368.521 98.5391 369.986L165.344 410.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 255.11L134.21 297.079L169.508 321.976L90.1187 276.972L130.823 294.638L203.081 253.92L205.909 255.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7565"
            x="68"
            y="22"
            width="241"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7565"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7565"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7565"
            x="45"
            y="40"
            width="287"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7565"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7565"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7565"
            x="23"
            y="60"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7565"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7565"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7565">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 239)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListTinderSquareTheme1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListTinderSquareTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 32 product list tinder sq v1">
          <rect width="379" height="664" fill="white" />
          <g id="prev elem card pile">
            <mask
              id="mask0_76_7560"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="43"
              y="21"
              width="293"
              height="58"
            >
              <rect
                id="Rectangle 51"
                x="43"
                y="21"
                width="293"
                height="58"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_76_7560)">
              <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7560)">
                <rect x="88" y="41" width="203" height="89" fill="#FBFBFC" />
              </g>
              <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7560)">
                <rect x="65" y="59" width="249" height="89" fill="#FBFBFC" />
              </g>
            </g>
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7560)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="79"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <path
              id="Rectangle 3"
              d="M68 175C68 170.582 71.5817 167 76 167H302C306.418 167 310 170.582 310 175V479C310 483.418 306.418 487 302 487H76C71.5817 487 68 483.418 68 479V175Z"
              fill="#EAE8EE"
            />
            <g id="Comp icon product" clipPath="url(#clip0_76_7560)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 277.972C264.182 276.751 263.141 275.714 261.852 274.981L189.671 241.098C187.092 239.634 183.923 239.634 181.344 241.098L109.164 274.981C107.874 275.714 106.833 276.751 106.119 277.972L185.508 322.976L264.897 277.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 413C186.953 413 188.382 412.634 189.671 411.901L256.119 370.986C258.698 369.521 260.283 366.821 260.283 363.892L266.016 282.06C266.016 280.595 265.612 279.192 264.897 277.971L185.508 322.976V413Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 411.902C182.634 412.634 184.078 413 185.508 413V322.991L106.119 277.987C105.404 279.192 105 280.611 105 282.06L110.375 363.892C110.375 366.821 111.96 369.521 114.539 370.986L181.344 411.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 256.11L150.21 298.079L185.508 322.976L106.119 277.972L146.823 295.638L219.081 254.92L221.909 256.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor1">
              <path
                d="M68 561C68 558.239 70.2386 556 73 556H305C307.761 556 310 558.239 310 561V585C310 587.761 307.761 590 305 590H73C70.2386 590 68 587.761 68 585V561Z"
                fill={props.color}
              />
            </g>
            <g id="comp elem rect 4">
              <path
                d="M68 503C68 500.239 70.2386 498 73 498H305C307.761 498 310 500.239 310 503V516C310 518.761 307.761 521 305 521H73C70.2386 521 68 518.761 68 516V503Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 102C94.52 102 92.57 102.665 90.8682 103.961C89.2373 105.221 88.1382 106.815 87.5 107.97C86.8618 106.815 85.7627 105.221 84.1318 103.961C82.4123 102.665 80.48 102 78.3527 102C72.4495 102 68 106.762 68 113.1C68 119.928 73.5486 124.602 81.9691 131.693C83.405 132.901 85.0182 134.249 86.7023 135.702C86.9327 135.895 87.2164 136 87.5 136C87.7836 136 88.085 135.895 88.2977 135.702C89.9818 134.249 91.595 132.884 93.0309 131.676C101.434 124.602 107 119.928 107 113.1C107 106.762 102.533 102 96.6295 102Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <g id="themecolor2">
              <path
                d="M236 106C236 103.239 238.239 101 241 101H306C308.761 101 311 103.239 311 106V129C311 131.761 308.761 134 306 134H241C238.239 134 236 131.761 236 129V106Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7560"
            x="69"
            y="22"
            width="241"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7560"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7560"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7560"
            x="46"
            y="40"
            width="287"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7560"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7560"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7560"
            x="23"
            y="60"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7560"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7560"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7560">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 240)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListTinderSquareTheme2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListTinderSquareTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 33 product list tinder sq v2">
          <rect width="379" height="664" fill="white" />
          <g id="prev elem card pile">
            <mask
              id="mask0_76_7561"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="42"
              y="21"
              width="293"
              height="58"
            >
              <rect
                id="Rectangle 51"
                x="42"
                y="21"
                width="293"
                height="58"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_76_7561)">
              <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7561)">
                <rect x="87" y="41" width="203" height="89" fill="#FBFBFC" />
              </g>
              <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7561)">
                <rect x="64" y="59" width="249" height="89" fill="#FBFBFC" />
              </g>
            </g>
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7561)">
            <rect
              id="prev comp fullcard bg_3"
              x="42"
              y="79"
              width="293"
              height="534"
              fill="#FBFBFC"
            />
            <g id="Comp icon product" clipPath="url(#clip0_76_7561)">
              <g id="Comp icon - Product">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M264.897 277.972C264.182 276.751 263.141 275.714 261.852 274.981L189.671 241.098C187.092 239.634 183.923 239.634 181.344 241.098L109.164 274.981C107.874 275.714 106.833 276.751 106.119 277.972L185.508 322.976L264.897 277.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_2"
                    d="M185.508 413C186.953 413 188.382 412.634 189.671 411.901L256.119 370.986C258.698 369.521 260.283 366.821 260.283 363.892L266.016 282.06C266.016 280.595 265.612 279.192 264.897 277.971L185.508 322.976V413Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_3"
                    d="M181.344 411.902C182.634 412.634 184.078 413 185.508 413V322.991L106.119 277.987C105.404 279.192 105 280.611 105 282.06L110.375 363.892C110.375 366.821 111.96 369.521 114.539 370.986L181.344 411.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_4"
                    d="M221.909 256.11L150.21 298.079L185.508 322.976L106.119 277.972L146.823 295.638L219.081 254.92L221.909 256.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
            <g id="comp elem heart">
              <g id="Group_2">
                <g id="Group_3">
                  <path
                    id="Vector_5"
                    d="M96.6295 102C94.52 102 92.57 102.665 90.8682 103.961C89.2373 105.221 88.1382 106.815 87.5 107.97C86.8618 106.815 85.7627 105.221 84.1318 103.961C82.4123 102.665 80.48 102 78.3527 102C72.4495 102 68 106.762 68 113.1C68 119.928 73.5486 124.602 81.9691 131.693C83.405 132.901 85.0182 134.249 86.7023 135.702C86.9327 135.895 87.2164 136 87.5 136C87.7836 136 88.085 135.895 88.2977 135.702C89.9818 134.249 91.595 132.884 93.0309 131.676C101.434 124.602 107 119.928 107 113.1C107 106.762 102.533 102 96.6295 102Z"
                    fill="#9694A0"
                  />
                </g>
              </g>
            </g>
            <path
              id="themecolor1"
              d="M42 511H335V613H42V511Z"
              fill={props.color}
            />
            <g id="comp elem rect 6">
              <path
                d="M129 537C129 534.239 131.239 532 134 532H244C246.761 532 249 534.239 249 537V547C249 549.761 246.761 552 244 552H134C131.239 552 129 549.761 129 547V537Z"
                fill="#DCDAE2"
              />
            </g>
            <g id="themecolor2">
              <path
                d="M68 565C68 562.239 70.2386 560 73 560H305C307.761 560 310 562.239 310 565V584C310 586.761 307.761 589 305 589H73C70.2386 589 68 586.761 68 584V565Z"
                fill={selectedColor}
              />
            </g>
            <g id="themecolor2_2">
              <path
                d="M236 106C236 103.239 238.239 101 241 101H306C308.761 101 311 103.239 311 106V129C311 131.761 308.761 134 306 134H241C238.239 134 236 131.761 236 129V106Z"
                fill={selectedColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7561"
            x="68"
            y="22"
            width="241"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7561"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7561"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7561"
            x="45"
            y="40"
            width="287"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7561"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7561"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7561"
            x="23"
            y="60"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7561"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7561"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7561">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(105 240)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductListTinderSquareTheme3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="ProductListTinderSquareTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 34 product list tinder sq v3">
          <rect width="379" height="664" fill="white" />
          <g id="prev elem card pile">
            <mask
              id="mask0_76_7562"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="43"
              y="21"
              width="293"
              height="58"
            >
              <rect
                id="Rectangle 51"
                x="43"
                y="21"
                width="293"
                height="58"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_76_7562)">
              <g id="prev comp fullcard bg" filter="url(#filter0_d_76_7562)">
                <rect x="88" y="41" width="203" height="89" fill="#9694A0" />
              </g>
              <g id="prev comp fullcard bg_2" filter="url(#filter1_d_76_7562)">
                <rect x="65" y="59" width="249" height="89" fill="#9694A0" />
              </g>
            </g>
          </g>
          <g id="comp elem card theme 5" filter="url(#filter2_d_76_7562)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1"
                x="42"
                y="79"
                width="293"
                height="534"
                fill={props.color}
              />
            </g>
            <path id="Rectangle 6" d="M42 252H335V613H42V252Z" fill="#FBFBFC" />
            <g id="themecolor2">
              <path
                d="M68 555C68 552.239 70.2386 550 73 550H305C307.761 550 310 552.239 310 555V584C310 586.761 307.761 589 305 589H73C70.2386 589 68 586.761 68 584V555Z"
                fill={selectedColor}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M42 142H282C286.418 142 290 145.582 290 150V519C290 523.418 286.418 527 282 527H42V142Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_2">
              <path
                d="M255 104C255 101.239 257.239 99 260 99H305C307.761 99 310 101.239 310 104V114C310 116.761 307.761 119 305 119H260C257.239 119 255 116.761 255 114V104Z"
                fill={selectedColor}
              />
            </g>
            <g id="comp elem rect 3">
              <path
                d="M68 104C68 101.239 70.2386 99 73 99H176C178.761 99 181 101.239 181 104V114C181 116.761 178.761 119 176 119H73C70.2386 119 68 116.761 68 114V104Z"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="281.5"
              cy="196.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M288.693 183C286.8 183 285.05 183.587 283.523 184.73C282.059 185.842 281.073 187.248 280.5 188.268C279.927 187.248 278.941 185.842 277.477 184.73C275.934 183.587 274.2 183 272.291 183C266.993 183 263 187.202 263 192.794C263 198.819 267.98 202.943 275.536 209.2C276.825 210.266 278.273 211.455 279.784 212.737C279.991 212.907 280.245 213 280.5 213C280.755 213 281.025 212.907 281.216 212.737C282.727 211.455 284.175 210.25 285.464 209.184C293.005 202.943 298 198.819 298 192.794C298 187.202 293.991 183 288.693 183Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clipPath="url(#clip0_76_7562)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M248.897 276.972C248.182 275.751 247.141 274.714 245.852 273.981L173.671 240.098C171.092 238.634 167.923 238.634 165.344 240.098L93.1637 273.981C91.8742 274.714 90.8333 275.751 90.1187 276.972L169.508 321.976L248.897 276.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M169.508 412C170.953 412 172.382 411.634 173.671 410.901L240.119 369.986C242.698 368.521 244.283 365.821 244.283 362.892L250.016 281.06C250.016 279.595 249.612 278.192 248.897 276.971L169.508 321.976V412Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M165.344 410.902C166.634 411.634 168.078 412 169.508 412V321.991L90.1186 276.987C89.4039 278.192 89 279.611 89 281.06L94.3755 362.892C94.3755 365.821 95.9601 368.521 98.5391 369.986L165.344 410.902Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M205.909 255.11L134.21 297.079L169.508 321.976L90.1187 276.972L130.823 294.638L203.081 253.92L205.909 255.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7562"
            x="69"
            y="22"
            width="241"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7562"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7562"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7562"
            x="46"
            y="40"
            width="287"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7562"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7562"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7562"
            x="23"
            y="60"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7562"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7562"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7562">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(89 239)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

function hex2(c) {
  c = Math.round(c);
  if (c < 0) c = 0;
  if (c > 255) c = 255;

  var s = c.toString(16);
  if (s.length < 2) s = "0" + s;

  return s;
}

function color(r, g, b) {
  return "#" + hex2(r) + hex2(g) + hex2(b);
}

function shade(col, light) {
  // TODO: Assert that col is good and that -1 < light < 1
  var r = parseInt(col.substr(1, 2), 16);
  var g = parseInt(col.substr(3, 2), 16);
  var b = parseInt(col.substr(5, 2), 16);

  if (light < 0) {
    r = (1 + light) * r;
    g = (1 + light) * g;
    b = (1 + light) * b;
  } else {
    r = (1 - light) * r + light * 255;
    g = (1 - light) * g + light * 255;
    b = (1 - light) * b + light * 255;
  }

  return color(r, g, b);
}

export default ProductList;
