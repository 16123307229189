import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Button, ButtonGroup } from "reactstrap";
import { getSavedVideos, uploadVideo, removeVideo } from "../../api";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import { UploadedVideos } from "../home/main/configurator/uploaded-videos";

export const UpdateVideoModal = ({
  savedValue,
  label,
  onChange,
  parentOption,
  userType,
  storeKey
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");

  const [savedVideos, setSavedVideos] = useState([]);

  useEffect(() => {
    async function fetchSavedVideos() {
      let response = await getSavedVideos(storeKey);
      setSavedVideos(response.data);
    }

    fetchSavedVideos();
    console.log("saved", savedValue);
    setValue(savedValue);
  }, [savedValue]);

  const saveVideo = (value) => {
    onChange(label, value, parentOption);
    setIsOpen(false);
  };

  const onSelect = (image) => {
    setValue(image);
    saveVideo(image);
  };

  const onRemove = async (image) => {
    console.log("onRemove", image.name);
    await removeVideo({
      name: image.name,
      storeKey: storeKey
    });
  };

const uploadHandler = async (e) => {
  try {
      const data = new FormData();
      data.append("uploadedFileName", e.target.files[0]);
      data.append("storeKey", storeKey); // Append the storeKey to the form data

      console.log("await UPLOADED", data);
      const response = await uploadVideo(data);
      
      if (response && response.data) {
          console.log("FILE UPLOADED", response.data);
          console.log("FILE UPLOADED FINAL", response.data.data);
          setSavedVideos([...savedVideos, response.data.data]);
      } else {
          console.error("Unexpected response:", response);
      }
  } catch (error) {
      console.error("Error during upload:", error);
  }
};

const shouldDisableButton = () => {
  switch (userType) {
    case "trial":
      return true
    default:
      return false;
  }
};

  return (
    <Container>
      <Row>        

        <CustomButton
          type="CONTENT"
          onClick={() => setIsOpen(true)}
          label={"SELECT VIDEO"}
        />
      </Row>
      <Modal isOpen={isOpen} className="update-image-modal" size="lg3">
        <Container fluid={true}>
          <Row xs="2" className="title-row">
            <Col xs="6" className="modal-title">
            </Col>
            <Col xs="4">
              <div style={{ display: "flex", width: "400px", marginLeft: "50px" }}>
                <label
                  for="file-upload"
                  class="custom-button content-button upload-image"
                  style={{ opacity: shouldDisableButton() ? 0.5 : 1 }}
                >
                  UPLOAD VIDEO
                </label>
                <input type="file" id="file-upload" onChange={uploadHandler} disabled={shouldDisableButton()} />
                <CustomButton
                  type="CANCEL"
                  label="CANCEL"
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </Col>
          </Row>
          <UploadedVideos
            savedImages={savedVideos}
            onSelect={onSelect}
            onRemove={onRemove}
            selectedImage={value}
            userType={userType}
          />
        </Container>
      </Modal>
    </Container>
  );
};
