import React, { useState, useEffect } from "react";
import Background from "../img/phone.png";
import Image from "../common/img";
import ModalProducts from "./modalProducts";
import ModalCollections from "./modalCollections";

function ModalNotification({
  close,
  handle,
  isOpen,
  showSaveButton,
  onSendNow,
  storeKey
}) {
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const [actionItem, setActionItem] = useState(null);
  const [action, setAction] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    // Check the conditions and update the isDisabled state accordingly
    setIsDisabled(
      notificationTitle.length <= 3 ||
      notificationMessage.length <= 4 ||
      (action !== "gotoHome" && !actionItem)
    );
  }, [notificationTitle, notificationMessage, action, actionItem]);

  useEffect(() => {
    setAction("gotoHome");
    setActionItem(null);  
  }, []);

  

  const handleOnChangeNotificationTitle = (evt) => {
    setNotificationTitle(evt.target.value);
  };

  const handleOnChangeNotificationMessage = (evt) => {
    setNotificationMessage(evt.target.value);
  };

  const handleOnClose = () => {
    setNotificationTitle("");
    setNotificationMessage("");
    setActionItem(null);
    close();
  };

  const handleOnSendNow = () => {
    const newNotification = {
      titleCard: notificationTitle,
      paragraphCard: notificationMessage,
      image: action === "gotoProduct" 
      ? actionItem?.images && actionItem?.images[0]?.url
      : actionItem?.images && actionItem?.images[0]?.path,
      action,
      productId: "",
      collectionId: "",
    };

    
    if (action === "gotoProduct") {
      newNotification.productId = actionItem.id.toString();
    }

    if (action === "gotoCollection") {
      newNotification.collectionId = actionItem.id.toString();
    }
    onSendNow(newNotification);
    handleOnClose();
  };

  const handleOnApply = (item) => {
    console.log("handleOnApply item", item);
    console.log("handleOnApply product", item.configuration.products[0]);

    const product = item.configuration.products?.[0];
    if (product) {
      setActionItem(product);
    }

    setOpenProductModal(false);
  };

  const handleOnChangeAction = (val) => {
    setAction(val);
    setActionItem(null);
  };

  function capitalizeFirstLetter(sentence) {
    const words = sentence.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  }

  return (
    <>
      {isOpen && (
        <article className="modal isOpen" onClick={close}>
          <div className="modal__container col" onClick={handle}>
            <div className="header__modal row">
              <div className="col">
                <h2 className="modal__title">Notification settings</h2>
              </div>
              <div className="row">
                <button
                  onClick={handleOnClose}
                  className="buttonComponent default"
                >
                  Cancel
                </button>
                {showSaveButton && (
                  <button onClick={close} className="buttonComponent edit">
                    Save
                  </button>
                )}
              </div>
            </div>
            <div className="body__modal row">
              <div className="info__modal col">
                <div className="notification row">
                  <div className="title__notification">
                    Customize Your Push Notification: Fill in the Notification
                    Contents and Preview on the Right. Choose Actions from
                    Product, Collection, or Home.
                  </div>
                </div>
                <div className="form__modal col">
                  <label className="labelText col">
                    TITLE AND MESSAGE:
                    <input
                      className="inputComponent --title"
                      type="text"
                      name="titleText"
                      placeholder="Title"
                      value={notificationTitle}
                      onChange={handleOnChangeNotificationTitle}
                    />
                    <textarea
                      className="inputComponent --message"
                      name="textArea"
                      placeholder="Message"
                      form="usrform"
                      value={notificationMessage}
                      onChange={handleOnChangeNotificationMessage}
                    ></textarea>
                  </label>
                  <div className="contentProducts">
                    <label className="labelProduct col">
                      ACTION (When the user opens the Notification):
                    </label>
                    <div className="actionContainer">
                      <select
                        className="inputComponent --product tagSelect"
                        name="Products"
                        onChange={(e) => handleOnChangeAction(e.target.value)}
                      >
                        <option className="item" value="gotoHome">
                          Home
                        </option>
                        <option className="item" value="gotoProduct">
                          Product
                        </option>
                        <option className="item" value="gotoCollection">
                          Collection
                        </option>
                      </select>
                      <input
                        id="select"
                        className={`inputComponent ${
                          action === "gotoHome" ? "--submit-disabled" : "--submit"
                        }`}
                        type="submit"
                        value="Select"
                        onClick={() => setOpenProductModal(true)}
                        disabled={action === "gotoHome"}
                      />
                    </div>
                  </div>
                  {openProductModal && action == "gotoProduct" && (
                    <ModalProducts
                      isOpen={isOpen}
                      onClose={() => setOpenProductModal(false)}
                      onDone={handleOnApply}
                      availableProducts={[]}
                      selectedProducts={actionItem ? [actionItem] : []}
                      isModalNotification={true}
                      selectOneItem={true}
                      storeKey={storeKey}
                    />
                  )}
                  {openProductModal && action == "gotoCollection" && (
                    <ModalCollections
                      isOpen={isOpen}
                      onClose={() => setOpenProductModal(false)}
                      onDone={handleOnApply}
                      availableCategories={[]}
                      selectedCategories={actionItem ? [actionItem] : []}
                      isModalNotification={true}
                      selectOneItem={true}
                      storeKey={storeKey}
                    />
                  )}
                </div>
                <div className="contentSend row">
                  <input
                    id="send"
                    className={`inputComponent ${
                      isDisabled ? "--submit-disabled" : "--submit"
                    }`}
                    type="submit"
                    value="Send Now"
                    disabled={isDisabled}
                    onClick={handleOnSendNow}
                  />
                </div>
                <div>
                      {actionItem && (
                        <p className="itemSelectedTitle">
                          Selected
                          {action === "gotoCollection"
                            ? " Collection: "
                            : " Product: "}
                          <span className="itemSelected">
                            {capitalizeFirstLetter(actionItem.name)}
                          </span>
                        </p>
                      )}
                    </div>
              </div>
              <div
                className="img__modal col"
                style={{ backgroundImage: `url(${Background})` }}
              >
                <picture className="picture__modal">
                  <div className="miniCard">
                    <div className="content__mini">
                      <span className="title__card">{notificationTitle}</span>
                      <span className="paragraph__card">
                        {notificationMessage}
                      </span>
                    </div>
                    <div>
  {actionItem?.images && (
    <Image
      width={49}
      height={60}
      image={action === "gotoProduct"
              ? actionItem?.images[0]?.url
              : actionItem?.images[0]?.path}
      alt="Push Image"
    />
  )}
</div>

                  </div>
                </picture>
              </div>
            </div>
          </div>
        </article>
      )}
    </>
  );
}

export default ModalNotification;