import React from "react";
import { Modal, Col } from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import WarningIcon from "./items/WarningIcon";

export const ModalAlertConfiguration = ({
  onCancel,
  isOpen,
  hasProductAlert,
  hasCollectionAlert,
}) => (
  <Modal isOpen={isOpen} className="modal-dialog-centered confirm-remove-modal">
    <div className="publish-modal-header-alert">
      <div>
        <WarningIcon />
      </div>
      <div className="publish-modal-message" 
     style={{ 
         display: 'block', 
         height: (hasProductAlert || hasCollectionAlert) && !(hasProductAlert && hasCollectionAlert) ? '80px' : undefined 
     }}>

{hasProductAlert && (
    <div>
        <span style={{ color: '#660000', fontWeight: 'bold' }}>Reminder! </span> 
        There are <span style={{ color: '#092D5E', fontWeight: 'bold' }}>Product blocks</span> without any product. Each block should have at least one product
    </div>
)}
{hasCollectionAlert && (
    <div>
      <span style={{ color: '#660000', fontWeight: 'bold' }}>Reminder! </span> 
        There are <span style={{ color: '#092D5E', fontWeight: 'bold' }}>Categories blocks</span> without any category. Each block should have at least one category        
    </div>
)}


      </div>
    </div>
    <div className="publish-modal-buttons-alert">
      <Col xs="14">
        <CustomButton type="CANCEL" onClick={onCancel} label="CLOSE" />
      </Col>
    </div>
  </Modal>
);
