import React from "react";

export const Toggle = ({ label, savedValue, onChange, parentOption }) => {
  const handleChange = () => {
    onChange(label, !savedValue, parentOption);
  };
  // Para la config de ocultar titulo el valor del toggle es contrario, rojo significa que se oculta, verde que se muestra.
  return (
    <label className="custom-toggle">
      <input
        type="checkbox"
        checked={
          label.toLowerCase() === "ocultar titulo" ? !savedValue : savedValue
        }
        value={
          label.toLowerCase() === "ocultar titulo"
            ? (!savedValue).toString()
            : savedValue.toString()
        }
        onChange={handleChange}
      />
      <span className="custom-toggle-slider rounded-circle" />
    </label>
  );
};
