import React from "react";

const CartIcon = ({ active }) => {
  return (
    <svg
      width="83"
      height="43"
      viewBox="0 0 83 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0889 35.8588C25.0889 33.268 27.1945 31.1678 29.7928 31.1678C32.3911 31.1678 34.4967 33.268 34.4967 35.8588C34.4967 38.4495 32.3911 40.5496 29.7928 40.5496C27.1945 40.5496 25.0889 38.4495 25.0889 35.8588Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.31738 35.8588C7.31738 33.268 9.42373 31.1678 12.022 31.1678C14.6196 31.1678 16.726 33.268 16.726 35.8588C16.726 38.4495 14.6196 40.5496 12.022 40.5496C9.42373 40.5496 7.31738 38.4495 7.31738 35.8588Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03945 27.3606L5.56844 12.6875C5.55334 12.6183 5.54099 12.5476 5.53207 12.4756L4.72864 6.00693H2.32247C1.04013 6.00693 0 4.96681 0 3.6838C0 2.40076 1.04014 1.36133 2.32247 1.36133H7.353C8.63121 1.36133 9.66792 2.39256 9.67614 3.66868C9.70909 3.78398 9.73378 3.90333 9.74888 4.02547L10.4021 9.27828H37.7941C39.6809 9.27828 40.9386 11.2179 40.1653 12.9339L35.0648 24.234C34.7094 25.021 33.9849 25.5802 33.1327 25.7256L10.0652 29.6577C9.91698 29.6831 9.76947 29.6954 9.62401 29.6954C8.31423 29.6954 7.1753 28.7061 7.03945 27.3606Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />

      {active && <path d="M83 42V0L62 20.2422L83 42Z" fill="#6D7A8B" />}
    </svg>
  );
};
export default CartIcon;
