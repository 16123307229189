const NAMESPACE = "layout";
export const PLACE_COMPONENT_REQUEST = `${NAMESPACE}/PLACE_COMPONENT_REQUEST`;
export const PLACE_COMPONENT = `${NAMESPACE}/PLACE_COMPONENT`;

export const RE_ARRANGE_PLACED_COMPONENT = `${NAMESPACE}/RE_ARRANGE_PLACED_COMPONENT`;
export const REMOVE_COMPONENT = `${NAMESPACE}/REMOVE_COMPONENT`;
export const SAVE_COMPONENT = `${NAMESPACE}/SAVE_COMPONENT`;
export const SELECT_COMPONENT = `${NAMESPACE}/SELECT_COMPONENT`;
export const CLEAR_COMPONENT_SELECTION = `${NAMESPACE}/CLEAR_COMPONENT_SELECTION`;

export const INIT_LAYOUT = `${NAMESPACE}/FETCH_AVAILABLE_COMPONENTS`;
export const FEED_AVAILABLE_COMPONENTS = `${NAMESPACE}/FEED_AVAILABLE_COMPONENTS`;
export const FEED_USER_CURRENT_CONFIGURATION = `${NAMESPACE}/FEED_USER_CURRENT_CONFIGURATION`;
export const UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT = `${NAMESPACE}/UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT`;

export const GET_CONFIG = `${NAMESPACE}/GET_CONFIG`;

export const LOAD_TEMPLATE = `${NAMESPACE}/LOAD_TEMPLATE`;
export const UPDATE_LOAD_TEMPLATE = `${NAMESPACE}/LOAD_TEMPLATE_SUCCESS`;
export const RELOAD_TEMPLATE = `${NAMESPACE}/RELOAD_TEMPLATE`;
