import productoDestacado from "../../assets/toolbar/products/producto_destacado.svg"; // producto destacado
import sliderLeftHome from "../../assets/toolbar/products/slider1-left.svg"; // Slider con listado de productos configurable #1 - left
import sliderRightHome from "../../assets/toolbar/products/slider1-right.svg"; // Slider con listado de productos configurable #1 - right
import slider2ItemsHome from "../../assets/toolbar/products/slider4-2items.svg"; // Slider con listado de productos configurable #4
import slider3ItemsHome from "../../assets/toolbar/products/slider2-3items.svg"; // Slider con listado de productos configurable #2
import slider6ItemsHome from "../../assets/toolbar/products/slider3-6items.svg"; // Slider con listado de productos configurable #3
import homeCollectionHorizontalSquareCell from "../../assets/toolbar/products/products-carrousel.svg"; // Carrusel de productos
import productListingRegularCell from "../../assets/toolbar/products/products-grid-vertical-theme1.svg"; // Slider productos GRID theme1
import productListingCustomCell from "../../assets/toolbar/products/products-grid-vertical-theme2.svg"; // Slider productos GRID theme2
import sliderHorizontalTheme1 from "../../assets/toolbar/products/slider_horizontal_theme1.svg"; // Slider productos HORIZONTAL theme1
import sliderHorizontalTheme2 from "../../assets/toolbar/products/slider_horizontal_theme2.svg"; // Slider productos HORIZONTAL theme2
import sliderNormalPagerProductHome from "../../assets/toolbar/products/sliderFull_noThemeSelected.svg"; // Slider con productos destacados - sliderFull
import sliderFlipPagerProductHome from "../../assets/toolbar/products/sliderFlip_noThemeSelected.svg"; // Slider con productos destacados - sliderFlip
import sliderCardsPagerProductHome from "../../assets/toolbar/products/sliderCards_noThemeSelected.svg"; // Slider con productos destacados - sliderCards
import sliderContinuousPagerProductHome from "../../assets/toolbar/products/sliderContinuous_noThemeSelected.svg"; // Slider con productos destacados - sliderContinuous

import productoDestacadoPhone from "../../assets/phone/products/producto_destacado.svg";
import sliderLeftHomePhone from "../../assets/phone/products/slider1-left.svg";
import sliderRightHomePhone from "../../assets/phone/products/slider1-right.svg";
import slider2ItemsHomePhone from "../../assets/phone/products/slider4-2items.svg";
import slider3ItemsHomePhone from "../../assets/phone/products/slider2-3items.svg";
import slider6ItemsHomePhone from "../../assets/phone/products/slider3-6items.svg";
import homeCollectionHorizontalSquareCellPhone from "../../assets/phone/products/products-carrousel.svg";
import productListingRegularCellPhone from "../../assets/phone/products/products-grid-vertical-theme1.svg";
import productListingCustomCellPhone from "../../assets/phone/products/products-grid-vertical-theme2.svg";
import sliderHorizontalTheme1Phone from "../../assets/phone/products/slider_horizontal_theme1.svg";
import sliderHorizontalTheme2Phone from "../../assets/phone/products/slider_horizontal_theme2.svg";
import sliderNormalPagerProductHomePhone from "../../assets/phone/products/sliderFull_noThemeSelected.svg";
import sliderFlipPagerProductHomePhone from "../../assets/phone/products/sliderFlip_noThemeSelected.svg";
import sliderCardsPagerProductHomePhone from "../../assets/phone/products/sliderCards_noThemeSelected.svg";
import sliderContinuousPagerProductHomePhone from "../../assets/phone/products/sliderContinuous_noThemeSelected.svg";

// Images
import imageDouble50 from "../../assets/toolbar/images/image_double_50.svg";
import imageDouble100 from "../../assets/toolbar/images/image_double_100.svg";
import imageFull25 from "../../assets/toolbar/images/image_full_25.svg";
import imageFull50 from "../../assets/toolbar/images/image_full_50.svg";
import imageFull75 from "../../assets/toolbar/images/image_full_75.svg";
import imageFull100 from "../../assets/toolbar/images/image_full_100.svg";
import imageTriple50 from "../../assets/toolbar/images/image_triple_50.svg";
import imageTriple100 from "../../assets/toolbar/images/image_triple_100.svg";

import imageDouble50Phone from "../../assets/phone/images/image_double_50.svg";
import imageDouble100Phone from "../../assets/phone/images/image_double_100.svg";
import imageFull25Phone from "../../assets/phone/images/image_full_25.svg";
import imageFull50Phone from "../../assets/phone/images/image_full_50.svg";
import imageFull75Phone from "../../assets/phone/images/image_full_75.svg";
import imageFull100Phone from "../../assets/phone/images/image_full_100.svg";
import imageTriple50Phone from "../../assets/phone/images/image_triple_50.svg";
import imageTriple100Phone from "../../assets/phone/images/image_triple_100.svg";

// separators
import button from "../../assets/toolbar/separators/button.svg";
import lineText from "../../assets/toolbar/separators/separator_line_and_text.svg";
import line from "../../assets/toolbar/separators/separator_line.svg";
import text from "../../assets/toolbar/separators/separator_text.svg";

import buttonPhone from "../../assets/phone/separators/button.svg";
import lineTextPhone from "../../assets/phone/separators/separator_line_and_text.svg";
import linePhone from "../../assets/phone/separators/separator_line.svg";
import textPhone from "../../assets/phone/separators/separator_text.svg";

// articles
import articleNoImage from "../../assets/toolbar/articles/article_no_image.svg"; // Item Noticias no image
import articleWithImage from "../../assets/toolbar/articles/article_with_image.svg"; // Item Noticias with image

import articleNoImagePhone from "../../assets/phone/articles/article_no_image.svg";
import articleWithImagePhone from "../../assets/phone/articles/article_with_image.svg";

// collections
import sliderNormalPagerCategoriesHome from "../../assets/toolbar/collections/sliderFull_noThemeSelected.svg"; // Slider categorias con animaciones - sliderFull
import sliderFlipPagerCategoriesHome from "../../assets/toolbar/collections/sliderFlip_noThemeSelected.svg"; // Slider categorias con animaciones - sliderFlip
import sliderCardsPagerCategoriesHome from "../../assets/toolbar/collections/sliderCards_noThemeSelected.svg"; // Slider categorias con animaciones - sliderCards
import sliderContinuousPagerCategoriesHome from "../../assets/toolbar/collections/sliderContinuous_noThemeSelected.svg"; // Slider categorias con animaciones - sliderContinuous
import homeCategoriesHorizontalCell from "../../assets/toolbar/collections/collectionscarousel.svg"; // Carrusell de categorias

import sliderNormalPagerCategoriesHomePhone from "../../assets/phone/collections/sliderFull_noThemeSelected.svg";
import sliderFlipPagerCategoriesHomePhone from "../../assets/phone/collections/sliderFlip_noThemeSelected.svg";
import sliderCardsPagerCategoriesHomePhone from "../../assets/phone/collections/sliderCards_noThemeSelected.svg";
import sliderContinuousPagerCategoriesHomePhone from "../../assets/phone/collections/sliderContinuous_noThemeSelected.svg";
import homeCategoriesHorizontalCellPhone from "../../assets/phone/collections/collectionscarousel.svg";

import homeVideo from "../../assets/toolbar/videos/video.svg";
import homeVideoPhone from "../../assets/phone/videos/video.svg";

import homeVideoTikTok from "../../assets/toolbar/videos/videoTikTok.svg";
import homeVideoTikTokPhone from "../../assets/phone/videos/videoTikTok.svg";


export const imageMapper = {
  "Producto Destacado": {
    image: productoDestacado,
    phone: productoDestacadoPhone,
    realTitle: "Featuring product",
    realDescription: "Experience the Highlighted Selection of the Day, Week, or Month in a Captivating Display with Personalized Main and Secondary Colors",
    video: "./sidevideos/productFeatured.mp4"
  },
  "Slider con listado de productos configurables #1 - left": {
    image: sliderLeftHome,
    phone: sliderLeftHomePhone,
    realTitle: "Carousel Slider - Left",
    realDescription: "Product Image on the left\nDescription on the right\nExperience smooth navigation with the horizontal scroll bar",
    video: "./sidevideos/productSliderLeft.mp4"
  },
  "Slider con listado de productos configurables #1 - right": {
    //typeView: right
    image: sliderRightHome,
    phone: sliderRightHomePhone,
    realTitle: "Carousel Slider - Right",
    realDescription: "Product Image on the right\nDescription on the left\nExperience smooth navigation with the horizontal scroll bar",
    video: "./sidevideos/productSliderRight.mp4"
  },
  "Slider con listado de productos configurables #2": {
    image: slider3ItemsHome,
    phone: slider3ItemsHomePhone,
    realTitle: "Carousel Slider",
    realDescription: "Engaging 3-image slider\nExperience smooth navigation with the horizontal scroll bar",
    video: "./sidevideos/productCarousel3Items.mp4"
  },
  "Slider con listado de productos configurables #3": {
    image: slider6ItemsHome,
    phone: slider6ItemsHomePhone,
    realTitle: "Carousel Slider",
    realDescription: "Engaging 6-image slider with\n3 products on top, 3 products below\nExperience smooth navigation with the horizontal scroll bar",
    video: "./sidevideos/productCarousel6Items.mp4"
  },
  "Slider con listado de productos configurables #4": {
    image: slider2ItemsHome,
    phone: slider2ItemsHomePhone,
    realTitle: "Carousel Slider",
    realDescription: "Engaging 2-image slider\nExperience smooth navigation with the horizontal scroll bar",
    video: "./sidevideos/productCarousel2Items.mp4"
  },
  "Carousel de productos": {
    image: homeCollectionHorizontalSquareCell,
    phone: homeCollectionHorizontalSquareCellPhone,
    realTitle: "Small Carousel Slider",
    realDescription: "Discover a compact and visually engaging display of product images and descriptions using a small-height horizontal scroll component",
    video: "./sidevideos/productCarouselSmall.mp4"
  },
  "Slider productos GRID - theme 1": {
    image: productListingRegularCell,
    phone: productListingRegularCellPhone,
    realTitle: "Product Grid",
    realDescription: "Discover the visually appealing grid layout of products showcased in this theme, characterized by its beautifully designed presentation.",
    video: "./sidevideos/productGridRegular.mp4"
  },
  "Slider productos GRID - theme 2": {
    image: productListingCustomCell,
    phone: productListingCustomCellPhone,
    realTitle: "Product Grid 3D",
    realDescription: "Discover a visually appealing grid layout of products showcasing a 3D perspective for multi-image viewing.",
    video: "./sidevideos/productGrid3D.mp4"
    
  },
  "Slider productos HORIZONTAL - theme 1": {
    image: sliderHorizontalTheme1,
    phone: sliderHorizontalTheme1Phone,
    realTitle: "Slider - Horizontal",
    realDescription: "Experience the Versatile Product Slider: Revel in its Exquisitely Designed Presentation",
    video: "./sidevideos/productHorizontalListingSmall.mp4"
  },
  "Slider productos HORIZONTAL - theme 2": {
    image: sliderHorizontalTheme2,
    phone: sliderHorizontalTheme2Phone,
    realTitle: "Slider - Horizontal 3D",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products in a 3D perspective for multi-image viewing.",
    video: "./sidevideos/productHorizontalListingSmall3D.mp4"
  },
  "Slider productos destacados - sliderFull": {
    // Normal
    image: sliderNormalPagerProductHome,
    phone: sliderNormalPagerProductHomePhone,
    realTitle: "Featuring Slider - Zoom Out",
    realDescription: "Explore the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs.",
    video: "./sidevideos/productSliderFull.mp4"
  },
  "Slider productos destacados - sliderFlip": {
    image: sliderFlipPagerProductHome,
    phone: sliderFlipPagerProductHomePhone,
    realTitle: "Featuring Slider - 3D",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs in a Captivating 3D Flip View.",
    video: "./sidevideos/productSlider3D.mp4"
  },
  "Slider productos destacados - sliderCards": {
    image: sliderCardsPagerProductHome,
    phone: sliderCardsPagerProductHomePhone,
    realTitle: "Featuring Slider - Overlap",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs in an Engaging Overlap View",
    video: "./sidevideos/productSliderOverlap.mp4"
  },
  "Slider productos destacados - sliderContinuous": {
    image: sliderContinuousPagerProductHome,
    phone: sliderContinuousPagerProductHomePhone,
    realTitle: "Featuring Slider - Linear",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs in an Engaging Linear View",
    video: "./sidevideos/productSliderContinuos.mp4"    
  },
  "Image 100": {
    image: imageFull100,
    phone: imageFull100Phone,
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of 54 Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites",
    video: "./sidevideos/image100.mp4"
  },
  "Image 75": {
    image: imageFull75,
    phone: imageFull75Phone,
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of 54 Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Component Stands at 75% Height",
    video: "./sidevideos/image75.mp4"
  },
  "Image 50": {
    image: imageFull50,
    phone: imageFull50Phone,
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Component Stands at 50% Height",
    video: "./sidevideos/image50.mp4"
  },
  "Image 25": {
    image: imageFull25,
    phone: imageFull25Phone,
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Component Stands at 25% Height",
    video: "./sidevideos/image25.mp4"
  },
  "Image Double 100": {
    image: imageDouble100,
    phone: imageDouble100Phone,
    realTitle: "Double Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Website",
    video: "./sidevideos/imageDouble100.mp4"
  },
  "Image Double 50": {
    image: imageDouble50,
    phone: imageDouble50Phone,
    realTitle: "Double Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Website. This Double Image Component Stands at 50% Height",
    video: "./sidevideos/imageDouble50.mp4"
  },
  "Image Triple 100": {
    image: imageTriple100,
    phone: imageTriple100Phone,
    realTitle: "Triple Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites",
    video: "./sidevideos/imageTriple100.mp4"
  },
  "Image Triple 50": {
    image: imageTriple50,
    phone: imageTriple50Phone,
    realTitle: "Triple Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections, or Websites. This Component Stands at 50% Height",
    video: "./sidevideos/imageTriple50.mp4"
  },
  "Botones": {
    image: button,
    phone: buttonPhone,
    realTitle: "Button",
    realDescription: "Customize Your Design with Background Color, Rounded Corners, and Adjustable Spacing. Link it to Products, Collections, or Websites",
    video: "./sidevideos/buttons.mp4"
  },
  "Separator Line Text": {
    image: lineText,
    phone: lineTextPhone,
    realTitle: "Text Separator with lines",
    realDescription: "Distinctive Text Separator: Elevate Visual Hierarchy and Organize Content with Elegant Text Dividers. Customize Line Color, Spacing, and Line Separators on Both Sides",
    video: "./sidevideos/separatorTextLines.mp4"
  },
  "Separator Line": {
    image: line,
    phone: linePhone,
    realTitle: "Line Separator",
    realDescription: "Versatile Line Separator: Create Visual Divisions with Customizable Line Color for Added Flair and Structure.",
    video: "./sidevideos/separatorLine.mp4"
    
  },
  "Separator Text": {
    image: text,
    phone: textPhone,
    realTitle: "Text Separator",
    realDescription: "Distinctive Text Separator: Elevate Visual Hierarchy and Organize Content with Elegant Text Dividers.",
    video: "./sidevideos/separatorText.mp4"
  },
  "News No Image": {
    image: articleNoImage,
    phone: articleNoImagePhone,
    realTitle: "Blog Post - No Image",
    realDescription: "Engage Readers with the Blog Post Component: Title, Descriptions and Linking to Products, Collections, or Websites.",
    video: "./sidevideos/newTopNoImage.mp4"
  },
  "News Top Image": {
    image: articleWithImage,
    phone: articleWithImagePhone,
    realTitle: "Blog Post with Image",
    realDescription: "Engage Readers with the Blog Post Component: Image Transitions, Title, Descriptions and Linking to Products, Collections, or Websites.",
    video: "./sidevideos/newTopImage.mp4"
  },
  "Slider categorias con animaciones - sliderFull": {
    image: sliderNormalPagerCategoriesHome,
    phone: sliderNormalPagerCategoriesHomePhone,
    realTitle: "Slider - Zoom Out",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options.",
    video: "./sidevideos/categorySliderZoomOut.mp4"    
  },
  "Slider categorias con animaciones - sliderFlip": {
    image: sliderFlipPagerCategoriesHome,
    phone: sliderFlipPagerCategoriesHomePhone,
    realTitle: "Slider  - 3D",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options in a Captivating 3D Flip View.",
    video: "./sidevideos/categorySlider3D.mp4"
  },
  "Slider categorias con animaciones - sliderCards": {
    image: sliderCardsPagerCategoriesHome,
    phone: sliderCardsPagerCategoriesHomePhone,
    realTitle: "Slider - Overlap",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options in an Engaging Overlap View",
    video: "./sidevideos/categorySliderOverlap.mp4"
  },
  "Slider categorias con animaciones - sliderContinuous": {
    image: sliderContinuousPagerCategoriesHome,
    phone: sliderContinuousPagerCategoriesHomePhone,
    realTitle:  "Slider - Linear",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options in an Engaging Linear View",
    video: "./sidevideos/categorySliderContinuos.mp4"
  },
  "Carousel de categorías": {
    image: homeCategoriesHorizontalCell,
    phone: homeCategoriesHorizontalCellPhone,
    realTitle: "Small Carousel Slider",
    realDescription: "Discover a compact and visually engaging display of categories images and descriptions using a small-height horizontal scroll component",
    video: "./sidevideos/categorySliderSmall.mp4"    
  },
  "Video": {
    image: homeVideo,
    phone: homeVideoPhone,
    realTitle: "Video Player",
    realDescription: "Elevate Your Digital Presence with the Ultimate Video Feature. A Platform to Broadcast Your Brand's Videos, Enhancing Engagement Through Visual Storytelling",
    video: "./sidevideos/videoPlayer.mp4"    
  },
  "Video TikTok": {
    image: homeVideoTikTok,
    phone: homeVideoTikTokPhone,
    realTitle: "TikTok Video",
    realDescription: "Engage and Inspire with TikTok: Feature the Creative, Fast-Paced World of TikTok on Mobile App, Providing Users with a Stream of Entertaining Content",
    video: "./sidevideos/videoTikTok.mp4"    
  },
};
