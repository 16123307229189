import React from "react";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Menu from "../components/menu-page/Menu.js";

export const CollectionsMenuPage = ({ storeKey }) => {
  return (
   <div className="market-layout">
        <Container fluid className="layout-container">
          <Row className="main-row no-gutters">
            <div style={{display: "flex",    width: "100%" }}>
            <Menu storeKey={storeKey}/>
            </div>
          </Row>
        </Container>
    </div>
  );
};
