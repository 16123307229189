import { all, takeLatest, put } from "redux-saga/effects";
import { layoutSagas, initLayout } from "./layout";
import { initConfigurator, configuratorSagas } from "./configurator";
import { INIT } from "./rootTypes";

function* init(data) {  
  yield put(initLayout(data.payload));
  yield put(initConfigurator(data.payload));
}

const mainSagas = [takeLatest(INIT, init)];

export function* rootSaga(firebaseConfig) {
  yield all([...layoutSagas, ...mainSagas, ...configuratorSagas, firebaseConfig]);
}