import React, { useEffect, useState } from "react";
import { CustomButton } from "../home-layout-draggable-columns/custom-button";

export const UploadedImages = ({ onSelect, onRemove, savedImages, selectedImage, userType }) => {
  const [images, setImages] = useState(savedImages);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    setImages(savedImages);
  });

  const removeHandler = async (image) => {
    console.log("removeHandler", image);

    images.splice(images.indexOf(image), 1);
    setImages(images);
    onRemove(image)
  };

  const getImageContainerClass = (currentImage) => {
    console.log("savedImages", savedImages);
    console.log("savedImages[currentImage]", savedImages[currentImage]);
    console.log("current", currentImage);
    console.log("selectedImage", selectedImage);
    return hover === currentImage ||
      selectedImage === savedImages[currentImage].url
      ? "image hover"
      : "image";
  };

  // Function to truncate image name if it's longer than 100 characters
  const truncateImageName = (name) => {
    return name.length > 100 ? `${name.substring(0, 50)}...` : name;
  };

  return (
    <div className="uploaded-images-container">
      {images.map((entry, image) => (
        <div
          onMouseEnter={() => setHover(image)}
          onMouseLeave={() => setHover(null)}
          className="image-container"
          key={image}
        >
          <div className={getImageContainerClass(image)}>
            {hover === image && (
              <>
                <div className={userType === 'trial' ? 'save-button-trial' : 'save-button'}>
                  <CustomButton
                    type="SAVE"
                    label="SELECT"
                    onClick={() => onSelect(entry.url)}
                  />
                </div>
                {/* Conditionally render remove button */}
                {!shouldDisableRemoveButton(userType) && (
                  <div className="remove-button">
                    <CustomButton
                      type="REMOVE"
                      icon={true}
                      customWidth="57px"
                      onClick={() => removeHandler(entry)}
                    />
                  </div>
                )}
              </>
            )}
            <div className="up-image-wrapper">
              <img className="up-image" alt="Description" src={entry.url} />
            </div>
            <div className="image-name">{truncateImageName(entry.name)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Function to check if remove button should be disabled based on user type
const shouldDisableRemoveButton = (userType) => {
  return userType === "trial";
};
