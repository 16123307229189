import React, { useState, useEffect } from "react";

export const TYPE_CLASS = {
  SAVE: "save-button",
  SAVEAS: "save-as-button",
  THEMES: "themes-button",
  PREVIEW: "preview-button",
  PUBLISH: "publish-button",
  REMOVE: "delete-button",
  CANCEL: "cancel-button",
  CONTENT: "content-button",
  CONTENT_IMAGE: "content-button upload-image",
};

export const CustomButton = (props) => {
  const buttonStyles = {
    width: props.customWidth,
    marginLeft: props.customMarginLeft,
    paddingLeft: props.customPaddingLeft,
    textAlign: props.alignLeft ? 'left' : 'center',
    opacity: props.disabled ? 0.5 : 1,
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    display: props.display,
  };

  const buttonClassName = `custom-button ${TYPE_CLASS[props.type]} ${props.icon ? 'btn with-icon' : ''}`;

  const handleClick = () => {
    if (!props.disabled && typeof props.onClick === 'function') {
      props.onClick();
    }
  };  

  return (
    <div
      style={buttonStyles}
      className={buttonClassName}
      onClick={handleClick}
    >
      {props.label && <span>{props.label}</span>}
      {props.icon && (
        <span style={{ paddingLeft: "10px" }}>
          <i className="fa fa-trash-o" />
        </span>
      )}
      {props.counter && (
        <span className="content-counter">{props.itemsCounter}</span>
      )}
    </div>
  );
};


