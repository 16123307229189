import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import CollectionsIcon from "./CollectionsIcon";
import HomeIcon from "./HomeIcon";
import ProductListsIcon from "./ProductListsIcon";
import ProductDetailsIcon from "./ProductDetailsIcon";
import ColorsIcon from "./ColorsIcon";
import CartIcon from "./CartIcon";

const AppBar = ({activeOption}) => {

  
  const [appBarOptions, setAppBarOptions] = useState([
    {
      label: "home",
      active: true,
    },
    {
      label: "collections",
      active: false,
    },
    {
      label: "lists",
      active: false,
    },
    {
      label: "details",
      active: false,
    },
    {
      label: "cart",
      active: false,
    },
    {
      label: "colors",
      active: false,
    },
  ]);
  
  useEffect(() => {
    if (activeOption) {
      const temp = appBarOptions.map(op => {
        if (op.label !== activeOption) {
          op.active = false
        } else {
          op.active = true
        }
        return op
      })

      setAppBarOptions(temp)
    }
  }, [activeOption])

  const getActive = (option) => {
    return appBarOptions.find((op) => op?.label === option)?.active;
  };
  return (
    <div className="appbar">
      <Link to="/home" className="home">
        <HomeIcon active={getActive("home")} />
      </Link>
      <Link to="/collections" className="collections">
        <CollectionsIcon active={getActive("collections")} />
      </Link>
      <Link to="/lists" className="prod-lists">
        <ProductListsIcon active={getActive("lists")} />
      </Link>
      <Link to="/details" className="prod-details">
        <ProductDetailsIcon active={getActive("details")} />
      </Link>
      <Link to="/cart" className="cart">
        <CartIcon active={getActive("cart")} />
      </Link>
      <Link to="/colors" className="colors">
        <ColorsIcon active={getActive("colors")} />
      </Link>
    </div>
  );
};
export default AppBar;
