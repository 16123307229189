import {
  insert,
  update,
  remove,
  assoc,
  findIndex,
  propEq,
  adjust,
} from "ramda";
import {
  PLACE_COMPONENT,
  RE_ARRANGE_PLACED_COMPONENT,
  REMOVE_COMPONENT,
  SAVE_COMPONENT,
  SELECT_COMPONENT,
  CLEAR_COMPONENT_SELECTION,
  FEED_AVAILABLE_COMPONENTS,
  FEED_USER_CURRENT_CONFIGURATION,
  UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT,
  UPDATE_LOAD_TEMPLATE,
} from "./types";

const initalState = {
  availableComponents: [],
  placedComponents: [],
  selectedComponent: null,
};

export const layoutReducer = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_LOAD_TEMPLATE:
      console.log("REDUCER LOAD TEMPLATE", payload);
      return {
        ...state,
        currentTheme: payload,
      };

    case FEED_USER_CURRENT_CONFIGURATION:
      return {
        ...state,
        placedComponents: payload,
      };
    case FEED_AVAILABLE_COMPONENTS:
      return {
        ...state,
        availableComponents: payload,
      };
    case PLACE_COMPONENT:
      const { component, idx, uuid: insertionID } = payload;

      return {
        ...state,
        placedComponents: insert(
          idx,
          assoc("uuid", insertionID, component),
          state.placedComponents
        ),
        selectedComponent: insertionID,
      };
    case RE_ARRANGE_PLACED_COMPONENT:
      // Modificar payload con ids de componentes y nuevos order in list
      const { oldIdx, newIdx, encryptedConfig } = payload;
      const componentToMove = state.placedComponents[oldIdx];
      const listWithoutComponent = remove(oldIdx, 1, state.placedComponents);
      const updatedPlacedComponents = insert(
        newIdx,
        componentToMove,
        listWithoutComponent
      );
      const newOrder = updatedPlacedComponents.map((comp, index) => {
        return {
          uuid: comp.uuid,
          order: index,
        };
      });
      payload.newOrder = newOrder;
      return {
        ...state,
        placedComponents: updatedPlacedComponents,
      };
    case REMOVE_COMPONENT:
      const { uuid: targetComponentUUID } = payload;
      const componentIdx = state.placedComponents.findIndex(
        ({ uuid }) => uuid === targetComponentUUID
      );
      return {
        ...state,
        placedComponents: remove(componentIdx, 1, state.placedComponents),
      };
    case SAVE_COMPONENT:
      return {
        ...state,
        placedComponents: adjust(
          findIndex(
            propEq("uuid", state.selectedComponent),
            state.placedComponents
          ),
          assoc("configurationOptions", payload.configuration),
          state.placedComponents
        ),
      };
    case SELECT_COMPONENT:
      console.log("SELECT_COMPONENT", payload);
      const { uuid } = payload;
      return {
        ...state,
        selectedComponent: uuid,
      };
    case UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT:
      console.log("UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT", payload);
      console.log(
        "UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT",
        state.selectedComponent
      );
      console.log(
        "UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT",
        state.placedComponents
      );
      return {
        ...state,
        placedComponents: adjust(
          findIndex(
            propEq("uuid", state.selectedComponent),
            state.placedComponents
          ),
          assoc("configurationOptions", payload),
          state.placedComponents
        ),
      };
    case CLEAR_COMPONENT_SELECTION:
      console.log("CLEAR_COMPONENT_SELECTION", state);
      return {
        ...state,
        selectedComponent: null,
      };
    default:
      return state;
  }
};
