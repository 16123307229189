import * as types from "./types";

export const getConfig = (payload) => ({
  type: types.GET_CONFIG,
  payload,
});

export const placeComponent = (payload) => ({
  type: types.PLACE_COMPONENT,
  payload,
});

export const reArrangePlacedComponents = (payload) => ({
  type: types.RE_ARRANGE_PLACED_COMPONENT,
  payload,
});

export const removeComponentFromStack = (payload) => ({
  type: types.REMOVE_COMPONENT,
  payload,
});
export const saveComponentConfig = (payload) => ({
  type: types.SAVE_COMPONENT,
  payload,
});

export const selectComponent = (payload) => ({
  type: types.SELECT_COMPONENT,
  payload,
});

export const clearComponentSelection = () => ({
  type: types.CLEAR_COMPONENT_SELECTION,
});

export const feedAvailableComponents = (payload) => ({
  type: types.FEED_AVAILABLE_COMPONENTS,
  payload,
});

export const initLayout = (payload) => ({
  type: types.INIT_LAYOUT,
  payload: payload,
});

export const feedUserConfiguration = (payload) => ({
  type: types.FEED_USER_CURRENT_CONFIGURATION,
  payload,
});

export const placeComponentRequest = (payload) => ({
  type: types.PLACE_COMPONENT_REQUEST,
  payload,
});

export const updateConfigurationOfSelectedComponent = (payload) => ({
  type: types.UPDATE_CONFIGURATION_OF_SELECTED_COMPONENT,
  payload,
});

// NEW TYPES
export const feedLoadTemplate = (payload) => ({
  type: types.UPDATE_LOAD_TEMPLATE,
  payload,
});

export const loadTemplate = (payload) => ({
  type: types.LOAD_TEMPLATE,
  payload,
});

export const reLoadTemplate = (payload) => ({
  type: types.RELOAD_TEMPLATE,
  payload,
});

