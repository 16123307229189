import React from "react";

const Image = ({ image, alt, classes, width, height }) => {
  const className = `img ${classes ? classes : ""}`;
  if (!image) return null;
  return (
    <img
      width={width}
      height={height}
      className={className}
      src={image}
      alt={alt}
    />
  );
};

export default Image;
