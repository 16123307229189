import React from "react";
import { Modal, Col } from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import WarningIcon from "./items/WarningIcon";

export const ModalPublish = ({ onConfirm, onCancel, isOpen }) => (
  <Modal isOpen={isOpen} className="modal-dialog-centered confirm-remove-modal">
    <div className="publish-modal-header">
      <div>
        <WarningIcon />
      </div>
      <div className="publish-modal-message">
      Confirm Publishing: Are You Sure You Want to Deploy This Theme to Your Production App?
      </div>
    </div>
    <div className="publish-modal-buttons">
    <Col xs="14">
      <CustomButton type="CANCEL" onClick={onCancel} label="CANCEL" />
      </Col>
      <Col xs="4">
      <CustomButton type="CONTENT" onClick={onConfirm} label="PUBLISH" />
      </Col>
      
    </div>
  </Modal>
);
