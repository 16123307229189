import React from "react";

const ProductListsIcon = ({ active }) => {
  return (
    <svg
      width="83"
      height="43"
      viewBox="0 0 83 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54031 40.6178C2.48027 40.6178 0 38.1375 0 35.0782C0 32.0181 2.48027 29.5378 5.54031 29.5378H5.57187C8.6319 29.5378 11.1122 32.0181 11.1122 35.0782C11.1122 38.1375 8.6319 40.6178 5.57187 40.6178H5.54031Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1333 38.6177C16.2012 38.6177 14.6355 37.052 14.6355 35.12C14.6355 33.1886 16.2012 31.6222 18.1333 31.6222H38.3164C40.2485 31.6222 41.8149 33.1886 41.8149 35.12C41.8149 37.052 40.2485 38.6177 38.3164 38.6177H18.1333Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54031 26.025C2.48027 26.025 0 23.5447 0 20.4847C0 17.4246 2.48027 14.9443 5.54031 14.9443H5.57187C8.6319 14.9443 11.1122 17.4246 11.1122 20.4847C11.1122 23.5447 8.6319 26.025 5.57187 26.025H5.54031Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1333 24.025C16.2012 24.025 14.6355 22.4586 14.6355 20.5272C14.6355 18.5951 16.2012 17.0294 18.1333 17.0294H38.3164C40.2485 17.0294 41.8149 18.5951 41.8149 20.5272C41.8149 22.4586 40.2485 24.025 38.3164 24.025H18.1333Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54031 11.4315C2.48027 11.4315 0 8.95125 0 5.89188C0 2.83187 2.48027 0.351562 5.54031 0.351562H5.57187C8.6319 0.351562 11.1122 2.83187 11.1122 5.89188C11.1122 8.95125 8.6319 11.4315 5.57187 11.4315H5.54031Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1333 9.4315C16.2012 9.4315 14.6355 7.86578 14.6355 5.93373C14.6355 4.00233 16.2012 2.43591 18.1333 2.43591H38.3164C40.2485 2.43591 41.8149 4.00233 41.8149 5.93373C41.8149 7.86577 40.2485 9.4315 38.3164 9.4315H18.1333Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />

      {active && <path d="M83 42V0L62 20.2422L83 42Z" fill="#6D7A8B" />}
    </svg>
  );
};
export default ProductListsIcon;
