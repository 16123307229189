import React from "react";

const CollectionsIcon = ({ active }) => {
  return (
    <svg
      width="83"
      height="43"
      viewBox="0 0 83 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93412 41.6946C0.865858 41.6946 0 40.8315 0 39.7667V32.0768C0 31.0113 0.865866 30.1482 1.93412 30.1482H39.9567C41.025 30.1482 41.8909 31.0113 41.8909 32.0768V39.7667C41.8909 40.8315 41.025 41.6946 39.9567 41.6946H1.93412Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5014 32.354C34.0925 32.7609 34.0925 33.4202 34.5014 33.8278L36.5158 35.8367L34.3299 38.0164C33.9216 38.4227 33.9216 39.0827 34.3299 39.4902C34.5343 39.6933 34.8019 39.7956 35.0695 39.7956C35.3364 39.7956 35.604 39.6933 35.8091 39.4902L38.7326 36.5736C39.1415 36.166 39.1415 35.506 38.7326 35.0998L35.9793 32.354C35.7748 32.1502 35.5079 32.0487 35.2403 32.0487C34.9727 32.0487 34.7058 32.1502 34.5014 32.354L34.5014 32.354Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93412 26.6203C0.865858 26.6203 0 25.7571 0 24.6916V17.0031C0 15.9376 0.865866 15.0745 1.93412 15.0745H39.9567C41.025 15.0745 41.8909 15.9376 41.8909 17.0031V24.6916C41.8909 25.7571 41.025 26.6203 39.9567 26.6203H1.93412Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5014 17.167C34.0925 17.5732 34.0925 18.2332 34.5014 18.6407L36.5158 20.649L34.3299 22.8288C33.9216 23.2363 33.9216 23.895 34.3299 24.3025C34.5343 24.5063 34.8019 24.6085 35.0695 24.6085C35.3364 24.6085 35.604 24.5063 35.8091 24.3025L38.7326 21.3865C39.1415 20.9783 39.1415 20.3197 38.7326 19.9121L35.9793 17.167C35.7748 16.9632 35.5079 16.8617 35.2403 16.8617C34.9727 16.8617 34.7058 16.9632 34.5014 17.167L34.5014 17.167Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93412 11.5465C0.865858 11.5465 0 10.6834 0 9.61784V1.92865C0 0.863121 0.865866 0 1.93412 0H39.9567C41.025 0 41.8909 0.863121 41.8909 1.92865V9.61784C41.8909 10.6834 41.025 11.5465 39.9567 11.5465H1.93412Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5014 2.20454C34.0925 2.6121 34.0925 3.27211 34.5014 3.67897L36.5158 5.68858L34.3299 7.86695C33.9216 8.27451 33.9216 8.93452 34.3299 9.34073C34.5343 9.54518 34.8019 9.64605 35.0695 9.64605C35.3364 9.64605 35.604 9.54518 35.8091 9.34073L38.7326 6.42546C39.1415 6.01791 39.1415 5.35789 38.7326 4.95169L35.9793 2.20453C35.7748 2.00143 35.5079 1.8999 35.2403 1.8999C34.9727 1.8999 34.7058 2.00143 34.5014 2.20453L34.5014 2.20454Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      {active && <path d="M83 42V0L62 20.2422L83 42Z" fill="#6D7A8B" />}
    </svg>
  );
};
export default CollectionsIcon;
