import React, { useState, useEffect } from "react";
import { Row, Container } from "reactstrap";
import { connect } from "react-redux";
import { ModalProducts } from "../../../../modals/modalProducts";
import {
  getAvailableProducts,
  getSelectedProducts,
  addProducts,
  getSelectedComponentsCurrentConfiguration,
  getComponentProducts,
  getComponentProductsOther
} from "../../../../../ducks";
import { addMarginTop } from "../../../../utils/utilities";
import { CustomButton } from "../../home-layout-draggable-columns/custom-button";

export const UpdateContentSectionBase = ({
  title,
  availableProducts,
  selectedProducts,
  label,
  onInit,
  currentSelectedConfiguration,
  currentProduct,
  onChange,
  parentOption,
  storeKey
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(0);

  const done = (params) => {
    
    console.log("params", params);
    console.log("products", params.configuration.products);
    console.log("parentOption", parentOption);
    
    setSelectedItems(params.configuration.products.length);
    onChange(params.label, params.configuration.products, parentOption);
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("currentProduct", currentProduct);
    setSelectedItems(currentProduct ? currentProduct.length : 0);   
  }, [currentProduct]);

// WORK AROUND FOR DUPLICATE BLOCK ITEMS

  // We look for regular products or inside image 1,2,3 or buttons and news. There are those 3 options
  const findLabelAndUpdateContent = (dataArray) => {
    console.log("label", label);
    console.log("dataArray", dataArray);

    const includesTitle =
      title.includes("Boton") ||
      title.includes("News Top Image") ||
      title.includes("News No Image")  ||
      title.includes("Video") 

      // regular
    let labelSearch = "Update content";

    // If its Button, etc
    if (includesTitle == true) {
      labelSearch = "Update product";
    }
 
    const updateContentObject = dataArray.find(
      (obj) => obj.label === labelSearch
    );
    if (updateContentObject && updateContentObject.products) {
      return updateContentObject.products;
    }

    // If not found, then look for 'Image 1', 'Image 2', or 'Image 3'
    for (let i = 1; i <= 3; i++) {
      const imageObject = dataArray.find((obj) => obj.label === `Image ${i}`);

      // Check if 'Image' exists and it has options
      if (imageObject && imageObject.options) {
        const updateProductObject = imageObject.options.find(
          (obj) => obj.label === "Update product"
        );
        if (updateProductObject && updateProductObject.products) {
          return updateProductObject.products;
        }
      }
    }

    return null;
  };
  

  
  // Usage:

  useEffect(() => {
    console.log("ENTRA AL INIt currentSelectedConfiguration", currentSelectedConfiguration);
    
    const products = findLabelAndUpdateContent(currentSelectedConfiguration);
    if (products !== null) {
      console.log("findLabelAndUpdateContent" , products);
      setSelectedItems(products.length);
      selectedProducts = products;
    } else {
      selectedProducts = [];
      console.log("No object with the label 'Update content' found, or the object doesn't have a 'products' property.");
    }

    
  }, [currentSelectedConfiguration]);
 
  
  return (
    <Container style={addMarginTop(title)}>
      <Row>
        <CustomButton
          type="CONTENT"
          onClick={() => {
            setIsOpen(true);
          }}          
          label="SELECT PRODUCTS"
          counter={true}
          itemsCounter={selectedItems}
        />
      </Row>
      <ModalProducts
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDone={done}
        label={label}
        availableProducts={availableProducts}
        selectedProducts={currentProduct}
        onInit={onInit}
        currentSelectedConfiguration={currentSelectedConfiguration}
        parentOption={parentOption}
        selectOneItem={
          title.includes("Boton") ||
          title.includes("Producto Destacado") ||
          title.includes("News Top Image") ||
          title.includes("News No Image") ||
          title.includes("Image 50") ||
          title.includes("Image 25") ||
          title.includes("Image 75") ||
          title.includes("Image 100") ||
          title.includes("Video")
        }
        storeKey={storeKey}
        >
        {console.log("title",title)}
      </ModalProducts>
    </Container>
  );
};

const mapStateToProps = (state, { title }) => {
  const includesTitle = title.includes("Boton") ||
                        title.includes("News Top Image") ||
                        title.includes("News No Image") ||
                        title.includes("Video")

  return {
    availableProducts: getAvailableProducts(state),
    selectedProducts: getSelectedProducts(state),
    currentSelectedConfiguration: getSelectedComponentsCurrentConfiguration(state),
    currentProduct: includesTitle ? getComponentProductsOther(state) : getComponentProducts(state)
  }
};


const dispatchToProps = {
  onInit: addProducts,
};

export const UpdateContentSection = connect(
  mapStateToProps,
  dispatchToProps
)(UpdateContentSectionBase);
