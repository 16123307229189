import React from "react";

const CollectionFullImage = (props) => {
  return (
    <div className="CollectionFullImage">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7499)">
          <rect width="379" height="664" fill="white" />
          <rect x="-17" y="-3" width="409" height="282" rx="8" fill="#EAE8EE" />
          <path
            d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9168 175.903 77.0217 176.438 77.1566Z"
            fill="#FBFBFC"
          />
          <path
            d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7724 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9845 62.0382 75.6202 63.0571 78.1332 62.6225C81.1072 62.1131 83.8135 63.5066 86.6388 64.1209C90.044 67.672 93.583 71.1632 92.9585 76.767Z"
            fill="#FBFBFC"
          />
          <path
            d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
            fill="#FBFBFC"
          />
          <path
            d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.729 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.59476 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.506C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.489 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
            fill="#C0BFCA"
          />
          <path
            d="M92.1467 151.836L92.1303 155.197C90.7352 155.654 89.455 156.469 87.9614 156.6C82.5779 156.208 77.3586 154.936 72.1721 153.598C55.2995 149.242 37.9837 151.836 20.75 151.836C10.8858 150.939 -0.471955 151.428 -10.369 151.836C-10.369 151.836 -14.1932 151.64 -16.967 151.836V119.303C-14.4558 118.308 -12.1088 119.026 -10.369 121.522C-8.10401 124.752 -6.24934 125.127 -2.93391 123.023C-2.49076 122.745 -2.06402 122.468 -1.63728 122.158C0.627718 120.527 2.95837 120.184 5.50238 121.587C7.50477 122.68 9.63847 122.892 11.9035 122.452C13.3806 122.158 15.0219 122.011 16.1216 123.316C17.9763 125.552 20.4711 126.188 23.1792 126.498C25.7561 126.792 28.2508 127.51 30.0891 129.402C33.8477 133.253 37.7704 133.497 42.2183 130.61C44.0894 129.386 44.7952 129.745 46.1082 131.572C46.5842 132.225 47.0437 132.894 47.5854 133.481C49.6206 135.667 51.0978 135.945 53.9372 134.786C55.0205 134.884 56.0873 134.998 57.2034 134.949C59.534 134.884 60.9292 136.385 61.717 138.343C62.4392 140.17 63.5881 141.068 65.6069 141.084C67.5928 141.1 69.267 142.096 70.7113 143.368C73.124 145.473 75.9471 146.452 79.1148 146.501C81.6424 146.533 83.8417 147.365 85.8113 148.915C87.666 150.384 89.6848 151.607 92.1467 151.836Z"
            fill="#66646F"
          />
          <path
            d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
            fill="#9694A0"
          />
          <path
            d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
            fill="#C0BFCA"
          />
          <path
            d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.812 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.907 129.598 343.886 133.026 343.823 136.464C343.781 138.795 343.263 140.968 340.35 141C337.436 141.031 336.866 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
            fill="#66646F"
          />
          <path
            d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5578 350.473 90.7409C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
            fill="#66646F"
          />
          <path
            d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6323 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1876C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
            fill="#C0BFCA"
          />
          <path
            d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.319 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
            fill="#66646F"
          />
          <path
            d="M269.856 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.577 36.9479C271.148 40.7897 275.859 48.0707 279.754 55.9892C285.672 68.0348 289.414 80.785 291.846 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.703 139.419L269.856 139.553Z"
            fill="#66646F"
          />
          <path
            d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7728 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2432 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
            fill="#C0BFCA"
          />
          <rect
            x="-17"
            y="252"
            width="409"
            height="282"
            rx="8"
            fill="#EAE8EE"
          />
          <path
            d="M176.438 332.157C180.022 334.344 183.635 336.472 186.193 339.978C187.189 341.342 188.631 341.027 190.044 340.862C197.657 339.918 204.587 344.533 206.817 352.01C207.828 355.411 207.382 356.085 203.873 356.1C186.475 356.115 169.077 356.1 151.68 356.13C150.074 356.13 148.468 356.31 146.877 356.4C140.527 356.31 134.193 356.16 127.843 356.19C125.375 356.205 124.602 355.426 125.167 352.894C126.877 345.222 134.193 339.873 141.955 340.847C143.59 341.057 144.973 341.237 146.312 339.708C154.074 330.763 163.68 328.306 174.877 331.782C175.382 331.917 175.903 332.022 176.438 332.157Z"
            fill="#FBFBFC"
          />
          <path
            d="M92.9585 331.767C68.7504 331.782 44.5571 331.797 20.349 331.857C17.8508 331.857 17.4791 330.808 17.9698 328.62C19.4568 321.953 25.2263 317.338 32.3787 317.653C34.966 317.772 36.7058 317.113 38.5348 315.195C47.8879 305.396 62.8024 305.441 72.2596 315.24C73.9845 317.038 75.6202 318.057 78.1332 317.623C81.1072 317.113 83.8135 318.507 86.6388 319.121C90.044 322.672 93.583 326.163 92.9585 331.767Z"
            fill="#FBFBFC"
          />
          <path
            d="M107.263 273.436C115.338 269.256 123.471 268.536 131.59 273.256C138.148 277.077 141.865 282.891 143.159 290.397C143.575 292.84 142.877 293.739 140.378 293.709C130.906 293.634 121.434 293.679 111.977 293.679C103.159 293.679 94.3265 293.694 85.5087 293.664C82.044 293.649 81.6276 292.99 82.4901 289.543C83.2187 286.622 84.9287 284.269 86.5793 281.857C89.6871 279.489 92.9436 277.137 97.0031 277.377C101.152 277.631 104.557 276.627 107.263 273.436Z"
            fill="#FBFBFC"
          />
          <path
            d="M53.9043 389.77C51.0648 390.928 49.5876 390.635 47.5524 388.465C47.0108 387.877 46.5348 387.208 46.0752 386.556C44.7622 384.729 44.0729 384.386 42.1854 385.593C37.7374 388.481 33.8147 388.236 30.0561 384.386C28.2179 382.51 25.7231 381.792 23.1463 381.482C20.4381 381.172 17.9433 380.535 16.0887 378.3C14.989 376.995 13.3477 377.142 11.8705 377.435C9.60551 377.892 7.47182 377.664 5.46943 376.571C2.92541 375.168 0.59476 375.51 -1.67024 377.142C-2.09698 377.452 -2.52371 377.729 -2.96687 378.007C-6.26588 380.111 -8.13697 379.736 -10.402 376.506C-12.1417 374.009 -14.4888 373.291 -17 374.287V363.6C-14.177 364.546 -11.239 364.922 -8.26827 364.791C-3.67263 364.579 0.2665 365.786 3.61476 368.984C5.35454 370.648 7.43899 371.187 9.86812 370.518C12.6583 369.751 14.9726 370.501 16.7288 372.9C18.0254 374.646 19.7652 375.755 22.0466 375.657C26.593 375.461 30.4172 377.093 33.6506 380.177C35.1606 381.612 36.884 382.656 39.0669 382.493C43.9251 382.118 47.9627 383.489 50.753 387.665C51.5408 388.758 52.9031 389.019 53.9043 389.77Z"
            fill="#C0BFCA"
          />
          <path
            d="M92.1467 406.836L92.1303 410.197C90.7352 410.654 89.455 411.469 87.9614 411.6C82.5779 411.208 77.3586 409.936 72.1721 408.598C55.2995 404.242 37.9837 406.836 20.75 406.836C10.8858 405.939 -0.471955 406.428 -10.369 406.836C-10.369 406.836 -14.1932 406.64 -16.967 406.836V374.303C-14.4558 373.308 -12.1088 374.026 -10.369 376.522C-8.10401 379.752 -6.24934 380.128 -2.93391 378.023C-2.49076 377.745 -2.06402 377.468 -1.63728 377.158C0.627718 375.527 2.95837 375.184 5.50238 376.587C7.50477 377.68 9.63847 377.892 11.9035 377.452C13.3806 377.158 15.0219 377.011 16.1216 378.317C17.9763 380.552 20.4711 381.188 23.1792 381.498C25.7561 381.792 28.2508 382.51 30.0891 384.402C33.8477 388.253 37.7704 388.497 42.2183 385.61C44.0894 384.386 44.7952 384.745 46.1082 386.572C46.5842 387.225 47.0437 387.894 47.5854 388.481C49.6206 390.667 51.0978 390.945 53.9372 389.786C55.0205 389.884 56.0873 389.998 57.2034 389.949C59.534 389.884 60.9292 391.385 61.717 393.343C62.4392 395.17 63.5881 396.068 65.6069 396.084C67.5928 396.1 69.267 397.096 70.7113 398.368C73.124 400.473 75.9471 401.452 79.1148 401.501C81.6424 401.533 83.8417 402.365 85.8113 403.915C87.666 405.384 89.6848 406.607 92.1467 406.836Z"
            fill="#66646F"
          />
          <path
            d="M392 367.963V526.036C392 530.434 387.892 534 382.841 534H-7.84078C-12.9079 534 -17 530.434 -17 526.036V398.404C27.3255 404.023 106.737 413.652 190.609 394.256C270.676 375.719 340.529 364.023 392 367.963Z"
            fill="#9694A0"
          />
          <path
            d="M392 367.963V526.036C392 530.434 387.892 534 382.841 534H-7.84078C-12.9079 534 -17 530.434 -17 526.036V413.097C27.3255 418.716 113.643 455.539 190.609 420.714C272.243 383.753 340.529 364.023 392 367.963Z"
            fill="#C0BFCA"
          />
          <path
            d="M336.687 384.169C336.687 382.246 336.698 380.334 336.698 378.411C336.74 377 336.782 375.579 336.824 374.168L336.74 374.084C339.083 374.742 341.448 374.596 343.812 374.209C343.855 376.53 343.897 378.839 343.939 381.159C343.907 384.598 343.886 388.026 343.823 391.464C343.781 393.795 343.263 395.968 340.35 396C337.436 396.031 336.866 393.878 336.803 391.537C336.74 389.071 336.729 386.625 336.687 384.169Z"
            fill="#66646F"
          />
          <path
            d="M336.824 374.168C325.899 371.785 318.372 363.685 318.858 353.266C319.586 337.882 323.872 323.376 332.306 310.25C337.627 312.643 340.551 317.178 342.968 322.111C346.641 329.615 348.964 337.558 350.473 345.741C351.202 349.702 352.173 353.673 351.423 357.76C349.882 366.152 344.699 371.346 336.729 374.084L336.824 374.168Z"
            fill="#66646F"
          />
          <path
            d="M336.75 374.073C344.71 371.335 349.893 366.141 351.444 357.749C352.194 353.673 351.223 349.691 350.494 345.73C348.985 337.558 346.662 329.605 342.989 322.101C340.572 317.168 337.658 312.632 332.327 310.239C337.373 303.373 342.282 302.996 347.57 309.173C348.848 310.667 350.019 312.308 350.948 314.043C355.698 322.906 358.696 332.385 360.66 342.188C361.166 344.696 361.536 347.235 361.969 349.765C362.285 356.464 361.029 362.64 356.163 367.719C354.157 369.82 351.708 371.273 349.312 372.819C347.486 373.279 345.66 373.739 343.823 374.199C341.458 374.586 339.094 374.732 336.75 374.073Z"
            fill="#C0BFCA"
          />
          <path
            d="M269.635 410.608C269.635 407.522 269.652 404.451 269.652 401.365C269.72 399.1 269.788 396.818 269.856 394.553L269.72 394.419C273.495 395.476 277.305 395.241 281.114 394.62C281.182 398.345 281.25 402.052 281.319 405.777C281.267 411.296 281.233 416.799 281.131 422.318C281.063 426.06 280.23 429.549 275.536 429.599C270.842 429.65 269.924 426.194 269.822 422.436C269.72 418.477 269.703 414.551 269.635 410.608Z"
            fill="#66646F"
          />
          <path
            d="M269.856 394.553C252.253 390.728 240.127 377.726 240.91 361C242.083 336.305 248.988 313.019 262.577 291.948C271.148 295.79 275.859 303.071 279.754 310.989C285.672 323.035 289.414 335.785 291.846 348.921C293.019 355.279 294.584 361.654 293.376 368.214C290.893 381.686 282.543 390.023 269.703 394.419L269.856 394.553Z"
            fill="#66646F"
          />
          <path
            d="M269.736 394.402C282.56 390.007 290.91 381.669 293.41 368.197C294.618 361.654 293.053 355.262 291.88 348.904C289.448 335.785 285.706 323.018 279.788 310.972C275.893 303.054 271.199 295.773 262.611 291.931C270.74 280.909 278.648 280.305 287.169 290.22C289.226 292.619 291.114 295.253 292.611 298.038C300.264 312.264 305.094 327.48 308.257 343.217C309.074 347.243 309.669 351.32 310.366 355.38C310.876 366.134 308.852 376.049 301.012 384.202C297.781 387.574 293.835 389.906 289.975 392.389C287.033 393.127 284.09 393.865 281.131 394.603C277.322 395.224 273.512 395.459 269.736 394.402Z"
            fill="#C0BFCA"
          />
          <rect
            x="-17"
            y="507"
            width="409"
            height="282"
            rx="8"
            fill="#EAE8EE"
          />
          <path
            d="M176.438 587.157C180.022 589.344 183.635 591.472 186.193 594.978C187.189 596.342 188.631 596.027 190.044 595.862C197.657 594.918 204.587 599.533 206.817 607.01C207.828 610.411 207.382 611.085 203.873 611.1C186.475 611.115 169.077 611.1 151.68 611.13C150.074 611.13 148.468 611.31 146.877 611.4C140.527 611.31 134.193 611.16 127.843 611.19C125.375 611.205 124.602 610.426 125.167 607.894C126.877 600.222 134.193 594.873 141.955 595.847C143.59 596.057 144.973 596.237 146.312 594.708C154.074 585.763 163.68 583.306 174.877 586.782C175.382 586.917 175.903 587.022 176.438 587.157Z"
            fill="#FBFBFC"
          />
          <path
            d="M92.9585 586.767C68.7504 586.782 44.5571 586.797 20.349 586.857C17.8508 586.857 17.4791 585.808 17.9698 583.62C19.4568 576.953 25.2263 572.338 32.3787 572.653C34.966 572.772 36.7058 572.113 38.5348 570.195C47.8879 560.396 62.8024 560.441 72.2596 570.24C73.9845 572.038 75.6202 573.057 78.1332 572.623C81.1072 572.113 83.8135 573.507 86.6388 574.121C90.044 577.672 93.583 581.163 92.9585 586.767Z"
            fill="#FBFBFC"
          />
          <path
            d="M107.263 528.436C115.338 524.256 123.471 523.536 131.59 528.256C138.148 532.077 141.865 537.891 143.159 545.397C143.575 547.84 142.877 548.739 140.378 548.709C130.906 548.634 121.434 548.679 111.977 548.679C103.159 548.679 94.3265 548.694 85.5087 548.664C82.044 548.649 81.6276 547.99 82.4901 544.543C83.2187 541.622 84.9287 539.269 86.5793 536.857C89.6871 534.489 92.9436 532.137 97.0031 532.377C101.152 532.631 104.557 531.627 107.263 528.436Z"
            fill="#FBFBFC"
          />
          <path
            d="M53.9043 644.77C51.0648 645.928 49.5876 645.635 47.5524 643.465C47.0108 642.877 46.5348 642.208 46.0752 641.556C44.7622 639.728 44.0729 639.386 42.1854 640.593C37.7374 643.481 33.8147 643.236 30.0561 639.386C28.2179 637.51 25.7231 636.792 23.1463 636.482C20.4381 636.172 17.9433 635.535 16.0887 633.3C14.989 631.995 13.3477 632.142 11.8705 632.435C9.60551 632.892 7.47182 632.664 5.46943 631.571C2.92541 630.168 0.59476 630.51 -1.67024 632.142C-2.09698 632.452 -2.52371 632.729 -2.96687 633.007C-6.26588 635.111 -8.13697 634.736 -10.402 631.505C-12.1417 629.009 -14.4888 628.291 -17 629.287V618.6C-14.177 619.546 -11.239 619.922 -8.26827 619.791C-3.67263 619.579 0.2665 620.786 3.61476 623.984C5.35454 625.648 7.43899 626.187 9.86812 625.518C12.6583 624.751 14.9726 625.501 16.7288 627.9C18.0254 629.646 19.7652 630.755 22.0466 630.657C26.593 630.461 30.4172 632.093 33.6506 635.176C35.1606 636.612 36.884 637.656 39.0669 637.493C43.9251 637.118 47.9627 638.488 50.753 642.665C51.5408 643.758 52.9031 644.019 53.9043 644.77Z"
            fill="#C0BFCA"
          />
          <path
            d="M92.1467 661.836L92.1303 665.197C90.7352 665.654 89.455 666.469 87.9614 666.6C82.5779 666.208 77.3586 664.936 72.1721 663.598C55.2995 659.242 37.9837 661.836 20.75 661.836C10.8858 660.938 -0.471955 661.428 -10.369 661.836C-10.369 661.836 -14.1932 661.64 -16.967 661.836V629.303C-14.4558 628.308 -12.1088 629.025 -10.369 631.522C-8.10401 634.752 -6.24934 635.127 -2.93391 633.023C-2.49076 632.745 -2.06402 632.468 -1.63728 632.158C0.627718 630.527 2.95837 630.184 5.50238 631.587C7.50477 632.68 9.63847 632.892 11.9035 632.452C13.3806 632.158 15.0219 632.011 16.1216 633.316C17.9763 635.552 20.4711 636.188 23.1792 636.498C25.7561 636.792 28.2508 637.51 30.0891 639.402C33.8477 643.253 37.7704 643.497 42.2183 640.609C44.0894 639.386 44.7952 639.745 46.1082 641.572C46.5842 642.225 47.0437 642.894 47.5854 643.481C49.6206 645.667 51.0978 645.945 53.9372 644.786C55.0205 644.884 56.0873 644.998 57.2034 644.949C59.534 644.884 60.9292 646.385 61.717 648.343C62.4392 650.17 63.5881 651.068 65.6069 651.084C67.5928 651.1 69.267 652.096 70.7113 653.368C73.124 655.473 75.9471 656.452 79.1148 656.501C81.6424 656.533 83.8417 657.365 85.8113 658.915C87.666 660.384 89.6848 661.607 92.1467 661.836Z"
            fill="#66646F"
          />
          <path
            d="M392 622.963V781.036C392 785.434 387.892 789 382.841 789H-7.84078C-12.9079 789 -17 785.434 -17 781.036V653.404C27.3255 659.023 106.737 668.652 190.609 649.256C270.676 630.719 340.529 619.023 392 622.963Z"
            fill="#9694A0"
          />
          <path
            d="M392 622.963V781.036C392 785.434 387.892 789 382.841 789H-7.84078C-12.9079 789 -17 785.434 -17 781.036V668.097C27.3255 673.716 113.643 710.539 190.609 675.714C272.243 638.753 340.529 619.023 392 622.963Z"
            fill="#C0BFCA"
          />
          <path
            d="M336.687 639.169C336.687 637.246 336.698 635.334 336.698 633.411C336.74 632 336.782 630.578 336.824 629.168L336.74 629.084C339.083 629.742 341.448 629.596 343.812 629.209C343.855 631.53 343.897 633.839 343.939 636.159C343.907 639.598 343.886 643.026 343.823 646.464C343.781 648.794 343.263 650.968 340.35 651C337.436 651.031 336.866 648.878 336.803 646.537C336.74 644.071 336.729 641.625 336.687 639.169Z"
            fill="#66646F"
          />
          <path
            d="M336.824 629.168C325.899 626.785 318.372 618.685 318.858 608.266C319.586 592.882 323.872 578.376 332.306 565.249C337.627 567.643 340.551 572.178 342.968 577.111C346.641 584.615 348.964 592.558 350.473 600.741C351.202 604.702 352.173 608.673 351.423 612.76C349.882 621.152 344.699 626.346 336.729 629.084L336.824 629.168Z"
            fill="#66646F"
          />
          <path
            d="M336.75 629.073C344.71 626.335 349.893 621.141 351.444 612.749C352.194 608.673 351.223 604.691 350.494 600.73C348.985 592.558 346.662 584.605 342.989 577.101C340.572 572.168 337.658 567.632 332.327 565.239C337.373 558.373 342.282 557.996 347.57 564.173C348.848 565.667 350.019 567.308 350.948 569.043C355.698 577.906 358.696 587.385 360.66 597.188C361.166 599.696 361.536 602.235 361.969 604.764C362.285 611.464 361.029 617.64 356.163 622.719C354.157 624.82 351.708 626.273 349.312 627.819C347.486 628.279 345.66 628.739 343.823 629.199C341.458 629.586 339.094 629.732 336.75 629.073Z"
            fill="#C0BFCA"
          />
          <path
            d="M269.635 665.608C269.635 662.522 269.652 659.451 269.652 656.365C269.72 654.1 269.788 651.818 269.856 649.553L269.72 649.419C273.495 650.476 277.305 650.241 281.114 649.62C281.182 653.345 281.25 657.052 281.319 660.777C281.267 666.296 281.233 671.799 281.131 677.318C281.063 681.06 280.23 684.549 275.536 684.599C270.842 684.65 269.924 681.194 269.822 677.436C269.72 673.477 269.703 669.551 269.635 665.608Z"
            fill="#66646F"
          />
          <path
            d="M269.856 649.553C252.253 645.728 240.127 632.726 240.91 616C242.083 591.305 248.988 568.019 262.577 546.948C271.148 550.79 275.859 558.071 279.754 565.989C285.672 578.035 289.414 590.785 291.846 603.921C293.019 610.279 294.584 616.654 293.376 623.214C290.893 636.686 282.543 645.023 269.703 649.419L269.856 649.553Z"
            fill="#66646F"
          />
          <path
            d="M269.736 649.402C282.56 645.007 290.91 636.669 293.41 623.197C294.618 616.654 293.053 610.262 291.88 603.904C289.448 590.785 285.706 578.018 279.788 565.972C275.893 558.054 271.199 550.773 262.611 546.931C270.74 535.909 278.648 535.305 287.169 545.22C289.226 547.619 291.114 550.253 292.611 553.038C300.264 567.264 305.094 582.48 308.257 598.217C309.074 602.243 309.669 606.32 310.366 610.38C310.876 621.134 308.852 631.049 301.012 639.202C297.781 642.574 293.835 644.906 289.975 647.389C287.033 648.127 284.09 648.865 281.131 649.603C277.322 650.224 273.512 650.459 269.736 649.402Z"
            fill="#C0BFCA"
          />
        </g>
        <defs>
          <clipPath id="clip0_76_7499">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CollectionFullImage;
