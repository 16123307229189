import {
  CONFIGURE_COMPONENT,
  ADD_PRODUCT,
  ADD_PRODUCT_2,
  ADD_PRODUCT_3,
  FEED_CATEGORIES,
  FEED_CATEGORIES_FOR_PRODUCTS,
  SELECT_CATEGORY_REQUEST,
  SELECT_PRODUCT_REQUEST,
  FEED_PRODUCTS,
  ADD_PRODUCTS,
  ADD_PRODUCTS_2,
  ADD_PRODUCTS_3,
  ADD_ALL_PRODUCTS,
  RE_ARRANGE_PLACED_COMPONENT,
  RE_ARRANGE_PLACED_COMPONENT_CATEGORIES,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_2,
  REMOVE_PRODUCT_3,
  REMOVE_CATEGORY,
  REMOVE_CATEGORY_2,
  REMOVE_CATEGORY_3,
  ADD_CATEGORY,
  ADD_CATEGORY_2,
  ADD_CATEGORY_3,
  ADD_CATEGORIES,
  ADD_CATEGORIES_2,
  ADD_CATEGORIES_3,
  REMOVE_PRODUCTS,
  REMOVE_CATEGORIES,
  ADD_ALL_CATEGORIES,
} from "./types";
import { insert, isEmpty, remove } from "ramda";

const initalState = {
  component: null,
  availableProducts: [],
  selectedProducts: [],
  availableProducts2: [],
  selectedProducts2: [],
  availableProducts3: [],
  selectedProducts3: [],
  availableCategories: [],
  selectedCategories: [],
  availableCategories2: [],
  selectedCategories2: [],
  availableCategories3: [],
  selectedCategories3: [],
  availableCategoriesForProducts: [],
  selectedCategoriesForProducts: [],
};

export const configuratorReducer = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CONFIGURE_COMPONENT:
      return {
        ...state,
        component: payload,
      };

    case ADD_PRODUCT:
      if (!payload) {
        console.warn('ADD_PRODUCT action was dispatched without a payload');
        return state;
      }
      const { product, idx } = payload;
      return {
        ...state,
        selectedProducts: insert(idx, product, state.selectedProducts),
      };
    case ADD_PRODUCT_2:
      if (!payload) {
        console.warn('ADD_PRODUCT_2 action was dispatched without a payload');
        return state;
      }
      const { product: prod2, idx: idxProd2 } = payload;
      return {
        ...state,
        selectedProducts2: insert(idxProd2, prod2, state.selectedProducts2),
      };
    case ADD_PRODUCT_3:
      if (!payload) {
        console.warn('ADD_PRODUCT_3 action was dispatched without a payload');
        return state;
      }
      const { product: prod3, idx: idxProd3 } = payload;
      return {
        ...state,
        selectedProducts3: insert(idxProd3, prod3, state.selectedProducts3),
      };
    case ADD_PRODUCTS: // TODO rename, unclear
    if (!payload) {
      console.warn('payload action was dispatched without a payload');
      return state;
    }
      return {
        ...state,
        selectedProducts: payload,
      };
    case ADD_ALL_PRODUCTS: // TODO rename, unclear
    if (!payload) {
      console.warn('ADD_ALL_PRODUCTS action was dispatched without a payload');
      return state;
    }
      return {
        ...state,
        selectedProducts: payload,
        availableProducts: [],
      };
    case ADD_ALL_CATEGORIES: // TODO rename, unclear
    if (!payload) {
      console.warn('ADD_ALL_CATEGORIES action was dispatched without a payload');
      return state;
    }
      return {
        ...state,
        selectedCategories: payload,
        availableCategories: [],
      };
    case REMOVE_PRODUCTS: // TODO rename, unclear
      return {
        ...state,
        selectedProducts: [],
        availableProducts: payload,
      };
    case REMOVE_CATEGORIES: // TODO rename, unclear
      return {
        ...state,
        selectedCategories: [],
        availableCategories: payload,
      };
    case ADD_PRODUCTS_2: // TODO rename, unclear
      return {
        ...state,
        selectedProducts2: payload,
      };
    case ADD_PRODUCTS_3: // TODO rename, unclear
      return {
        ...state,
        selectedProducts3: payload,
      };
    case ADD_CATEGORY:
      const { product: category, idx: idxC } = payload;
      return {
        ...state,
        selectedCategories: insert(idxC, category, state.selectedCategories),
      };
    case ADD_CATEGORY_2:
      const { product: category2, idx: idxC2 } = payload;
      return {
        ...state,
        selectedCategories2: insert(
          idxC2,
          category2,
          state.selectedCategories2
        ),
      };
    case ADD_CATEGORY_3:
      const { product: category3, idx: idxC3 } = payload;
      return {
        ...state,
        selectedCategories3: insert(
          idxC3,
          category3,
          state.selectedCategories3
        ),
      };
    case ADD_CATEGORIES: // TODO rename, unclear
      return {
        ...state,
        selectedCategories: payload,
      };
    case ADD_CATEGORIES_2: // TODO rename, unclear
      return {
        ...state,
        selectedCategories2: payload,
      };
    case ADD_CATEGORIES_3: // TODO rename, unclear
      return {
        ...state,
        selectedCategories3: payload,
      };
    case FEED_CATEGORIES:
      return {
        ...state,
        availableCategories: payload,
        availableCategories2: payload,
        availableCategories3: payload,
      };
    case FEED_CATEGORIES_FOR_PRODUCTS:
      return {
        ...state,
        availableCategoriesForProducts: payload,
        selectedCategoriesForProducts: payload.map(({ id }) => id),
      };
    case SELECT_CATEGORY_REQUEST:
      const deducedSelectedCategories = state.selectedCategoriesForProducts.includes(
        payload
      )
        ? state.selectedCategoriesForProducts.filter((id) => id !== payload)
        : state.selectedCategoriesForProducts.concat(payload);
      // If no selection then that means all
      const selectedCategoriesForProducts = isEmpty(deducedSelectedCategories)
        ? state.availableCategoriesForProducts.map(({ id }) => id)
        : deducedSelectedCategories;

      return {
        ...state,
        selectedCategoriesForProducts,
      };
    case SELECT_PRODUCT_REQUEST:
      let availableProducts = state.availableProducts2;
      const results = state.availableProducts.filter((ap) =>
        payload.includes(ap)
      );
      if (results.length) {
        availableProducts = results;
      }

      return {
        ...state,
        availableProducts,
      };
    case FEED_PRODUCTS:
      return {
        ...state,
        availableProducts: payload,
        availableProducts2: payload,
        availableProducts3: payload,
      };
    case REMOVE_PRODUCT:
      if (!payload) {
        console.warn('REMOVE_PRODUCT action was dispatched without a payload');
        return state;
      }
      const { id: productId } = payload;
      return {
        ...state,
        selectedProducts: state.selectedProducts.filter(
          ({ id }) => id !== productId
        ),
      };
    case REMOVE_PRODUCT_2:
      if (!payload) {
        console.warn('REMOVE_PRODUCT action was dispatched without a payload');
        return state;
      }
      const { id: productId2 } = payload;
      return {
        ...state,
        selectedProducts2: state.selectedProducts2.filter(
          ({ id }) => id !== productId2
        ),
      };
    case REMOVE_PRODUCT_3:
      if (!payload) {
        console.warn('REMOVE_PRODUCT action was dispatched without a payload');
        return state;
      }
      const { id: productId3 } = payload;
      return {
        ...state,
        selectedProducts3: state.selectedProducts3.filter(
          ({ id }) => id !== productId3
        ),
      };
    case REMOVE_CATEGORY:
      if (!payload) {
        console.warn('REMOVE_CATEGORY action was dispatched without a payload');
        return state;
      }
      const { id: categoryId } = payload;
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(
          ({ id }) => id !== categoryId
        ),
      };
    case REMOVE_CATEGORY_2:
      if (!payload) {
        console.warn('REMOVE_CATEGORY action was dispatched without a payload');
        return state;
      }
      const { id: categoryId2 } = payload;
      return {
        ...state,
        selectedCategories2: state.selectedCategories2.filter(
          ({ id }) => id !== categoryId2
        ),
      };
    case REMOVE_CATEGORY_3:
      if (!payload) {
        console.warn('REMOVE_CATEGORY action was dispatched without a payload');
        return state;
      }
      const { id: categoryId3 } = payload;
      return {
        ...state,
        selectedCategories3: state.selectedCategories3.filter(
          ({ id }) => id !== categoryId3
        ),
      };
    case RE_ARRANGE_PLACED_COMPONENT:
      if (!payload) {
        console.warn('RE_ARRANGE_PLACED_COMPONENT action was dispatched without a payload');
        return state;
      }
      const { oldIdx, newIdx } = payload;
      const componentToMove = state.selectedProducts[oldIdx];
      const listWithoutComponent = remove(oldIdx, 1, state.selectedProducts);
      return {
        ...state,
        selectedProducts: insert(newIdx, componentToMove, listWithoutComponent),
      };

    case RE_ARRANGE_PLACED_COMPONENT_CATEGORIES:
      if (!payload) {
        console.warn('RE_ARRANGE_PLACED_COMPONENT_CATEGORIES action was dispatched without a payload');
        return state;
      }
      const { oldIdx: oldCategoryId, newIdx: newCategoryId } = payload;

      const categoryToMove = state.selectedCategories[oldCategoryId];
      const listWithoutCategory = remove(
        oldCategoryId,
        1,
        state.selectedCategories
      );
      return {
        ...state,
        selectedCategories: insert(
          newCategoryId,
          categoryToMove,
          listWithoutCategory
        ),
      };
    default:
      return state;
  }
};
