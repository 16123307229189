import { useState } from "react";

export const IModalAction = {
  SHOW: "SHOW",
  HIDE: "HIDE",
};

export const IModalAnimation = {
  SHOW: "translateX(0)",
  HIDE: "translateX(125%)",
};

const useSetAnimation = () => {
  const [state, setStyle] = useState({
    transform: IModalAnimation.HIDE,
  });

  const setState = (action) => {
    setStyle({
      transition: "0.5s",
      transform: IModalAnimation[action],
    });
  };

  return [state, setState];
};
export default useSetAnimation;
