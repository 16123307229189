import React, { useState, useEffect } from 'react';
import { Modal, ButtonGroup } from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import { fetchForFengCategories } from "../../api";
import  Collection  from "./items/Collection.jsx"

import "./items/modalCSS.css"

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';


export const ModalCollections = ({ 
  isOpen,
  onClose,
  onDone,
  availableCategories,
  selectedCategories,
  label,
  isModalNotification,
  selectOneItem,
  storeKey
}) => {

  const filterProducts = () => {
    console.log("filtered availableCategories", availableCategories);
    console.log("filtered products", selectedCategories);
    const selectedProductList = selectedCategories ?? [];
    const filteredAvailableProducts = availableCategories.filter((ap) => {
      // returns true if ap is not found in selectedProducts
      return !selectedProductList.some((sp) => sp.id === ap.id);
    });
  
    setProducts(filteredAvailableProducts);
  }

  useEffect(() => {
    if (isModalNotification == true) {
      fetchData()
    }   
  }, [isModalNotification]);

  useEffect(() => {  
    setCartItems(selectedCategories ?? []);
    filterProducts();
  }, [selectedCategories]);

  useEffect(() => {
    filterProducts();
  }, [availableCategories]);


  // Fetch Products for Notification Modal
  const fetchData = async () => {
    const response = await fetchForFengCategories({storeKey: storeKey, onlyRoot: true});
    const filteredCategories = response.data.filter((category) => {
      const foundIndex = selectedCategories.findIndex((selectedCategory) => selectedCategory.id === category.id);
      return foundIndex === -1; // Exclude categories found in selectedCategories
    });
    console.log("filteredCategories", filteredCategories);
    setProducts(filteredCategories);
  };

  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const handleAddToCart = (product) => {
    if (
      (!selectOneItem || (selectOneItem && cartItems.length === 0))
    ) {
      console.log("handleAddToCart", product);
      const foundProduct = products.find((p) => p.id === product.id);
      if (foundProduct) {
        setCartItems([...cartItems, foundProduct]);
        setProducts(products.filter((p) => p.id !== product.id));
      }

    }
  };

  const handleRemoveFromCart = (productId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== productId);
    setCartItems(updatedCartItems);

    const removedProduct = cartItems.find((item) => item.id === productId);
    if (removedProduct) {
      setProducts([...products, removedProduct]);
    }
  };

  const handleAddAllToCart = () => {
    setCartItems([...cartItems, ...products]);
    setProducts([]);
  };

  const handleRemoveAllFromCart = () => {
    setProducts([...products, ...cartItems]);
    setCartItems([]);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const secondHandleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setCartItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="transitionSelector preview previewModal isOpen"
      size="xl"
    >
      <section>
        <div class="container-modal-product">
          <div class="left_container">
            <div class="product_container">
              <h2 class="">Select Collections</h2>
              <div class="products">
                <div class="product_list">
                 {products &&
                    products.map((product) => (
                    <Collection
                      key={product.id}
                      id={product.id}
                      name={product.name}
                      price={product.price}
                      image={product.images}
                      onAddToCart={handleAddToCart}
                      onRemoveFromCart={null} // Disable remove button in the products list
                    />
                  ))}
                </div>
              </div>

              {/* {products.length > 0 && ( */}
              <div class="bottom_product_container">
                <h3 class="product_found">{products.length} Collections Found</h3>
                <h3 class="addToCart" onClick={handleAddAllToCart}>
                  Add them all
                </h3>
              </div>
              {/* )} */}
            </div>
          </div>
          <div class="right_container">
            <div class="item_container">
              <div class="right_container_btn">
                <ButtonGroup>
                  <CustomButton
                    type="CANCEL"
                    label="CANCEL"
                    onClick={onClose}
                  />
                   <button
                type="button"
                className="close__button custom-button save-modal-button"
                onClick={() => {
                  console.log("cartItems: ", cartItems);
                  onDone({
                    label,
                    configuration: {
                      type: label === "Update category" ? "supermodalCategories" : "supermodal",
                      products: cartItems,
                    },
                  });
                }} 
              >
                UPDATE
              </button>
                </ButtonGroup>
              </div>

              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={secondHandleDragEnd}
              >
                <SortableContext
                  items={cartItems}
                  strategy={rectSortingStrategy}
                >
                  <div class="products">
                    <div class="product_list">
                    {cartItems &&
                        cartItems.map((item) => {
                          // Check if item has valid id, name, price, and images properties
                          if (
                            item &&
                            item.id &&
                            item.name
                          ) {
                            return (
                        <Collection
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          image={item.images}
                          onAddToCart={null}
                          onRemoveFromCart={() => handleRemoveFromCart(item.id)} // Update the prop
                          >
                          {console.log("cartItems", item)}
                        </Collection>
                      );
                    } else {
                      // Handle validation error, e.g., log an error message
                      console.error("Invalid item in cartItems:", item);
                      return null; // Skip rendering invalid item
                    }
                  })}
                    </div>
                  </div>
                </SortableContext>
              </DndContext>

              <div class="bottom_product_container">
                <h3 class="product_found">{cartItems.length} Collections Found</h3>
                <h3 class="removeFromCart" onClick={handleRemoveAllFromCart}>
                  CLEAR
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ModalCollections;

