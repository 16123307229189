import React from "react";

const ProductDetailsIcon = ({ active }) => {
  return (
    <svg
      width="83"
      height="43"
      viewBox="0 0 83 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5813 41.626C28.2969 41.626 27.2554 40.5844 27.2554 39.3V34.8644C27.2554 33.58 28.2969 32.5385 29.5813 32.5385H39.5641C40.8492 32.5385 41.8907 33.58 41.8907 34.8644V39.3C41.8907 40.5844 40.8492 41.626 39.5641 41.626H29.5813Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1268 41.6259C9.19437 41.6259 8.43896 40.8705 8.43896 39.9381C8.43896 39.0057 9.19437 38.2502 10.1268 38.2502H23.477C24.4088 38.2502 25.1649 39.0057 25.1649 39.9381C25.1649 40.8705 24.4088 41.6259 23.477 41.6259H10.1268Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.76399 35.9141C0.831575 35.9141 0.0761719 35.1587 0.0761719 34.2263C0.0761719 33.2946 0.831575 32.5385 1.76399 32.5385H23.4772C24.4089 32.5385 25.165 33.2946 25.165 34.2263C25.165 35.1587 24.4089 35.9141 23.4772 35.9141H1.76399Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93139 29.6664C0.864486 29.6664 0 28.8019 0 27.735V1.98343C0 0.917205 0.864493 0.0527344 1.93139 0.0527344H39.9595C41.0257 0.0527344 41.8909 0.917205 41.8909 1.98343V27.735C41.8909 28.8019 41.0257 29.6664 39.9595 29.6664H1.93139Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />

      {active && <path d="M83 42V0L62 20.2422L83 42Z" fill="#6D7A8B" />}
    </svg>
  );
};
export default ProductDetailsIcon;
