import React from "react";
import { DragableLayout } from "../components";

export const DesignPage = ({ storeKey, userType }) => {
  console.log('DesignPage storeKey:', storeKey); // Add console.log here
  console.log('DesignPage userType:', userType); // Add console.log here
  return (
    <>
      <DragableLayout storeKey={storeKey} userType={userType}/>
    </>
  );
};
