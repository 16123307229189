import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";

export const MultilineInput = ({
  label,
  savedValue,
  onChange,
  parentOption,
  placeholder,
  disabled,
  isSearch,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  const handleKeyPress = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange && isSearch) {
      onChange(e.target.value);
    }
  };

  const handleOnBlur = () => {
    if (onChange && !isSearch) {
      onChange(label, value, parentOption);
    }
  };

  return (
    <Row>
      <textarea
        className={"multiline-input"}
        value={value}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        onBlur={handleOnBlur}
        disabled={disabled}
        placeholder={placeholder || ""}
        maxLength={55000} 
      />
    </Row>
  );
};
