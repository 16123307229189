import { assoc } from "ramda";
import { createSelector } from "reselect";

const getEcomerce = (state) => state.layout;

export const getCurrentTheme = createSelector(
  getEcomerce,
  (namespace) => namespace.currentTheme
);

export const getConfig = createSelector(
  getEcomerce,
  (namespace) => namespace.config
);

export const getAvailableComponents = createSelector(
  getEcomerce,
  (namespace) => namespace.availableComponents
);

export const getPlacedComponents = createSelector(
  getEcomerce,
  (namespace) => namespace.placedComponents
);

export const getSelectedComponentUuid = createSelector(
  getEcomerce,
  (namespace) => namespace.selectedComponent
);

export const WHATNAMEINHERE = createSelector(
  getEcomerce,
  (namespace) => namespace.componentProducts
);


export const getSelectedComponent = createSelector(
  [getSelectedComponentUuid, getPlacedComponents],
  (selectedUUID, placedComponents) => {
    console.log('getSelectedComponent - selectedUUID:', selectedUUID);
    console.log('getSelectedComponent - placedComponents:', placedComponents);

    const response = placedComponents.find(({ uuid }) => uuid === selectedUUID);
    console.log('getSelectedComponent - response:', response);

    return response;
  }
);


// New Method to get products
export const getComponentProducts = createSelector(
  [getSelectedComponentUuid, getPlacedComponents],
  (selectedUUID, placedComponents) => {
    const selectedComponent = placedComponents.find(({ uuid }) => uuid === selectedUUID);
    
    if (!selectedComponent || !selectedComponent.configurationOptions) {
      console.log('No selected component found or no configuration options available.');
      return null;
    }

    const updateContentOption = selectedComponent.configurationOptions.find(option => option.label === "Update content");

    if (!updateContentOption || !updateContentOption.products) {
      console.log('No configuration option with label "Update content" found, or no products in the option.');
      return null;
    }

    return updateContentOption.products;
  }
);

export const getComponentProductsOther = createSelector(
  [getSelectedComponentUuid, getPlacedComponents],
  (selectedUUID, placedComponents) => {
    const selectedComponent = placedComponents.find(({ uuid }) => uuid === selectedUUID);
    
    if (!selectedComponent || !selectedComponent.configurationOptions) {
      console.log('No selected component found or no configuration options available.');
      return null;
    }

    const updateProductOption = selectedComponent.configurationOptions.find(option => option.label === "Update product");

    if (!updateProductOption || !updateProductOption.products) {
      console.log('No configuration option with label "Update product" found, or no products in the option.');
      return null;
    }

    return updateProductOption.products;
  }
);

export const getComponentProductsImages = createSelector(
  [getSelectedComponentUuid, getPlacedComponents, (_, props) => props.imageNumber],
  (selectedUUID, placedComponents, imageNumber) => {

    const selectedComponent = placedComponents.find(({ uuid }) => uuid === selectedUUID);
    console.log("selectedComponent", selectedComponent);

    if (!selectedComponent || !selectedComponent.configurationOptions) {
      console.log('No selected component found or no configuration options available.');
      return null;
    }

    const imageOption = selectedComponent.configurationOptions.find(option => option.label === `Image ${imageNumber}`);

    if (imageOption && imageOption.options) {
      const updateContentOptionImages = imageOption.options.find(option => option.label === "Update product");

      if (updateContentOptionImages && updateContentOptionImages.products) {
        return updateContentOptionImages.products;
      }
    }

    console.log('No products in the options.');
    return null;
  }
);

export const getComponentCollectionImages = createSelector(
  [getSelectedComponentUuid, getPlacedComponents, (_, props) => props.imageNumber],
  (selectedUUID, placedComponents, imageNumber) => {

    const selectedComponent = placedComponents.find(({ uuid }) => uuid === selectedUUID);
    console.log("selectedComponent", selectedComponent);

    if (!selectedComponent || !selectedComponent.configurationOptions) {
      console.log('No selected component found or no configuration options available.');
      return null;
    }

    const imageOption = selectedComponent.configurationOptions.find(option => option.label === `Image ${imageNumber}`);

    if (imageOption && imageOption.options) {
      const updateContentOptionImages = imageOption.options.find(option => option.label === "Update category");

      if (updateContentOptionImages && updateContentOptionImages.products) {
        return updateContentOptionImages.products;
      }
    }

    console.log('No products in the options.');
    return null;
  }
);

// New Method to get products
export const getComponentCollections = createSelector(
  [getSelectedComponentUuid, getPlacedComponents],
  (selectedUUID, placedComponents) => {
    const selectedComponent = placedComponents.find(({ uuid }) => uuid === selectedUUID);
    
    if (!selectedComponent || !selectedComponent.configurationOptions) {
      console.log('No selected component found or no configuration options available.');
      return null;
    }

    const updateContentOption = selectedComponent.configurationOptions.find(option => option.label === "Update category");

    if (!updateContentOption || !updateContentOption.products) {
      console.log('No configuration option with label "Update category" found, or no collections in the option.');
      return null;
    }

    return updateContentOption.products;
  }
);

export const getSelectedComponentsCurrentConfiguration = createSelector(
  getSelectedComponent,
  ({ configurationOptions }) => {
    console.log('getSelectedComponentsCurrentConfiguration - configurationOptions:', configurationOptions);

    return configurationOptions;
  }
);

export const availableComponentsAsObject = createSelector(
  getAvailableComponents,
  (availableComponents) =>
    availableComponents.reduce((acc, c) => assoc(c.componentId, c, acc), {})
);
