import { useState } from 'react';

function useWizard() {

    const [isOpenModal, setIsOpenModal] = useState(false)
    const openModal = ()=> {
        setIsOpenModal(true);
    }
    const closeModal = ()=> {
        setIsOpenModal(false);
    }

    return ( 
        [isOpenModal, openModal, closeModal]
    );
}

export default useWizard;