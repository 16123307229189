/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButtonIcon from "../../common/RadioButtonIcon";
import BoxText from "../../common/BoxText";
import ListStyle from "./ListStyle";
import { Link } from "react-router-dom";

import {
  fetchCurrentConfigTheme,
  fetchCheckoutListData,
  updateCheckoutListData,
} from "../../../api";

function CartCheckoutListStyle({ storeKey }) {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState();
  const [container, setContainer] = useState(); // no veo esto en los svgs
  const [block, setBlock] = useState("plain");
  const [checkOut, setCheckOut] = useState();
  const [bottomView, setBottomView] = useState();
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [templateID]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);

    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);
    console.log("storeKey", storeKey);

    // API
    fetchCheckoutListData(storeKey, templateID)
      .then((response) => {
        const data = response.data;
        const cartData = data.checkout;

        console.log("cartData", cartData);

        if (cartData) {
          setIsSelected(Number(cartData.theme));
          setContainer(cartData.isRounded ? "rounded" : "square");
          setBlock(cartData.theme == 0 ? "plain" : "bubbles");
          setCheckOut(cartData.checkOut);
          setBottomView(cartData.bottomView);
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching cart checkout data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const updateConfig = async () => {
    setIsSaving(true);

    // API
    updateCheckoutListData(
      storeKey,
      templateID,
      container === "rounded" ? true : false,
      isSelected,
      checkOut,
      bottomView
    )
      .then((response) => {
        console.log("response", response);
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateCheckoutListData:", error);
      });
  };

  const checkedChange = (e) => {
    setBlock(e.target.name);
    setIsSelected(Number(e.target.value));
  };

  return (
    <section className="--checkoutList productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Loading...</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Saving...</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">Cart Checkout:</h2>
          <nav
            className="componentNav"
            style={{
              position: "absolute",
              top: "8px",
              left: "275px",
              width: "500px",
            }}
          >
            <ul className="componentList">
              <li className="itemList active">
                LIST STYLE
                <BoxText handleSaveButton={updateConfig}>
                Choose the perfect list style for your cart to create a seamless and visually appealing checkout experience.
                </BoxText>
              </li>
              <li className="itemList">
                <Link to="/qty-stepper" style={{ color: "#6D7A8B" }}>
                QUANTITY STEPPER
                </Link>
              </li>
              <li className="itemList">
                <Link to="/bottom" style={{ color: "#6D7A8B" }}>
                BOTTOM
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Themes:</h2>
          <div className="col settings radios">
            <RadioButtonIcon
              label="PLAIN WITH LINES"
              id="plain"
              value={0}
              onChange={checkedChange}
              check={isSelected}
              title="Plain with Lines"
              description=""
              video="./sidevideos/checkoutList1.mp4"  
            />
            <RadioButtonIcon
              label="SQUARED BUBBLES"
              id="bubbles"
              value={1}
              onChange={checkedChange}
              check={isSelected}
              title="Squared Bubbles"
              description=""
              video="./sidevideos/checkoutList2.mp4"  
            />
          </div>
        </div>
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <ListStyle theme={block} />
      </div>
    </section>
  );
}

export default CartCheckoutListStyle;
