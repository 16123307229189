import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  PLACE_COMPONENT_REQUEST,
  RE_ARRANGE_PLACED_COMPONENT,
  REMOVE_COMPONENT,
  SAVE_COMPONENT,
  INIT_LAYOUT,
  LOAD_TEMPLATE,
  RELOAD_TEMPLATE
} from "./types";
import {
  feedAvailableComponents,
  feedUserConfiguration,
  placeComponent,
  feedLoadTemplate,
} from "./actions";
import { availableComponentsAsObject } from "./selectors";
import {
  fetchAvailableComponents,
  fetchForUserComponentsConfiguration,
  insertComponentInConfiguration,
  swapComponentConfigurationOrder,
  deleteComponentConfiguration,
  updateComponentConfiguration,
  getCurrentThemeRequest
} from "../../api";


function* fetchForAvailableComponents(templateID, storeKey) {

  try {
    const response = yield call(fetchAvailableComponents, { templateID: templateID, storeKey: storeKey });
    const availableComponents = response.data;
    console.log("result availableComponents sagas.js availableComponents", availableComponents);

    if (Array.isArray(availableComponents)) {
      yield put(
        feedAvailableComponents(
          availableComponents.map(({ configuration_options, id, ...rest }) => ({
            ...rest,
            componentId: id,
            configurationOptions: configuration_options,
          }))
        )
      );
    } else {
      console.error('Error: availableComponents is not an array:', availableComponents);
    }
  } catch (error) {
    console.error('Error in fetchForAvailableComponents saga:', error);
  }
}


function* fetchForUserConfiguration(  templateID, storeKey ) {
console.log("fetchForUserConfiguration",templateID);
  const data = yield call(fetchForUserComponentsConfiguration, {  templateID: templateID, storeKey: storeKey });
  const organizedAvailableComponents = yield select(
    availableComponentsAsObject
  );

  const dataArray = Object.keys(data.data)
    .filter((key) => key !== "id")
    .map((key) => ({ ...data.data[key], id: key }));

  const dataWithComponent = dataArray.map(
    ({
      id,
      component_id: componentId,
      order_in_list: order,
      configuration: configurationOptions,
    }) => ({
      ...organizedAvailableComponents[componentId],
      configurationOptions,
      order,
      uuid: id,
    })
  ).filter(configurationOptions => configurationOptions.title && configurationOptions.componentId);

  yield put(feedUserConfiguration(dataWithComponent));
}

// INIT
function* init(data) {
  console.log("INIT EN SAGAS", data);
  const templateID = data.payload.templateID; // Destructure the payload correctly
  const storeKey = data.payload.storeKey; // Destructure the payload correctly

  yield call(fetchForAvailableComponents, templateID, storeKey);
  yield call(fetchForUserConfiguration, templateID, storeKey);
}
// END INIT

function* placeComponentRequest({ payload }) {
  const { order, component, templateID, storeKey } = payload;
  const { componentId, configurationOptions: configuration } = component;
  const response = yield call(insertComponentInConfiguration, {
    order,
    componentId,
    configuration,
    templateID,
    storeKey
  });
  yield put(
    placeComponent({ idx: order, component, uuid: response.data.componentId })
  );
}

function* swapComponentConfiguration({ payload }) {
  yield call(swapComponentConfigurationOrder, {
    newOrderList: payload.newOrder,
    templateID: payload.templateID,
    storeKey: payload.storeKey
  });
}

function* removeComponentConfiguration({ payload }) {
  yield call(deleteComponentConfiguration, {
    id: payload.uuid,
    templateID: payload.templateID,
    storeKey: payload.storeKey
  });
}


function* saveComponentConfiguration({ payload }) {
  yield call(updateComponentConfiguration, {
    id: payload.uuid,
    configuration: payload.configuration,
    templateID: payload.templateID,
    storeKey: payload.storeKey
  });
}

// NEW METHODS
function* getTemplateConfiguration({ payload }) {
  console.log("LOAD_TEMPLATE getTemplateConfiguration", payload);

  const { storeKey } = payload;
  const response = yield call(getCurrentThemeRequest, {
    storeKey,
  });
  
  yield put(
    feedLoadTemplate({
      templateID: response.data.templateID,
      name: response.data.name,
    })
  );

}

function* reloadTemplate({ payload }) {
  console.log("reloadTemplate", payload.templateID);
  console.log("reloadTemplate", payload.storeKey);


  yield call(fetchForAvailableComponents, payload.templateID, payload.storeKey);
  yield call(fetchForUserConfiguration, payload.templateID, payload.storeKey);
}


export default [
  takeLatest(INIT_LAYOUT, init),
  takeLatest(PLACE_COMPONENT_REQUEST, placeComponentRequest),
  takeLatest(RE_ARRANGE_PLACED_COMPONENT, swapComponentConfiguration),
  takeLatest(REMOVE_COMPONENT, removeComponentConfiguration),
  takeLatest(SAVE_COMPONENT, saveComponentConfiguration),

  // NEW TYPES
  takeLatest(LOAD_TEMPLATE, getTemplateConfiguration),
  takeLatest(RELOAD_TEMPLATE, reloadTemplate),
];
