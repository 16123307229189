import React from "react";
import Image from "../components/common/img";
import defaultImage from "../components/common/image.png";

const NotificationCard = ({ titleCard, paragraphCard, image }) => {
  return (
    <section className="contentCard">
      <article className="card">
        <header>
          <picture className="picture__card">
          {image ? (
              <Image
                width={97}
                height={107}
                image={image || defaultImage}
                alt="imagen de prueba"
              />
            ) : null}
          </picture>
        </header>
        <div>
          <h2 className="title__card">{titleCard}</h2>
          <p className="paragraph__card">{paragraphCard}</p>
        </div>
      </article>
    </section>
  );
};

export default NotificationCard;
