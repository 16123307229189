import React from 'react';
import ReactTooltip from 'react-tooltip';
import VideoComponent from "./VideoComponent";
import iconImage from '../../img/icon9.png';

function RadioButtonSlideIcon({
  label,
  id,
  isActive,
  isSelected,
  onClick,
  onSelect,
  style,
  title,
  description,
  video
}) {
  if (!label) return null;

  const handleInputClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    e.stopPropagation();
  };

  const handleLabelClick = (e) => {
    if (onSelect) {
      onSelect();
    }
  };

  const handleDivClick = (e) => {
    if (e.target !== e.currentTarget) return;
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <>
<div className={`radioSlide ${isSelected ? "active" : ""}`} style={style} onClick={handleDivClick}>
    <div className="contentWrapper" onClick={handleDivClick}>
        <input
            type="radio"
            name={id}
            id={id}
            className={`radioColors ${isActive ? "active" : ""}`}
            onClick={handleInputClick}
        />
        <label onClick={handleLabelClick}>
            {label}
        </label>
    </div>
    <div className="iconWrapper">
        <img style={{width: "17px", height: "17px"}} src={iconImage} data-tip data-for={`slideTip_${title}`} />
    </div>
</div>

      <ReactTooltip
        className={"custom-to-v2"}
        id={`slideTip_${title}`}
        place="right"
        effect="solid"
        delayHide={0}
        delayShow={0}
        delayUpdate={0}
        offset={{ left: -20 }}
        backgroundColor={"#414953"}
      >
        <div>
          <div className={"react-tooltip-title"}>{title}</div>
          <div className={"react-tooltip-desc"}>{description}</div>
          {video && <div className={"react-tooltip-video"}> <VideoComponent width="300px" height="430px" compoVideo={video}/></div>}
        </div>
      </ReactTooltip>
    </>
  );
}

export default RadioButtonSlideIcon;
