import React from "react";
import { Modal, Col, ButtonGroup} from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import WarningIcon from "./items/WarningIcon";

export const ModalConfirmRemove = ({ onConfirm, onCancel, isOpen }) => (
  <Modal isOpen={isOpen} className="modal-dialog-centered confirm-remove-modal">
    <div className="remove-modal-header">
      <div>
        <WarningIcon />
      </div>
      <div className="remove-modal-message">
        Are you sure? This action is undoable
      </div>
    </div>
    <div className="remove-modal-buttons">
    
    <Col xs="4">
      <CustomButton type="CANCEL" onClick={onCancel} label="CANCEL" />
      </Col>
      <Col xs="4">
      <CustomButton type="REMOVE" onClick={onConfirm} label="REMOVE" />
      </Col>
      
    </div>
  </Modal>
);
