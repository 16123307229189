import React from "react";

const ColorsIcon = ({ active }) => {
  return (
    <svg
      width="83"
      height="43"
      viewBox="0 0 83 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M27.4699 29.15C26.76 29.15 26.0499 29.14 25.34 29.15C24.01 29.18 23 29.79 22.44 31C21.89 32.17 21.99 33.34 22.7999 34.36C23.5399 35.29 23.92 36.28 23.66 37.46C23.33 38.95 22 39.97 20.36 39.95C15 39.85 10.33 37.97 6.63995 34.08C1.39995 28.56 -0.28005 22 1.97995 14.73C4.23995 7.46 9.34995 3 16.7799 1.42C24.7299 -0.269999 33.39 3.34 37.49 9.92C39.57 13.26 40.53 16.88 39.65 20.8C38.58 25.53 34.52 28.87 29.67 29.13C28.94 29.17 28.1999 29.14 27.4699 29.14C27.4699 29.13 27.4699 29.14 27.4699 29.15ZM15.1099 11.83C16.8799 11.83 18.35 10.37 18.35 8.6C18.35 6.84 16.8699 5.37 15.0999 5.37C13.33 5.37 11.8599 6.84 11.8599 8.6C11.8699 10.38 13.3299 11.83 15.1099 11.83ZM25.91 11.83C27.69 11.83 29.15 10.38 29.16 8.61C29.16 6.84 27.7 5.38 25.93 5.37C24.16 5.37 22.69 6.83 22.68 8.6C22.67 10.36 24.1399 11.83 25.91 11.83ZM11.8499 17.26C11.86 15.48 10.41 14.02 8.62995 14.01C6.86995 14 5.39995 15.46 5.38995 17.24C5.37995 19.01 6.83995 20.48 8.60995 20.49C10.3899 20.49 11.8499 19.04 11.8499 17.26ZM29.17 17.27C29.18 19.05 30.6399 20.5 32.41 20.49C34.18 20.48 35.6299 19.01 35.6199 17.24C35.61 15.46 34.13 14 32.37 14.02C30.6 14.03 29.16 15.49 29.17 17.27Z"
          fill={active ? "#0C92EF" : "#6D7A8B"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="38.98"
            height="38.94"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>

      {active && <path d="M83 42V0L62 20.2422L83 42Z" fill="#6D7A8B" />}
    </svg>
  );
};
export default ColorsIcon;
