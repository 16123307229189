const NAMESPACE = "configurator";

export const CONFIGURE_COMPONENT = `${NAMESPACE}/CONFIGURE_COMPONENT`;
export const ADD_PRODUCT = `${NAMESPACE}/ADD_PRODUCT`;
export const ADD_PRODUCT_2 = `${NAMESPACE}/ADD_PRODUCT_2`;
export const ADD_PRODUCT_3 = `${NAMESPACE}/ADD_PRODUCT_3`;
export const ADD_PRODUCTS = `${NAMESPACE}/ADD_PRODUCTS`;
export const ADD_PRODUCTS_2 = `${NAMESPACE}/ADD_PRODUCTS_2`;
export const ADD_PRODUCTS_3 = `${NAMESPACE}/ADD_PRODUCTS_3`;
export const ADD_CATEGORY = `${NAMESPACE}/ADD_CATEGORY`;
export const ADD_CATEGORY_2 = `${NAMESPACE}/ADD_CATEGORY_2`;
export const ADD_CATEGORY_3 = `${NAMESPACE}/ADD_CATEGORY_3`;
export const ADD_CATEGORIES = `${NAMESPACE}/ADD_CATEGORIES`;
export const ADD_CATEGORIES_2 = `${NAMESPACE}/ADD_CATEGORIES_2`;
export const ADD_CATEGORIES_3 = `${NAMESPACE}/ADD_CATEGORIES_3`;
export const FEED_CATEGORIES = `${NAMESPACE}/HIDRATE_CATEGORIES`;
export const FEED_CATEGORIES_FOR_PRODUCTS = `${NAMESPACE}/HIDRATE_CATEGORIES_FOR_PRODUCTS`;
export const INIT_CONFIGURATOR = `${NAMESPACE}/INIT`;
export const SELECT_CATEGORY_REQUEST = `${NAMESPACE}/SELECT_CATEGORY_REQUEST`;
export const SELECT_PRODUCT_REQUEST = `${NAMESPACE}/SELECT_PRODUCT_REQUEST`;
export const FEED_PRODUCTS = `${NAMESPACE}/FEED_PRODUCTS`;
export const RE_ARRANGE_PLACED_COMPONENT = `${NAMESPACE}/RE_ARRANGE_PLACED_COMPONENT`;
export const RE_ARRANGE_PLACED_COMPONENT_CATEGORIES = `${NAMESPACE}/RE_ARRANGE_PLACED_COMPONENT_CATEGORIES`;
export const REMOVE_PRODUCT = `${NAMESPACE}/REMOVE_PRODUCT`;
export const REMOVE_PRODUCT_2 = `${NAMESPACE}/REMOVE_PRODUCT_2"`;
export const REMOVE_PRODUCT_3 = `${NAMESPACE}/REMOVE_PRODUCT_3"`;
export const REMOVE_CATEGORY = `${NAMESPACE}/REMOVE_CATEGORY`;
export const REMOVE_CATEGORY_2 = `${NAMESPACE}/REMOVE_CATEGORY_2`;
export const REMOVE_CATEGORY_3 = `${NAMESPACE}/REMOVE_CATEGORY_3`;
export const REMOVE_PRODUCTS = `${NAMESPACE}/REMOVE_PRODUCTS`;
export const ADD_ALL_PRODUCTS = `${NAMESPACE}/ADD_ALL_PRODUCTS`;
export const REMOVE_CATEGORIES = `${NAMESPACE}/REMOVE_CATEGORIES`;
export const ADD_ALL_CATEGORIES = `${NAMESPACE}/ADD_ALL_CATEGORIES`;

// New Types
export const LOAD_TEMPLATE = `${NAMESPACE}/LOAD_TEMPLATE`;
export const RELOAD_TEMPLATE = `${NAMESPACE}/RELOAD_TEMPLATE`;