/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButtonIcon from "../../common/RadioButtonIcon";
import RadioButtonSlide from "../../common/radioButtonSlide";
import BoxText from "../../common/BoxText";
import CollectionContainer from "./CollectionContainer";
import CollectionFullImage from "./CollectionFullImage";
import CollectionBigImage from "./CollectionBigImage";
import {
  fetchCurrentConfigTheme,
  fetchCollectionData,
  updateCollectionData
} from "../../../api";

const IMAGE_POSITION = {
  left: 0,
  right: 1,
  alt: 2,
};

function Collections({ storeKey }) {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState();
  const [container, setContainer] = useState();
  const [imageShape, setImageShape] = useState();
  const [imagePosition, setImagePosition] = useState();

  const [color1, setColor1] = useState();
  const [isActive, setActive] = useState(true);
  const [block, setBlock] = useState("container");
  const [isColor, setIsColor] = useState("#666666");
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);

    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchCollectionData(storeKey, templateID)
      .then((response) => {
        // Here's the change
        const data = response.data;
        const colorData = data.color;
        const collectionData = data.collection;

        console.log("data", data);
        console.log("data collection", collectionData);
        console.log("data color", colorData);
        if (colorData) {
          setColor1(colorData.mainColor);
        }
        if (collectionData) {
          setBlock(getThemeValue(collectionData.theme));
          setIsSelected(collectionData.theme);
          setContainer(collectionData.isRounded ? "rounded" : "square");
          setImageShape(collectionData.imageRounded ? "rounded" : "square");
          setIsColor(collectionData.mainColor || color1);
          setActive(collectionData.mainColor ? true : false);
          setImagePosition(
            Object.keys(IMAGE_POSITION).find(
              (key) => IMAGE_POSITION[key] === collectionData.aligment
            )
          );
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching collection data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  useEffect(() => {
    console.log("isActive has changed:", isActive);
    updateColorComponent();
  }, [isActive]);

  const updateConfig = async () => {
    console.log("updateConfig", templateID);

    setIsSaving(true);

    updateCollectionData(storeKey,templateID,
      container === "rounded" ? true : false,
      imageShape === "rounded" ? true : false,
      IMAGE_POSITION[imagePosition],
      isActive ? isColor : null,
      isSelected)
      .then((response) => {
        console.log("response",response);
        updateColorComponent();
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateCollectionData:", error);
      });
  };

  const getThemeValue = (theme) => {
    switch (theme) {
      case 0:
        return "container";
      case 1:
        return "fullImage";
      case 2:
        return "bigImage";
      default:
        return "container";
    }
  };

  const colorChange = (e) => {
    setIsColor(e.target.value);
    setActive(true);
  };

  const updateColorComponent = () => {
    console.log("updateColorComponent isActive:", isActive);
    if (isActive == false) {
      setIsColor(color1);
    }
  };

  const handleToggle = () => {
    setActive(!isActive);
  };

  const checkedChange = (e) => {
    setBlock(e.target.name);
    setIsSelected(Number(e.target.value)); // theme
  };

  const renderCollectionComponent = () => {
    if (isSelected === 0) {
      return (
        <CollectionContainer
          theme={`${container}_${imageShape}_${imagePosition}`}
        />
      );
    }
    if (isSelected === 1) {
      return <CollectionFullImage />;
    }

    return <CollectionBigImage color={isColor} theme={container} />;
  };

  const handleContainerChange = (e) => {
    setContainer(e.target.value);
  };

  const handleImageShapeChange = (e) => {
    setImageShape(e.target.value);
  };

  const handleImagePositionChange = (e) => {
    setImagePosition(e.target.value);
  };

  return (
    <section className="--collection productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Loading...</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Saving...</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">Collections:</h2>
          <nav
            className="componentNav"
            style={{ position: "absolute", top: "8px", left: "205px" }}
          >
            <ul className="componentList">
              <li className="itemList active">
                COLLECTIONS LIST
                <BoxText handleSaveButton={updateConfig}>
                Choose the perfect container type for your collections and personalize every detail to match your store’s design
                </BoxText>
              </li>
            </ul>
          </nav>
        </div>
        <div className="settingsGroup row">
          <h2 className="title">Settings group title:</h2>
          <div className="col settings radios">
            <RadioButtonIcon
              label="CONTAINER"
              id="container"
              value={0}
              onChange={checkedChange}
              check={isSelected}
              title="Container View"
              description="Choose container type: Rounded/Square, Image shapes and image positions"
              video="./sidevideos/collectionContainer.mp4"
              
            />
            <RadioButtonIcon
              label="BIG IMAGE WITH TITLE"
              id="bigImage"
              value={2}
              onChange={checkedChange}
              check={isSelected}
              title="Big Image with title"
              description="Choose container type: Rounded/Square, Image shapes and image positions"
              video="./sidevideos/collectionTitle.mp4"
            />
            <RadioButtonIcon
              label="FULL IMAGE"
              id="fullImage"
              value={1}
              onChange={checkedChange}
              check={isSelected}
              title="Full Image"
              description="Choose container type: Rounded/Square, Image shapes and image positions"
              video="./sidevideos/collectionFull.mp4"
            />
          </div>
          {isSelected !== 1 ? (
            <div
              className="col-xl-auto settings selects"
              style={{ marginTop: "20px", marginRight: "50px" }}
            >
              <div
                className="selectComponent row"
                style={{ marginLeft: "40px" }}
              >
                <label for="ContainerCorners">Container Type</label>
                <select
                  name="ContainerCorners"
                  id="ContainerCorners"
                  className="tagSelect"
                  onChange={handleContainerChange}
                  value={container}
                >
                  <option value="rounded">ROUNDED</option>
                  <option value="square">SQUARE</option>
                </select>
              </div>
              {isSelected === 0 && (
                <>
                  <div
                    className="selectComponent row"
                    style={{ marginLeft: "40px" }}
                  >
                    <label for="ImageShapes">Image Shapes</label>
                    <select
                      name="ImageShapes"
                      id="ImageShapes"
                      className="tagSelect"
                      onChange={handleImageShapeChange}
                      value={imageShape}
                    >
                      <option value="rounded">ROUNDED</option>
                      <option value="square">SQUARE</option>
                    </select>
                  </div>
                  <div
                    className="selectComponent row"
                    style={{ marginLeft: "40px" }}
                  >
                    <label for="ImagePosition">Image Position</label>
                    <select
                      name="ImagePosition"
                      id="ImagePosition"
                      className="tagSelect"
                      onChange={handleImagePositionChange}
                      value={imagePosition}
                    >
                      <option value="left">LEFT</option>
                      <option value="right">RIGHT</option>
                      <option value="alt">ALTERNATE</option>
                    </select>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="col settings selects" />
          )}

          {block == "bigImage" && (
            <div
              className="settingsGroup row"
              style={{ width: "70%", marginTop: "20px" }}
            >
              <h2 className="title">Colors:</h2>
              <div className="row radioCustom --colors">
                <RadioButtonSlide
                  label="CUSTOM COLORS"
                  id="customColors"
                  isActive={isActive}
                  onClick={handleToggle}
                />
                <div className="contentColors">
                  <input
                    type="text"
                    name="textColors"
                    className="textColor"
                    value={isColor}
                    onChange={colorChange}
                  />
                  <input
                    type="color"
                    name="colors"
                    className="pickColor"
                    value={isColor}
                    onChange={colorChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        {renderCollectionComponent()}
      </div>
    </section>
  );
}

export default Collections;
