import React from "react";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Publish from "../components/publish/Publish.js";

export const PublishPage = ({ storeKey }) => {
  return (
   <div className="market-layout">
        <Container fluid className="layout-container">
          <Row className="main-row no-gutters">
            <div style={{display: "flex",    width: "100%" }}>
            <Publish storeKey={storeKey}/>
            </div>
          </Row>
        </Container>
    </div>
  );
};
