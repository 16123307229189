import React, { useState } from "react";
import { Row, Button } from "reactstrap";
import { ModalConfirmRemove } from "../../../modals";
import { CustomButton } from "../home-layout-draggable-columns/custom-button";

export const RemoveButton = ({ onRemove }) => (
  <CustomButton type="REMOVE" icon={true} onClick={onRemove} label="REMOVE" />
);

export const RemoveButtonRow = ({ onRemove }) => (
  <Row className="remove-button justify-content-center">
    <RemoveButton onRemove={onRemove} />
  </Row>
);

export const RemoveWithConfirmRow = ({ onRemove }) => {
  const [isConfirmOpen, setIsOpen] = useState(false);
  return (
    <Row className="remove-button justify-content-left">
      <RemoveButton onRemove={() => setIsOpen(true)} />
      <ModalConfirmRemove
        isOpen={isConfirmOpen}
        onConfirm={() => {
          onRemove();
          setIsOpen(false);
        }}
        onCancel={() => setIsOpen(false)}
      />
    </Row>
  );
};
