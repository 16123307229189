import React from "react";

const ListStyle = (props) => {
  switch (props.theme) {
    case "plain":
      return <ListStylePlain color={props.color} />;
    case "bubbles":
      return <ListStyleBubbles color={props.color} />;
    default:
      return <ListStylePlain color={props.color} />;
  }
};

const ListStylePlain = (props) => {
  return (
    <div className="ListStylePlain">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 65 checkout list v1" clipPath="url(#clip0_76_7593)">
          <rect width="379" height="664" fill="white" />
          <g id="Rectangle 58" opacity="0.8" filter="url(#filter0_d_76_7593)">
            <rect y="141" width="379" height="135" fill="#EAE8EE" />
          </g>
          <g id="Comp icon product" clipPath="url(#clip1_76_7593)">
            <g id="Comp icon - Product">
              <g id="Group">
                <path
                  id="Vector"
                  d="M120.349 43.3878C119.928 42.6683 119.313 42.0566 118.552 41.6249L75.9615 21.6476C74.4398 20.7841 72.5696 20.7841 71.0479 21.6476L28.4569 41.6249C27.6961 42.0566 27.0818 42.6683 26.6602 43.3878L73.5047 69.9222L120.349 43.3878Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M73.5046 123C74.3572 123 75.2006 122.784 75.9615 122.352L115.17 98.2284C116.691 97.3649 117.626 95.7729 117.626 94.0459L121.009 45.7983C121.009 44.9348 120.771 44.1073 120.349 43.3877L73.5046 69.9221V123Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M71.0478 122.353C71.8086 122.784 72.6612 123 73.5046 123V69.9314L26.66 43.397C26.2383 44.1076 26 44.9441 26 45.7986L29.1719 94.0462C29.1719 95.7731 30.1069 97.3652 31.6287 98.2287L71.0478 122.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M94.9835 30.4985L52.6768 55.2429L73.5047 69.9223L26.6602 43.3879L50.6783 53.8038L93.3151 29.7969L94.9835 30.4985Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_2" clipPath="url(#clip2_76_7593)">
            <g id="Comp icon - Product_2">
              <g id="Group_2">
                <path
                  id="Vector_5"
                  d="M120.349 178.388C119.928 177.668 119.313 177.057 118.552 176.625L75.9615 156.648C74.4398 155.784 72.5696 155.784 71.0479 156.648L28.4569 176.625C27.6961 177.057 27.0818 177.668 26.6602 178.388L73.5047 204.922L120.349 178.388Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_6"
                  d="M73.5046 258C74.3572 258 75.2006 257.784 75.9615 257.352L115.17 233.228C116.691 232.365 117.626 230.773 117.626 229.046L121.009 180.798C121.009 179.935 120.771 179.107 120.349 178.388L73.5046 204.922V258Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M71.0478 257.353C71.8086 257.784 72.6612 258 73.5046 258V204.931L26.66 178.397C26.2383 179.108 26 179.944 26 180.799L29.1719 229.046C29.1719 230.773 30.1069 232.365 31.6287 233.229L71.0478 257.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_8"
                  d="M94.9835 165.498L52.6768 190.243L73.5047 204.922L26.6602 178.388L50.6783 188.804L93.3151 164.797L94.9835 165.498Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_3" clipPath="url(#clip3_76_7593)">
            <g id="Comp icon - Product_3">
              <g id="Group_3">
                <path
                  id="Vector_9"
                  d="M120.349 313.388C119.928 312.668 119.313 312.057 118.552 311.625L75.9615 291.648C74.4398 290.784 72.5696 290.784 71.0479 291.648L28.4569 311.625C27.6961 312.057 27.0818 312.668 26.6602 313.388L73.5047 339.922L120.349 313.388Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_10"
                  d="M73.5046 393C74.3572 393 75.2006 392.784 75.9615 392.352L115.17 368.228C116.691 367.365 117.626 365.773 117.626 364.046L121.009 315.798C121.009 314.935 120.771 314.107 120.349 313.388L73.5046 339.922V393Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_11"
                  d="M71.0478 392.353C71.8086 392.784 72.6612 393 73.5046 393V339.931L26.66 313.397C26.2383 314.108 26 314.944 26 315.799L29.1719 364.046C29.1719 365.773 30.1069 367.365 31.6287 368.229L71.0478 392.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M94.9835 300.498L52.6768 325.243L73.5047 339.922L26.6602 313.388L50.6783 323.804L93.3151 299.797L94.9835 300.498Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_4" clipPath="url(#clip4_76_7593)">
            <g id="Comp icon - Product_4">
              <g id="Group_4">
                <path
                  id="Vector_13"
                  d="M120.349 448.388C119.928 447.668 119.313 447.057 118.552 446.625L75.9615 426.648C74.4398 425.784 72.5696 425.784 71.0479 426.648L28.4569 446.625C27.6961 447.057 27.0818 447.668 26.6602 448.388L73.5047 474.922L120.349 448.388Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_14"
                  d="M73.5046 528C74.3572 528 75.2006 527.784 75.9615 527.352L115.17 503.228C116.691 502.365 117.626 500.773 117.626 499.046L121.009 450.798C121.009 449.935 120.771 449.107 120.349 448.388L73.5046 474.922V528Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_15"
                  d="M71.0478 527.353C71.8086 527.784 72.6612 528 73.5046 528V474.931L26.66 448.397C26.2383 449.108 26 449.944 26 450.799L29.1719 499.046C29.1719 500.773 30.1069 502.365 31.6287 503.229L71.0478 527.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_16"
                  d="M94.9835 435.498L52.6768 460.243L73.5047 474.922L26.6602 448.388L50.6783 458.804L93.3151 434.797L94.9835 435.498Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="comp elem rect 3">
            <path
              d="M138 69C138 66.2386 140.239 64 143 64H192C194.761 64 197 66.2386 197 69V85C197 87.7614 194.761 90 192 90H143C140.239 90 138 87.7614 138 85V69Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 6">
            <path
              d="M138 204C138 201.239 140.239 199 143 199H192C194.761 199 197 201.239 197 204V220C197 222.761 194.761 225 192 225H143C140.239 225 138 222.761 138 220V204Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 9">
            <path
              d="M138 339C138 336.239 140.239 334 143 334H192C194.761 334 197 336.239 197 339V355C197 357.761 194.761 360 192 360H143C140.239 360 138 357.761 138 355V339Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 12">
            <path
              d="M138 474C138 471.239 140.239 469 143 469H192C194.761 469 197 471.239 197 474V490C197 492.761 194.761 495 192 495H143C140.239 495 138 492.761 138 490V474Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 4">
            <path
              d="M138 37C138 34.2386 140.239 32 143 32H277C279.761 32 282 34.2386 282 37V53C282 55.7614 279.761 58 277 58H143C140.239 58 138 55.7614 138 53V37Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 7">
            <path
              d="M138 172C138 169.239 140.239 167 143 167H277C279.761 167 282 169.239 282 172V188C282 190.761 279.761 193 277 193H143C140.239 193 138 190.761 138 188V172Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 10">
            <path
              d="M138 307C138 304.239 140.239 302 143 302H277C279.761 302 282 304.239 282 307V323C282 325.761 279.761 328 277 328H143C140.239 328 138 325.761 138 323V307Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 13">
            <path
              d="M138 442C138 439.239 140.239 437 143 437H277C279.761 437 282 439.239 282 442V458C282 460.761 279.761 463 277 463H143C140.239 463 138 460.761 138 458V442Z"
              fill="#9694A0"
            />
          </g>
          <g id="themecolor1">
            <path
              d="M275 82C275 76.4772 279.477 72 285 72H347C352.523 72 357 76.4772 357 82V103C357 108.523 352.523 113 347 113H285C279.477 113 275 108.523 275 103V82Z"
              fill={props.color}
            />
          </g>
          <g id="themecolor1_2">
            <path
              d="M275 217C275 211.477 279.477 207 285 207H347C352.523 207 357 211.477 357 217V238C357 243.523 352.523 248 347 248H285C279.477 248 275 243.523 275 238V217Z"
              fill={props.color}
            />
          </g>
          <g id="themecolor1_3">
            <path
              d="M275 352C275 346.477 279.477 342 285 342H347C352.523 342 357 346.477 357 352V373C357 378.523 352.523 383 347 383H285C279.477 383 275 378.523 275 373V352Z"
              fill={props.color}
            />
          </g>
          <g id="themecolor1_4">
            <path
              d="M275 487C275 481.477 279.477 477 285 477H347C352.523 477 357 481.477 357 487V508C357 513.523 352.523 518 347 518H285C279.477 518 275 513.523 275 508V487Z"
              fill={props.color}
            />
          </g>
          <path id="Vector_17" d="M379 138H0V141H379V138Z" fill="#DCDAE2" />
          <path id="Vector_18" d="M379 273H0V276H379V273Z" fill="#DCDAE2" />
          <path id="Vector_19" d="M379 408H0V411H379V408Z" fill="#DCDAE2" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7593"
            x="-19"
            y="126"
            width="417"
            height="173"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7593"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7593"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7593">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7593">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(26 21)"
            />
          </clipPath>
          <clipPath id="clip2_76_7593">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(26 156)"
            />
          </clipPath>
          <clipPath id="clip3_76_7593">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(26 291)"
            />
          </clipPath>
          <clipPath id="clip4_76_7593">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(26 426)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
const ListStyleBubbles = (props) => {
  return (
    <div className="ListStyleBubbles">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 66 checkout list v2">
          <rect width="379" height="664" fill="white" />
          <g id="Rectangle 58" opacity="0.8" filter="url(#filter0_d_76_7594)">
            <rect x="22" y="21" width="339" height="135" fill="#FBFBFC" />
          </g>
          <g id="Rectangle 59" opacity="0.8" filter="url(#filter1_d_76_7594)">
            <rect x="22" y="173" width="339" height="135" fill="#EAE8EE" />
          </g>
          <g id="Rectangle 60" opacity="0.8" filter="url(#filter2_d_76_7594)">
            <rect x="22" y="325" width="339" height="135" fill="#FBFBFC" />
          </g>
          <g id="Rectangle 61" opacity="0.8" filter="url(#filter3_d_76_7594)">
            <rect x="22" y="477" width="339" height="135" fill="#FBFBFC" />
          </g>
          <g id="Comp icon product" clipPath="url(#clip0_76_7594)">
            <g id="Comp icon - Product">
              <g id="Group">
                <path
                  id="Vector"
                  d="M136.349 59.3878C135.928 58.6683 135.313 58.0566 134.552 57.6249L91.9615 37.6476C90.4398 36.7841 88.5696 36.7841 87.0479 37.6476L44.4569 57.6249C43.6961 58.0566 43.0818 58.6683 42.6602 59.3878L89.5047 85.9222L136.349 59.3878Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M89.5046 139C90.3572 139 91.2006 138.784 91.9615 138.352L131.17 114.228C132.691 113.365 133.626 111.773 133.626 110.046L137.009 61.7983C137.009 60.9348 136.771 60.1073 136.349 59.3877L89.5046 85.9221V139Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M87.0478 138.353C87.8086 138.784 88.6612 139 89.5046 139V85.9314L42.66 59.397C42.2383 60.1076 42 60.9441 42 61.7986L45.1719 110.046C45.1719 111.773 46.1069 113.365 47.6287 114.229L87.0478 138.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M110.984 46.4985L68.6768 71.2429L89.5047 85.9223L42.6602 59.3879L66.6783 69.8038L109.315 45.7969L110.984 46.4985Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_2" clipPath="url(#clip1_76_7594)">
            <g id="Comp icon - Product_2">
              <g id="Group_2">
                <path
                  id="Vector_5"
                  d="M136.349 211.388C135.928 210.668 135.313 210.057 134.552 209.625L91.9615 189.648C90.4398 188.784 88.5696 188.784 87.0479 189.648L44.4569 209.625C43.6961 210.057 43.0818 210.668 42.6602 211.388L89.5047 237.922L136.349 211.388Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_6"
                  d="M89.5046 291C90.3572 291 91.2006 290.784 91.9615 290.352L131.17 266.228C132.691 265.365 133.626 263.773 133.626 262.046L137.009 213.798C137.009 212.935 136.771 212.107 136.349 211.388L89.5046 237.922V291Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M87.0478 290.353C87.8086 290.784 88.6612 291 89.5046 291V237.931L42.66 211.397C42.2383 212.108 42 212.944 42 213.799L45.1719 262.046C45.1719 263.773 46.1069 265.365 47.6287 266.229L87.0478 290.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_8"
                  d="M110.984 198.498L68.6768 223.243L89.5047 237.922L42.6602 211.388L66.6783 221.804L109.315 197.797L110.984 198.498Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_3" clipPath="url(#clip2_76_7594)">
            <g id="Comp icon - Product_3">
              <g id="Group_3">
                <path
                  id="Vector_9"
                  d="M136.349 363.388C135.928 362.668 135.313 362.057 134.552 361.625L91.9615 341.648C90.4398 340.784 88.5696 340.784 87.0479 341.648L44.4569 361.625C43.6961 362.057 43.0818 362.668 42.6602 363.388L89.5047 389.922L136.349 363.388Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_10"
                  d="M89.5046 443C90.3572 443 91.2006 442.784 91.9615 442.352L131.17 418.228C132.691 417.365 133.626 415.773 133.626 414.046L137.009 365.798C137.009 364.935 136.771 364.107 136.349 363.388L89.5046 389.922V443Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_11"
                  d="M87.0478 442.353C87.8086 442.784 88.6612 443 89.5046 443V389.931L42.66 363.397C42.2383 364.108 42 364.944 42 365.799L45.1719 414.046C45.1719 415.773 46.1069 417.365 47.6287 418.229L87.0478 442.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M110.984 350.498L68.6768 375.243L89.5047 389.922L42.6602 363.388L66.6783 373.804L109.315 349.797L110.984 350.498Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_4" clipPath="url(#clip3_76_7594)">
            <g id="Comp icon - Product_4">
              <g id="Group_4">
                <path
                  id="Vector_13"
                  d="M136.349 515.388C135.928 514.668 135.313 514.057 134.552 513.625L91.9615 493.648C90.4398 492.784 88.5696 492.784 87.0479 493.648L44.4569 513.625C43.6961 514.057 43.0818 514.668 42.6602 515.388L89.5047 541.922L136.349 515.388Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_14"
                  d="M89.5046 595C90.3572 595 91.2006 594.784 91.9615 594.352L131.17 570.228C132.691 569.365 133.626 567.773 133.626 566.046L137.009 517.798C137.009 516.935 136.771 516.107 136.349 515.388L89.5046 541.922V595Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_15"
                  d="M87.0478 594.353C87.8086 594.784 88.6612 595 89.5046 595V541.931L42.66 515.397C42.2383 516.108 42 516.944 42 517.799L45.1719 566.046C45.1719 567.773 46.1069 569.365 47.6287 570.229L87.0478 594.353Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_16"
                  d="M110.984 502.498L68.6768 527.243L89.5047 541.922L42.6602 515.388L66.6783 525.804L109.315 501.797L110.984 502.498Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="comp elem rect 6">
            <path
              d="M157 80C157 77.2386 159.239 75 162 75H203C205.761 75 208 77.2386 208 80V92C208 94.7614 205.761 97 203 97H162C159.239 97 157 94.7614 157 92V80Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 9">
            <path
              d="M157 232C157 229.239 159.239 227 162 227H203C205.761 227 208 229.239 208 232V244C208 246.761 205.761 249 203 249H162C159.239 249 157 246.761 157 244V232Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 12">
            <path
              d="M157 384C157 381.239 159.239 379 162 379H203C205.761 379 208 381.239 208 384V396C208 398.761 205.761 401 203 401H162C159.239 401 157 398.761 157 396V384Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 15">
            <path
              d="M157 536C157 533.239 159.239 531 162 531H203C205.761 531 208 533.239 208 536V548C208 550.761 205.761 553 203 553H162C159.239 553 157 550.761 157 548V536Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 7">
            <path
              d="M157 52C157 49.2386 159.239 47 162 47H277C279.761 47 282 49.2386 282 52V65C282 67.7614 279.761 70 277 70H162C159.239 70 157 67.7614 157 65V52Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 10">
            <path
              d="M157 204C157 201.239 159.239 199 162 199H277C279.761 199 282 201.239 282 204V217C282 219.761 279.761 222 277 222H162C159.239 222 157 219.761 157 217V204Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 13">
            <path
              d="M157 356C157 353.239 159.239 351 162 351H277C279.761 351 282 353.239 282 356V369C282 371.761 279.761 374 277 374H162C159.239 374 157 371.761 157 369V356Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 16">
            <path
              d="M157 508C157 505.239 159.239 503 162 503H277C279.761 503 282 505.239 282 508V521C282 523.761 279.761 526 277 526H162C159.239 526 157 523.761 157 521V508Z"
              fill="#9694A0"
            />
          </g>
          <g id="themecolor1">
            <path
              d="M262 107C262 101.477 266.477 97 272 97H334C339.523 97 344 101.477 344 107V128C344 133.523 339.523 138 334 138H272C266.477 138 262 133.523 262 128V107Z"
              fill={props.color}
            />
          </g>
          <g id="themecolor1_2">
            <path
              d="M262 259C262 253.477 266.477 249 272 249H334C339.523 249 344 253.477 344 259V280C344 285.523 339.523 290 334 290H272C266.477 290 262 285.523 262 280V259Z"
              fill={props.color}
            />
          </g>
          <g id="themecolor1_3">
            <path
              d="M262 411C262 405.477 266.477 401 272 401H334C339.523 401 344 405.477 344 411V432C344 437.523 339.523 442 334 442H272C266.477 442 262 437.523 262 432V411Z"
              fill={props.color}
            />
          </g>
          <g id="themecolor1_4">
            <path
              d="M262 563C262 557.477 266.477 553 272 553H334C339.523 553 344 557.477 344 563V584C344 589.523 339.523 594 334 594H272C266.477 594 262 589.523 262 584V563Z"
              fill={props.color}
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7594"
            x="7"
            y="10"
            width="369"
            height="165"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="7.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7594"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7594"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7594"
            x="7"
            y="162"
            width="369"
            height="165"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="7.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7594"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7594"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7594"
            x="7"
            y="314"
            width="369"
            height="165"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="7.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7594"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7594"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7594"
            x="7"
            y="466"
            width="369"
            height="165"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="7.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7594"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7594"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7594">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(42 37)"
            />
          </clipPath>
          <clipPath id="clip1_76_7594">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(42 189)"
            />
          </clipPath>
          <clipPath id="clip2_76_7594">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(42 341)"
            />
          </clipPath>
          <clipPath id="clip3_76_7594">
            <rect
              width="95"
              height="102"
              fill="white"
              transform="translate(42 493)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ListStyle;
