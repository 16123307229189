import { prop, includes } from "ramda";
import { createSelector } from "reselect";

const NAMESPACE = (state) => state.configurator;

// Product and image products 1
export const getSelectedProducts = createSelector(
  NAMESPACE,
  (namespace) => {
    return namespace.selectedProducts || [];
  }
);

const getSelectedProductsIds = createSelector(
  getSelectedProducts,
  (selectedProducts) => {
    console.log('Selected Products: ', selectedProducts);
    if(!selectedProducts) {
      console.warn('Selected products is undefined');
      return [];
    }
    return selectedProducts.map(prop("id"));
  }
);

export const getAvailableProducts = createSelector(
  [NAMESPACE, getSelectedProductsIds],
  (namespace, selectedProductsIds = []) => {
    const availableProductsObj = namespace.availableProducts || {};
    const availableProductsArr = Object.keys(availableProductsObj).map(key => availableProductsObj[key]);
    console.log("availableProductsArr", availableProductsArr)
    return availableProductsArr
    /*
    return availableProductsArr.filter(
      ({ id }) => !includes(id, selectedProductsIds)
    );
    */
  }
);

// image products 2
export const getSelectedProducts2 = createSelector(
  NAMESPACE,
  (namespace) => {
    return namespace.selectedProducts2 || [];
  }
);

const getSelectedProductsIds2 = createSelector(
  getSelectedProducts2,
  (selectedProducts) => {
    console.log('Selected Products: ', selectedProducts);
    if(!selectedProducts) {
      console.warn('Selected products is undefined');
      return [];
    }
    return selectedProducts.map(prop("id"));
  }
);

export const getAvailableProducts2 = createSelector(
  [NAMESPACE, getSelectedProductsIds2],
  (namespace, selectedProductsIds = []) => {
    const availableProductsObj = namespace.availableProducts2 || {};
    const availableProductsArr = Object.keys(availableProductsObj).map(key => availableProductsObj[key]);
    console.log("availableProductsArr", availableProductsArr)
    return availableProductsArr.filter(
      ({ id }) => !includes(id, selectedProductsIds)
    );
  }
);

// image products 3
export const getSelectedProducts3 = createSelector(
  NAMESPACE,
  (namespace) => {
    console.log('Namespace: ', namespace);
    return namespace.selectedProducts3 || [];
  }
);

const getSelectedProductsIds3 = createSelector(
  getSelectedProducts3,
  (selectedProducts) => {
    console.log('Selected Products 3: ', selectedProducts);
    if(!selectedProducts) {
      console.warn('Selected products is undefined');
      return [];
    }
    return selectedProducts.map(prop("id"));
  }
);

export const getAvailableProducts3 = createSelector(
  [NAMESPACE, getSelectedProductsIds3],
  (namespace, selectedProductsIds = []) => {
    const availableProductsObj = namespace.availableProducts3 || {};
    const availableProductsArr = Object.keys(availableProductsObj).map(key => availableProductsObj[key]);
    return availableProductsArr.filter(
      ({ id }) => !includes(id, selectedProductsIds)
    );
  }
);

// Category and image categories 1
export const getSelectedCategories = createSelector(
  NAMESPACE,
  (namespace) => {
    return namespace.selectedCategories || [];
  }
);

const getSelectedCategoriesIds = createSelector(
  getSelectedCategories,
  (selectedCategories) => {
    console.log('Selected selectedCategories: ', selectedCategories);
    if(!selectedCategories) {
      console.warn('Selected categories is undefined');
      return [];
    }
    return selectedCategories.map(prop("id"));
  }
);

export const getAvailableCategories = createSelector(
  [NAMESPACE, getSelectedCategoriesIds],
  (namespace, selectedCategoriesIds = []) => {
    const availableCategoriesObj = namespace.availableCategories || {};
    const availableCategoriesArr = Object.keys(availableCategoriesObj).map(key => availableCategoriesObj[key]);
    console.log("availableProductsArr", availableCategoriesArr)
    return availableCategoriesArr
    /*
    return availableCategoriesArr.filter(
      ({ id }) => !includes(id, selectedCategoriesIds)
    );
    */
  }
);

// image categories 2
export const getSelectedCategories2 = createSelector(
  NAMESPACE,
  (namespace) => {
    console.log('Namespace: ', namespace);
    return namespace.selectedCategories2 || [];
  }
);

// MIGRADO
const getSelectedCategoriesIds2 = createSelector(
  getSelectedCategories2,
  (selectedCategories) => {
    console.log('Selected selectedCategories 2: ', selectedCategories);
    if(!selectedCategories) {
      console.warn('Selected categories is undefined');
      return [];
    }
    return selectedCategories.map(prop("id"));
  }
);

// MIGRADO
export const getAvailableCategories2 = createSelector(
  [NAMESPACE, getSelectedCategoriesIds2],
  (namespace, selectedCategoriesIds2 = []) => {
    const availableCategoriesObj = namespace.availableCategories2 || {};
    const availableCategoriesArr = Object.keys(availableCategoriesObj).map(key => availableCategoriesObj[key]);
    console.log("availableProductsArr", availableCategoriesArr)
    return availableCategoriesArr.filter(
      ({ id }) => !includes(id, selectedCategoriesIds2)
    );
  }
);

// image categories 3
export const getSelectedCategories3 = createSelector(
  NAMESPACE,
  (namespace) => {
    console.log('Namespace: ', namespace);
    return namespace.selectedCategories3 || [];
  }
);

const getSelectedCategoriesIds3 = createSelector(
  getSelectedCategories3,
  (selectedCategories) => {
    console.log('Selected selectedCategories 3: ', selectedCategories);
    if(!selectedCategories) {
      console.warn('Selected categories is undefined');
      return [];
    }
    return selectedCategories.map(prop("id"));
  }
);

// MIGRADO
export const getAvailableCategories3 = createSelector(
  [NAMESPACE, getSelectedCategoriesIds3],
  (namespace, selectedCategoriesIds3 = []) => {
    const availableCategoriesObj = namespace.availableCategories3 || {};
    const availableCategoriesArr = Object.keys(availableCategoriesObj).map(key => availableCategoriesObj[key]);
    console.log("availableProductsArr", availableCategoriesArr)
    return availableCategoriesArr.filter(
      ({ id }) => !includes(id, selectedCategoriesIds3)
    );
  }
);

// MIGRADO
export const getAvailableCategoriesForProducts = createSelector(
  NAMESPACE,
  (namespace) => {
    console.log('Namespace: ', namespace);
    return namespace.availableCategoriesForProducts || [];
  }
);

// MIGRADO
export const getSelectedCategoriesForProducts = createSelector(
  NAMESPACE,
  (namespace) => {
    console.log('Namespace: ', namespace);
    return namespace.selectedCategoriesForProducts || [];
  });
