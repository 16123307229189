import React, { useContext } from "react";
import LoadingImage from "./components/img/FengLoaderWhitePNG.png";
import { StoreKeyContextProvider, StoreKeyContext } from './StoreKeyContextProvider'; // Corrección en la importación
import { RouteAwareNavigation } from "./components";
import { DesignPage } from "./pages";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { store } from "./ducks";

// Pages
import { CollectionsPage } from "./pages/collections";
import { CollectionsMenuPage } from "./pages/collections-menu";
import { PublishPage } from "./pages/publish";
import { NotificationsPage } from "./pages/notifications";
import { ProductHeaderPage } from "./pages/product-header";
import { ProductNavbarPage } from "./pages/product-navbar";
import { ProductListsPage } from "./pages/product-lists";
import { ProductDetailsPage } from "./pages/product-details";
import { AddToCartPage } from "./pages/add-to-cart";
import { CartCheckoutListStylePage } from "./pages/cart-checkout-list-style";
import { CartCheckoutQtyStepperPage } from "./pages/cart-checkout-qty-stepper";
import { CartCheckoutBottomPage } from "./pages/cart-checkout-bottom";
import { GlobalColorsPage } from "./pages/global-colors";
import { LoadersPage } from "./pages/loaders";

// CSS
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./App.scss";
import "./Styles/stylesAll.scss";
import "./Styles/modules/_modalPreview.scss";
import "./Styles/modules/_modalVersionSelector.scss";

const history = createBrowserHistory();

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <StoreKeyContextProvider>
          <Routes />
        </StoreKeyContextProvider>
      </Router>
    </Provider>
  );
};

const Routes = () => {
  const { storeKey, userType, cacheFlushed } = useContext(StoreKeyContext); 

  if (!cacheFlushed) {
    return  <div className="market-layout">
      <div className="loading-overlay">
        <div className="loading-content">
          <img src={LoadingImage} alt="Loading" />
        </div>
        <div className="loading-text">Loading...</div>
      </div>
      </div>
  }

  return (
    <>
      <RouteAwareNavigation storeKey={storeKey} userType={userType}/>
      <Switch>
        <Route path="/collections">
          <CollectionsPage storeKey={storeKey} />
        </Route>
        <Route path="/menu">
          <CollectionsMenuPage storeKey={storeKey} />
        </Route>
        <Route path="/lists">
          <ProductHeaderPage storeKey={storeKey} />
        </Route>
        <Route path="/lists-navbar">
          <ProductNavbarPage storeKey={storeKey} />
        </Route>
        <Route path="/lists-lists">
          <ProductListsPage storeKey={storeKey} />
        </Route>
        <Route path="/details">
          <ProductDetailsPage storeKey={storeKey} />
        </Route>
        <Route path="/add-to-cart">
          <AddToCartPage storeKey={storeKey} />
        </Route>
        <Route path="/cart">
          <CartCheckoutListStylePage storeKey={storeKey} />
        </Route>
        <Route path="/qty-stepper">
          <CartCheckoutQtyStepperPage storeKey={storeKey} />
        </Route>
        <Route path="/bottom">
          <CartCheckoutBottomPage storeKey={storeKey} />
        </Route>
        <Route path="/colors">
          <GlobalColorsPage storeKey={storeKey} />
        </Route>
        <Route path="/loaders">
          <LoadersPage storeKey={storeKey} />
        </Route>
        {storeKey !== "default-store-key" && (
          <Route path="/design">
            <DesignPage storeKey={storeKey} userType={userType}/>
          </Route>
        )}
        <Route path="/notifications">
          <NotificationsPage storeKey={storeKey} userType={userType}/>
        </Route>
        <Route path="/publish">
          <PublishPage storeKey={storeKey}/>
        </Route>
        {storeKey !== "default-store-key" && (
          <Route path="/">
            <DesignPage storeKey={storeKey} userType={userType}/>
          </Route>
        )}
      </Switch>
    </>
  );
};

export default App;
