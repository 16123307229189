import React, { useState, useEffect } from "react";
import { fetchForFengCategories, saveFengCategories } from "../../api";
import LoadingImage from "../img/FengLoaderWhitePNG.png";
import Collection from "../modals/items/Collection.jsx";
import "./Menu.css";

import {
  SimpleTreeItemWrapper,
  SortableTree,
  TreeItems,
} from "dnd-kit-sortable-tree";

function Menu({ storeKey }) {
  useEffect(() => {
    fetchData();
  }, []);

  console.log("storeKey", storeKey);

  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [items, setItems] = useState([]);
  const [disabledItem, setDisabledItem] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchForFengCategories({ storeKey: storeKey, onlyRoot: false });
      if (response && response.data) {
        const parentMap = new Map();
        let disabledItemsArray = [];

        // First, categorize items into normal and disabled
        response.data.forEach((item) => {
          const itemWithChildren = {
            ...item,
            children: [],
            images: item.images
              ? item.images.map((image) => ({ url: image.url || image.path  }))
              : [],
          };

          if (item.parentId === "-1") {
            disabledItemsArray.push(itemWithChildren);
          } else {
            parentMap.set(item.id, itemWithChildren);
          }
        });

        // Now, build the tree structure for normal items
        response.data.forEach((item) => {
          if (item.parentId !== "0" && item.parentId !== "-1") {
            const parent = parentMap.get(item.parentId);
            if (parent) {
              parent.children.push({
                id: item.id,
                description: item.description,
                parentId: item.parentId,
                name: item.name,
                children: [],
                images: item.images
                  ? item.images.map((image) => ({ url: image.url || image.path  }))
                  : [],
              });
            }
          }
        });

        // Extract root items (where parentId is "0")
        const rootItems = [];
        parentMap.forEach((value, key) => {
          if (value.parentId === "0") {
            rootItems.push(value);
          }
        });

        setItems(rootItems);
        setDisabledItem(disabledItemsArray);

        setIsLoading(false);
        console.log("rootItems", rootItems);
        console.log("disabledItemsArray", disabledItemsArray);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  };

  // BUG: IF THE ITEM HAS ITEMS AND ITS DRAGGED TO A SUBITEM -> SUBITEMS DISSAPEAR
  const handleItemsChanged = (newItems) => {
    const adjustedItems = newItems.map((item) => {
      let flattenedChildren = [];

      if (item.children) {
        item.children.forEach((child) => {
          flattenedChildren.push({ ...child, children: [] });

          if (child.children) {
            child.children.forEach((nestedChild) => {
              flattenedChildren.push({ ...nestedChild, children: [] });
            });
          }
        });
      }

      return { ...item, children: flattenedChildren };
    });

    setItems(adjustedItems);
  };

  const onAddToCart = ({ id }) => {
    console.log("onAddToCart ID ", id);

    // Find the item in the disabledItem array
    const itemToAdd = disabledItem.find((item) => item.id === id);

    if (itemToAdd) {
      // Update the parentId to "0" for the item
      const updatedItemToAdd = { ...itemToAdd, parentId: "0" };

      // Remove the item from the disabledItem array
      const newDisabledItems = disabledItem.filter((item) => item.id !== id);
      setDisabledItem(newDisabledItems);

      // Add the updated item back to the top of the items array
      const updatedItems = [updatedItemToAdd, ...items];
      setItems(updatedItems);
    }
  };

  const removeItemById = (itemId) => {
    console.log("REMOVE ID ", itemId);

    let removedItem = null;

    const findAndRemoveItemRecursive = (items, itemIdToRemove) => {
      return items.reduce((acc, item) => {
        if (item.id === itemIdToRemove) {
          // Store the removed item without children
          removedItem = { ...item, children: [] };
          // Add child items to the top level, if any
          if (item.children && item.children.length > 0) {
            acc.push(...item.children);
          }
          return acc; // Skip adding this item
        }

        if (item.children) {
          item.children = findAndRemoveItemRecursive(
            item.children,
            itemIdToRemove
          );
        }

        acc.push(item); // Add the current item to the accumulator
        return acc;
      }, []);
    };

    const newItems = findAndRemoveItemRecursive([...items], itemId);
    setItems(newItems);

    if (removedItem) {
      console.log("REMOVED OBJ", removedItem);
      setDisabledItem((prevDisabledItems) => [
        ...prevDisabledItems,
        removedItem,
      ]);
    }
  };

  const saveData = () => {
    setIsSaving(true);

    const prepareItemsForFirebase = (
      items,
      parentId = "0",
      isDisabled = false
    ) => {
      let flatItems = [];

      items.forEach((item) => {
        const effectiveParentId = isDisabled
          ? "-1"
          : item.parentId
          ? item.parentId
          : "0";

        // Handle images array to keep the structure with 'path'
        const imagesWithPaths = item.images
          ? item.images.map((image) => ({ url: image.url || image.path  }))
          : [];

        const firebaseItem = {
          id: item.id,
          name: item.name,
          description: item.description,
          parentId:
            item.children && item.children.length > 0
              ? parentId
              : effectiveParentId,
          images: imagesWithPaths,
        };

        flatItems.push(firebaseItem);

        // Recursively process children, passing the isDisabled flag
        if (item.children && item.children.length > 0) {
          flatItems = flatItems.concat(
            prepareItemsForFirebase(item.children, item.id, isDisabled)
          );
        }
      });

      return flatItems;
    };

    // Prepare active items
    const activeItemsData = prepareItemsForFirebase(items);

    // Prepare disabled items with parentId set to -1
    const disabledItemsData = prepareItemsForFirebase(disabledItem, "0", true);

    // Combine active and disabled items
    const firebaseData = [...activeItemsData, ...disabledItemsData];

    console.log("Prepared data for Firebase:", firebaseData);

    saveFengCategories(storeKey, firebaseData)
      .then((response) => {
        console.log("response", response);
        setIsSaving(false);
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  const countAllItems = (items) => {
    let count = 0;

    const countItemsRecursive = (items) => {
      items.forEach((item) => {
        count++;
        if (item.children && item.children.length > 0) {
          countItemsRecursive(item.children);
        }
      });
    };

    countItemsRecursive(items);
    return count;
  };

  function truncateName(name, maxLength) {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  }

  const TreeItem = React.forwardRef(({ ...props }, ref) => {
    const { item } = props;

    const handleRemoveClick = (e) => {
      e.stopPropagation(); // Prevents the drag action when clicking the remove button
      removeItemById(item.id);
    };

    return (
      <SimpleTreeItemWrapper className="treeCollection" {...props} ref={ref}>
        <div {...props.handleProps} className="drag-handle-menu">
          <h3 className="product_name">{truncateName(item.name, 80)}</h3>
        </div>
        <button onClick={handleRemoveClick} className="remove-button-menu">
          <span>
            <i className="fa fa-trash-o" />
          </span>
        </button>
      </SimpleTreeItemWrapper>
    );
  });

  return (
    <div style={{ width: "100%" }}>
      <section>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Loading...</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Saving...</div>
        </div>
      )}      
        <div class="container-modal-product">
          <div class="left_container_sortable">
            <SortableTree
              indicator={true}
              indentationWidth={50}
              items={items}
              onItemsChanged={handleItemsChanged}
              TreeItemComponent={TreeItem}
              keepGhostInPlace={false}
              pointerSensorOptions={2}
              dropAnimation={null}
              sortableProps={{
                animateLayoutChanges: () => false,
              }}
              manualDrag={true}
            />
          </div>

          <div class="right_container_sortable">
            <div class="top_container">
              <div class="left_top_container">
                <h2>Collections</h2>
                <h6>Left Panel: Order Main Collections and Subcollections</h6>
                <h6>Right Panel: Unused Collections</h6>
              </div>
              <div class="right_top_container">
                <div class="menu_bottom_product_container menu_product_found" style={{paddingBottom: "20px"}}>
                  <button                  
                    className="buttonComponent default"
                    onClick={saveData}
                  >
                    UPDATE COLLECTIONS
                  </button>
                </div>
                <div class="menu_bottom_product_container">
                  <h3 class="menu_product_found">
                    {countAllItems(items)} Collections on the Menu
                  </h3>
                </div>
                <div class="menu_bottom_product_container">
                  <h3 class="menu_product_found">
                    {disabledItem.length} Collections unused
                  </h3>
                </div>
              </div>
            </div>

            <div class="product_list"  style={{paddingLeft: "20px"}}>
              {disabledItem &&
                disabledItem.map((product) => (
                  <Collection
                    key={product.id}
                    id={product.id}
                    name={product.name}
                    image={product.images}
                    onAddToCart={onAddToCart} // Disable remove button in the products list
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Menu;
