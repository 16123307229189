import React from "react";
import RadioButton from "../../common/RadioButton";

export const options = [
  { label: "RED WARM", color1: "#F02F31", color2: "#FF9E67" },
  { label: "SUNSET FLAME", color1: "#FF5733", color2: "#FFB347" },
  { label: "PINK", color1: "#FF008A", color2: "#FF93B3" },
  { label: "MAGENTA DREAM", color1: "#FF509A", color2: "#FFB0C3" },
  { label: "PURPLE", color1: "#8E22A9", color2: "#E26EFF" },
  { label: "LAVENDER FROST", color1: "#A64AC9", color2: "#DAA5FF" },
  { label: "DEEP PURPLE", color1: "#5D35AF", color2: "#B794FF" },
  { label: "INDIGO", color1: "#3E32CF", color2: "#7A8CFC" },
  { label: "STARRY INDIGO", color1: "#5543D7", color2: "#9DA2FF" },
  { label: "BLUE", color1: "#157CDA", color2: "#85C1EC" },
  { label: "SKYLINE BLUE", color1: "#1B82E1", color2: "#8CD0F5" },
  { label: "LIGHT BLUE", color1: "#059CE5", color2: "#7EDEEB" },
  { label: "AQUA BREEZE", color1: "#2AB0E9", color2: "#8CEFFD" },
  { label: "CYAN", color1: "#00A6BA", color2: "#A5DFE2" },
  { label: "SEAFOAM", color1: "#20B5C6", color2: "#B0E7F2" },
  { label: "TEAL", color1: "#01897B", color2: "#6BCAC0" },
  { label: "MINT SPLASH", color1: "#2AAE91", color2: "#82D9D3" },
  { label: "GREEN", color1: "#43A047", color2: "#72DD76" },
  { label: "EMERALD GLOW", color1: "#4CB963", color2: "#8CEA8B" },
  { label: "LIGHT GREEN", color1: "#7DB343", color2: "#BDE790" },
  { label: "SPRING BUD", color1: "#8CC653", color2: "#C2ED9E" },
  { label: "LIME", color1: "#BFC932", color2: "#E4EC79" },
  { label: "LEMON LIME", color1: "#D1D846", color2: "#E9F29C" },
  { label: "YELLOW", color1: "#FED837", color2: "#FFEFAA" },
  { label: "SUNNY GLOW", color1: "#FEEB4D", color2: "#FFF8C0" },
  { label: "AMBER", color1: "#FEB300", color2: "#F9DA8F" },
  { label: "GOLDEN HAZE", color1: "#FEC72E", color2: "#FCE4A2" },
  { label: "ORANGE", color1: "#FB7901", color2: "#F6CF41" },
  { label: "SUNRISE ORANGE", color1: "#FC8E20", color2: "#FAD67D" },
  { label: "DEEP ORANGE", color1: "#F3521E", color2: "#FFA53B" },
  { label: "FLAME KISS", color1: "#F46A3F", color2: "#FFBC6D" },
  { label: "BROWN", color1: "#6D4D42", color2: "#DACFA7" },
  { label: "MOCHA SWIRL", color1: "#7A6650", color2: "#E0D9B2" },
  { label: "GREY", color1: "#7B807E", color2: "#CFD6D9" },
  { label: "SILVER MIST", color1: "#8A918D", color2: "#D9E0E3" },
  { label: "BLUE GREY", color1: "#536D7A", color2: "#B4CEDB" },
  { label: "STORMY SKY", color1: "#627C86", color2: "#C2D6E7" },
  { label: "MIDNIGHT BLUE", color1: "#2B2D42", color2: "#8D99AE" },
];



const GlobalColorOptions = (props) => {
  const checkedChange = (e, color1, color2) => {
    props.onSelectColor(color1, color2, e.target.value);
  };

  return (
    <div
      className="col settings radios"
      style={{ overflowY: "scroll", height: "475px",  overflowX: "hidden" }}
    >
      {options.map((o, index) => (
        <RadioButton
          label={o.label}
          id={o.label.toLowerCase()}
          value={index}
          onChange={(e) => checkedChange(e, o.color1, o.color2)}
          check={props.selected}
          themeColor
          color1={o.color1}
          color2={o.color2}
        />
      ))}
    </div>
  );
};

export default GlobalColorOptions;
