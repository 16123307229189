const addMarginTop = (title) => {
  return title.includes("Botones") ||
    title.includes("News") ||
    title.includes("Image")
    ? { marginTop: "15px" }
    : null;
};

const moveContentConfigUp = (config) => {
  let conf = config;
  if (config.length) {
    const content = config.find((c) => c.type.includes("supermodal"));
    if (content) {
      const filtered = config.filter((c) => !c.type.includes("supermodal"));
      conf = [content, ...filtered];
    }
  }

  return conf;
};

const blockSorting = {
  "Producto Destacado": ["Update Content", "TOP", "Type"],
};

function sortComponentConfigs(config, componentTitle) {
  const sorting = blockSorting[componentTitle];
  if (sorting) {
    config.sort(function (a, b) {
      var A = a["label"],
        B = b["label"];

      if (sorting.indexOf(A) > sorting.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return config;
  }

  // No mover arriba las configuraciones de supermodal para estos componentes.
  if (componentTitle.includes("News") || componentTitle.includes("Image ") || componentTitle.includes("Botones") || componentTitle.includes("Video")) {
    return config;
  }
  return moveContentConfigUp(config);
}

const sortComponentsByOrderNumber = (componentsArray) => {
  return componentsArray.sort((a, b) => a.order - b.order);
};

const uiLabels = {
  "Is Rounded": "Rounded",
  "Bottom Color": "Secondary Color",
};

const mapLabel = (label) => {
  return uiLabels[label] || label;
};

export {
  addMarginTop,
  moveContentConfigUp,
  sortComponentsByOrderNumber,
  sortComponentConfigs,
  mapLabel,
};
