/*
PLAIN WITH LINES = Theme 1
BUBBLES = Theme 2 - Top / Bottom
ANIMATED BUBBLES = Theme 3
*/

import React from "react";

const ProductCardBlocks = (props) => {
  switch (props.theme) {
    case "theme1":
      return <ProductCardTheme1 color={props.color} />;
    case "theme2_top":
      return (
        <ProductCardTheme2Top
          color={props.color}
          secondaryColor={props.secondaryColor}
        />
      );
    case "theme2_bottom":
      return (
        <ProductCardTheme2Bottom
          color={props.color}
          secondaryColor={props.secondaryColor}
        />
      );
    case "theme3":
      return <ProductCardTheme3 color={props.color} />;
    default:
      return <ProductCardTheme1 color={props.color} />;
  }
};

const ProductCardTheme1 = (props) => {
  return (
    <div className="ProductcardTheme1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="379" height="664" fill="white" />
        <g clipPath="url(#clip0_76_7584)">
          <path
            d="M284.657 124.312C283.787 122.824 282.52 121.558 280.95 120.665L193.078 79.3397C189.939 77.5534 186.08 77.5534 182.941 79.3397L95.0689 120.665C93.499 121.558 92.2318 122.824 91.3618 124.312L188.009 179.202L284.657 124.312Z"
            fill="#DCDAE2"
          />
          <path
            d="M188.01 289C189.768 289 191.509 288.553 193.078 287.66L273.971 237.757C277.111 235.971 279.04 232.677 279.04 229.105L286.019 129.299C286.019 127.512 285.527 125.801 284.657 124.312L188.01 179.202V289Z"
            fill="#9694A0"
          />
          <path
            d="M182.941 287.66C184.51 288.553 186.269 289 188.009 289V179.22L91.3618 124.331C90.4917 125.8 90 127.531 90 129.299L96.544 229.105C96.544 232.677 98.4732 235.971 101.613 237.757L182.941 287.66Z"
            fill="#66646F"
          />
          <path
            d="M232.324 97.6486L145.038 148.836L188.01 179.202L91.3618 124.312L140.915 145.859L228.881 96.1973L232.324 97.6486Z"
            fill="#66646F"
          />
        </g>
        <path
          d="M321.291 560H47.709C42.904 560 39 556.868 39 552.991V550.009C39 546.142 42.8915 543 47.709 543H321.291C326.096 543 330 546.132 330 550.009V552.991C330 556.868 326.096 560 321.291 560Z"
          fill="#DCDAE2"
        />
        <path
          d="M321.291 581H47.709C42.904 581 39 577.868 39 573.991V571.009C39 567.142 42.8915 564 47.709 564H321.291C326.096 564 330 567.132 330 571.009V573.991C330 577.868 326.096 581 321.291 581Z"
          fill="#DCDAE2"
        />
        <path
          d="M321.291 602H47.709C42.904 602 39 598.868 39 594.991V592.009C39 588.142 42.8915 585 47.709 585H321.291C326.096 585 330 588.132 330 592.009V594.991C330 598.868 326.096 602 321.291 602Z"
          fill="#DCDAE2"
        />
        <path
          d="M245.532 623H46.4683C42.3457 623 39 620.309 39 616.994V612.006C39 608.691 42.3457 606 46.4683 606H245.532C249.654 606 253 608.691 253 612.006V616.994C253 620.309 249.654 623 245.532 623Z"
          fill="#DCDAE2"
        />
        <path
          d="M39 455C39 452.239 41.2386 450 44 450H131C133.761 450 136 452.239 136 455V468C136 470.761 133.761 473 131 473H44C41.2386 473 39 470.761 39 468V455Z"
          fill="#C0BFCA"
        />
        <path
          d="M39 410C39 407.239 41.2386 405 44 405H223C225.761 405 228 407.239 228 410V434C228 436.761 225.761 439 223 439H44C41.2386 439 39 436.761 39 434V410Z"
          fill="#9694A0"
        />
        <path
          d="M265 410C265 407.239 267.239 405 270 405H325C327.761 405 330 407.239 330 410V434C330 436.761 327.761 439 325 439H270C267.239 439 265 436.761 265 434V410Z"
          fill="#9694A0"
        />
        <path d="M379 373H0V376H379V373Z" fill="#9694A0" />
        <path d="M379 509H0V512H379V509Z" fill="#9694A0" />
        <defs>
          <clipPath id="clip0_76_7584">
            <rect
              width="196"
              height="211"
              fill="white"
              transform="translate(90 78)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductCardTheme2Top = (props) => {
  return (
    <div className="ProductCardTheme2Top">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 57 product detail v2" clipPath="url(#clip0_76_7585)">
          <rect width="379" height="664" fill={props.secondaryColor} />
          <g id="Group">
            <path
              id="themecolor1"
              d="M379 112.75C329.598 77.35 51.7605 75.67 0 112.75V0H379V112.75Z"
              fill={props.color}
            />
          </g>
          <rect
            id="Rectangle 52"
            x="24"
            y="25"
            width="332"
            height="380"
            rx="10"
            fill="white"
          />
          <rect
            id="Rectangle 53"
            x="24"
            y="551"
            width="332"
            height="145"
            rx="10"
            fill="white"
          />
          <rect
            id="Rectangle 54"
            x="24"
            y="419"
            width="332"
            height="117"
            rx="10"
            fill="white"
          />
          <g id="Comp icon product" clipPath="url(#clip1_76_7585)">
            <g id="Comp icon - Product">
              <g id="Group_2">
                <path
                  id="Vector"
                  d="M286.657 154.312C285.787 152.824 284.52 151.558 282.95 150.665L195.078 109.34C191.939 107.553 188.08 107.553 184.941 109.34L97.0689 150.665C95.499 151.558 94.2318 152.824 93.3618 154.312L190.009 209.202L286.657 154.312Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M190.01 319C191.768 319 193.509 318.553 195.078 317.66L275.971 267.757C279.111 265.971 281.04 262.677 281.04 259.105L288.019 159.299C288.019 157.512 287.527 155.801 286.657 154.312L190.01 209.202V319Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M184.941 317.66C186.51 318.553 188.269 319 190.009 319V209.22L93.3618 154.331C92.4917 155.8 92 157.531 92 159.299L98.544 259.105C98.544 262.677 100.473 265.971 103.613 267.757L184.941 317.66Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M234.324 127.649L147.038 178.836L190.01 209.202L93.3618 154.312L142.915 175.859L230.881 126.197L234.324 127.649Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <path
            id="Vector_5"
            d="M326.291 590H52.709C47.904 590 44 586.868 44 582.991V580.009C44 576.142 47.8915 573 52.709 573H326.291C331.096 573 335 576.132 335 580.009V582.991C335 586.868 331.096 590 326.291 590Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_6"
            d="M326.291 611H52.709C47.904 611 44 607.868 44 603.991V601.009C44 597.142 47.8915 594 52.709 594H326.291C331.096 594 335 597.132 335 601.009V603.991C335 607.868 331.096 611 326.291 611Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_7"
            d="M326.291 632H52.709C47.904 632 44 628.868 44 624.991V622.009C44 618.142 47.8915 615 52.709 615H326.291C331.096 615 335 618.132 335 622.009V624.991C335 628.868 331.096 632 326.291 632Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_8"
            d="M250.532 653H51.4683C47.3457 653 44 650.309 44 646.994V642.006C44 638.691 47.3457 636 51.4683 636H250.532C254.654 636 258 638.691 258 642.006V646.994C258 650.309 254.654 653 250.532 653Z"
            fill="#DCDAE2"
          />
          <g id="comp elem rect 3">
            <path
              d="M44 495C44 492.239 46.2386 490 49 490H136C138.761 490 141 492.239 141 495V508C141 510.761 138.761 513 136 513H49C46.2386 513 44 510.761 44 508V495Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 4">
            <path
              d="M44 450C44 447.239 46.2386 445 49 445H228C230.761 445 233 447.239 233 450V474C233 476.761 230.761 479 228 479H49C46.2386 479 44 476.761 44 474V450Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 5">
            <path
              d="M270 450C270 447.239 272.239 445 275 445H330C332.761 445 335 447.239 335 450V474C335 476.761 332.761 479 330 479H275C272.239 479 270 476.761 270 474V450Z"
              fill="#9694A0"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_76_7585">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7585">
            <rect
              width="196"
              height="211"
              fill="white"
              transform="translate(92 108)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductCardTheme2Bottom = (props) => {
  return (
    <div className="ProductCardTheme2Bottom">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 58 product detail v3" clipPath="url(#clip0_76_7586)">
          <rect width="379" height="664" fill={props.secondaryColor} />
          <g id="Group">
            <path
              id="themecolor1"
              d="M379 104.144C323.555 158.46 58.8638 161.796 0 104.144V-50H379V104.144Z"
              fill={props.color}
            />
          </g>
          <rect
            id="Rectangle 52"
            x="24"
            y="25"
            width="332"
            height="380"
            rx="10"
            fill="white"
          />
          <rect
            id="Rectangle 53"
            x="24"
            y="551"
            width="332"
            height="145"
            rx="10"
            fill="white"
          />
          <rect
            id="Rectangle 54"
            x="24"
            y="419"
            width="332"
            height="117"
            rx="10"
            fill="white"
          />
          <g id="Comp icon product" clipPath="url(#clip1_76_7586)">
            <g id="Comp icon - Product">
              <g id="Group_2">
                <path
                  id="Vector"
                  d="M286.657 154.312C285.787 152.824 284.52 151.558 282.95 150.665L195.078 109.34C191.939 107.553 188.08 107.553 184.941 109.34L97.0689 150.665C95.499 151.558 94.2318 152.824 93.3618 154.312L190.009 209.202L286.657 154.312Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M190.01 319C191.768 319 193.509 318.553 195.078 317.66L275.971 267.757C279.111 265.971 281.04 262.677 281.04 259.105L288.019 159.299C288.019 157.512 287.527 155.801 286.657 154.312L190.01 209.202V319V319Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M184.941 317.66C186.51 318.553 188.269 319 190.009 319V209.22L93.3618 154.331C92.4917 155.8 92 157.531 92 159.299L98.544 259.105C98.544 262.677 100.473 265.971 103.613 267.757L184.941 317.66Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M234.324 127.649L147.038 178.836L190.01 209.202L93.3618 154.312L142.915 175.859L230.881 126.197L234.324 127.649Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <path
            id="Vector_5"
            d="M326.291 590H52.709C47.904 590 44 586.868 44 582.991V580.009C44 576.142 47.8915 573 52.709 573H326.291C331.096 573 335 576.132 335 580.009V582.991C335 586.868 331.096 590 326.291 590Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_6"
            d="M326.291 611H52.709C47.904 611 44 607.868 44 603.991V601.009C44 597.142 47.8915 594 52.709 594H326.291C331.096 594 335 597.132 335 601.009V603.991C335 607.868 331.096 611 326.291 611Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_7"
            d="M326.291 632H52.709C47.904 632 44 628.868 44 624.991V622.009C44 618.142 47.8915 615 52.709 615H326.291C331.096 615 335 618.132 335 622.009V624.991C335 628.868 331.096 632 326.291 632Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_8"
            d="M250.532 653H51.4683C47.3457 653 44 650.309 44 646.994V642.006C44 638.691 47.3457 636 51.4683 636H250.532C254.654 636 258 638.691 258 642.006V646.994C258 650.309 254.654 653 250.532 653Z"
            fill="#DCDAE2"
          />
          <g id="comp elem rect 3">
            <path
              d="M44 495C44 492.239 46.2386 490 49 490H136C138.761 490 141 492.239 141 495V508C141 510.761 138.761 513 136 513H49C46.2386 513 44 510.761 44 508V495Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 4">
            <path
              d="M44 450C44 447.239 46.2386 445 49 445H228C230.761 445 233 447.239 233 450V474C233 476.761 230.761 479 228 479H49C46.2386 479 44 476.761 44 474V450Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 5">
            <path
              d="M270 450C270 447.239 272.239 445 275 445H330C332.761 445 335 447.239 335 450V474C335 476.761 332.761 479 330 479H275C272.239 479 270 476.761 270 474V450Z"
              fill="#9694A0"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_76_7586">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7586">
            <rect
              width="196"
              height="211"
              fill="white"
              transform="translate(92 108)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const ProductCardTheme3 = (props) => {
  return (
    <div className="ProductCardTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 59 product detail v4" clipPath="url(#clip0_76_7587)">
          <rect id="themecolor1" width="379" height="664" fill={props.color} />
          <path
            id="Rectangle 52"
            d="M0 -9.53674e-07C0 -16.5685 13.4315 -30 30 -30H349C365.569 -30 379 -16.5685 379 -9.53674e-07V392C379 408.569 365.569 422 349 422H30C13.4315 422 0 408.569 0 392V-9.53674e-07Z"
            fill="white"
          />
          <rect
            id="Rectangle 53"
            x="24"
            y="457"
            width="332"
            height="125"
            rx="10"
            fill="white"
          />
          <rect
            id="Rectangle 54"
            x="24"
            y="600"
            width="332"
            height="125"
            rx="10"
            fill="white"
          />
          <g id="Comp icon product" clipPath="url(#clip1_76_7587)">
            <g id="Comp icon - Product">
              <g id="Group">
                <path
                  id="Vector"
                  d="M280.74 121.459C279.923 120.062 278.733 118.875 277.259 118.037L194.768 79.2571C191.82 77.581 188.198 77.581 185.251 79.2571L102.759 118.037C101.285 118.875 100.095 120.062 99.2786 121.459L190.009 172.967L280.74 121.459Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M190.009 276C191.66 276 193.294 275.581 194.767 274.743L270.708 227.914C273.655 226.238 275.466 223.147 275.466 219.795L282.018 126.138C282.018 124.462 281.556 122.855 280.739 121.458L190.009 172.966V276Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M185.25 274.743C186.724 275.581 188.375 276 190.009 276V172.984L99.2784 121.476C98.4616 122.855 98 124.479 98 126.138L104.143 219.795C104.143 223.147 105.954 226.238 108.902 227.914L185.25 274.743Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M231.61 96.4381L149.669 144.471L190.009 172.967L99.2786 121.459L145.798 141.678L228.379 95.0762L231.61 96.4381Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <path
            id="Vector_5"
            d="M326.291 496H52.709C47.904 496 44 492.868 44 488.991V486.009C44 482.142 47.8915 479 52.709 479H326.291C331.096 479 335 482.132 335 486.009V488.991C335 492.868 331.096 496 326.291 496Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_6"
            d="M326.291 639H52.709C47.904 639 44 635.868 44 631.991V629.009C44 625.142 47.8915 622 52.709 622H326.291C331.096 622 335 625.132 335 629.009V631.991C335 635.868 331.096 639 326.291 639Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_7"
            d="M326.291 517H52.709C47.904 517 44 513.868 44 509.991V507.009C44 503.142 47.8915 500 52.709 500H326.291C331.096 500 335 503.132 335 507.009V509.991C335 513.868 331.096 517 326.291 517Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_8"
            d="M326.291 660H52.709C47.904 660 44 656.868 44 652.991V650.009C44 646.142 47.8915 643 52.709 643H326.291C331.096 643 335 646.132 335 650.009V652.991C335 656.868 331.096 660 326.291 660Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_9"
            d="M326.291 538H52.709C47.904 538 44 534.868 44 530.991V528.009C44 524.142 47.8915 521 52.709 521H326.291C331.096 521 335 524.132 335 528.009V530.991C335 534.868 331.096 538 326.291 538Z"
            fill="#DCDAE2"
          />
          <path
            id="Vector_10"
            d="M250.532 559H51.4683C47.3457 559 44 556.309 44 552.994V548.006C44 544.691 47.3457 542 51.4683 542H250.532C254.654 542 258 544.691 258 548.006V552.994C258 556.309 254.654 559 250.532 559Z"
            fill="#DCDAE2"
          />
          <g id="comp elem rect 3">
            <path
              d="M44 380C44 377.239 46.2386 375 49 375H136C138.761 375 141 377.239 141 380V393C141 395.761 138.761 398 136 398H49C46.2386 398 44 395.761 44 393V380Z"
              fill="#C0BFCA"
            />
          </g>
          <g id="comp elem rect 4">
            <path
              d="M44 337C44 334.239 46.2386 332 49 332H228C230.761 332 233 334.239 233 337V361C233 363.761 230.761 366 228 366H49C46.2386 366 44 363.761 44 361V337Z"
              fill="#9694A0"
            />
          </g>
          <g id="comp elem rect 5">
            <path
              d="M270 337C270 334.239 272.239 332 275 332H330C332.761 332 335 334.239 335 337V361C335 363.761 332.761 366 330 366H275C272.239 366 270 363.761 270 361V337Z"
              fill="#C0BFCA"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_76_7587">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7587">
            <rect
              width="184"
              height="198"
              fill="white"
              transform="translate(98 78)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ProductCardBlocks;
