import React from 'react';
import ReactPlayer from "react-player";

const VideoComponent = ({ compoVideo, width, height, left }) => {
  return (
    <div className="video-player-container">
        <ReactPlayer
          url={compoVideo}
          width={width}
          height={height}
          muted
          loop
          playing={true}
          style={{ position: "relative", top: "5px", left: {left} }}
        />
    </div>
  );
};

VideoComponent.defaultProps = {
  width: "230px",
  height: "250px",
  left: "-22px"
};

export default VideoComponent;
