import React from 'react';

function RadioButtonSlide({
  label,
  id,
  isActive,
  isSelected,
  onClick,
  onSelect,
  style
}) {
  if (!label) return null;

  const handleInputClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    e.stopPropagation();
  };

  const handleLabelClick = (e) => {
    if (onSelect) {
      onSelect();
    }
  };

  const handleDivClick = (e) => {
    if (e.target !== e.currentTarget) return;
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <div
      className={`radioSlide ${isSelected ? "active" : ""}`}
      style={style}
      onClick={handleDivClick}
    >
      <label onClick={handleLabelClick}>
        {label}
      </label>
      <input
        type="radio"
        name={id}
        id={id}
        className={`radioColors ${isActive ? "active" : ""}`}
        onClick={handleInputClick}
      />
    </div>
  );
}

export default RadioButtonSlide;
