import React from "react";
import ButtonComponent from "./ButtonComponent";

function BoxText({ children, handleSaveButton }) {
  if (!children) return null;
  return (
    <div className="content-box">
      <div className="box row">
        <div className="paragraph">{children}</div>
        <ButtonComponent
          classes="edit"
          children="Save Changes"
          handleOnClick={handleSaveButton}
        />
      </div>
    </div>
  );
}

export default BoxText;
