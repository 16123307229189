import React from "react";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import AppBar from "../components/appbar/appbar";
import BracketSection from "../components/appbar/BracketSection";
import Loaders from "../components/home/colors-page/Loaders";

export const LoadersPage = ({ storeKey }) => {
  return (
    <div className="market-layout">
      <Container fluid className="layout-container">
        <Row className="main-row no-gutters">
          <div style={{ display: "flex", width: "100%" }}>
            <AppBar activeOption="colors" />
            <BracketSection />

            <Loaders storeKey={storeKey}/>
          </div>
        </Row>
      </Container>
    </div>
  );
};
