
import React from "react";

const NavBar = (props) => {
  switch (props.theme) {
    case "SQUARE":
      return <NavbarMenu1 color={props.color} />;
    case "SQUARE TOP LINE":
      return <NavbarMenu2 color={props.color} />;
    case "SQUARE  BOTTOM LINE":
      return <NavbarMenu3 color={props.color} />;
    case "SQUARE DOUBLE LINE":
      return <NavbarMenu4 color={props.color} />;
    case "ROUNDED":
      return <NavbarMenu5 color={props.color} />;
    case "FOUR CORNERS":
      return <NavbarMenu7 color={props.color} />;
    case "SKEW":
      return <NavbarMenu11 color={props.color} />;
    case "PANAL":
      return <NavbarMenu6 color={props.color} />;
    case "LINEAR BADGE":
      return <NavbarMenu8 color={props.color} />;
    case "ROUNDED BADGE":
      return <NavbarMenu9 color={props.color} />;
    case "HALF OVAL":
      return <NavbarMenu10 color={props.color} />;
    case "TRAPEZE":
      return <NavbarMenu12 color={props.color} />;
    default:
      return <NavbarMenu1 color={props.color} />;
  }
};

const NavbarMenu1 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu1">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 16 product list - nav 1">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <rect
                id="themecolor2"
                x="15"
                y="244"
                width="89"
                height="61"
                fill={selectedColor}
              />
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7533"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7533)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7723 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9846 62.0381 75.6202 63.057 78.1332 62.6225C81.1072 62.1131 83.8135 63.5065 86.6388 64.1209C90.044 67.672 93.583 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1467 151.836L92.1303 155.197C90.7352 155.654 89.455 156.469 87.9614 156.6C82.5779 156.208 77.3586 154.936 72.1721 153.598C55.2995 149.242 37.9837 151.836 20.75 151.836C10.8858 150.938 -0.471954 151.428 -10.369 151.836C-10.369 151.836 -14.1932 151.64 -16.967 151.836V119.303C-14.4558 118.308 -12.1088 119.025 -10.369 121.522C-8.10401 124.752 -6.24934 125.127 -2.93391 123.023C-2.49076 122.745 -2.06402 122.468 -1.63728 122.158C0.627718 120.527 2.95837 120.184 5.50238 121.587C7.50477 122.68 9.63847 122.892 11.9035 122.452C13.3806 122.158 15.0219 122.011 16.1216 123.316C17.9763 125.552 20.4711 126.188 23.1792 126.498C25.7561 126.792 28.2508 127.51 30.0891 129.402C33.8477 133.253 37.7704 133.497 42.2183 130.609C44.0894 129.386 44.7952 129.745 46.1082 131.572C46.5842 132.225 47.0437 132.894 47.5854 133.481C49.6206 135.667 51.0978 135.945 53.9372 134.786C55.0205 134.884 56.0873 134.998 57.2034 134.949C59.534 134.884 60.9292 136.385 61.717 138.343C62.4392 140.17 63.5881 141.068 65.6069 141.084C67.5928 141.1 69.267 142.096 70.7113 143.368C73.124 145.473 75.9471 146.452 79.1148 146.501C81.6424 146.533 83.8417 147.365 85.8113 148.915C87.666 150.384 89.6848 151.607 92.1467 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.812 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.907 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.263 140.968 340.35 141C337.436 141.031 336.866 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.319 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.856 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.577 36.9479C271.148 40.7897 275.859 48.0707 279.754 55.9892C285.672 68.0348 289.414 80.785 291.846 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.703 139.419L269.856 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

const NavbarMenu2 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  // Top / Bottom Colors
  let bottomTopColor = shade(props.color, 0.7);
  return (
    <div className="NavbarMenu2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="379" height="664" fill="#E5E5E5" />
        <g id="preview 17 product list - nav 2">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <rect
                  id="themecolor2"
                  x="15"
                  y="255"
                  width="89"
                  height="50"
                  fill={selectedColor}
                />
              </g>
              <rect
                id="themecolor2_2"
                opacity="0.5"
                x="15"
                y="244"
                width="89"
                height="11"
                fill={bottomTopColor}
              />
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_40_435"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_40_435)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7723 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9846 62.0381 75.6202 63.057 78.1332 62.6225C81.1072 62.1131 83.8135 63.5065 86.6388 64.1209C90.044 67.672 93.583 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1467 151.836L92.1303 155.197C90.7352 155.654 89.455 156.469 87.9614 156.6C82.5779 156.208 77.3586 154.936 72.1721 153.598C55.2995 149.242 37.9837 151.836 20.75 151.836C10.8858 150.938 -0.471954 151.428 -10.369 151.836C-10.369 151.836 -14.1932 151.64 -16.967 151.836V119.303C-14.4558 118.308 -12.1088 119.025 -10.369 121.522C-8.10401 124.752 -6.24934 125.127 -2.93391 123.023C-2.49076 122.745 -2.06402 122.468 -1.63728 122.158C0.627718 120.527 2.95837 120.184 5.50238 121.587C7.50477 122.68 9.63847 122.892 11.9035 122.452C13.3806 122.158 15.0219 122.011 16.1216 123.316C17.9763 125.552 20.4711 126.188 23.1792 126.498C25.7561 126.792 28.2508 127.51 30.0891 129.402C33.8477 133.253 37.7704 133.497 42.2183 130.609C44.0894 129.386 44.7952 129.745 46.1082 131.572C46.5842 132.225 47.0437 132.894 47.5854 133.481C49.6206 135.667 51.0978 135.945 53.9372 134.786C55.0205 134.884 56.0873 134.998 57.2034 134.949C59.534 134.884 60.9292 136.385 61.717 138.343C62.4392 140.17 63.5881 141.068 65.6069 141.084C67.5928 141.1 69.267 142.096 70.7113 143.368C73.124 145.473 75.9471 146.452 79.1148 146.501C81.6424 146.533 83.8417 147.365 85.8113 148.915C87.666 150.384 89.6848 151.607 92.1467 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.812 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.907 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.263 140.968 340.35 141C337.436 141.031 336.866 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.319 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.856 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.577 36.9479C271.148 40.7897 275.859 48.0707 279.754 55.9892C285.672 68.0348 289.414 80.785 291.846 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.703 139.419L269.856 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

const NavbarMenu3 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  // Top / Bottom Colors
  let bottomTopColor = shade(props.color, 0.7);
  return (
    <div className="NavbarMenu3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 18 product list - nav 3">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <rect
                  id="themecolor2"
                  x="15"
                  y="244"
                  width="89"
                  height="50"
                  fill={selectedColor}
                />
              </g>
              <rect
                id="themecolor2_2"
                opacity="0.5"
                x="15"
                y="294"
                width="89"
                height="11"
                fill={bottomTopColor}
              />
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7546"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7546)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7723 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9846 62.0381 75.6202 63.057 78.1332 62.6225C81.1072 62.1131 83.8135 63.5065 86.6388 64.1209C90.044 67.672 93.583 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1467 151.836L92.1303 155.197C90.7352 155.654 89.455 156.469 87.9614 156.6C82.5779 156.208 77.3586 154.936 72.1721 153.598C55.2995 149.242 37.9837 151.836 20.75 151.836C10.8858 150.938 -0.471954 151.428 -10.369 151.836C-10.369 151.836 -14.1932 151.64 -16.967 151.836V119.303C-14.4558 118.308 -12.1088 119.025 -10.369 121.522C-8.10401 124.752 -6.24934 125.127 -2.93391 123.023C-2.49076 122.745 -2.06402 122.468 -1.63728 122.158C0.627718 120.527 2.95837 120.184 5.50238 121.587C7.50477 122.68 9.63847 122.892 11.9035 122.452C13.3806 122.158 15.0219 122.011 16.1216 123.316C17.9763 125.552 20.4711 126.188 23.1792 126.498C25.7561 126.792 28.2508 127.51 30.0891 129.402C33.8477 133.253 37.7704 133.497 42.2183 130.609C44.0894 129.386 44.7952 129.745 46.1082 131.572C46.5842 132.225 47.0437 132.894 47.5854 133.481C49.6206 135.667 51.0978 135.945 53.9372 134.786C55.0205 134.884 56.0873 134.998 57.2034 134.949C59.534 134.884 60.9292 136.385 61.717 138.343C62.4392 140.17 63.5881 141.068 65.6069 141.084C67.5928 141.1 69.267 142.096 70.7113 143.368C73.124 145.473 75.9471 146.452 79.1148 146.501C81.6424 146.533 83.8417 147.365 85.8113 148.915C87.666 150.384 89.6848 151.607 92.1467 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.812 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.907 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.263 140.968 340.35 141C337.436 141.031 336.866 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.319 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.856 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.577 36.9479C271.148 40.7897 275.859 48.0707 279.754 55.9892C285.672 68.0348 289.414 80.785 291.846 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.703 139.419L269.856 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

const NavbarMenu4 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  // Top / Bottom Colors
  let bottomTopColor = shade(props.color, 0.7);
  return (
    <div className="NavbarMenu4">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 19 product list - nav 4">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <rect
                  id="themecolor2"
                  x="15"
                  y="255"
                  width="89"
                  height="39"
                  fill={selectedColor}
                />
              </g>
              <rect
                id="themecolor2_2"
                opacity="0.5"
                x="15"
                y="294"
                width="89"
                height="11"
                fill={bottomTopColor}
              />
              <rect
                id="themecolor2_3"
                opacity="0.5"
                x="15"
                y="244"
                width="89"
                height="11"
                fill={bottomTopColor}
              />
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7547"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7547)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7723 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9846 62.0381 75.6202 63.057 78.1332 62.6225C81.1072 62.1131 83.8135 63.5065 86.6388 64.1209C90.044 67.672 93.583 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1467 151.836L92.1303 155.197C90.7352 155.654 89.455 156.469 87.9614 156.6C82.5779 156.208 77.3586 154.936 72.1721 153.598C55.2995 149.242 37.9837 151.836 20.75 151.836C10.8858 150.938 -0.471954 151.428 -10.369 151.836C-10.369 151.836 -14.1932 151.64 -16.967 151.836V119.303C-14.4558 118.308 -12.1088 119.025 -10.369 121.522C-8.10401 124.752 -6.24934 125.127 -2.93391 123.023C-2.49076 122.745 -2.06402 122.468 -1.63728 122.158C0.627718 120.527 2.95837 120.184 5.50238 121.587C7.50477 122.68 9.63847 122.892 11.9035 122.452C13.3806 122.158 15.0219 122.011 16.1216 123.316C17.9763 125.552 20.4711 126.188 23.1792 126.498C25.7561 126.792 28.2508 127.51 30.0891 129.402C33.8477 133.253 37.7704 133.497 42.2183 130.609C44.0894 129.386 44.7952 129.745 46.1082 131.572C46.5842 132.225 47.0437 132.894 47.5854 133.481C49.6206 135.667 51.0978 135.945 53.9372 134.786C55.0205 134.884 56.0873 134.998 57.2034 134.949C59.534 134.884 60.9292 136.385 61.717 138.343C62.4392 140.17 63.5881 141.068 65.6069 141.084C67.5928 141.1 69.267 142.096 70.7113 143.368C73.124 145.473 75.9471 146.452 79.1148 146.501C81.6424 146.533 83.8417 147.365 85.8113 148.915C87.666 150.384 89.6848 151.607 92.1467 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.812 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.907 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.263 140.968 340.35 141C337.436 141.031 336.866 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.319 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.856 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.577 36.9479C271.148 40.7897 275.859 48.0707 279.754 55.9892C285.672 68.0348 289.414 80.785 291.846 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.703 139.419L269.856 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

const NavbarMenu5 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu5">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 20 product list - nav 5">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="themecolor2">
                <rect
                  id="Rectangle 46"
                  x="15"
                  y="253"
                  width="89"
                  height="43"
                  rx="8"
                  fill={selectedColor}
                />
              </g>
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7548"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7548)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7723 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9846 62.0381 75.6202 63.057 78.1332 62.6225C81.1072 62.1131 83.8135 63.5065 86.6388 64.1209C90.044 67.672 93.583 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1467 151.836L92.1303 155.197C90.7352 155.654 89.455 156.469 87.9614 156.6C82.5779 156.208 77.3586 154.936 72.1721 153.598C55.2995 149.242 37.9837 151.836 20.75 151.836C10.8858 150.938 -0.471954 151.428 -10.369 151.836C-10.369 151.836 -14.1932 151.64 -16.967 151.836V119.303C-14.4558 118.308 -12.1088 119.025 -10.369 121.522C-8.10401 124.752 -6.24934 125.127 -2.93391 123.023C-2.49076 122.745 -2.06402 122.468 -1.63728 122.158C0.627718 120.527 2.95837 120.184 5.50238 121.587C7.50477 122.68 9.63847 122.892 11.9035 122.452C13.3806 122.158 15.0219 122.011 16.1216 123.316C17.9763 125.552 20.4711 126.188 23.1792 126.498C25.7561 126.792 28.2508 127.51 30.0891 129.402C33.8477 133.253 37.7704 133.497 42.2183 130.609C44.0894 129.386 44.7952 129.745 46.1082 131.572C46.5842 132.225 47.0437 132.894 47.5854 133.481C49.6206 135.667 51.0978 135.945 53.9372 134.786C55.0205 134.884 56.0873 134.998 57.2034 134.949C59.534 134.884 60.9292 136.385 61.717 138.343C62.4392 140.17 63.5881 141.068 65.6069 141.084C67.5928 141.1 69.267 142.096 70.7113 143.368C73.124 145.473 75.9471 146.452 79.1148 146.501C81.6424 146.533 83.8417 147.365 85.8113 148.915C87.666 150.384 89.6848 151.607 92.1467 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.813 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.908 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

const NavbarMenu6 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu6">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 21 product list - nav 6">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <path
                id="themecolor2"
                d="M27 253H92L104 274.5L92 296H27L15 274.5L27 253Z"
                fill={selectedColor}
              />
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7549"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7549)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7723 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9846 62.0381 75.6202 63.057 78.1332 62.6225C81.1072 62.1131 83.8135 63.5065 86.6388 64.1209C90.044 67.672 93.583 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1466 151.836L92.1302 155.197C90.7351 155.654 89.4549 156.469 87.9613 156.6C82.5778 156.208 77.3585 154.936 72.172 153.598C55.2994 149.242 37.9836 151.836 20.7499 151.836C10.8857 150.938 -0.472076 151.428 -10.3691 151.836C-10.3691 151.836 -14.1934 151.64 -16.9672 151.836V119.303C-14.456 118.308 -12.1089 119.025 -10.3691 121.522C-8.10413 124.752 -6.24946 125.127 -2.93403 123.023C-2.49088 122.745 -2.06414 122.468 -1.6374 122.158C0.627596 120.527 2.95824 120.184 5.50226 121.587C7.50465 122.68 9.63834 122.892 11.9033 122.452C13.3805 122.158 15.0218 122.011 16.1215 123.316C17.9762 125.552 20.4709 126.188 23.1791 126.498C25.7559 126.792 28.2507 127.51 30.089 129.402C33.8475 133.253 37.7703 133.497 42.2182 130.609C44.0893 129.386 44.795 129.745 46.1081 131.572C46.5841 132.225 47.0436 132.894 47.5853 133.481C49.6205 135.667 51.0976 135.945 53.9371 134.786C55.0203 134.884 56.0872 134.998 57.2033 134.949C59.5339 134.884 60.929 136.385 61.7169 138.343C62.439 140.17 63.5879 141.068 65.6068 141.084C67.5927 141.1 69.2668 142.096 70.7112 143.368C73.1239 145.473 75.947 146.452 79.1147 146.501C81.6423 146.533 83.8416 147.365 85.8112 148.915C87.6658 150.384 89.6847 151.607 92.1466 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.813 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.908 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

const NavbarMenu7 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu7">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 22 product list - nav 7">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <path
                id="themecolor2"
                d="M25 253H94L104 263.75V285.5L94 296H25L15 285.5V263.75L25 253Z"
                fill={selectedColor}
              />
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7550"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7550)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.966 62.7723 36.7058 62.1131 38.5348 60.1952C47.8879 50.3959 62.8024 50.4409 72.2596 60.2401C73.9846 62.0381 75.6202 63.057 78.1332 62.6225C81.1072 62.1131 83.8135 63.5065 86.6388 64.1209C90.044 67.672 93.583 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.575 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6276 37.9895 82.4901 34.5433C83.2187 31.6215 84.9287 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1466 151.836L92.1302 155.197C90.7351 155.654 89.4549 156.469 87.9613 156.6C82.5778 156.208 77.3585 154.936 72.172 153.598C55.2994 149.242 37.9836 151.836 20.7499 151.836C10.8857 150.938 -0.472076 151.428 -10.3691 151.836C-10.3691 151.836 -14.1934 151.64 -16.9672 151.836V119.303C-14.456 118.308 -12.1089 119.025 -10.3691 121.522C-8.10413 124.752 -6.24946 125.127 -2.93403 123.023C-2.49088 122.745 -2.06414 122.468 -1.6374 122.158C0.627596 120.527 2.95824 120.184 5.50226 121.587C7.50465 122.68 9.63834 122.892 11.9033 122.452C13.3805 122.158 15.0218 122.011 16.1215 123.316C17.9762 125.552 20.4709 126.188 23.1791 126.498C25.7559 126.792 28.2507 127.51 30.089 129.402C33.8475 133.253 37.7703 133.497 42.2182 130.609C44.0893 129.386 44.795 129.745 46.1081 131.572C46.5841 132.225 47.0436 132.894 47.5853 133.481C49.6205 135.667 51.0976 135.945 53.9371 134.786C55.0203 134.884 56.0872 134.998 57.2033 134.949C59.5339 134.884 60.929 136.385 61.7169 138.343C62.439 140.17 63.5879 141.068 65.6068 141.084C67.5927 141.1 69.2668 142.096 70.7112 143.368C73.1239 145.473 75.947 146.452 79.1147 146.501C81.6423 146.533 83.8416 147.365 85.8112 148.915C87.6658 150.384 89.6847 151.607 92.1466 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.813 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.908 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

const NavbarMenu8 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu8">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 23 product list - nav 8">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <path
                  id="themecolor2"
                  d="M15 253H104V290L59.5 302L15 290V253Z"
                  fill={selectedColor}
                />
              </g>
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7551"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7551)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.591 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9586 76.767C68.7504 76.782 44.5572 76.797 20.349 76.8569C17.8509 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.9661 62.7723 36.7059 62.1131 38.5349 60.1952C47.888 50.3959 62.8025 50.4409 72.2597 60.2401C73.9846 62.0381 75.6203 63.057 78.1333 62.6225C81.1073 62.1131 83.8136 63.5065 86.6389 64.1209C90.0441 67.672 93.5831 71.1631 92.9586 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.866 27.8906 143.159 35.3974C143.576 37.8397 142.877 38.7387 140.379 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3266 38.6938 85.5087 38.6638C82.0441 38.6488 81.6277 37.9895 82.4901 34.5433C83.2188 31.6215 84.9288 29.2691 86.5794 26.8567C89.6872 24.4893 92.9437 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1466 151.836L92.1302 155.197C90.7351 155.654 89.4549 156.469 87.9613 156.6C82.5778 156.208 77.3585 154.936 72.172 153.598C55.2994 149.242 37.9836 151.836 20.7499 151.836C10.8857 150.938 -0.472076 151.428 -10.3691 151.836C-10.3691 151.836 -14.1934 151.64 -16.9672 151.836V119.303C-14.456 118.308 -12.1089 119.025 -10.3691 121.522C-8.10413 124.752 -6.24946 125.127 -2.93403 123.023C-2.49088 122.745 -2.06414 122.468 -1.6374 122.158C0.627596 120.527 2.95824 120.184 5.50226 121.587C7.50465 122.68 9.63834 122.892 11.9033 122.452C13.3805 122.158 15.0218 122.011 16.1215 123.316C17.9762 125.552 20.4709 126.188 23.1791 126.498C25.7559 126.792 28.2507 127.51 30.089 129.402C33.8475 133.253 37.7703 133.497 42.2182 130.609C44.0893 129.386 44.795 129.745 46.1081 131.572C46.5841 132.225 47.0436 132.894 47.5853 133.481C49.6205 135.667 51.0976 135.945 53.9371 134.786C55.0203 134.884 56.0872 134.998 57.2033 134.949C59.5339 134.884 60.929 136.385 61.7169 138.343C62.439 140.17 63.5879 141.068 65.6068 141.084C67.5927 141.1 69.2668 142.096 70.7112 143.368C73.1239 145.473 75.947 146.452 79.1147 146.501C81.6423 146.533 83.8416 147.365 85.8112 148.915C87.6658 150.384 89.6847 151.607 92.1466 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.813 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.908 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.663 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.167 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.879 93.9041C289.447 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.073 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.032 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
const NavbarMenu9 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu9">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 24 product list - nav 9">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <path
                  id="themecolor2"
                  d="M15 253H104V288C104 288 83 300 59.5 300C36 300 15 288 15 288V253Z"
                  fill={selectedColor}
                />
              </g>
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7552"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7552)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.591 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.9661 62.7723 36.7058 62.1131 38.5348 60.1952C47.888 50.3959 62.8024 50.4409 72.2597 60.2401C73.9846 62.0381 75.6203 63.057 78.1333 62.6225C81.1072 62.1131 83.8136 63.5065 86.6388 64.1209C90.044 67.672 93.5831 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.576 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6277 37.9895 82.4901 34.5433C83.2187 31.6215 84.9288 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1466 151.836L92.1302 155.197C90.7351 155.654 89.4549 156.469 87.9613 156.6C82.5778 156.208 77.3585 154.936 72.172 153.598C55.2994 149.242 37.9836 151.836 20.7499 151.836C10.8857 150.938 -0.472076 151.428 -10.3691 151.836C-10.3691 151.836 -14.1934 151.64 -16.9672 151.836V119.303C-14.456 118.308 -12.1089 119.025 -10.3691 121.522C-8.10413 124.752 -6.24946 125.127 -2.93403 123.023C-2.49088 122.745 -2.06414 122.468 -1.6374 122.158C0.627596 120.527 2.95824 120.184 5.50226 121.587C7.50465 122.68 9.63834 122.892 11.9033 122.452C13.3805 122.158 15.0218 122.011 16.1215 123.316C17.9762 125.552 20.4709 126.188 23.1791 126.498C25.7559 126.792 28.2507 127.51 30.089 129.402C33.8475 133.253 37.7703 133.497 42.2182 130.609C44.0893 129.386 44.795 129.745 46.1081 131.572C46.5841 132.225 47.0436 132.894 47.5853 133.481C49.6205 135.667 51.0976 135.945 53.9371 134.786C55.0203 134.884 56.0872 134.998 57.2033 134.949C59.5339 134.884 60.929 136.385 61.7169 138.343C62.439 140.17 63.5879 141.068 65.6068 141.084C67.5927 141.1 69.2668 142.096 70.7112 143.368C73.1239 145.473 75.947 146.452 79.1147 146.501C81.6423 146.533 83.8416 147.365 85.8112 148.915C87.6658 150.384 89.6847 151.607 92.1466 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.812 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.907 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.879 93.9041C289.447 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.073 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.032 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
const NavbarMenu10 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu10">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 25 product list - nav 10">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <path
                  id="themecolor2"
                  d="M15 263H104V288C104 288 83 300 59.5 300C36 300 15 288 15 288V263Z"
                  fill={selectedColor}
                />
                <path
                  id="themecolor2_2"
                  d="M15 286H104V261C104 261 83 249 59.5 249C36 249 15 261 15 261V286Z"
                  fill={selectedColor}
                />
              </g>
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7553"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7553)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.59 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.9661 62.7723 36.7058 62.1131 38.5348 60.1952C47.888 50.3959 62.8024 50.4409 72.2597 60.2401C73.9846 62.0381 75.6203 63.057 78.1333 62.6225C81.1073 62.1131 83.8136 63.5065 86.6388 64.1209C90.044 67.672 93.5831 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.576 37.8397 142.877 38.7387 140.379 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6277 37.9895 82.4901 34.5433C83.2187 31.6215 84.9288 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1466 151.836L92.1302 155.197C90.7351 155.654 89.4549 156.469 87.9613 156.6C82.5778 156.208 77.3585 154.936 72.172 153.598C55.2994 149.242 37.9836 151.836 20.7499 151.836C10.8857 150.938 -0.472065 151.428 -10.3691 151.836C-10.3691 151.836 -14.1934 151.64 -16.9672 151.836V119.303C-14.456 118.308 -12.1089 119.025 -10.3691 121.522C-8.10412 124.752 -6.24945 125.127 -2.93402 123.023C-2.49087 122.745 -2.06413 122.468 -1.63739 122.158C0.627607 120.527 2.95826 120.184 5.50227 121.587C7.50466 122.68 9.63836 122.892 11.9034 122.452C13.3805 122.158 15.0218 122.011 16.1215 123.316C17.9762 125.552 20.4709 126.188 23.1791 126.498C25.7559 126.792 28.2507 127.51 30.089 129.402C33.8476 133.253 37.7703 133.497 42.2182 130.609C44.0893 129.386 44.795 129.745 46.1081 131.572C46.5841 132.225 47.0436 132.894 47.5853 133.481C49.6205 135.667 51.0976 135.945 53.9371 134.786C55.0204 134.884 56.0872 134.998 57.2033 134.949C59.5339 134.884 60.929 136.385 61.7169 138.343C62.439 140.17 63.588 141.068 65.6068 141.084C67.5927 141.1 69.2669 142.096 70.7112 143.368C73.1239 145.473 75.947 146.452 79.1147 146.501C81.6423 146.533 83.8416 147.365 85.8112 148.915C87.6659 150.384 89.6847 151.607 92.1466 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.813 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.908 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.663 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.879 93.9041C289.447 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.073 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.032 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
const NavbarMenu11 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu11">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 26 product list - nav 11">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <path
                  id="themecolor2"
                  d="M25 253H104L94 296H15L25 253Z"
                  fill={selectedColor}
                />
              </g>
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7554"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7554)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.591 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9585 76.767C68.7504 76.782 44.5571 76.797 20.349 76.8569C17.8508 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.9661 62.7723 36.7058 62.1131 38.5348 60.1952C47.888 50.3959 62.8024 50.4409 72.2597 60.2401C73.9846 62.0381 75.6203 63.057 78.1333 62.6225C81.1072 62.1131 83.8136 63.5065 86.6388 64.1209C90.044 67.672 93.5831 71.1631 92.9585 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.865 27.8906 143.159 35.3974C143.576 37.8397 142.877 38.7387 140.378 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3265 38.6938 85.5087 38.6638C82.044 38.6488 81.6277 37.9895 82.4901 34.5433C83.2187 31.6215 84.9288 29.2691 86.5793 26.8567C89.6871 24.4893 92.9436 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1466 151.836L92.1302 155.197C90.7351 155.654 89.4549 156.469 87.9613 156.6C82.5778 156.208 77.3585 154.936 72.172 153.598C55.2994 149.242 37.9836 151.836 20.7499 151.836C10.8857 150.938 -0.472076 151.428 -10.3691 151.836C-10.3691 151.836 -14.1934 151.64 -16.9672 151.836V119.303C-14.456 118.308 -12.1089 119.025 -10.3691 121.522C-8.10413 124.752 -6.24946 125.127 -2.93403 123.023C-2.49088 122.745 -2.06414 122.468 -1.6374 122.158C0.627596 120.527 2.95824 120.184 5.50226 121.587C7.50465 122.68 9.63834 122.892 11.9033 122.452C13.3805 122.158 15.0218 122.011 16.1215 123.316C17.9762 125.552 20.4709 126.188 23.1791 126.498C25.7559 126.792 28.2507 127.51 30.089 129.402C33.8475 133.253 37.7703 133.497 42.2182 130.609C44.0893 129.386 44.795 129.745 46.1081 131.572C46.5841 132.225 47.0436 132.894 47.5853 133.481C49.6205 135.667 51.0976 135.945 53.9371 134.786C55.0203 134.884 56.0872 134.998 57.2033 134.949C59.5339 134.884 60.929 136.385 61.7169 138.343C62.439 140.17 63.5879 141.068 65.6068 141.084C67.5927 141.1 69.2668 142.096 70.7112 143.368C73.1239 145.473 75.947 146.452 79.1147 146.501C81.6423 146.533 83.8416 147.365 85.8112 148.915C87.6658 150.384 89.6847 151.607 92.1466 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.813 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.908 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.663 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.167 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.879 93.9041C289.447 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.073 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.032 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
const NavbarMenu12 = (props) => {
  // Selected Color
  let selectedColor = shade(props.color, 0.65);
  return (
    <div className="NavbarMenu12">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 27 product list - nav 12">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              y="244"
              width="379"
              height="61"
              fill={props.color}
            />
            <g id="preview elem nav button">
              <g id="Frame 68">
                <path
                  id="themecolor2"
                  d="M25 253H94L104 296H15L25 253Z"
                  fill={selectedColor}
                />
              </g>
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="266"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="266"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="266"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <mask
            id="mask0_76_7555"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="379"
            height="244"
          >
            <rect id="Rectangle 42" width="379" height="244" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7555)">
            <g id="prev comp elem img 4:3">
              <g id="comp elem img sky">
                <rect
                  id="Rectangle 16"
                  x="-17"
                  y="-3"
                  width="409"
                  height="282"
                  rx="8"
                  fill="#EAE8EE"
                />
              </g>
              <g id="comp elem img clouds">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M176.438 77.1566C180.022 79.3442 183.635 81.4719 186.193 84.978C187.189 86.3415 188.631 86.0269 190.044 85.862C197.657 84.9181 204.587 89.533 206.817 97.0098C207.828 100.411 207.382 101.085 203.873 101.1C186.475 101.115 169.077 101.1 151.68 101.13C150.074 101.13 148.468 101.31 146.877 101.4C140.527 101.31 134.193 101.16 127.843 101.19C125.375 101.205 124.602 100.426 125.167 97.8939C126.877 90.2223 134.193 84.8731 141.955 85.8471C143.591 86.0568 144.973 86.2366 146.312 84.7083C154.074 75.7631 163.68 73.3058 174.877 76.782C175.382 76.9169 175.903 77.0217 176.438 77.1566Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_2"
                    d="M92.9586 76.767C68.7504 76.782 44.5572 76.797 20.349 76.8569C17.8509 76.8569 17.4791 75.8081 17.9698 73.6205C19.4568 66.9528 25.2263 62.3378 32.3787 62.6525C34.9661 62.7723 36.7059 62.1131 38.5349 60.1952C47.888 50.3959 62.8025 50.4409 72.2597 60.2401C73.9846 62.0381 75.6203 63.057 78.1333 62.6225C81.1073 62.1131 83.8136 63.5065 86.6389 64.1209C90.0441 67.672 93.5831 71.1631 92.9586 76.767Z"
                    fill="#FBFBFC"
                  />
                  <path
                    id="Vector_3"
                    d="M107.263 18.436C115.338 14.2556 123.471 13.5363 131.59 18.2562C138.148 22.077 141.866 27.8906 143.159 35.3974C143.576 37.8397 142.877 38.7387 140.379 38.7087C130.906 38.6338 121.434 38.6788 111.977 38.6788C103.159 38.6788 94.3266 38.6938 85.5087 38.6638C82.0441 38.6488 81.6277 37.9895 82.4901 34.5433C83.2188 31.6215 84.9288 29.2691 86.5794 26.8567C89.6872 24.4893 92.9437 22.1369 97.0031 22.3767C101.152 22.6314 104.557 21.6275 107.263 18.436Z"
                    fill="#FBFBFC"
                  />
                </g>
              </g>
              <g id="comp elem img bush">
                <path
                  id="Vector_4"
                  d="M53.9043 134.77C51.0648 135.928 49.5876 135.635 47.5524 133.465C47.0108 132.877 46.5348 132.208 46.0752 131.556C44.7622 129.728 44.0729 129.386 42.1854 130.593C37.7374 133.481 33.8147 133.236 30.0561 129.386C28.2179 127.51 25.7231 126.792 23.1463 126.482C20.4381 126.172 17.9433 125.535 16.0887 123.3C14.989 121.995 13.3477 122.142 11.8705 122.435C9.60551 122.892 7.47182 122.664 5.46943 121.571C2.92541 120.168 0.594761 120.51 -1.67024 122.142C-2.09698 122.452 -2.52371 122.729 -2.96687 123.007C-6.26588 125.111 -8.13697 124.736 -10.402 121.505C-12.1417 119.009 -14.4888 118.291 -17 119.287V108.6C-14.177 109.546 -11.239 109.922 -8.26827 109.791C-3.67263 109.579 0.2665 110.786 3.61476 113.984C5.35454 115.648 7.43899 116.187 9.86812 115.518C12.6583 114.751 14.9726 115.501 16.7288 117.9C18.0254 119.646 19.7652 120.755 22.0466 120.657C26.593 120.461 30.4172 122.093 33.6506 125.176C35.1606 126.612 36.884 127.656 39.0669 127.493C43.9251 127.118 47.9627 128.488 50.753 132.665C51.5408 133.758 52.9031 134.019 53.9043 134.77Z"
                  fill="#C0BFCA"
                />
                <path
                  id="Vector_5"
                  d="M92.1466 151.836L92.1302 155.197C90.7351 155.654 89.4549 156.469 87.9613 156.6C82.5778 156.208 77.3585 154.936 72.172 153.598C55.2994 149.242 37.9836 151.836 20.7499 151.836C10.8857 150.938 -0.472076 151.428 -10.3691 151.836C-10.3691 151.836 -14.1934 151.64 -16.9672 151.836V119.303C-14.456 118.308 -12.1089 119.025 -10.3691 121.522C-8.10413 124.752 -6.24946 125.127 -2.93403 123.023C-2.49088 122.745 -2.06414 122.468 -1.6374 122.158C0.627596 120.527 2.95824 120.184 5.50226 121.587C7.50465 122.68 9.63834 122.892 11.9033 122.452C13.3805 122.158 15.0218 122.011 16.1215 123.316C17.9762 125.552 20.4709 126.188 23.1791 126.498C25.7559 126.792 28.2507 127.51 30.089 129.402C33.8475 133.253 37.7703 133.497 42.2182 130.609C44.0893 129.386 44.795 129.745 46.1081 131.572C46.5841 132.225 47.0436 132.894 47.5853 133.481C49.6205 135.667 51.0976 135.945 53.9371 134.786C55.0203 134.884 56.0872 134.998 57.2033 134.949C59.5339 134.884 60.929 136.385 61.7169 138.343C62.439 140.17 63.5879 141.068 65.6068 141.084C67.5927 141.1 69.2668 142.096 70.7112 143.368C73.1239 145.473 75.947 146.452 79.1147 146.501C81.6423 146.533 83.8416 147.365 85.8112 148.915C87.6658 150.384 89.6847 151.607 92.1466 151.836Z"
                  fill="#66646F"
                />
              </g>
              <g id="comp elem img hills">
                <path
                  id="Vector_6"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V143.404C27.3255 149.023 106.737 158.652 190.609 139.256C270.676 120.719 340.529 109.023 392 112.963Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M392 112.963V271.036C392 275.434 387.892 279 382.841 279H-7.84078C-12.9079 279 -17 275.434 -17 271.036V158.097C27.3255 163.716 113.643 200.539 190.609 165.714C272.243 128.753 340.529 109.023 392 112.963Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree">
                <path
                  id="Vector_8"
                  d="M336.687 129.169C336.687 127.246 336.698 125.334 336.698 123.411C336.74 122 336.782 120.578 336.824 119.168L336.74 119.084C339.083 119.742 341.448 119.596 343.813 119.209C343.855 121.53 343.897 123.839 343.939 126.159C343.908 129.598 343.886 133.026 343.823 136.464C343.781 138.794 343.264 140.968 340.35 141C337.437 141.031 336.867 138.878 336.803 136.537C336.74 134.071 336.729 131.625 336.687 129.169Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_9"
                  d="M336.824 119.168C325.899 116.785 318.372 108.685 318.858 98.2656C319.586 82.8818 323.872 68.3759 332.306 55.2495C337.627 57.6428 340.551 62.1785 342.968 67.1113C346.641 74.6151 348.964 82.5579 350.473 90.741C351.202 94.7019 352.173 98.6732 351.423 102.76C349.882 111.152 344.699 116.346 336.729 119.084L336.824 119.168Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_10"
                  d="M336.75 119.073C344.71 116.335 349.893 111.141 351.444 102.749C352.194 98.6732 351.223 94.6913 350.494 90.7304C348.985 82.5578 346.662 74.6046 342.989 67.1008C340.572 62.168 337.658 57.6322 332.327 55.239C337.373 48.3727 342.282 47.9965 347.57 54.173C348.848 55.6675 350.019 57.3083 350.948 59.0431C355.698 67.9055 358.696 77.3846 360.66 87.1875C361.166 89.6958 361.536 92.2354 361.969 94.7645C362.285 101.464 361.029 107.64 356.163 112.719C354.157 114.82 351.708 116.273 349.312 117.819C347.486 118.279 345.66 118.739 343.823 119.199C341.458 119.586 339.094 119.732 336.75 119.073Z"
                  fill="#C0BFCA"
                />
              </g>
              <g id="comp elem img tree_2">
                <path
                  id="Vector_11"
                  d="M269.635 155.608C269.635 152.522 269.652 149.451 269.652 146.365C269.72 144.1 269.788 141.818 269.856 139.553L269.72 139.419C273.495 140.476 277.305 140.241 281.114 139.62C281.182 143.345 281.25 147.052 281.318 150.777C281.267 156.296 281.233 161.799 281.131 167.318C281.063 171.06 280.23 174.549 275.536 174.599C270.842 174.65 269.924 171.194 269.822 167.436C269.72 163.477 269.703 159.551 269.635 155.608Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_12"
                  d="M269.855 139.553C252.253 135.728 240.127 122.726 240.91 106C242.083 81.305 248.988 58.0192 262.576 36.9479C271.148 40.7897 275.859 48.0707 279.753 55.9892C285.672 68.0348 289.413 80.785 291.845 93.921C293.019 100.279 294.584 106.654 293.376 113.214C290.893 126.686 282.543 135.023 269.702 139.419L269.855 139.553Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_13"
                  d="M269.736 139.402C282.56 135.007 290.91 126.669 293.41 113.197C294.618 106.654 293.053 100.262 291.88 93.9041C289.448 80.7849 285.706 68.0179 279.788 55.9724C275.893 48.0539 271.199 40.7729 262.611 36.931C270.74 25.9088 278.648 25.3049 287.169 35.2198C289.226 37.6189 291.114 40.2528 292.611 43.0377C300.264 57.2642 305.094 72.4805 308.257 88.2169C309.074 92.2433 309.669 96.32 310.366 100.38C310.876 111.134 308.852 121.049 301.012 129.202C297.781 132.574 293.835 134.906 289.975 137.389C287.033 138.127 284.09 138.865 281.131 139.603C277.322 140.224 273.512 140.459 269.736 139.402Z"
                  fill="#C0BFCA"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

function hex2(c) {
  c = Math.round(c);
  if (c < 0) c = 0;
  if (c > 255) c = 255;

  var s = c.toString(16);
  if (s.length < 2) s = "0" + s;

  return s;
}

function color(r, g, b) {
  return "#" + hex2(r) + hex2(g) + hex2(b);
}

function shade(col, light) {
  // TODO: Assert that col is good and that -1 < light < 1
  var r = parseInt(col.substr(1, 2), 16);
  var g = parseInt(col.substr(3, 2), 16);
  var b = parseInt(col.substr(5, 2), 16);

  if (light < 0) {
    r = (1 + light) * r;
    g = (1 + light) * g;
    b = (1 + light) * b;
  } else {
    r = (1 - light) * r + light * 255;
    g = (1 - light) * g + light * 255;
    b = (1 - light) * b + light * 255;
  }

  return color(r, g, b);
}

export default NavBar;
