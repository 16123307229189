import React, { useState, useEffect } from "react";

export const DropdownSelect = ({
  label,
  savedValue,
  onChange,
  parentOption,
  availableOptions,
  style,
}) => {
 
  const defaultValue = availableOptions && availableOptions[0] && availableOptions[0].value;
  const [value, setValue] = useState(savedValue || defaultValue);

  useEffect(() => {
    // Only call the onChange when the component is initialized 
    // and the savedValue is not present.
    if (!savedValue && defaultValue) {
      onChange(label, defaultValue, parentOption);
    }
  }, [availableOptions]);

  useEffect(() => {
    setValue(savedValue || defaultValue);
  }, [savedValue]);

 /*
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  */


  const handleChange = (val) => {
    console.log("handleChange", val)
    setValue(val);
    onChange(label, val, parentOption);
  };
  console.log("options", availableOptions)
  return (
    
    <select
      style={style || null}
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      className="custom-dropdown"
    >
      {availableOptions &&
        availableOptions.map((opt, i) => (
          <option key={i} value={opt.value}>
            {opt.label}
          </option>
        ))}
    </select>
  );
};
