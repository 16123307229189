import React from "react";

const HomeIcon = ({ active }) => {
  return (
    <svg
      width="83"
      height="42"
      viewBox="0 0 83 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1935 41.7324C24.1037 41.7324 22.4097 40.0384 22.4097 37.9485V34.3986C22.4097 32.3087 24.1037 30.6147 26.1935 30.6147H38.1448C40.2347 30.6147 41.9287 32.3087 41.9287 34.3986V37.9485C41.9287 40.0384 40.2347 41.7324 38.1448 41.7324H26.1935Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78456 41.7076C1.69468 41.7076 0 40.0136 0 37.9231V16.297C0 14.2065 1.69468 12.5125 3.78456 12.5125H15.7351C17.825 12.5125 19.5197 14.2065 19.5197 16.297V37.9231C19.5197 40.0136 17.825 41.7076 15.7351 41.7076H3.78456Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1935 27.5602C24.1037 27.5602 22.4097 25.8662 22.4097 23.7763V3.78458C22.4097 1.69468 24.1037 0 26.1935 0H38.1448C40.2347 0 41.9287 1.69467 41.9287 3.78458V23.7763C41.9287 25.8662 40.2347 27.5602 38.1448 27.5602H26.1935Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78505 9.73173C1.69517 9.73173 0.000488281 8.03775 0.000488281 5.94785V3.78458C0.000488281 1.69468 1.69517 0 3.78505 0H15.7356C17.8255 0 19.5202 1.69467 19.5202 3.78458V5.94785C19.5202 8.03775 17.8255 9.73173 15.7356 9.73173H3.78505Z"
        fill={active ? "#0C92EF" : "#6D7A8B"}
      />

      {active && <path d="M83 42V0L62 20.2422L83 42Z" fill="#6D7A8B" />}
    </svg>
  );
};
export default HomeIcon;
