import React from "react";

const BracketSection = () => {
  return (
    <svg width="22" height="87%" viewBox="0 0 25 843" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '0px' }} >
<path d="M7 823.165V19.8353C7 8.8818 15.06 0 25 0H18C8.06 0 0 8.8818 0 19.8353V823.165C0 834.118 8.06 843 18 843H25C15.06 843 7 834.118 7 823.165Z" fill="#606D7D"/>
</svg>

  );
};
export default BracketSection;
