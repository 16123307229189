import React from "react";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import AppBar from "../components/appbar/appbar";
import BracketSection from "../components/appbar/BracketSection";
import AddToCart from "../components/home/product-details-page/AddToCart";

export const AddToCartPage = ({ storeKey }) => {
  return (
   <div className="market-layout">
        <Container fluid className="layout-container">
          <Row className="main-row no-gutters">
            <div style={{display: "flex",    width: "100%" }}>

            <AppBar activeOption="details" />
            <BracketSection />

            <AddToCart storeKey={storeKey}/>
            </div>
          </Row>
        </Container>
    </div>
  );
};
