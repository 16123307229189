/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "reactstrap";
import { ModalSave } from "../modals/modalSave";
import { ModalPreview } from "../modals/modalPreview";
import { ModalConfirmRemove } from ".";
import { configuration } from "./configuration";

import LoadingImage from "../img/FengLoaderPNGFull.png";
import "../../Styles/modules/_modalVersionSelector.scss";

import {
  fetchThemes,
  deleteTheme,
  renameTheme,
  duplicateTheme,
  addNewTheme,
} from "../../api";

export const ModalTemplate = ({
  isOpen,
  onClose,
  onSelectTemplate,
  currentTemplateID,
  userType,
  storeKey,
}) => {
  const options = { year: "numeric", month: "long", day: "numeric" };

  const [tableData, setTableData] = useState(null); // Table data
  const [isShowingSaveAs, setIsShowingSaveAs] = useState(false); // Saving Modal
  const [isShowingRename, setIsShowingRename] = useState(false); // Rename Modal
  const [isShowingDelete, setIsShowingDelete] = useState(false); // Delete Modal
  const [isShowingPreview, setIsShowingPreview] = useState(false); // Preview Modal
  const [isShowingCreateTheme, setIsShowingCreateTheme] = useState(false); // Create Theme Modal
  const [selectedKey, setSelectedKey] = useState(null);
  const [dataUpdateCount, setDataUpdateCount] = useState(0);

  // Template data
  const [templateData, setTemplateData] = useState({
    templateID: null,
    name: null,
  });

  const shouldDisableButton = () => {
    // Subtract 2 from themeCount because there are always 2 extra entries
    const themeCount = tableData ? Object.keys(tableData).length - 2 : 0;
    console.log("themeCount", themeCount);

    // Mapping the new user types to the corresponding groups
    const userTypeMapping = {
      monthly1: "paid1",
      yearly1: "paid1",
      monthly2: "paid2",
      yearly2: "paid2",
    };

    // Determine the group for the current user type
    const userGroup = userTypeMapping[userType] || userType;

    switch (userGroup) {
      case "paid1":
        // For 'paid1' users, check if themeCount exceeds 2
        return themeCount > 1;
      case "paid2":
        // For 'paid2' users, check if themeCount exceeds 5
        return themeCount > 4;
      default:
        // For 'trial', 'monthly3', 'yearly3' and other user types, no restriction
        return false;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [dataUpdateCount]);

  const fetchData = async () => {
    setTableData(null);

    // API
    fetchThemes(storeKey)
      .then((response) => {
        const data = response.data;
        if (data) {
          setTimeout(() => {
            console.log(data);
            setTableData(data); // Update table data
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Error fetching navBar data:", error);
      });
  };

  const doDeleteTheme = () => {
    // API
    deleteTheme(storeKey, selectedKey)
      .then((response) => {
        setSelectedKey(null); // Reset the selectedKey state
        setIsShowingDelete(false);
        setDataUpdateCount((prevCount) => prevCount + 1);
      })
      .catch((error) => {
        console.error("Error delete Theme:", error);
      });
  };

  const doRenameTheme = (newName) => {
    if (newName.length > 3) {
      // API
      renameTheme(storeKey, selectedKey, newName)
        .then((response) => {
          setSelectedKey(null); // Reset the selectedKey state
          setIsShowingRename(false); // Close the modal
          setDataUpdateCount((prevCount) => prevCount + 1);
        })
        .catch((error) => {
          console.error("Error renameTheme:", error);
        });
    } else {
      console.error("Name length < 3");
    }
  };

  const saveAsTheme = (newName) => {
    if (newName.length > 3) {
      // API
      duplicateTheme(storeKey, selectedKey, newName)
        .then((response) => {
          setSelectedKey(null); // Reset the selectedKey state
          setIsShowingSaveAs(false); // Close the modal
          setDataUpdateCount((prevCount) => prevCount + 1);
        })
        .catch((error) => {
          console.error("Error renameTheme:", error);
        });
    } else {
      console.error("Name lenght < 3");
    }
  };

  const createTheme = (name) => {
    if (name.length > 3) {
      // API
      addNewTheme(storeKey, name, configuration)
        .then((response) => {
          setSelectedKey(null); // Reset the selectedKey state
          setIsShowingCreateTheme(false); // Close the modal
          setDataUpdateCount((prevCount) => prevCount + 1);
        })
        .catch((error) => {
          console.error("Error renameTheme:", error);
        });
    } else {
      console.error("Name length < 3");
    }
  };

  const handleCreateEmptyTheme = (key) => {
    setIsShowingCreateTheme(true);
  };

  const handleDelete = (key) => {
    setSelectedKey(key);
    setIsShowingDelete(true);
  };

  const handleSaveAs = (key) => {
    setSelectedKey(key);
    setIsShowingSaveAs(true);
  };

  const handleRename = (key) => {
    setSelectedKey(key);
    setIsShowingRename(true);
  };

  const handlePreview = (templateID, name) => {
    setTemplateData({ templateID: templateID, name: name });
    setIsShowingPreview(true);
  };

  const handleSelectTemplate = (templateID, name) => {
    onSelectTemplate(templateID, name);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      className="transitionSelector preview modalVersion isOpen"
      size="xl"
    >
      {isShowingDelete && (
        <ModalConfirmRemove
          isOpen={isShowingDelete}
          onConfirm={doDeleteTheme}
          onCancel={() => {
            setIsShowingDelete(false);
            setSelectedKey(null); // Reset the selectedKey state
          }}
        />
      )}
      {isShowingSaveAs && (
        <ModalSave
          isOpen={isShowingSaveAs}
          onConfirm={saveAsTheme}
          onClose={() => {
            setIsShowingSaveAs(false);
            setSelectedKey(null); // Reset the selectedKey state
          }}
        />
      )}
      {isShowingRename && (
        <ModalSave
          isOpen={isShowingRename}
          onConfirm={doRenameTheme}
          onClose={() => {
            setIsShowingRename(false);
            setSelectedKey(null); // Reset the selectedKey state
          }}
        />
      )}
      {isShowingCreateTheme && (
        <ModalSave
          isOpen={isShowingCreateTheme}
          onConfirm={createTheme}
          onClose={() => {
            setIsShowingCreateTheme(false);
          }}
        />
      )}
      {isShowingPreview && (
        <ModalPreview
          isOpen={isShowingPreview}
          onClose={() => setIsShowingPreview(false)}
          templateID={templateData.templateID}
          templateName={templateData.name}
          storeKey={storeKey}
        />
      )}
      <section>
        <div className="modal__container">
          <div className="row" style={{ marginBottom: "-40px" }}>
            <div className="modal__title primary">Themes</div>
            <div className="modal__buttons__themes">
              <button
                type="button"
                className="close__button custom-button-theme addtheme-button"
                onClick={() => handleCreateEmptyTheme()}
                disabled={shouldDisableButton()}
                style={{ opacity: shouldDisableButton() ? 0.5 : 1 }}
              >
                Add New Theme
              </button>
              <button
                type="button"
                className="close__button custom-button-theme cancel-button"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
          <div className="body__modal row">
            <thead className="table__head">
              <tr>
                <th className="head__th" style={{ width: "440px" }}>
                  Name
                </th>
                <th className="head__th__update">Created</th>
              </tr>
            </thead>

            <div className="body__background">
              <table
                className="table row"
                style={{ overflowY: "scroll", height: "520px" }}
              >
                <tbody className="table__body ">
                  {tableData ? (
                    Object.entries(tableData).map(([key, value]) => {
                      if (key !== "selectedConfig" && key !== "currentConfig") {
                        return (
                          <tr className="table__body--tr title__container">
                            <td className="text-uppercase item__title theme__container">
                              {value.name}
                            </td>
                            {(key === tableData.selectedConfig ||
                              currentTemplateID == key) && (
                              <td className="text-uppercase item__title span__container">
                                {key === tableData.selectedConfig && (
                                  <span>PUBLIC</span>
                                )}
                                {currentTemplateID == key && (
                                  <div className="container_span">
                                    <span className="centered_span">
                                      SELECTED THEME
                                    </span>
                                  </div>
                                )}
                              </td>
                            )}

                            <td className="day__container">
                              {" "}
                             {new Date(value.nameDate).toLocaleDateString("en-US")}
                            </td>
                            <td className="buttons__container preview">
                              <button
                                type="button"
                                className="buttonComponent edit"
                                onClick={() =>
                                  handleSelectTemplate(key, value.name)
                                }
                              >
                                edit
                              </button>

                              <button
                                type="button"
                                className=" buttonComponent preview"
                                onClick={() => handleRename(key)}
                              >
                                Rename
                              </button>

                              <button
                                type="button"
                                className=" buttonComponent duplicate"
                                onClick={() => handleSaveAs(key)}
                                disabled={shouldDisableButton()}
                                style={{
                                  opacity: shouldDisableButton() ? 0.5 : 1,
                                }}
                              >
                                Duplicate
                              </button>
                              <button
                                type="button"
                                className=" buttonComponent rename"
                                onClick={() => handlePreview(key, value.name)}
                              >
                                Preview
                              </button>
                              <button
                                type="button"
                                className=" buttonComponent delete"
                                style={{
                                  opacity:
                                    currentTemplateID !== key &&
                                    key !== tableData.selectedConfig
                                      ? 1
                                      : 0.5,
                                  pointerEvents:
                                    currentTemplateID !== key &&
                                    key !== tableData.selectedConfig
                                      ? "auto"
                                      : "none",
                                }}
                                onClick={() => {
                                  if (
                                    currentTemplateID !== key &&
                                    key !== tableData.selectedConfig
                                  )
                                    handleDelete(key);
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <div className="loading-content-theme ">
                      <div className="loading-container-theme">
                        <img src={LoadingImage} />
                      </div>
                      <div className="loading-text-black">
                        Loading ...
                      </div>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};
