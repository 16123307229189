import React from "react";
import classnames from "classnames";
import { CardComponentLayout } from "../card-layout";
import { Draggable } from "react-beautiful-dnd";

export const ConfigurableDragableComponent = ({
  title,
  subtitle,
  idx,
  text,
  image,
  mockSize,
  isSelected,
  onSelect,
  uuid,
}) => {
  const actualCardProps = {
    title,
    subtitle,
    text,
    image,
    mockSize,
    blockSelected: true,
  };
  return (
    <Draggable draggableId={`configurable_${uuid}`} index={idx}>
      {(provided, snapshot) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={classnames("wysiwyg-item", {
              dragging: snapshot.isDragging, // dragging styles we are not using this
              "wysiwyg-selected": isSelected,
            })}
            onClick={() => onSelect({ uuid })}
          >
            <div className={classnames("configurable-card")}>
              <CardComponentLayout {...actualCardProps} />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};
