const IndividualContentButtonsComponents = [
  "Botones",
  "News No Image",
  "News Top Image",
  "Image 100",
  "Image 75",
  "Image 50",
  "Image 25",
  "Video"
];

const productOnItemOnly = [
  "Producto Destacado",
  "Botones_0",
  "News Top Image_0",
  "News No Image_0",
  "Image 100_0",
  "Image 75_0",
  "Image 50_0",
  "Image 25_0",
  "Video_0"
];

export {
  IndividualContentButtonsComponents,
  productOnItemOnly,
};
