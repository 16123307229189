import React from "react";

const ColorV2 = (props) => {
  // Selected Color
  //let selectedColor = shade(props.color, 0.65);

  return (
    <div className="Color">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 78">
          <rect width="379" height="664" fill="white" />
          <g id="preview elem nav completa">
            <rect
              id="themecolor1"
              width="379"
              height="61"
              fill={props.color1}
            />
            <g id="preview elem nav button">
              <path
                id="themecolor2"
                d="M27 9H92L104 30.5L92 52H27L15 30.5L27 9Z"
                fill={props.color2}
              />
            </g>
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="29"
                y="22"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 43">
              <rect
                id="Rectangle 35_2"
                x="116"
                y="22"
                width="61"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 44">
              <rect
                id="Rectangle 35_3"
                x="202"
                y="22"
                width="60"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <g id="Rectangle 45">
              <rect
                id="Rectangle 35_4"
                x="287"
                y="22"
                width="62"
                height="17"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
          </g>
          <g id="comp elem card theme 5" filter="url(#filter0_d_2414_19086)">
            <g id="comp elem minicard bg">
              <rect
                id="themecolor1_2"
                x="45"
                y="94"
                width="293"
                height="534"
                rx="10"
                fill={props.color1}
              />
            </g>
            <path
              id="Rectangle 6"
              d="M45 267H338V618C338 623.523 333.523 628 328 628H55C49.4771 628 45 623.523 45 618V267Z"
              fill="#FBFBFC"
            />
            <g id="themecolor2_2">
              <rect
                x="71"
                y="565"
                width="242"
                height="39"
                rx="5"
                fill={props.color2}
              />
            </g>
            <path
              id="Rectangle 3"
              d="M45 157H285C289.418 157 293 160.582 293 165V534C293 538.418 289.418 542 285 542H45V157Z"
              fill="#EAE8EE"
            />
            <g id="themecolor2_3">
              <rect
                x="258"
                y="114"
                width="55"
                height="20"
                rx="5"
                fill={props.color2}
              />
            </g>
            <g id="comp elem rect 3">
              <rect
                x="71"
                y="114"
                width="113"
                height="20"
                rx="5"
                fill="#EAE8EE"
              />
            </g>
            <circle
              id="Ellipse 1"
              cx="284.5"
              cy="211.5"
              r="30.5"
              fill="#FBFBFC"
            />
            <g id="comp elem heart">
              <g id="Group">
                <g id="Group_2">
                  <path
                    id="Vector"
                    d="M291.693 198C289.8 198 288.05 198.587 286.523 199.73C285.059 200.842 284.073 202.248 283.5 203.268C282.927 202.248 281.941 200.842 280.477 199.73C278.934 198.587 277.2 198 275.291 198C269.993 198 266 202.202 266 207.794C266 213.819 270.98 217.943 278.536 224.2C279.825 225.266 281.273 226.455 282.784 227.737C282.991 227.907 283.245 228 283.5 228C283.755 228 284.025 227.907 284.216 227.737C285.727 226.455 287.175 225.25 288.464 224.184C296.005 217.943 301 213.819 301 207.794C301 202.202 296.991 198 291.693 198Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
            <g id="Comp icon product" clip-path="url(#clip0_2414_19086)">
              <g id="Comp icon - Product">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M251.897 291.972C251.182 290.751 250.141 289.714 248.852 288.981L176.672 255.098C174.093 253.634 170.923 253.634 168.344 255.098L96.1637 288.981C94.8743 289.714 93.8333 290.751 93.1187 291.972L172.508 336.976L251.897 291.972Z"
                    fill="#DCDAE2"
                  />
                  <path
                    id="Vector_3"
                    d="M172.508 427C173.953 427 175.382 426.634 176.671 425.902L243.119 384.986C245.698 383.521 247.283 380.821 247.283 377.892L253.016 296.06C253.016 294.596 252.612 293.192 251.897 291.972L172.508 336.976V427V427Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_4"
                    d="M168.344 425.901C169.634 426.634 171.078 427 172.508 427V336.991L93.1186 291.987C92.4039 293.192 92 294.611 92 296.06L97.3755 377.892C97.3755 380.821 98.9601 383.521 101.539 384.986L168.344 425.901Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M208.909 270.11L137.21 312.079L172.508 336.976L93.1187 291.971L133.823 309.638L206.081 268.92L208.909 270.11Z"
                    fill="#66646F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2414_19086"
            x="26"
            y="75"
            width="331"
            height="572"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2414_19086"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2414_19086"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2414_19086">
            <rect
              width="161"
              height="173"
              fill="white"
              transform="translate(92 254)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

function hex2(c) {
  c = Math.round(c);
  if (c < 0) c = 0;
  if (c > 255) c = 255;

  var s = c.toString(16);
  if (s.length < 2) s = "0" + s;

  return s;
}

function color(r, g, b) {
  return "#" + hex2(r) + hex2(g) + hex2(b);
}

function shade(col, light) {
  // TODO: Assert that col is good and that -1 < light < 1
  var r = parseInt(col.substr(1, 2), 16);
  var g = parseInt(col.substr(3, 2), 16);
  var b = parseInt(col.substr(5, 2), 16);

  if (light < 0) {
    r = (1 + light) * r;
    g = (1 + light) * g;
    b = (1 + light) * b;
  } else {
    r = (1 - light) * r + light * 255;
    g = (1 - light) * g + light * 255;
    b = (1 - light) * b + light * 255;
  }

  return color(r, g, b);
}

export default ColorV2;
