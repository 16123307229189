import React, { useEffect, useState } from "react";

export const ColorPicker = ({ label, savedValue, onChange, parentOption }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  const handleChange = (val) => {
    setValue(val);
    onChange(label, val, parentOption);
  };
  return (
    <div className="custom-input-color">
      <i className="fa fa-sort-down icon" />
      <input
        type="color"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};
