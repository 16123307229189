import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { MarketDraggableItem } from "./market-draggable-item";

export const MarketColumn = ({ availableComponents, droppableId }) => {
  return (
    <div>
      {availableComponents.map((componentItem, idx) => {
        console.log("Key for item: ", typeof componentItem === "string" ? `label-${idx}` : componentItem.componentId); // For debugging
        if (typeof componentItem === "string") {
          return <div key={`label-${idx}`} className="component-label">{componentItem}</div>;
        } else if (componentItem.isDraggable) {
          return (
            <Droppable key={componentItem.componentId} droppableId={droppableId} isDropDisabled={true}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <MarketDraggableItem
                    isDraggable={componentItem.isDraggable}
                    idx={idx}
                    key={componentItem.componentId}
                    isLeftPanel={true}
                    {...componentItem}
                  />
                </div>
              )}
            </Droppable>
          );
        } else {
          return (
            <MarketDraggableItem
              isDraggable={componentItem.isDraggable}
              key={componentItem.componentId}
              idx={idx}
              isLeftPanel={true}
              {...componentItem}
            />
          );
        }
      })}
    </div>
  );
};
