import React, { useEffect, useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { flushCache } from './api';

const StoreKeyContext = createContext();

const StoreKeyContextProvider = ({ children }) => {
  const location = useLocation();
  const [storeKey, setStoreKey] = useState('default-store-key');
  const [userType, setUserType] = useState('trial');
  const [cacheFlushed, setCacheFlushed] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userTypeParam = query.get('userType');
    const storeKeyParam = query.get('storeKey'); // Get storeKey from query parameters
    const chargeIdParam = query.get('chargeID'); // Get charge_id from query parameters

    // Log the charge_id parameter
    console.log('chargeID:', chargeIdParam);

    // Set userType if it's available
    if (userTypeParam) {
      setUserType(userTypeParam);
    }

    // Set storeKey if it's available, otherwise fall back to regex-based extraction
    if (storeKeyParam) {
      setStoreKey(storeKeyParam);
    } else {
      const uuidPathRegex = /^\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      const isUuidPath = uuidPathRegex.test(location.pathname);
      if (isUuidPath) {
        setStoreKey(location.pathname.substring(1));
      }
    }

    const checkCache = async () => {
      if (!cacheFlushed && storeKey && storeKey !== 'default-store-key') {
        console.log('Performing cache flush');
        await flushCache(storeKey);
        setCacheFlushed(true); // Mark cache as flushed immediately
      }
    };

    checkCache();
  }, [location, storeKey, cacheFlushed]);

  return (
    <StoreKeyContext.Provider value={{ storeKey, userType, cacheFlushed }}>
      {children}
    </StoreKeyContext.Provider>
  );
};

export { StoreKeyContext, StoreKeyContextProvider };
