import React, { useState, useEffect } from "react";
import NotificationCard from "../../Modules/NotificationCard";
import ModalNotification from "../modals/ModalNotification";
import LoadingImage from "../img/FengLoaderWhitePNG.png";
import useModal from "../../hooks/useModal";

import { fetchNotifications, sendNotificationMessage } from "../../api";

function Notifications({ storeKey, userType }) {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSendingPush, setIsSendingPush] = useState(false); // Saving state
  const [canSendNotification, setCanSendNotification] = useState(false); // Enabled to send push notification
  const [isOpenModal, openModal, closeModal] = useModal();
  const [notifications, setNotifications] = useState([]);

  
  const checkNotificationLimit = (notifications, userLimit) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
  
    console.log(`Current Month: ${currentMonth}, Current Year: ${currentYear}`);
  
    const monthlyNotifications = notifications.filter((notification) => {
      const timestamp = new Date(notification.timeStamp);
      console.log(`Notification Timestamp: ${timestamp}`);
  
      const isSameMonth = timestamp.getMonth() === currentMonth;
      const isSameYear = timestamp.getFullYear() === currentYear;
  
      console.log(`Is Same Month: ${isSameMonth}, Is Same Year: ${isSameYear}`);
  
      return isSameMonth && isSameYear;
    });
  
    console.log(`Filtered Monthly Notifications: ${monthlyNotifications.length}`);
    return monthlyNotifications.length < userLimit;
  };
  

  function getUserNotificationLimit(userType) {
    switch (userType) {
      case "trial":
        return Infinity; // Trial users have no notifications
      case "monthly1":
      case "yearly1":
        return 11; // Paid1 users can send up to 10 notifications per month
      case "monthly2":
      case "yearly2":
        return 31; // Paid2 users can send up to 30 notifications per month
      case "monthly3":
      case "yearly3":
        return Infinity; // Paid3 users can send unlimited notifications
      default:
        return 0; // Default case for undefined user types
    }
  }
  

  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    // API
    fetchNotifications(storeKey)
      .then(({ data }) => {
        if (data) {
          const userLimit = getUserNotificationLimit(userType);
          const canSendNotification = checkNotificationLimit(data, userLimit); // Use 'data' directly here

          setNotifications(data);
          setCanSendNotification(canSendNotification);

          console.log("userLimit", userLimit);
          console.log("canSendNotification", canSendNotification);

        } else {
          console.error("Error fetching:");
        }
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const handleModalDialogClick = (e) => {
    e.stopPropagation();
  };

  const handleAddNewNotification = async (newNotification) => {
    console.log("handleAddNewNotification DATA", newNotification);

    setNotifications([]);
    setIsSendingPush(true);

    // API
    sendNotificationMessage({
      title: newNotification.titleCard,
      body: newNotification.paragraphCard,
      image: newNotification.image,
      action: newNotification.action,
      productId: newNotification.productId,
      collectionId: newNotification.collectionId,
      storeKey: storeKey,
    })
      .then(({ data }) => {
        if (data) {
          setIsSendingPush(false);
          fetchData();
        } else {
          console.error("Error fetching:");
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setIsSendingPush(false);
        setIsLoading(false);
      });
  };

  return (
    <main className="main">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Loading...</div>
        </div>
      )}
      {isSendingPush && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Sending Push Notification...</div>
        </div>
      )}
      <div className="row">
        <section className="createNotification">
          <button className="buttonComponent edit" onClick={openModal} disabled={!canSendNotification} style={{ opacity: !canSendNotification ? 0.5 : 1 }} >
            create a new notification
          </button>
          <ModalNotification
            handle={handleModalDialogClick}
            close={closeModal}
            isOpen={isOpenModal}
            showSaveButton={false}
            onSendNow={handleAddNewNotification}
            storeKey={storeKey}
          />
        </section>
      </div>
      {notifications.length > 0 && (
        <div className="row">
          <section
            className="previously"
            style={{ overflowY: "scroll", height: "530px", marginLeft: "6px" }}
          >
            <h2 className="title">Previously sent notifications:</h2>
            {notifications.map((item, index) => (
              <NotificationCard
                key={item.titleCard + index} // Not ideal, but better if there are no unique identifiers
                paragraphCard={item.paragraphCard}
                titleCard={item.titleCard}
                messageAlert={item.messageAlert || false}
                image={item.image}
              />
            ))}
          </section>
        </div>
      )}
    </main>
  );
}

export default Notifications;
