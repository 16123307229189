/*
CIRCLE = THEME 1
SQUARES = THEME 2
FULL BACKGROUND = THEME 3
BLACK & WHITE = THEME 4
FULL BACKGROUND NO COLOR = THEME 5
*/

import React from "react";

const QuantityStepper = (props) => {
  switch (props.theme) {
    case "theme1":
      return <QuantityStepperTheme1 color={props.color} />;
    case "theme2":
      return <QuantityStepperTheme2 color={props.color} />;
    case "theme3":
      return <QuantityStepperTheme3 color={props.color} />;
    case "theme4":
      return <QuantityStepperTheme4 color={props.color} />;
    case "theme5":
      return <QuantityStepperTheme5 color={props.color} />;
    default:
      return <QuantityStepperTheme1 color={props.color} />;
  }
};

const QuantityStepperTheme1 = (props) => {
  return (
    <div className="QuantityStepperTheme1">
     <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
<g id="preview 67 checkout stepper v1">
<rect width="379" height="664" fill="white"/>
<g id="Comp icon product" clip-path="url(#clip0_137_17659)">
<g id="Comp icon - Product">
<g id="Group">
<path id="Vector" d="M123.335 218.046C122.905 217.306 122.278 216.676 121.501 216.231L78.0131 195.667C76.4593 194.778 74.5498 194.778 72.996 195.667L29.5084 216.231C28.7315 216.676 28.1044 217.306 27.6738 218.046L75.5046 245.361L123.335 218.046Z" fill="#DCDAE2"/>
<path id="Vector_2" d="M75.5049 300C76.3754 300 77.2365 299.778 78.0134 299.333L118.047 274.5C119.601 273.611 120.556 271.972 120.556 270.195L124.01 220.528C124.01 219.639 123.766 218.787 123.336 218.046L75.5049 245.361V300Z" fill="#9694A0"/>
<path id="Vector_3" d="M72.9961 299.333C73.773 299.778 74.6435 300 75.5047 300V245.37L27.6739 218.056C27.2434 218.787 27 219.648 27 220.528L30.2386 270.195C30.2386 271.972 31.1934 273.611 32.7472 274.5L72.9961 299.333Z" fill="#66646F"/>
<path id="Vector_4" d="M97.4356 204.778L54.2381 230.25L75.5046 245.361L27.6738 218.046L52.1976 228.769L95.732 204.056L97.4356 204.778Z" fill="#66646F"/>
</g>
</g>
</g>
<g id="Comp icon product_2" clip-path="url(#clip1_137_17659)">
<g id="Comp icon - Product_2">
<g id="Group_2">
<path id="Vector_5" d="M123.335 391.046C122.905 390.306 122.278 389.676 121.501 389.231L78.0131 368.667C76.4593 367.778 74.5498 367.778 72.996 368.667L29.5084 389.231C28.7315 389.676 28.1044 390.306 27.6738 391.046L75.5046 418.361L123.335 391.046Z" fill="#DCDAE2"/>
<path id="Vector_6" d="M75.5049 473C76.3754 473 77.2365 472.778 78.0134 472.333L118.047 447.5C119.601 446.611 120.556 444.972 120.556 443.195L124.01 393.528C124.01 392.639 123.766 391.787 123.336 391.046L75.5049 418.361V473Z" fill="#9694A0"/>
<path id="Vector_7" d="M72.9961 472.333C73.773 472.778 74.6435 473 75.5047 473V418.37L27.6739 391.056C27.2434 391.787 27 392.648 27 393.528L30.2386 443.195C30.2386 444.972 31.1934 446.611 32.7472 447.5L72.9961 472.333Z" fill="#66646F"/>
<path id="Vector_8" d="M97.4356 377.778L54.2381 403.25L75.5046 418.361L27.6738 391.046L52.1976 401.769L95.732 377.056L97.4356 377.778Z" fill="#66646F"/>
</g>
</g>
</g>
<g id="comp elem rect 9">
<rect x="140" y="236" width="45" height="23" rx="5" fill="#DCDAE2"/>
</g>
<g id="comp elem rect 11">
<rect x="140" y="409" width="45" height="23" rx="5" fill="#DCDAE2"/>
</g>
<g id="comp elem rect 10">
<rect x="140" y="206" width="111" height="23" rx="5" fill="#DCDAE2"/>
</g>
<g id="comp elem rect 12">
<rect x="140" y="379" width="111" height="23" rx="5" fill="#DCDAE2"/>
</g>
<path id="Vector_9" d="M379 330H0V333H379V330Z" fill="#DCDAE2"/>
<circle id="themecolor1" cx="296.5" cy="267.5" r="30.5" fill={props.color}/>
<circle id="Ellipse 3" cx="296.5" cy="440.5" r="30.5" fill="#EAE8EE"/>
<path id="+" d="M310.406 270.47H299.348V282.212H293.619V270.47H282.618V265.254H293.619V253.569H299.348V265.254H310.406V270.47Z" fill="#FBFBFC"/>
<path id="4" d="M297.941 442.917V434.167C297.941 433.341 297.996 432.428 298.107 431.429L289.801 442.917H297.941ZM305.285 442.917V445.563C305.285 445.81 305.205 446.025 305.045 446.21C304.884 446.383 304.656 446.469 304.36 446.469H301.918V453H297.941V446.469H286.656C286.347 446.469 286.076 446.377 285.842 446.192C285.607 445.995 285.459 445.754 285.398 445.47L284.917 443.158L297.589 426.23H301.918V442.917H305.285Z" fill="#66646F"/>
<circle id="themecolor1_2" cx="334" cy="440" r="18" fill={props.color}/>
<path id="+_2" d="M343.039 441.85H336.249V449.06H332.731V441.85H325.976V438.647H332.731V431.472H336.249V438.647H343.039V441.85Z" fill="#FBFBFC"/>
<circle id="Ellipse 5" cx="260" cy="440" r="17.5" fill="#FBFBFC" stroke="#9694A0"/>
<path id="-" d="M254.967 438.627H264.032V442.232H254.967V438.627Z" fill="#66646F"/>
</g>
<defs>
<clipPath id="clip0_137_17659">
<rect width="97" height="105" fill="white" transform="translate(27 195)"/>
</clipPath>
<clipPath id="clip1_137_17659">
<rect width="97" height="105" fill="white" transform="translate(27 368)"/>
</clipPath>
</defs>
</svg>
    </div>
  );
};

const QuantityStepperTheme2 = (props) => {
  return (
    <div className="QuantityStepperTheme2">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 68 checkout stepper v2">
          <rect width="379" height="664" fill="white" />
          <rect
            id="Rectangle 64"
            x="235"
            y="422"
            width="36"
            height="36"
            fill="#9694A0"
          />
          <rect
            id="themecolor1"
            x="318"
            y="422"
            width="36"
            height="36"
            fill={props.color}
          />
          <rect
            id="themecolor1_2"
            x="266"
            y="237"
            width="61"
            height="61"
            fill={props.color}
          />
          <g id="Comp icon product" clipPath="url(#clip0_76_7596)">
            <g id="Comp icon - Product">
              <g id="Group">
                <path
                  id="Vector"
                  d="M123.335 218.046C122.905 217.306 122.278 216.676 121.501 216.231L78.0131 195.667C76.4593 194.778 74.5498 194.778 72.996 195.667L29.5084 216.231C28.7315 216.676 28.1044 217.306 27.6738 218.046L75.5046 245.361L123.335 218.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M75.5046 300C76.3751 300 77.2363 299.778 78.0132 299.333L118.047 274.5C119.601 273.611 120.555 271.972 120.555 270.195L124.009 220.528C124.009 219.639 123.766 218.787 123.335 218.046L75.5046 245.361V300Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M72.9961 299.333C73.773 299.778 74.6435 300 75.5047 300V245.37L27.6739 218.056C27.2434 218.787 27 219.648 27 220.528L30.2386 270.195C30.2386 271.972 31.1934 273.611 32.7472 274.5L72.9961 299.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M97.4356 204.778L54.2381 230.25L75.5046 245.361L27.6738 218.046L52.1976 228.769L95.732 204.056L97.4356 204.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_2" clipPath="url(#clip1_76_7596)">
            <g id="Comp icon - Product_2">
              <g id="Group_2">
                <path
                  id="Vector_5"
                  d="M123.335 391.046C122.905 390.306 122.278 389.676 121.501 389.231L78.0131 368.667C76.4593 367.778 74.5498 367.778 72.996 368.667L29.5084 389.231C28.7315 389.676 28.1044 390.306 27.6738 391.046L75.5046 418.361L123.335 391.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_6"
                  d="M75.5046 473C76.3751 473 77.2363 472.778 78.0132 472.333L118.047 447.5C119.601 446.611 120.555 444.972 120.555 443.195L124.009 393.528C124.009 392.639 123.766 391.787 123.335 391.046L75.5046 418.361V473Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M72.9961 472.333C73.773 472.778 74.6435 473 75.5047 473V418.37L27.6739 391.056C27.2434 391.787 27 392.648 27 393.528L30.2386 443.195C30.2386 444.972 31.1934 446.611 32.7472 447.5L72.9961 472.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_8"
                  d="M97.4356 377.778L54.2381 403.25L75.5046 418.361L27.6738 391.046L52.1976 401.769L95.732 377.056L97.4356 377.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="comp elem rect 9">
            <path
              d="M140 241C140 238.239 142.239 236 145 236H180C182.761 236 185 238.239 185 241V254C185 256.761 182.761 259 180 259H145C142.239 259 140 256.761 140 254V241Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 11">
            <path
              d="M140 414C140 411.239 142.239 409 145 409H180C182.761 409 185 411.239 185 414V427C185 429.761 182.761 432 180 432H145C142.239 432 140 429.761 140 427V414Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 10">
            <path
              d="M140 211C140 208.239 142.239 206 145 206H246C248.761 206 251 208.239 251 211V224C251 226.761 248.761 229 246 229H145C142.239 229 140 226.761 140 224V211Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 12">
            <path
              d="M140 384C140 381.239 142.239 379 145 379H246C248.761 379 251 381.239 251 384V397C251 399.761 248.761 402 246 402H145C142.239 402 140 399.761 140 397V384Z"
              fill="#DCDAE2"
            />
          </g>
          <path id="Vector_9" d="M379 330H0V333H379V330Z" fill="#DCDAE2" />
          <path
            id="+"
            d="M299.291 253.569V265.254H310.377V270.47H299.291V282.212H293.591V270.47H282.561V265.254H293.591V253.569H299.291Z"
            fill="#FBFBFC"
          />
          <path
            id="4"
            d="M300.196 442.917H303.563V445.563C303.563 445.81 303.482 446.025 303.322 446.21C303.162 446.383 302.927 446.469 302.619 446.469H300.196V453H296.2V446.469H284.915C284.606 446.469 284.335 446.377 284.101 446.192C283.866 445.995 283.718 445.754 283.657 445.47L283.194 443.158L295.867 426.23H300.196V442.917ZM296.2 434.167C296.2 433.772 296.212 433.347 296.237 432.89C296.261 432.434 296.304 431.959 296.366 431.466L288.06 442.917H296.2V434.167Z"
            fill="#66646F"
          />
          <path
            id="+_2"
            d="M338.214 431.472V438.647H345.021V441.85H338.214V449.06H334.714V441.85H327.941V438.647H334.714V431.472H338.214Z"
            fill="#FBFBFC"
          />
          <path
            id="-"
            d="M248.961 438.627H258.026V442.232H248.961V438.627Z"
            fill="#FBFBFC"
          />
        </g>
        <defs>
          <clipPath id="clip0_76_7596">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 195)"
            />
          </clipPath>
          <clipPath id="clip1_76_7596">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 368)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const QuantityStepperTheme3 = (props) => {
  return (
    <div className="QuantityStepperTheme3">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 69 checkout stepper v3">
          <rect width="379" height="664" fill="white" />
          <rect
            id="themecolor1"
            x="236"
            y="417"
            width="123"
            height="46"
            rx="23"
            fill={props.color}
          />
          <rect
            id="themecolor1_2"
            x="236"
            y="244"
            width="123"
            height="46"
            rx="23"
            fill={props.color}
          />
          <circle id="Ellipse 6" cx="337" cy="440" r="14" fill="#FBFBFC" />
          <g id="Comp icon product" clipPath="url(#clip0_76_7597)">
            <g id="Comp icon - Product">
              <g id="Group">
                <path
                  id="Vector"
                  d="M123.335 218.046C122.905 217.306 122.278 216.676 121.501 216.231L78.0131 195.667C76.4593 194.778 74.5498 194.778 72.996 195.667L29.5084 216.231C28.7315 216.676 28.1044 217.306 27.6738 218.046L75.5046 245.361L123.335 218.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M75.5046 300C76.3751 300 77.2363 299.778 78.0132 299.333L118.047 274.5C119.601 273.611 120.555 271.972 120.555 270.195L124.009 220.528C124.009 219.639 123.766 218.787 123.335 218.046L75.5046 245.361V300Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M72.9961 299.333C73.773 299.778 74.6435 300 75.5047 300V245.37L27.6739 218.056C27.2434 218.787 27 219.648 27 220.528L30.2386 270.195C30.2386 271.972 31.1934 273.611 32.7472 274.5L72.9961 299.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M97.4356 204.778L54.2381 230.25L75.5046 245.361L27.6738 218.046L52.1976 228.769L95.732 204.056L97.4356 204.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_2" clipPath="url(#clip1_76_7597)">
            <g id="Comp icon - Product_2">
              <g id="Group_2">
                <path
                  id="Vector_5"
                  d="M123.335 391.046C122.905 390.306 122.278 389.676 121.501 389.231L78.0131 368.667C76.4593 367.778 74.5498 367.778 72.996 368.667L29.5084 389.231C28.7315 389.676 28.1044 390.306 27.6738 391.046L75.5046 418.361L123.335 391.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_6"
                  d="M75.5046 473C76.3751 473 77.2363 472.778 78.0132 472.333L118.047 447.5C119.601 446.611 120.555 444.972 120.555 443.195L124.009 393.528C124.009 392.639 123.766 391.787 123.335 391.046L75.5046 418.361V473Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M72.9961 472.333C73.773 472.778 74.6435 473 75.5047 473V418.37L27.6739 391.056C27.2434 391.787 27 392.648 27 393.528L30.2386 443.195C30.2386 444.972 31.1934 446.611 32.7472 447.5L72.9961 472.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_8"
                  d="M97.4356 377.778L54.2381 403.25L75.5046 418.361L27.6738 391.046L52.1976 401.769L95.732 377.056L97.4356 377.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="comp elem rect 9">
            <path
              d="M140 241C140 238.239 142.239 236 145 236H180C182.761 236 185 238.239 185 241V254C185 256.761 182.761 259 180 259H145C142.239 259 140 256.761 140 254V241Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 11">
            <path
              d="M140 414C140 411.239 142.239 409 145 409H180C182.761 409 185 411.239 185 414V427C185 429.761 182.761 432 180 432H145C142.239 432 140 429.761 140 427V414Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 10">
            <path
              d="M140 211C140 208.239 142.239 206 145 206H246C248.761 206 251 208.239 251 211V224C251 226.761 248.761 229 246 229H145C142.239 229 140 226.761 140 224V211Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 12">
            <path
              d="M140 384C140 381.239 142.239 379 145 379H246C248.761 379 251 381.239 251 384V397C251 399.761 248.761 402 246 402H145C142.239 402 140 399.761 140 397V384Z"
              fill="#DCDAE2"
            />
          </g>
          <path id="Vector_9" d="M379 330H0V333H379V330Z" fill="#DCDAE2" />
          <circle
            id="themecolor1_3"
            cx="296.5"
            cy="267.5"
            r="30.5"
            fill={props.color}
          />
          <circle id="Ellipse 7" cx="296.5" cy="267.5" r="27.5" fill="white" />
          <path
            id="themecolor1_4"
            d="M310.406 270.47H299.348V282.212H293.619V270.47H282.618V265.254H293.619V253.569H299.348V265.254H310.406V270.47Z"
            fill={props.color}
          />
          <circle id="Ellipse 4" cx="298" cy="440" r="18" fill="#FBFBFC" />
          <path
            id="themecolor1_5"
            d="M345.307 441.72H339.099V448.312H335.883V441.72H329.707V438.792H335.883V432.232H339.099V438.792H345.307V441.72Z"
            fill={props.color}
          />
          <circle id="Ellipse 5" cx="260" cy="440" r="14" fill="#FBFBFC" />
          <path
            id="themecolor1_6"
            d="M254.967 438.627H264.032V442.232H254.967V438.627Z"
            fill={props.color}
          />
          <path
            id="4"
            d="M301.929 442.825H304.659V444.97C304.659 445.17 304.594 445.345 304.464 445.495C304.334 445.635 304.144 445.705 303.894 445.705H301.929V451H298.689V445.705H289.539C289.289 445.705 289.069 445.63 288.879 445.48C288.689 445.32 288.569 445.125 288.519 444.895L288.144 443.02L298.419 429.295H301.929V442.825ZM298.689 435.73C298.689 435.41 298.699 435.065 298.719 434.695C298.739 434.325 298.774 433.94 298.824 433.54L292.089 442.825H298.689V435.73Z"
            fill="#66646F"
          />
        </g>
        <defs>
          <clipPath id="clip0_76_7597">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 195)"
            />
          </clipPath>
          <clipPath id="clip1_76_7597">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 368)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const QuantityStepperTheme4 = (props) => {
  return (
    <div className="QuantityStepperTheme4">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 70 checkout stepper v4">
          <rect width="379" height="664" fill="white" />
          <circle
            id="Ellipse 6"
            cx="335"
            cy="440"
            r="17.5"
            fill="#FBFBFC"
            stroke="#9694A0"
          />
          <g id="Comp icon product" clipPath="url(#clip0_76_7598)">
            <g id="Comp icon - Product">
              <g id="Group">
                <path
                  id="Vector"
                  d="M123.335 218.046C122.905 217.306 122.278 216.676 121.501 216.231L78.0132 195.667C76.4594 194.778 74.5499 194.778 72.9961 195.667L29.5086 216.231C28.7317 216.676 28.1045 217.306 27.674 218.046L75.5047 245.361L123.335 218.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M75.5046 300C76.3751 300 77.2363 299.778 78.0132 299.333L118.047 274.5C119.601 273.611 120.555 271.972 120.555 270.195L124.009 220.528C124.009 219.639 123.766 218.787 123.335 218.046L75.5046 245.361V300Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M72.9961 299.333C73.773 299.778 74.6435 300 75.5047 300V245.37L27.6739 218.056C27.2434 218.787 27 219.648 27 220.528L30.2386 270.195C30.2386 271.972 31.1934 273.611 32.7472 274.5L72.9961 299.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M97.4357 204.778L54.2383 230.25L75.5047 245.361L27.674 218.046L52.1977 228.769L95.7321 204.056L97.4357 204.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_2" clipPath="url(#clip1_76_7598)">
            <g id="Comp icon - Product_2">
              <g id="Group_2">
                <path
                  id="Vector_5"
                  d="M123.335 391.046C122.905 390.306 122.278 389.676 121.501 389.231L78.0132 368.667C76.4594 367.778 74.5499 367.778 72.9961 368.667L29.5086 389.231C28.7317 389.676 28.1045 390.306 27.674 391.046L75.5047 418.361L123.335 391.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_6"
                  d="M75.5046 473C76.3751 473 77.2363 472.778 78.0132 472.333L118.047 447.5C119.601 446.611 120.555 444.972 120.555 443.195L124.009 393.528C124.009 392.639 123.766 391.787 123.335 391.046L75.5046 418.361V473Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M72.9961 472.333C73.773 472.778 74.6435 473 75.5047 473V418.37L27.6739 391.056C27.2434 391.787 27 392.648 27 393.528L30.2386 443.195C30.2386 444.972 31.1934 446.611 32.7472 447.5L72.9961 472.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_8"
                  d="M97.4357 377.778L54.2383 403.25L75.5047 418.361L27.674 391.046L52.1977 401.769L95.7321 377.056L97.4357 377.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="comp elem rect 9">
            <path
              d="M140 241C140 238.239 142.239 236 145 236H180C182.761 236 185 238.239 185 241V254C185 256.761 182.761 259 180 259H145C142.239 259 140 256.761 140 254V241Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 11">
            <path
              d="M140 414C140 411.239 142.239 409 145 409H180C182.761 409 185 411.239 185 414V427C185 429.761 182.761 432 180 432H145C142.239 432 140 429.761 140 427V414Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 10">
            <path
              d="M140 211C140 208.239 142.239 206 145 206H246C248.761 206 251 208.239 251 211V224C251 226.761 248.761 229 246 229H145C142.239 229 140 226.761 140 224V211Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 12">
            <path
              d="M140 384C140 381.239 142.239 379 145 379H246C248.761 379 251 381.239 251 384V397C251 399.761 248.761 402 246 402H145C142.239 402 140 399.761 140 397V384Z"
              fill="#DCDAE2"
            />
          </g>
          <path id="Vector_9" d="M379 330H0V333H379V330Z" fill="#DCDAE2" />
          <circle
            id="Ellipse 2"
            cx="296.5"
            cy="267.5"
            r="29.5"
            fill="#FBFBFC"
            stroke="#9694A0"
            stroke-width="2"
          />
          <path
            id="+"
            d="M299.291 253.569V265.254H310.377V270.47H299.291V282.212H293.591V270.47H282.561V265.254H293.591V253.569H299.291Z"
            fill="#47444E"
          />
          <path
            id="4"
            d="M302.196 442.917H305.563V445.563C305.563 445.81 305.482 446.025 305.322 446.21C305.162 446.383 304.927 446.469 304.619 446.469H302.196V453H298.2V446.469H286.915C286.606 446.469 286.335 446.377 286.101 446.192C285.866 445.995 285.718 445.754 285.657 445.47L285.194 443.158L297.867 426.23H302.196V442.917ZM298.2 434.167C298.2 433.772 298.212 433.347 298.237 432.89C298.261 432.434 298.304 431.959 298.366 431.466L290.06 442.917H298.2V434.167Z"
            fill="#66646F"
          />
          <path
            id="+_2"
            d="M336.214 431.472V438.647H343.021V441.85H336.214V449.06H332.714V441.85H325.941V438.647H332.714V431.472H336.214Z"
            fill="#66646F"
          />
          <circle
            id="Ellipse 5"
            cx="260"
            cy="440"
            r="17.5"
            fill="#FBFBFC"
            stroke="#9694A0"
          />
          <path
            id="-"
            d="M254.961 438.627H264.026V442.232H254.961V438.627Z"
            fill="#66646F"
          />
        </g>
        <defs>
          <clipPath id="clip0_76_7598">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 195)"
            />
          </clipPath>
          <clipPath id="clip1_76_7598">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 368)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const QuantityStepperTheme5 = (props) => {
  return (
    <div className="QuantityStepperTheme5">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="preview 71 checkout stepper v5">
          <rect width="379" height="664" fill="white" />
          <rect
            id="Rectangle 67"
            x="236.5"
            y="244.5"
            width="122"
            height="45"
            rx="14.5"
            fill="#FBFBFC"
            stroke="#66646F"
          />
          <rect
            id="Rectangle 68"
            x="236.5"
            y="417.5"
            width="122"
            height="45"
            rx="14.5"
            fill="#FBFBFC"
            stroke="#66646F"
          />
          <g id="Comp icon product" clipPath="url(#clip0_76_7599)">
            <g id="Comp icon - Product">
              <g id="Group">
                <path
                  id="Vector"
                  d="M123.335 218.046C122.905 217.306 122.278 216.676 121.501 216.231L78.0132 195.667C76.4594 194.778 74.5499 194.778 72.9961 195.667L29.5086 216.231C28.7317 216.676 28.1045 217.306 27.674 218.046L75.5047 245.361L123.335 218.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_2"
                  d="M75.5046 300C76.3751 300 77.2363 299.778 78.0132 299.333L118.047 274.5C119.601 273.611 120.555 271.972 120.555 270.195L124.009 220.528C124.009 219.639 123.766 218.787 123.335 218.046L75.5046 245.361V300Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_3"
                  d="M72.9961 299.333C73.773 299.778 74.6435 300 75.5047 300V245.37L27.6739 218.056C27.2434 218.787 27 219.648 27 220.528L30.2386 270.195C30.2386 271.972 31.1934 273.611 32.7472 274.5L72.9961 299.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_4"
                  d="M97.4357 204.778L54.2383 230.25L75.5047 245.361L27.674 218.046L52.1977 228.769L95.7321 204.056L97.4357 204.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="Comp icon product_2" clipPath="url(#clip1_76_7599)">
            <g id="Comp icon - Product_2">
              <g id="Group_2">
                <path
                  id="Vector_5"
                  d="M123.335 391.046C122.905 390.306 122.278 389.676 121.501 389.231L78.0132 368.667C76.4594 367.778 74.5499 367.778 72.9961 368.667L29.5086 389.231C28.7317 389.676 28.1045 390.306 27.674 391.046L75.5047 418.361L123.335 391.046Z"
                  fill="#DCDAE2"
                />
                <path
                  id="Vector_6"
                  d="M75.5046 473C76.3751 473 77.2363 472.778 78.0132 472.333L118.047 447.5C119.601 446.611 120.555 444.972 120.555 443.195L124.009 393.528C124.009 392.639 123.766 391.787 123.335 391.046L75.5046 418.361V473Z"
                  fill="#9694A0"
                />
                <path
                  id="Vector_7"
                  d="M72.9961 472.333C73.773 472.778 74.6435 473 75.5047 473V418.37L27.6739 391.056C27.2434 391.787 27 392.648 27 393.528L30.2386 443.195C30.2386 444.972 31.1934 446.611 32.7472 447.5L72.9961 472.333Z"
                  fill="#66646F"
                />
                <path
                  id="Vector_8"
                  d="M97.4357 377.778L54.2383 403.25L75.5047 418.361L27.674 391.046L52.1977 401.769L95.7321 377.056L97.4357 377.778Z"
                  fill="#66646F"
                />
              </g>
            </g>
          </g>
          <g id="comp elem rect 9">
            <path
              d="M140 241C140 238.239 142.239 236 145 236H180C182.761 236 185 238.239 185 241V254C185 256.761 182.761 259 180 259H145C142.239 259 140 256.761 140 254V241Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 11">
            <path
              d="M140 414C140 411.239 142.239 409 145 409H180C182.761 409 185 411.239 185 414V427C185 429.761 182.761 432 180 432H145C142.239 432 140 429.761 140 427V414Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 10">
            <path
              d="M140 211C140 208.239 142.239 206 145 206H246C248.761 206 251 208.239 251 211V224C251 226.761 248.761 229 246 229H145C142.239 229 140 226.761 140 224V211Z"
              fill="#DCDAE2"
            />
          </g>
          <g id="comp elem rect 12">
            <path
              d="M140 384C140 381.239 142.239 379 145 379H246C248.761 379 251 381.239 251 384V397C251 399.761 248.761 402 246 402H145C142.239 402 140 399.761 140 397V384Z"
              fill="#DCDAE2"
            />
          </g>
          <path id="Vector_9" d="M379 330H0V333H379V330Z" fill="#DCDAE2" />
          <path
            id="4"
            d="M300.748 442.097H303.387V444.171C303.387 444.364 303.324 444.533 303.198 444.678C303.073 444.814 302.889 444.881 302.647 444.881H300.748V450H297.616V444.881H288.771C288.529 444.881 288.317 444.809 288.133 444.664C287.949 444.509 287.833 444.321 287.785 444.098L287.422 442.286L297.355 429.018H300.748V442.097ZM297.616 435.239C297.616 434.93 297.626 434.596 297.645 434.238C297.664 433.881 297.698 433.509 297.746 433.122L291.236 442.097H297.616V435.239Z"
            fill="#66646F"
          />
          <path
            id="+"
            d="M336.214 431.472V438.647H343.021V441.85H336.214V449.06H332.714V441.85H325.941V438.647H332.714V431.472H336.214Z"
            fill="#66646F"
          />
          <path
            id="-"
            d="M254.961 438.627H264.026V442.232H254.961V438.627Z"
            fill="#66646F"
          />
          <path
            id="0"
            d="M303.184 266.516C303.184 268.343 302.986 269.934 302.589 271.287C302.203 272.631 301.661 273.742 300.965 274.622C300.279 275.502 299.462 276.159 298.515 276.594C297.577 277.019 296.562 277.232 295.47 277.232C294.378 277.232 293.363 277.019 292.425 276.594C291.497 276.159 290.69 275.502 290.003 274.622C289.317 273.742 288.781 272.631 288.394 271.287C288.007 269.934 287.814 268.343 287.814 266.516C287.814 264.68 288.007 263.09 288.394 261.746C288.781 260.402 289.317 259.291 290.003 258.411C290.69 257.531 291.497 256.879 292.425 256.453C293.363 256.018 294.378 255.801 295.47 255.801C296.562 255.801 297.577 256.018 298.515 256.453C299.462 256.879 300.279 257.531 300.965 258.411C301.661 259.291 302.203 260.402 302.589 261.746C302.986 263.09 303.184 264.68 303.184 266.516ZM299.486 266.516C299.486 264.999 299.375 263.742 299.153 262.746C298.931 261.751 298.631 260.958 298.254 260.368C297.887 259.779 297.461 259.368 296.978 259.136C296.495 258.894 295.992 258.773 295.47 258.773C294.958 258.773 294.46 258.894 293.976 259.136C293.503 259.368 293.082 259.779 292.715 260.368C292.348 260.958 292.053 261.751 291.83 262.746C291.618 263.742 291.511 264.999 291.511 266.516C291.511 268.034 291.618 269.291 291.83 270.286C292.053 271.282 292.348 272.075 292.715 272.664C293.082 273.254 293.503 273.67 293.976 273.911C294.46 274.143 294.958 274.259 295.47 274.259C295.992 274.259 296.495 274.143 296.978 273.911C297.461 273.67 297.887 273.254 298.254 272.664C298.631 272.075 298.931 271.282 299.153 270.286C299.375 269.291 299.486 268.034 299.486 266.516Z"
            fill="#66646F"
          />
          <path
            id="+_2"
            d="M336.214 258.472V265.647H343.021V268.85H336.214V276.06H332.714V268.85H325.941V265.647H332.714V258.472H336.214Z"
            fill="#66646F"
          />
          <path
            id="-_2"
            d="M254.961 265.627H264.026V269.232H254.961V265.627Z"
            fill="#66646F"
          />
        </g>
        <defs>
          <clipPath id="clip0_76_7599">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 195)"
            />
          </clipPath>
          <clipPath id="clip1_76_7599">
            <rect
              width="97"
              height="105"
              fill="white"
              transform="translate(27 368)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
export default QuantityStepper;
