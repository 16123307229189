import React from "react";
import { Card } from "reactstrap";
import { imageMapper } from "./image-mapper";
import VideoComponent from "../common/VideoComponent"
import ReactTooltip from "react-tooltip";

export const CardComponentLayout = ({
  mockSize,
  image,
  title,
  subtitle,
  text,
  blockSelected,
  isDragging,
  isDraggable,
  isLeftPanel,
  ...rest
}) => {
  return (
    <div className={isDragging ? "card-container" : null}>
      <Card className={blockSelected ? null : "card-in-block-container"}>
        <img
         style={{ opacity: (isDraggable === false) ? 0.5 : 1 }}
          data-tip
          data-for={`registerTip_${title}`}
          width={blockSelected ? "100%" : "80%"}
          src={
            blockSelected
              ? imageMapper[title]?.phone
              : imageMapper[title]
              ? imageMapper[title].image
              : null
          }
          alt="fake2"
        />
        {!blockSelected && isLeftPanel && (
          <ReactTooltip
            className={"custom-to"}
            id={`registerTip_${title}`}
            place="right"
            effect="solid"
            delayHide={0}
            delayShow={0}
            delayUpdate={0}
            offset={{ left: -20 }}
            backgroundColor={"#414953"}
          >
            <div>              
            <div className={"react-tooltip-title"}>{imageMapper[title].realTitle}</div>
            <div className={"react-tooltip-desc"}>{imageMapper[title].realDescription}</div>
            <div className={"react-tooltip-video"}> <VideoComponent width="240px" height="390px" compoVideo={imageMapper[title].video}/></div>
            </div>
          </ReactTooltip>
        )}
      </Card>
    </div>
  );
};

export const CardComponentLayoutNoOver = ({
  mockSize,
  image,
  title,
  subtitle,
  text,
  blockSelected,
  isDragging,
  ...rest
}) => {
  return (
    <div className={isDragging ? "card-container" : null}>
      <Card className={blockSelected ? null : "card-in-block-container"}>
        <img
          data-tip
          data-for={`registerTip_${title}`}
          width={blockSelected ? "100%" : "80%"}
          src={
            blockSelected
              ? imageMapper[title]?.phone
              : imageMapper[title]
              ? imageMapper[title].image
              : null
          }
          alt="fake1"
        />
      </Card>
    </div>
  );
};

