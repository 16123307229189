import React, { useState, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import VideoComponent from "../common/VideoComponent";
import { Link, useLocation } from "react-router-dom";

const leftMenu = [
  {
    label: "design",
    active: false,
  },
  {
    label: "collections",
    active: false,
  },
  {
    label: "notifications",
    active: false,
  },
  {
    label: "Appstore info",
    active: false,
  },
];

const rightMenu = [
  {
    label: "Select a plan",
    active: false,
  },
  {
    label: "support",
    active: false,
  },
];

export const Navigation = ({ userType }) => {
  const location = useLocation(); // Using useLocation to get the current path
  const [menu, setMenu] = useState([...leftMenu, ...rightMenu]);

  const isMenuItemDisabled = (label) => {
    return userType === "trial" && (label === "stats" || label === "Appstore info");
  };

useEffect(() => {
  const uuidPathRegex = /^\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  const isUuidPath = uuidPathRegex.test(location.pathname);
  const designPaths = [
    "/home",
    "/collections",
    "/lists",
    "/lists-navbar",
    "/lists-lists",
    "/details",
    "/add-to-cart",
    "/cart",
    "/qty-stepper",
    "/bottom",
    "/colors",
    "/loaders",
  ];

  // Adjust here: Include the root path '/' as part of the designPaths
  // or directly in the condition that checks for active state.
  const isDesignPath = designPaths.includes(location.pathname) || location.pathname === '/' || isUuidPath;

  const tempMenu = menu.map((m) => ({
    ...m,
    active: (m.label === "design" && isDesignPath) || 
            (m.label === "Appstore info" && location.pathname === "/publish") || 
            (m.label === "collections" && location.pathname === "/menu") || // Agregamos esto para activar "collections" cuando esté en /menu
            "/" + m.label === location.pathname,
  }));

  setMenu(tempMenu);
}, [location]);


const renderLeftOptions = () => {
  return menu
    .filter((option) => leftMenu.map((m) => m.label).includes(option.label))
    .map((option) => {
      if (isMenuItemDisabled(option.label)) {
        return (
          <div key={option.label} className={`op disabled`}>
            <span style={{ color: "rgba(195, 191, 191, 0.891)" }}>
              {option.label === "collections" ? "collections" : option.label} {/* Mostrar 'collections' en lugar de 'menu' */}
            </span>
          </div>
        );
      }

      // Redirigir 'Appstore info' a '/publish' y 'collections' a '/menu' pero mostrar 'collections' como etiqueta visible
      const linkTarget = option.label === "Appstore info" ? "/publish" : option.label === "collections" ? "/menu" : "/" + option.label;

      return (
        <div key={option.label} className={`op`}>
          <Link to={linkTarget} style={{ color: "white" }}>
            {option.label === "collections" ? "collections" : option.label} {/* Mostrar 'collections' */}
          </Link>
          {option.active && <div className="underline" />}
        </div>
      );
    });
};

  const renderRightOptions = () => {
    return menu
      .filter((option) => rightMenu.map((m) => m.label).includes(option.label))
      .map((option) => {
        if (option.label === "Select a plan" && userType === "trial") {
          return (
            <div key={option.label} className={`op`} data-tip="" style={{ color: "white" }}>
              {option.label}
              <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                className={"custom-to-v3"}
                delayHide={0}
                delayShow={0}
                delayUpdate={0}
                backgroundColor={"#414953"}
                
              >
                <div>
                  
                  <div className={"react-tooltip-desc"}>Find your perfect plan: <br></br>Go to <b>Pricing</b> in the sidebar</div>
                  <div className={"react-tooltip-title"}><br></br>No Cuts, No Hidden Fees, No Chains!</div>                  
                  <div className={"react-tooltip-video-payment"}>
                    <VideoComponent
                    left="50px"
                      width="375px"
                      height="340px"                      
                      compoVideo="./sidevideos/ToolTip2.mp4"
                    />
                  </div>
                </div>
              </ReactTooltip>
            </div>
          );
        } else if (option.label === "support") {
          return (
            <div key={option.label} className={`op`} style={{ color: "white" }}>
              {option.label}
            </div>
          );
        }
        return null;
      });
  };

  return (
    <div className="main-menu">
      <div className="logo">FengCart</div>
      <div className="left-options">{renderLeftOptions()}</div>
      <div className="right-options">{renderRightOptions()}</div>
    </div>
  );
};
