import React, { useRef } from 'react';

function RadioButton({
  label,
  id,
  value,
  onChange,
  check,
  themeColor,
  color1,
  color2,
  style
}) {
  // Use useRef to get a reference to the input element
 const inputRef = useRef(null);

 const handleThemeClick = () => {
   if (inputRef.current) {
     inputRef.current.click();
   }
 };

  if (!label) return null;

  return (
    <div className={`radioCheck ${check == value ? "active" : ""}`} style={style}>
      <input
       ref={inputRef}
        type="radio"
        value={value}
        name={id}
        id={id}
        checked={check == value ? true : false}
        onChange={onChange}
      />
      {themeColor && (
        <div className="themeColor" onClick={handleThemeClick} style={{ background: color2 }}>
          <span className="halfColor" style={{ background: color1 }}></span>
        </div>
      )}
      <label for={id}>{label}</label>
    </div>
  );
}

export default RadioButton;
