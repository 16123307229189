import React from "react";
import Lottie from "react-lottie";

const getLoaderAnimation = (customLoaderJson) => {
    return JSON.parse(customLoaderJson);
};

const LoaderAnimattion = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: getLoaderAnimation(
      props.customLoaderJson
    ),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const animationStyle = {
    margin: "250px auto",
    zIndex: 5000,
  };

  return (
    <Lottie
      options={defaultOptions}
      height={"300px"}
      width={"300px"}
      style={animationStyle}
    />
  );
};

export default LoaderAnimattion;
