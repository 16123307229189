import React, { useState, useEffect } from "react";

export const OptionSelect = ({
  label,
  savedValue,
  onChange,
  parentOption,
  optionsAmount,
  availableOptions,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  const handleChange = (val) => {
    setValue(val);
    onChange(label, val, parentOption);
  };

  const themes = [];

  if (!availableOptions) {
    for (let i = 0; i < optionsAmount; i++) {
      const name = `Theme ${i + 1}`;
      themes.push(
        <div
          key={i}
          className="modern-radio-container"
          onClick={() => {
            handleChange(i);
          }}
        >
          <div className="helper-text">{name}</div>
          <div className={`radio-outer-circle ${value !== i && "unselected"}`}>
            <div
              className={`radio-inner-circle ${
                value !== i && "unselected-circle"
              }`}
            />
          </div>
        </div>
      );
    }
  } else {
    for (let i = 0; i < availableOptions.length; i++) {
      const optionLabel = availableOptions[i].label;
      const optionValue = availableOptions[i].value;
      themes.push(
        <div
          key={optionValue}
          className="modern-radio-container"
          onClick={() => {
            handleChange(optionValue);
          }}
        >
          <div className="helper-text">{optionLabel}</div>
          <div
            className={`radio-outer-circle ${
              value !== optionValue && "unselected"
            }`}
          >
            <div
              className={`radio-inner-circle ${
                value !== optionValue && "unselected-circle"
              }`}
            />
          </div>
        </div>
      );
    }
  }
  return <div className="radio-container">{themes}</div>;
};
