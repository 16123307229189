import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';  // Ensure you've imported ReactTooltip
import VideoComponent from "./VideoComponent"
import iconImage from '../../img/icon9.png';

function RadioButtonIcon({
  label,
  id,
  value,
  onChange,
  check,
  themeColor,
  color1,
  color2,
  style,
  title,  // Assuming the title prop is passed in
  description,  // Assuming the title prop is passed in
  video,  // Assuming the title prop is passed in
}) {
  const inputRef = useRef(null);

  const handleThemeClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  if (!label) return null;

  return (
    <>
      <div className={`radioCheck`} style={style}>
        <input
          ref={inputRef}
          type="radio"
          value={value}
          name={id}
          id={id}
          checked={check == value}
          onChange={onChange}
        />
        {themeColor && (
          <div className="themeColor" onClick={handleThemeClick} style={{ background: color2 }}>
            <span className="halfColor" style={{ background: color1 }}></span>
          </div>
        )}
        <label htmlFor={id}>{label}</label>
        <img style={{width: "17px", height: "17px"}} src={iconImage} data-tip data-for={`registerTip_${title}`} />
      </div>

      <ReactTooltip
        className={"custom-to-v2"}
        id={`registerTip_${title}`}
        place="right"
        effect="solid"
        delayHide={0}
        delayShow={0}
        delayUpdate={0}
        offset={{ left: -20 }}
        backgroundColor={"#414953"}
      >
        <div>
          <div className={"react-tooltip-title"}>{title}</div>
          <div className={"react-tooltip-desc"}>{description}</div>
          <div className={"react-tooltip-video"}> <VideoComponent left="0px" width="300px" height="430px" compoVideo={video}/></div>
        </div>
      </ReactTooltip>
    </>
  );
}
export default RadioButtonIcon;