import { call, put, select, takeLatest } from "redux-saga/effects";
import { findIndex, update } from "ramda";
import {
  fetchForCategories,
  fetchForAllProducts,
} from "../../api";
import {
  INIT_CONFIGURATOR,
} from "./types";
import {
  addCategories,
  feedCategories,
  feedCategoriesForProducts,
  feedProducts,
} from "./actions";


function* initConfigurator(data) {
  //console.log("initConfigurator", data);
  console.log("initConfigurator storeKey", data.payload.storeKey);
  
  const response = yield call(fetchForCategories, { storeKey: data.payload.storeKey });
  const categories = response.data;  
  const responseProducts = yield call(fetchForAllProducts, { storeKey: data.payload.storeKey });
  const products = responseProducts.data;
  
  console.log("result categories", categories);
  console.log("result products", products);

  yield put(feedCategories(categories));
  yield put(feedCategoriesForProducts(categories));
  yield put(addCategories(categories));
  yield put(feedProducts(products));
}



export default [
  takeLatest(INIT_CONFIGURATOR, initConfigurator),
];

