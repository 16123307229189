import { is } from "ramda";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { sortComponentConfigs } from "../../../utils/utilities";
import { ConfSection } from "./confsection";
import { RemoveWithConfirmRow } from "./remove-button";

export const Configurator = ({
  selectedComponent,
  onRemoveComponent,
  onClose,
  onConfigChange,
  onCompoUpdate,
  templateID,
  storeKey,
  userType
}) => {
  const [newConfigurationOptions, setNewConfigurationOptions] = useState([]);
  const [globalOptions, setGlobalOptions] = useState([]);

  useEffect(() => {
    if (selectedComponent) {
      console.log("selectedComponent", selectedComponent);
      console.log("globalOptions", globalOptions);
      const found = globalOptions.find(
        (gc) => gc.uuid === selectedComponent.uuid
      );
      if (found) {
        console.log("selectedComponent found.configuration", found.configuration);
        // settings global options if exist
        setNewConfigurationOptions(found.configuration);
      } else {
        console.log("selectedComponent selectedComponent.title", selectedComponent.title);
        console.log("selectedComponent selectedComponent.configurationOptions", selectedComponent.configurationOptions);
        
        const sortedConfigs = sortComponentConfigs(
          selectedComponent.configurationOptions,
          selectedComponent.title
        );

        console.log("selectedComponent sortedConfigs", sortedConfigs);
        setNewConfigurationOptions(sortedConfigs);
      }
    }
  }, [selectedComponent]);

  const handleConfigurationChange = (label, value, parentOption) => {

    console.log("handleConfigurationChange label", label);
    console.log("handleConfigurationChange value", value);
    console.log("handleConfigurationChang parentOptione", parentOption);

    //debugger;
    let temp = JSON.parse(JSON.stringify(newConfigurationOptions));

    console.log("temp", temp);
    console.log("newConfigurationOptions", newConfigurationOptions);

    if (parentOption) {
      console.log("IS PARENTOPTION");
      
      for (const config of temp) {
        if (config.label === parentOption) {
          if (parentOption === "Action") {
            config.externalLink = value;
            break;
          }
          if (
            parentOption === "Image 1" ||
            parentOption === "Image 2" ||
            parentOption === "Image 3"
          ) {
            for (const op of config.options) {
              if (op.label === label) {
                if (value == "0" || value == "1" || value == "2") {
                  op.value = value;
                } else if (Array.isArray(value)) {
                  op.products = value;
                } else {
                  op.externalLink = value;
                }
                break;
              }
            }
            break;
          }
          for (const op of config.options) {
            if (op.label === label) {
              op.value = value;
              break;
            }
          }
        }
      }
    } else {
      for (const config of temp) {
        if (config.label === label) {
          if (
            label === "Update content" ||
            label === "Update product" ||
            label === "Update category"
          ) {
            config.products = value;
          } else {
            config.value = value;
          }
          break;
        }
      }
    }

    // saving global options temp
    const found = globalOptions.findIndex(
      (gc) => gc.uuid === selectedComponent.uuid
    );
    if (found !== -1) {
      console.log("found", found);
      globalOptions[found] = {
        uuid: selectedComponent.uuid,
        configuration: temp,
      };
    } else {
      console.log("push", found);
      globalOptions.push({ uuid: selectedComponent.uuid, configuration: temp });
    }
    
    // Fix to update products in the global options
    //selectedComponent.configurationOptions = temp

    console.log("globalOptions", globalOptions);
    console.log("temp", temp);

    onCompoUpdate(selectedComponent.uuid, temp)
    onConfigChange(globalOptions);
    setGlobalOptions(globalOptions);
    setNewConfigurationOptions(temp);
  };

  /**
   * Workaround function to hide configs depending on another "sister" config.
   * (Hidding confings meant to be parent -> children instead sisters that's way im doing this)
   * @param configLabel
   * @returns hide true or false
   */
  const needToHide = (configLabel) => {
    if (selectedComponent.title.includes("Slider categorias")) {
      const categoryTitleConfig = newConfigurationOptions.find(
        (config) => config.label === "Category title"
      );
      const noneCategoryTitle =
        categoryTitleConfig &&
        categoryTitleConfig.options &&
        categoryTitleConfig.options[0] &&
        categoryTitleConfig.options[0].value === 2;
      if (
        noneCategoryTitle &&
        (configLabel === "Background Color" ||
          configLabel === "Category title position" ||
          configLabel === "Title Color")
      ) {
        return true;
      }
      return false;
    }

    return false;
  };

  /**
   * Workaround function to hide NESTED configs depending on another "sister" config.
   * (Hidding confings meant to be parent -> children instead sisters that's way im doing this)
   * @param options
   * @returns the same options with an additional property hide true or false
   */
  const needToHideNested = (options) => {
    if (options) {
      const autoScroll = options.find((o) => o.label === "Auto Scroll");
      if (autoScroll) {
        const timeDelay = options.find((o) => o.label === "Time Delay");
        if (timeDelay) {
          timeDelay.hide = !autoScroll.value;
        }
      }
    }
    return options;
  };

  const getRealTitle = (title) => {
    if (imageMapper[title]) {
      return imageMapper[title].realTitle;
    } else {
      return null; // or throw an error, or whatever you want to do when the title isn't found
    }
  };

  const getRealDescription = (title) => {
    if (imageMapper[title]) {
      return imageMapper[title].realDescription;
    } else {
      return null; // or throw an error, or whatever you want to do when the title isn't found
    }
  };

  return (
    <div className="configurator">
      <Container className="nomargin nopadding">
        <Row className="title-bar">
          {selectedComponent && (
            <Col >
              <div className="configuration-side-title">{getRealTitle(selectedComponent.title)}</div>
              <div className="configuration-side-desc" style={{ whiteSpace: 'pre-wrap' }}>{getRealDescription(selectedComponent.title)}</div>
            </Col>
          )}
          <Col lg={1} className="d-flex justify-content-end align-items-end pb-4"  style={{ marginBottom: "129px",  marginLeft: "20px" }}>
            <Button close size="lg" onClick={() => onClose()} />
          </Col>
        </Row>
        <Container>
    {console.log("CONTAINER:", newConfigurationOptions)}
    {selectedComponent &&
        newConfigurationOptions.map(
            ({ label, type, options, value, externalLink }) => {
                return (
                    <ConfSection
                        title={selectedComponent.title}
                        label={label}
                        type={type}
                        options={needToHideNested(options)}
                        value={value}
                        key={`${selectedComponent.title}-${label}`}
                        externalLink={externalLink}
                        onChange={handleConfigurationChange}
                        hide={needToHide(label)} // workaround para ocultar configuraciones que dependen de configuraciones "hermanas"
                        storeKey={storeKey}
                        userType={userType}
                    />
                );
            }
        )}
    <RemoveWithConfirmRow
        onRemove={() => {
            onRemoveComponent({
                uuid: selectedComponent.uuid,
                templateID: templateID,
                storeKey: storeKey
            });
            // Close toolbar after remove block to avoid showing empty config
            onClose();
        }}
    />
</Container>

      </Container>
    </div>
  );
};


export const imageMapper = {
  "Producto Destacado": {
    realTitle: "Featuring product",
    realDescription: "Experience the Highlighted Selection of the Day, Week, or Month in a Captivating Display with Personalized Main and Secondary Colors",
  },
  "Slider con listado de productos configurables #1 - left": {
    realTitle: "Product Carousel Slider - Left",
    realDescription: "Product Image on the left\nDescription on the right\nExperience smooth navigation with the horizontal scroll bar",
  },
  "Slider con listado de productos configurables #1 - right": {
    realTitle: "Product Carousel Slider - Right",
    realDescription: "Product Image on the right\nDescription on the left\nExperience smooth navigation with the horizontal scroll bar",
  },
  "Slider con listado de productos configurables #2": {
    realTitle: "Product Carousel Slider",
    realDescription: "Engaging 3-image slider\nExperience smooth navigation with the horizontal scroll bar",    
  },
  "Slider con listado de productos configurables #3": {
    realTitle: "Product Carousel Slider",
    realDescription: "Engaging 6-image slider with\n3 products on top, 3 products below\nExperience smooth navigation with the horizontal scroll bar",
  },
  "Slider con listado de productos configurables #4": {
    realTitle: "Product Carousel Slider",
    realDescription: "Engaging 2-image slider\nExperience smooth navigation with the horizontal scroll bar",
  },
  "Carousel de productos": {
    realTitle: "Small Product Carousel Slider",
    realDescription: "Discover a compact and visually engaging display of product images and descriptions using a small-height horizontal scroll component",
  },
  "Slider productos GRID - theme 1": {
    realTitle: "Product Grid",
    realDescription: "Discover the visually appealing grid layout of products showcased in this theme, characterized by its beautifully designed presentation."
  },
  "Slider productos GRID - theme 2": {
    realTitle: "Product Grid",
    realDescription: "Discover a visually appealing grid layout of products showcasing a 3D perspective for multi-image viewing."
  },
  "Slider productos HORIZONTAL - theme 1": {
    realTitle: "Product Slider - Horizontal",
    realDescription: "Experience the Versatile Product Slider: Revel in its Exquisitely Designed Presentation"
  },
  "Slider productos HORIZONTAL - theme 2": {
    realTitle: "Product Slider - Horizontal",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products in a 3D perspective for multi-image viewing."
  },
  "Slider productos destacados - sliderFull": {
    realTitle: "Product Slider - Zoom Out",
    realDescription: "Explore the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs."
  },
  "Slider productos destacados - sliderFlip": {
    realTitle: "Product Slider - 3D",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs in a Captivating 3D Flip View."
  },
  "Slider productos destacados - sliderCards": {
    realTitle: "Product Slider - Overlap",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs in an Engaging Overlap View"
  },
  "Slider productos destacados - sliderContinuous": {
    realTitle: "Product Slider - Linear",
    realDescription: "Experience the Versatile Product Slider: Showcase Your Products with a Variety of Theme Designs in an Engaging Linear View"
  },
  "Image 100": {
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of 54 Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites and Elevate Your Visual Experience"
  },
  "Image 75": {
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of 54 Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Image Component Stands at 75% Height"
  },
  "Image 50": {
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Image Component Stands at 50% Height"
  },
  "Image 25": {
    realTitle: "Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Image Component Stands at 25% Height"
  },
  "Image Double 100": {
    realTitle: "Double Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites and Elevate Your Visual Experience"
  },
  "Image Double 50": {
    realTitle: "Double Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Component Stands at 50% Height"
  },
  "Image Triple 100": {
    realTitle: "Triple Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites and Elevate Your Visual Experience"
  },
  "Image Triple 50": {
    realTitle: "Triple Image Component",
    realDescription: "Unleash the Magic of Astonishing Image Animations. Customize Spacing, Link to Products, Collections or Websites. This Component Stands at 50% Height"
  },
  "Botones": {
    realTitle: "Button",
    realDescription: "Customize Your Design with Background Color, Rounded Corners and Adjustable Spacing. Link it to Products, Collections or Websites with Ease"
  },
  "Separator Line Text": {
    realTitle: "Text Separator with lines",
    realDescription: "Distinctive Text Separator: Elevate Visual Hierarchy and Organize Content with Elegant Text Dividers. Customize Line Color, Spacing and Line Separators on Both Sides"
  },
  "Separator Line": {
    realTitle: "Line Separator",
    realDescription: "Versatile Line Separator: Create Visual Divisions with Customizable Line Color for Added Flair and Structure."
  },
  "Separator Text": {
    realTitle: "Text Separator",
    realDescription: "Distinctive Text Separator: Elevate Visual Hierarchy and Organize Content with Elegant Text Dividers."
  },
  "News No Image": {
    realTitle: "Blog Post - No Image",
    realDescription: "Engage Readers with the Blog Post Component: Title, Descriptions and Linking to Products, Collections or Websites."
  },
  "News Top Image": {
    realTitle: "Blog Post with Image",
    realDescription: "Engage Readers with the Blog Post Component: Image Transitions, Title, Descriptions and Linking to Products, Collections or Websites."
  },
  "Slider categorias con animaciones - sliderFull": {
    realTitle: "Category Slider - Zoom Out",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options."
  },
  "Slider categorias con animaciones - sliderFlip": {
    realTitle: "Category Slider  - 3D",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options in a Captivating 3D Flip View."
  },
  "Slider categorias con animaciones - sliderCards": {
    realTitle: "Category Slider - Overlap",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options in an Engaging Overlap View"
  },
  "Slider categorias con animaciones - sliderContinuous": {
    realTitle: "Category Slider - Linear",
    realDescription: "Explore the Versatile Category Slider: Showcase Your Categories with Customizable Title Backgrounds and Flexible Positioning Options in an Engaging Linear View"
  },
  "Carousel de categorías": {
    realTitle: "Small Categories Carousel Slider",
    realDescription: "Discover a compact and visually engaging display of categories images and descriptions using a small-height horizontal scroll component",
  },
  "Video": {
    realTitle: "Video Player",
    realDescription: "Elevate Your Digital Presence with the Ultimate Video Feature. A Platform to Broadcast Your Brand's Videos, Enhancing Engagement Through Visual Storytelling",
  },
  "Video TikTok": {
    realTitle: "TikTok Video",
    realDescription: "Engage and Inspire with TikTok: Feature the Creative, Fast-Paced World of TikTok on Your Mobile App, Providing Users with a Stream of Entertaining Content",
  },
};
