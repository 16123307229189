import React from "react";

const WarningIcon = () => {
  return (
    <svg
      width="94"
      height="83"
      viewBox="0 0 94 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M92.942 71.3302C95.9543 76.5168 92.1732 83 86.1569 83H7.84219C1.81428 83 -1.94931 76.5067 1.05706 71.3302L40.2149 3.88819C43.2286 -1.30076 50.7768 -1.29136 53.7852 3.88819L92.942 71.3302ZM47 57.3867C42.8541 57.3867 39.4931 60.7254 39.4931 64.8437C39.4931 68.9621 42.8541 72.3008 47 72.3008C51.146 72.3008 54.507 68.9621 54.507 64.8437C54.507 60.7254 51.146 57.3867 47 57.3867ZM39.8728 30.5826L41.0834 52.6295C41.14 53.6611 41.9988 54.4688 43.0388 54.4688H50.9613C52.0013 54.4688 52.86 53.6611 52.9166 52.6295L54.1272 30.5826C54.1884 29.4682 53.2952 28.5312 52.1718 28.5312H41.8281C40.7046 28.5312 39.8116 29.4682 39.8728 30.5826Z"
        fill="#FFB800"
      />
    </svg>
  );
};
export default WarningIcon;
