export const configuration = {
  
    "cart": {
      "bottomView": {
        "buttonHasShadow": true,
        "buttonIsRounded": true,
        "curvedPath": true
      },
      "checkOut": {
        "theme": 1
      },
      "isRounded": false,
      "theme": 0
    },
    "collection": {
      "aligment": 2,
      "imageRounded": false,
      "isRounded": true,
      "mainColor": "#96349d",
      "theme": 0
    },
    "colors": {
      "mainColor": "#1B82E1",
      "secondaryColor": "#8CD0F5"
    },
    "loader": {
      "lottieCustomIndex": 1
    },
    "productDetail": {
      "add2cart": {
        "checkOutButtonTheme": 2
      },
      "productCard": {
        "curvedPath": true,
        "gradientType": 3,
        "pillRounded": true,
        "theme": 1
      }
    },
    "productListing": {
      "animation": {
        "columns": 5,
        "isRandom": false,
        "rows": 4,
        "type": 54,
        "velocity": 1
      },
      "menu": {
        "fontColor": "#FFFFFF",
        "theme": 0
      },
      "productListingView": [
        {
          "hasShadow": true,
          "isCustom": false,
          "isRounded": true,
          "mainColor": "#8E22A9",
          "type": 0
        },
        {
          "hasShadow": true,
          "isCustom": false,
          "isRounded": false,
          "mainColor": "#059CE5",
          "theme": 2,
          "type": 3
        },
        {
          "animationType": 0,
          "hasShadow": true,
          "isCustom": false,
          "isRounded": false,
          "mainColor": "#A64AC9",
          "theme": 1,
          "type": 2
        }
      ]
    }
  };
  