/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { QRCodeSVG } from 'qrcode.react';
import LoadingImage from "../img/FengLoaderPNGFull.png";
import {
  createQRcode,
} from "../../api";




export const ModalPreview = ({ isOpen, onClose, templateID, templateName, storeKey }) => {
  const [isDeeplinkLoaded, setIsDeeplinkLoaded] = useState(false); // Loading state
  const [qrCodeValue, setQrCodeValue] = useState(`fengcart://content?=${templateID}&${templateName}`); 

  useEffect(() => {
    console.log("Create Deeplink");
    loadEncryptedData();
  }, []);

  // Update Current Theme
  const loadEncryptedData = () => {
    console.log("loadEncryptedData storeKey", storeKey);
    console.log("loadEncryptedData templateID", templateID);

    setIsDeeplinkLoaded(false);
    // API
    createQRcode(storeKey, templateID)
      .then((response) => {
        const data = response.data.data;
        const fetchedTemplateID = data.tid;
        const fetchedStoreKey = data.sk;
        const fetchedAt = data.at;
        const fetchedURI = data.uri;
        if (data) {
          const newQrCodeValue = `fengcart://content?sk=${fetchedStoreKey}&tid=${fetchedTemplateID}&at=${fetchedAt}&uri=${fetchedURI}`;

          console.log("newQrCodeValue", newQrCodeValue);
          setQrCodeValue(newQrCodeValue);
          setIsDeeplinkLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching navBar data:", error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      className="transitionSelector preview previewModal isOpen"
    >
      <section>
        <div className="modal__container">
          <div className="row">
            <div className="modal__title primary">Preview</div>
            <div className="modal__buttons">
              <button
                type="button"
                className="close__button custom-button cancel-button"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
          <div className="body__modal">
          {isDeeplinkLoaded ? (
              <div className="modal__container-image">
                <QRCodeSVG
                  value={qrCodeValue}
                  size={420}
                  bgColor={"#FFFFFF"}
                  fgColor={"#104B9E"}
                  level={"H"}
                  includeMargin={true}
                  marginSize={20}
                  imageSettings={{
                    src: LoadingImage,
                    x: undefined,
                    y: undefined,
                    height: 90,
                    width: 120,
                    excavate: true,
                  }}
                />
                <p className="textPreview">
                  Scan with your phone <br />
                  to open a preview <br />
                  of “{templateName}”
                </p>
              </div>
            ) : (
              <p className="textPreview" style={{height: "550px"}}>Generating QR...</p>
            )}
          </div>
        </div>
      </section>
    </Modal>
  );
};
