import React from "react";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Notifications from "../components/notifications-page/Notifications";

export const NotificationsPage = ({ storeKey, userType }) => {
  return (
    <div className="market-layout">
      <Container fluid className="layout-container">
        <Row className="main-row no-gutters">
          <div style={{ display: "flex", width: "100%" }}>
            <Notifications storeKey={storeKey} userType={userType}/>
          </div>
        </Row>
      </Container>
    </div>
  );
};
