import * as types from "./types";

export const addProduct = (payload) => ({
  type: types.ADD_PRODUCT,
  payload,
});
export const removeProducts = (payload) => ({
  type: types.REMOVE_PRODUCTS,
  payload,
});
export const removeCategories = (payload) => ({
  type: types.REMOVE_CATEGORIES,
  payload,
});
export const addProduct2 = (payload) => ({
  type: types.ADD_PRODUCT_2,
  payload,
});
export const addProduct3 = (payload) => ({
  type: types.ADD_PRODUCT_3,
  payload,
});
export const addProducts = (payload) => ({
  type: types.ADD_PRODUCTS,
  payload,
});
export const addProducts2 = (payload) => ({
  type: types.ADD_PRODUCTS_2,
  payload,
});
export const addProducts3 = (payload) => ({
  type: types.ADD_PRODUCTS_3,
  payload,
});
export const addAllProducts = (payload) => ({
  type: types.ADD_ALL_PRODUCTS,
  payload,
});
export const addAllCategories = (payload) => ({
  type: types.ADD_ALL_CATEGORIES,
  payload,
});
export const addCategory = (payload) => ({
  type: types.ADD_CATEGORY,
  payload,
});
export const addCategory2 = (payload) => ({
  type: types.ADD_CATEGORY_2,
  payload,
});
export const addCategory3 = (payload) => ({
  type: types.ADD_CATEGORY_3,
  payload,
});
export const addCategories = (payload) => ({
  type: types.ADD_CATEGORIES,
  payload,
});
export const addCategories2 = (payload) => ({
  type: types.ADD_CATEGORIES_2,
  payload,
});
export const addCategories3 = (payload) => ({
  type: types.ADD_CATEGORIES_3,
  payload,
});

export const feedCategories = (payload) => ({
  type: types.FEED_CATEGORIES,
  payload,
});

export const feedCategoriesForProducts = (payload) => ({
  type: types.FEED_CATEGORIES_FOR_PRODUCTS,
  payload,
});

export const initConfigurator = (payload) => ({
  type: types.INIT_CONFIGURATOR,
  payload,
});

export const selectCategoriesRequest = (payload) => ({
  type: types.SELECT_CATEGORY_REQUEST,
  payload,
});

export const selectProductRequest = (payload) => ({
  type: types.SELECT_PRODUCT_REQUEST,
  payload,
});

export const feedProducts = (payload) => ({
  type: types.FEED_PRODUCTS,
  payload,
});

export const changeProductOrder = (payload) => ({
  type: types.RE_ARRANGE_PLACED_COMPONENT,
  payload,
});
export const changeProductOrder2 = (payload) => ({
  type: types.RE_ARRANGE_PLACED_COMPONENT,
  payload,
});

export const changeCategoryOrder = (payload) => ({
  type: types.RE_ARRANGE_PLACED_COMPONENT_CATEGORIES,
  payload,
});

export const removeProduct = (payload) => ({
  type: types.REMOVE_PRODUCT,
  payload,
});

export const removeProduct2 = (payload) => ({
  type: types.REMOVE_PRODUCT_2,
  payload,
});
export const removeProduct3 = (payload) => ({
  type: types.REMOVE_PRODUCT_3,
  payload,
});

export const removeCategory = (payload) => ({
  type: types.REMOVE_CATEGORY,
  payload,
});

export const removeCategory2 = (payload) => ({
  type: types.REMOVE_CATEGORY_2,
  payload,
});

export const removeCategory3 = (payload) => ({
  type: types.REMOVE_CATEGORY_3,
  payload,
});

// NEW TYPES
export const loadTemplate = (payload) => ({
  type: types.LOAD_TEMPLATE,
  payload,
});
