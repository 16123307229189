import React, { useState, useEffect, useRef } from "react";
import { uploadImage } from "../../api";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import LoadingImage from "../img/FengLoaderWhitePNG.png"
import { fetchPublishData, updatePublishData } from "../../api";
import "./Publish.css";

function Publish({ storeKey }) {
  console.log("storeKey", storeKey);

  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Loading state

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [primaryCategory, setPrimaryCategory] = useState("Shopping");
  const [secondaryCategory, setSecondaryCategory] = useState("Lifestyle");
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [marketingURL, setMarketingURL] = useState("");
  const [supportURL, setSupportURL] = useState("");
  const [privacyPolicyURL, setPrivacyPolicyURL] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageLaunch, setSelectedImageLaunch] = useState(null);
  const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    // API
    fetchPublishData(storeKey)
    .then(({ data }) => {
      if (data) {
        console.log("data", data);
  
        // Set state only if the property exists
        if (data.appIconImage) setSelectedImage(data.appIconImage);
        if (data.launchScreenImage) setSelectedImageLaunch(data.launchScreenImage);
        if (data.listingTitle) setTitle(data.listingTitle);
        if (data.listingSubTitle) setSubtitle(data.listingSubTitle);
        if (data.listingPrimaryCategory) setPrimaryCategory(data.listingPrimaryCategory);
        if (data.listingSecondaryCategory) setSecondaryCategory(data.listingSecondaryCategory);
        if (data.listingKeywords) setKeywords(data.listingKeywords);
        if (data.listingDescription) setDescription(data.listingDescription);
        if (data.businessInfoMarketingUrl) setMarketingURL(data.businessInfoMarketingUrl);
        if (data.businessInfoSupportUrl) setSupportURL(data.businessInfoSupportUrl);
        if (data.businessInfoPrivacyUrl) setPrivacyPolicyURL(data.businessInfoPrivacyUrl);
      } else {
        console.error("Error fetching:");
      }
    })
    .catch((error) => {
      console.error("Error fetching publish data:", error);
    })
    .finally(() => {
      setIsLoading(false); // Set loading state to false after data is loaded
    });
  };
  

  const handleCustomButtonClick = async () => {
    // Use formRef to submit the form
    if (formRef.current) {
      console.log("submit");

      console.log("DATOS", {
        selectedImage,
        selectedImageLaunch,
        title,
        subtitle,
        primaryCategory,
        secondaryCategory,
        keywords,
        description,
        marketingURL,
        supportURL,
        privacyPolicyURL,
      });

      setIsSaving(true);

      updatePublishData(
        storeKey,
        selectedImage,
        marketingURL,
        privacyPolicyURL,
        supportURL,
        selectedImageLaunch,
        description,
        keywords,
        primaryCategory,
        secondaryCategory,
        subtitle,
        title
      )
        .then((response) => {
          console.log("response", response);
          setIsSaving(false);
        })
        .catch((error) => {
          console.error("Error updateCollectionData:", error);
        });
    }
  };

  const uploadHandler = async (e) => {
    try {
      const data = new FormData();
      data.append("uploadedFileName", e.target.files[0]);
      data.append("storeKey", storeKey);

      console.log("await UPLOADED", data);
      const response = await uploadImage(data);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.url
      ) {
        console.log("FILE UPLOADED", response.data);
        console.log("FILE UPLOADED FINAL", response.data.data);
        console.log("FILE UPLOADED URL", response.data.data.url);
        setSelectedImage(response.data.data.url);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error during upload:", error);
    }
  };

  const uploadHandlerLaunch = async (e) => {
    try {
      const data = new FormData();
      data.append("uploadedFileName", e.target.files[0]);
      data.append("storeKey", storeKey);

      console.log("await UPLOADED uploadHandlerLaunch", data);
      const response = await uploadImage(data);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.url
      ) {
        console.log("FILE UPLOADED uploadHandlerLaunch", response.data);
        console.log("FILE UPLOADED FINAL", response.data.data);
        console.log("FILE UPLOADED URL", response.data.data.url);
        setSelectedImageLaunch(response.data.data.url);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error during upload:", error);
    }
  };

  useEffect(() => {
    // Ensure all required fields are filled and valid
    const isValid = 
    title.length > 4 &&
    subtitle.length > 4 &&
    keywords.length > 4 &&
    description.length > 4 &&
    marketingURL.trim().startsWith('http') &&
    supportURL.trim().startsWith('http') &&
    privacyPolicyURL.trim().startsWith('http') &&
    selectedImage !== null &&
    selectedImageLaunch !== null;
  
  console.log ("isValid", isValid);
  
    setIsUpdateButtonEnabled(isValid);
  }, [
    title, 
    subtitle, 
    primaryCategory, 
    secondaryCategory, 
    keywords, 
    description, 
    marketingURL, 
    supportURL, 
    privacyPolicyURL, 
    selectedImage, 
    selectedImageLaunch
  ]);
  
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mainForm">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Loading...</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">Saving...</div>
        </div>
      )}
      <form ref={formRef} className="scrollable-content">
        <div>
          <h2>App Store Listing</h2> <div>Please note that Apple App Store publishing typically takes between 5 to 10 business days. We kindly ask for your patience during this time. The review process will start once you have completed and submitted all the required information in this form. Additionally, for security reasons, we will ask for your Apple Store username and password through a different channel to ensure it is not stored anywhere.</div>
          <div>
            <label>* App Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={30}
            />
            <span>{title.length}/30</span>
          </div>
          <div>
            <label>* App Sub-Title:</label>
            <input
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              maxLength={30}
            />
            <span>{subtitle.length}/30</span>
          </div>
          <div>
            <label>Primary Category:</label>
            <select
              value={primaryCategory}
              onChange={(e) => setPrimaryCategory(e.target.value)}
            >
              <option value="Books">Books</option>
              <option value="Business">Business</option>
              <option value="Developer Tools">Developer Tools</option>
              <option value="Education">Education</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Finance">Finance</option>
              <option value="Food & Drink">Food & Drink</option>
              <option value="Games">Games</option>
              <option value="Graphics & Design">Graphics & Design</option>
              <option value="Health & Fitness">Health & Fitness</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Kids">Kids</option>
              <option value="Magazines & Newspapers">
                Magazines & Newspapers
              </option>
              <option value="Medical">Medical</option>
              <option value="Music">Music</option>
              <option value="Navigation">Navigation</option>
              <option value="News">News</option>
              <option value="Photo & Video">Photo & Video</option>
              <option value="Productivity">Productivity</option>
              <option value="Reference">Reference</option>
              <option value="Shopping">Shopping</option>
              <option value="Social Networking">Social Networking</option>
              <option value="Sports">Sports</option>
              <option value="Travel">Travel</option>
            </select>
          </div>
          <div>
            <label>Secondary Category:</label>
            <select
              value={secondaryCategory}
              onChange={(e) => setSecondaryCategory(e.target.value)}
            >
              <option value="Books">Books</option>
              <option value="Business">Business</option>
              <option value="Developer Tools">Developer Tools</option>
              <option value="Education">Education</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Finance">Finance</option>
              <option value="Food & Drink">Food & Drink</option>
              <option value="Games">Games</option>
              <option value="Graphics & Design">Graphics & Design</option>
              <option value="Health & Fitness">Health & Fitness</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Kids">Kids</option>
              <option value="Magazines & Newspapers">
                Magazines & Newspapers
              </option>
              <option value="Medical">Medical</option>
              <option value="Music">Music</option>
              <option value="Navigation">Navigation</option>
              <option value="News">News</option>
              <option value="Photo & Video">Photo & Video</option>
              <option value="Productivity">Productivity</option>
              <option value="Reference">Reference</option>
              <option value="Shopping">Shopping</option>
              <option value="Social Networking">Social Networking</option>
              <option value="Sports">Sports</option>
              <option value="Travel">Travel</option>
            </select>
          </div>
          <div>
            <label>* Keywords:</label>
            <input
              type="text"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            />
          </div>
          <div>
            <label>* Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div>
          <h2>Business Information</h2>
          <div>
            <label>* Marketing URL:</label>
            <input
              type="url"
              value={marketingURL}
              onChange={(e) => setMarketingURL(e.target.value)}
            />
          </div>
          <div>
            <label>* Support URL:</label>
            <input
              type="url"
              value={supportURL}
              onChange={(e) => setSupportURL(e.target.value)}
            />
          </div>
          <div>
            <label>* Privacy Policy URL:</label>
            <input
              type="url"
              value={privacyPolicyURL}
              onChange={(e) => setPrivacyPolicyURL(e.target.value)}
            />
          </div>
        </div>
        <div>
          <h5>* Mobile App Icon</h5>
          <p>The App Icon in your mobile app</p>
          <div>
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Uploaded Icon"
                style={{ width: "400px", height: "400px", paddingBottom: "30px" }}
              />
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#f0f0f0",
                }}
              >
                App Icon Placeholder
              </div>
            )}
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              onChange={uploadHandler}
            />
            <label
              htmlFor="file-upload"
              className="custom-button content-button upload-image"
              style={{ width: "200px", margin: "0", textAlign: "center" }}
            >
              SELECT IMAGE
            </label>
          </div>
        </div>
        <div>
          <h5>* Launch Screen</h5>
          <p>
            Your launch screen will display every time a user starts a new app
            session
          </p>
          <div>
            {selectedImageLaunch ? (
              <img
                src={selectedImageLaunch}
                alt="Uploaded Icon"
                style={{ width: "400px", height: "400px", paddingBottom: "30px" }}
              />
            ) : (
              <div
                style={{
                  width: "300px",
                  height: "400px",
                  backgroundColor: "#f0f0f0",
                }}
              >
                App Icon Placeholder
              </div>
            )}
            <input
              type="file"
              id="file-upload-launch"
              style={{ display: "none" }}
              onChange={uploadHandlerLaunch}
            />
            <label
              htmlFor="file-upload-launch"
              className="custom-button content-button upload-image"
              style={{ width: "300px", margin: "0", textAlign: "center" }}
            >
              SELECT IMAGE
            </label>
          </div>
        </div>
        <div> * Required Fields</div>
        <div
          style={{
            width: "300px",
            marginTop: "80px",
            marginRight: "auto",
            textAlign: "center",
            display:"block"
          }}
        >
          <CustomButton
            type="SAVE"
            label="UPDATE"
            onClick={handleCustomButtonClick}
             disabled={!isUpdateButtonEnabled}
             display={'block'}
          />
        </div>
      </form>
    </div>
  );
}

export default Publish;
