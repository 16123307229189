import React, { useState, useEffect, useRef } from "react";
import { Row } from "reactstrap";
import Input from "reactstrap/lib/Input";

export const EditableInput = ({
  label,
  savedValue,
  onChange,
  parentOption,
  placeholder,
  disabled,
  isSearch,
}) => {
  const [value, setValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    setIsSaving(true);

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    const newValue = e.target.value; // Capture the current value

    timeoutIdRef.current = setTimeout(() => {
      onChange(label, newValue, parentOption); // Use the captured value
      setIsSaving(false);
      timeoutIdRef.current = null;
    }, 1000); // Delay of 1 second (1000 milliseconds)
  };

  const handleKeyPress = (event) => {
    console.log("handleKeyPress" , event.keyCode  );
    const code = event.keyCode || event.which;
    if (code === 13) {
    }
  };

  return (
    <Row className={parentOption === "Action" ? "link-input" : ""}>
      <Input
        className={
          !disabled ? "input-title-hiddable" : "input-title-hiddable disabled"
        }
        value={value}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder || ""}
      />
    </Row>
  );
};
