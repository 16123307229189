import React from "react";

const CollectionContainer = (props) => {
  switch (props.theme) {
    case "rounded_rounded_left":
      return <CollectionContainerRoundedRoundedLeft />;
    case "rounded_rounded_right":
      return <CollectionContainerRoundedRoundedRight />;
    case "rounded_rounded_alt":
      return <CollectionContainerRoundedRoundedAlt />;
    case "rounded_square_left":
      return <CollectionContainerRoundedSquareLeft />;
    case "rounded_square_right":
      return <CollectionContainerRoundedSquareRight />;
    case "rounded_square_alt":
      return <CollectionContainerRoundedSquareAlt />;
    case "square_rounded_left":
      return <CollectionContainerSquareRoundedLeft />;
    case "square_rounded_right":
      return <CollectionContainerSquareRoundedRight />;
    case "square_rounded_alt":
      return <CollectionContainerSquareRoundedAlt />;
    case "square_square_left":
      return <CollectionContainerSquareSquareLeft />;
    case "square_square_right":
      return <CollectionContainerSquareSquareRight />;
    case "square_square_alt":
      return <CollectionContainerSquareSquareAlt />;
    default:
      return <CollectionContainerRoundedRoundedLeft />;
  }
};

// Container Rounded / Image Rounded / Position
const CollectionContainerRoundedRoundedLeft = (props) => {
  return (
    <div className="CollectionContainerRoundedRoundedLeft">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7496)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7496)">
            <rect x="17" y="19" width="346" height="159" rx="14" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7496)">
            <rect
              x="17"
              y="193"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter2_d_76_7496)">
            <rect
              x="17"
              y="367"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_76_7496)">
            <rect
              x="17"
              y="541"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <mask
            id="mask0_76_7496"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="42"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="98.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7496)">
            <rect x="39" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 116.267C64.6033 116.704 64.0477 116.593 63.2821 115.776C63.0783 115.554 62.8993 115.302 62.7264 115.057C62.2325 114.368 61.9732 114.239 61.2632 114.694C59.5901 115.782 58.1145 115.69 56.7007 114.239C56.0092 113.533 55.0707 113.262 54.1014 113.145C53.0827 113.029 52.1443 112.789 51.4467 111.947C51.033 111.455 50.4156 111.511 49.8599 111.621C49.0079 111.793 48.2053 111.707 47.4521 111.296C46.4952 110.767 45.6185 110.896 44.7665 111.511C44.6059 111.627 44.4454 111.732 44.2787 111.836C43.0378 112.629 42.3339 112.488 41.4819 111.271C40.8275 110.331 39.9446 110.06 39 110.435V106.41C40.0619 106.766 41.1671 106.908 42.2845 106.859C44.0132 106.779 45.495 107.233 46.7545 108.438C47.4089 109.065 48.193 109.268 49.1067 109.016C50.1563 108.727 51.0268 109.01 51.6874 109.913C52.1752 110.57 52.8296 110.988 53.6878 110.951C55.398 110.878 56.8365 111.492 58.0528 112.654C58.6208 113.195 59.269 113.588 60.0902 113.526C61.9176 113.385 63.4364 113.901 64.486 115.475C64.7823 115.886 65.2948 115.985 65.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0567 122.696L80.0506 123.961C79.5258 124.134 79.0442 124.441 78.4824 124.49C76.4573 124.343 74.494 123.863 72.5431 123.359C66.1962 121.718 59.6827 122.696 53.2001 122.696C49.4896 122.358 45.2172 122.542 41.4943 122.696C41.4943 122.696 40.0558 122.622 39.0124 122.696V110.441C39.957 110.067 40.8399 110.337 41.4943 111.277C42.3463 112.494 43.044 112.635 44.2911 111.843C44.4578 111.738 44.6184 111.634 44.7789 111.517C45.6309 110.902 46.5076 110.773 47.4645 111.302C48.2178 111.714 49.0204 111.793 49.8724 111.628C50.428 111.517 51.0454 111.462 51.4591 111.953C52.1567 112.795 53.0952 113.035 54.1139 113.152C55.0832 113.262 56.0216 113.533 56.7131 114.245C58.1269 115.696 59.6025 115.788 61.2756 114.7C61.9795 114.239 62.2449 114.375 62.7388 115.063C62.9179 115.309 63.0908 115.561 63.2945 115.782C64.0601 116.605 64.6157 116.71 65.6838 116.273C66.0913 116.31 66.4926 116.353 66.9124 116.335C67.7891 116.31 68.3139 116.876 68.6103 117.613C68.8819 118.302 69.3141 118.64 70.0735 118.646C70.8205 118.652 71.4503 119.027 71.9936 119.506C72.9011 120.299 73.9631 120.668 75.1546 120.686C76.1054 120.698 76.9327 121.012 77.6736 121.595C78.3712 122.149 79.1306 122.609 80.0567 122.696Z"
              fill="#66646F"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V116.205C51.2464 117.968 73.1866 120.991 96.359 114.902C118.48 109.084 137.779 105.413 152 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V120.817C51.2464 122.58 75.0945 134.139 96.359 123.208C118.913 111.606 137.779 105.413 152 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 117.312C134.64 116.619 134.644 115.93 134.644 115.237C134.659 114.728 134.674 114.216 134.689 113.708L134.659 113.678C135.495 113.915 136.339 113.862 137.182 113.723C137.197 114.559 137.212 115.391 137.228 116.228C137.216 117.467 137.209 118.702 137.186 119.942C137.171 120.781 136.986 121.565 135.947 121.576C134.907 121.588 134.704 120.812 134.681 119.968C134.659 119.079 134.655 118.198 134.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 113.708C130.79 112.849 128.105 109.93 128.278 106.174C128.538 100.63 130.067 95.4017 133.077 90.6707C134.975 91.5333 136.018 93.168 136.881 94.9459C138.192 97.6504 139.02 100.513 139.559 103.462C139.819 104.89 140.166 106.321 139.898 107.794C139.348 110.819 137.499 112.691 134.655 113.678L134.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 113.674C137.502 112.687 139.352 110.815 139.905 107.79C140.173 106.321 139.826 104.886 139.566 103.459C139.028 100.513 138.199 97.6466 136.888 94.9421C136.026 93.1643 134.986 91.5295 133.084 90.667C134.884 88.1923 136.636 88.0567 138.523 90.2828C138.979 90.8214 139.397 91.4128 139.728 92.038C141.423 95.2322 142.493 98.6485 143.194 102.182C143.375 103.086 143.506 104.001 143.661 104.912C143.774 107.327 143.326 109.553 141.589 111.384C140.873 112.141 140 112.664 139.145 113.222C138.493 113.387 137.841 113.553 137.186 113.719C136.342 113.858 135.498 113.911 134.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 127.595C109.439 126.325 109.446 125.061 109.446 123.79C109.474 122.858 109.501 121.918 109.529 120.986L109.474 120.931C111.007 121.366 112.554 121.269 114.101 121.014C114.129 122.547 114.156 124.073 114.184 125.606C114.163 127.878 114.149 130.144 114.108 132.416C114.08 133.956 113.742 135.392 111.836 135.413C109.93 135.434 109.557 134.011 109.515 132.464C109.474 130.834 109.467 129.218 109.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 120.986C102.381 119.411 97.4568 114.059 97.7745 107.174C98.2511 97.0078 101.055 87.422 106.573 78.7478C110.054 80.3293 111.967 83.3266 113.549 86.5863C115.952 91.545 117.471 96.7937 118.459 102.201C118.936 104.819 119.571 107.443 119.081 110.143C118.072 115.689 114.681 119.121 109.467 120.931L109.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 120.924C114.688 119.114 118.079 115.682 119.094 110.136C119.585 107.443 118.949 104.812 118.473 102.194C117.485 96.7936 115.966 91.538 113.563 86.5794C111.981 83.3197 110.075 80.3224 106.587 78.7409C109.888 74.2035 113.1 73.9549 116.56 78.0364C117.396 79.024 118.162 80.1083 118.77 81.2547C121.878 87.1112 123.839 93.3751 125.124 99.8531C125.455 101.511 125.697 103.189 125.98 104.86C126.187 109.287 125.365 113.368 122.182 116.725C120.869 118.113 119.267 119.073 117.699 120.095C116.505 120.399 115.31 120.703 114.108 121.007C112.561 121.262 111.014 121.359 109.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 74.4687C55.6849 75.1077 54.622 75.7292 53.8696 76.7533C53.5765 77.1516 53.1522 77.0597 52.7367 77.0115C50.4971 76.7358 48.4587 78.0838 47.8026 80.2677C47.5051 81.2612 47.6364 81.4581 48.6687 81.4625C53.7865 81.4669 58.9043 81.4625 64.0222 81.4712C64.4946 81.4712 64.967 81.5238 65.435 81.55C67.3028 81.5238 69.1662 81.48 71.034 81.4888C71.7601 81.4931 71.9876 81.2656 71.8214 80.5259C71.3184 78.2851 69.1662 76.7227 66.8829 77.0072C66.4017 77.0684 65.9949 77.1209 65.6013 76.6745C63.3179 74.0617 60.4922 73.344 57.1984 74.3593C57.0497 74.3987 56.8966 74.4294 56.7391 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 74.355C88.4171 74.3593 95.534 74.3637 102.655 74.3812C103.39 74.3812 103.499 74.0749 103.355 73.4359C102.918 71.4883 101.22 70.1403 99.1164 70.2322C98.3553 70.2673 97.8435 70.0747 97.3055 69.5145C94.5541 66.6522 90.1668 66.6653 87.3848 69.5276C86.8774 70.0528 86.3962 70.3504 85.657 70.2235C84.7821 70.0747 83.986 70.4817 83.1549 70.6611C82.1532 71.6984 81.1122 72.7181 81.2959 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 57.317C74.7127 56.0959 72.32 55.8858 69.9317 57.2645C68.0026 58.3805 66.9091 60.0786 66.5285 62.2712C66.4061 62.9846 66.6116 63.2472 67.3465 63.2385C70.1329 63.2166 72.9193 63.2297 75.7013 63.2297C78.2952 63.2297 80.8934 63.2341 83.4873 63.2253C84.5065 63.221 84.629 63.0284 84.3753 62.0218C84.161 61.1683 83.6579 60.4812 83.1724 59.7766C82.2582 59.0851 81.3002 58.398 80.1061 58.468C78.8857 58.5424 77.884 58.2492 77.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask1_76_7496"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="216"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="272.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask1_76_7496)">
            <rect x="39" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 290.267C64.6033 290.704 64.0477 290.593 63.2821 289.776C63.0783 289.554 62.8993 289.302 62.7264 289.057C62.2325 288.368 61.9732 288.239 61.2632 288.694C59.5901 289.782 58.1145 289.69 56.7007 288.239C56.0092 287.533 55.0707 287.262 54.1014 287.145C53.0827 287.029 52.1443 286.789 51.4467 285.947C51.033 285.455 50.4156 285.511 49.8599 285.621C49.0079 285.793 48.2053 285.707 47.4521 285.296C46.4952 284.767 45.6185 284.896 44.7665 285.511C44.6059 285.627 44.4454 285.732 44.2787 285.836C43.0378 286.629 42.3339 286.488 41.4819 285.271C40.8275 284.331 39.9446 284.06 39 284.435V280.41C40.0619 280.766 41.1671 280.908 42.2845 280.859C44.0132 280.779 45.495 281.233 46.7545 282.438C47.4089 283.065 48.193 283.268 49.1067 283.016C50.1563 282.727 51.0268 283.01 51.6874 283.913C52.1752 284.57 52.8296 284.988 53.6878 284.951C55.398 284.878 56.8365 285.492 58.0528 286.654C58.6208 287.195 59.269 287.588 60.0902 287.526C61.9176 287.385 63.4364 287.901 64.486 289.475C64.7823 289.886 65.2948 289.985 65.6714 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0567 296.696L80.0506 297.961C79.5258 298.134 79.0442 298.441 78.4824 298.49C76.4573 298.343 74.494 297.863 72.5431 297.359C66.1962 295.718 59.6827 296.696 53.2001 296.696C49.4896 296.358 45.2172 296.542 41.4943 296.696C41.4943 296.696 40.0558 296.622 39.0124 296.696V284.441C39.957 284.067 40.8399 284.337 41.4943 285.277C42.3463 286.494 43.044 286.635 44.2911 285.843C44.4578 285.738 44.6184 285.634 44.7789 285.517C45.6309 284.902 46.5076 284.773 47.4645 285.302C48.2178 285.714 49.0204 285.793 49.8724 285.628C50.428 285.517 51.0454 285.462 51.4591 285.953C52.1567 286.795 53.0952 287.035 54.1139 287.152C55.0832 287.262 56.0216 287.533 56.7131 288.245C58.1269 289.696 59.6025 289.788 61.2756 288.7C61.9795 288.239 62.2449 288.375 62.7388 289.063C62.9179 289.309 63.0908 289.561 63.2945 289.782C64.0601 290.605 64.6157 290.71 65.6838 290.273C66.0913 290.31 66.4926 290.353 66.9124 290.335C67.7891 290.31 68.3139 290.876 68.6103 291.613C68.8819 292.302 69.3141 292.64 70.0735 292.646C70.8205 292.652 71.4503 293.027 71.9936 293.506C72.9011 294.299 73.9631 294.668 75.1546 294.686C76.1054 294.698 76.9327 295.012 77.6736 295.595C78.3712 296.149 79.1306 296.609 80.0567 296.696Z"
              fill="#66646F"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V290.205C51.2464 291.968 73.1866 294.991 96.359 288.902C118.48 283.084 137.779 279.413 152 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V294.817C51.2464 296.58 75.0945 308.139 96.359 297.208C118.913 285.606 137.779 279.413 152 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 291.312C134.64 290.619 134.644 289.93 134.644 289.237C134.659 288.728 134.674 288.216 134.689 287.708L134.659 287.678C135.495 287.915 136.339 287.862 137.182 287.723C137.197 288.559 137.212 289.391 137.228 290.228C137.216 291.467 137.209 292.702 137.186 293.942C137.171 294.781 136.986 295.565 135.947 295.576C134.907 295.588 134.704 294.812 134.681 293.968C134.659 293.079 134.655 292.198 134.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 287.708C130.79 286.849 128.105 283.93 128.278 280.174C128.538 274.63 130.067 269.402 133.077 264.671C134.975 265.533 136.018 267.168 136.881 268.946C138.192 271.65 139.02 274.513 139.559 277.462C139.819 278.89 140.166 280.321 139.898 281.794C139.348 284.819 137.499 286.691 134.655 287.678L134.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 287.674C137.502 286.687 139.352 284.815 139.905 281.79C140.173 280.321 139.826 278.886 139.566 277.459C139.028 274.513 138.199 271.647 136.888 268.942C136.026 267.164 134.986 265.53 133.084 264.667C134.884 262.192 136.636 262.057 138.523 264.283C138.979 264.821 139.397 265.413 139.728 266.038C141.423 269.232 142.493 272.649 143.194 276.182C143.375 277.086 143.506 278.001 143.661 278.912C143.774 281.327 143.326 283.553 141.589 285.384C140.873 286.141 140 286.664 139.145 287.222C138.493 287.387 137.841 287.553 137.186 287.719C136.342 287.858 135.498 287.911 134.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 301.595C109.439 300.325 109.446 299.061 109.446 297.79C109.474 296.858 109.501 295.918 109.529 294.986L109.474 294.931C111.007 295.366 112.554 295.269 114.101 295.014C114.129 296.547 114.156 298.073 114.184 299.606C114.163 301.878 114.149 304.144 114.108 306.416C114.08 307.956 113.742 309.392 111.836 309.413C109.93 309.434 109.557 308.011 109.515 306.464C109.474 304.834 109.467 303.218 109.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 294.986C102.381 293.411 97.4568 288.059 97.7745 281.174C98.2511 271.008 101.055 261.422 106.573 252.748C110.054 254.329 111.967 257.327 113.549 260.586C115.952 265.545 117.471 270.794 118.459 276.201C118.936 278.819 119.571 281.443 119.081 284.143C118.072 289.689 114.681 293.121 109.467 294.931L109.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 294.924C114.688 293.114 118.079 289.682 119.094 284.136C119.585 281.443 118.949 278.812 118.473 276.194C117.485 270.794 115.966 265.538 113.563 260.579C111.981 257.32 110.075 254.322 106.587 252.741C109.888 248.203 113.1 247.955 116.56 252.036C117.396 253.024 118.162 254.108 118.77 255.255C121.878 261.111 123.839 267.375 125.124 273.853C125.455 275.511 125.697 277.189 125.98 278.86C126.187 283.287 125.365 287.368 122.182 290.725C120.869 292.113 119.267 293.073 117.699 294.095C116.505 294.399 115.31 294.703 114.108 295.007C112.561 295.262 111.014 295.359 109.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 248.469C55.6849 249.108 54.622 249.729 53.8696 250.753C53.5765 251.152 53.1522 251.06 52.7367 251.012C50.4971 250.736 48.4587 252.084 47.8026 254.268C47.5051 255.261 47.6364 255.458 48.6687 255.462C53.7865 255.467 58.9043 255.462 64.0222 255.471C64.4946 255.471 64.967 255.524 65.435 255.55C67.3028 255.524 69.1662 255.48 71.034 255.489C71.7601 255.493 71.9876 255.266 71.8214 254.526C71.3184 252.285 69.1662 250.723 66.8829 251.007C66.4017 251.068 65.9949 251.121 65.6013 250.675C63.3179 248.062 60.4922 247.344 57.1984 248.359C57.0497 248.399 56.8966 248.429 56.7391 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 248.355C88.4171 248.359 95.534 248.364 102.655 248.381C103.39 248.381 103.499 248.075 103.355 247.436C102.918 245.488 101.22 244.14 99.1164 244.232C98.3553 244.267 97.8435 244.075 97.3055 243.514C94.5541 240.652 90.1668 240.665 87.3848 243.528C86.8774 244.053 86.3962 244.35 85.657 244.223C84.7821 244.075 83.986 244.482 83.1549 244.661C82.1532 245.698 81.1122 246.718 81.2959 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 231.317C74.7127 230.096 72.32 229.886 69.9317 231.264C68.0026 232.38 66.9091 234.079 66.5285 236.271C66.4061 236.985 66.6116 237.247 67.3465 237.238C70.1329 237.217 72.9193 237.23 75.7013 237.23C78.2952 237.23 80.8934 237.234 83.4873 237.225C84.5065 237.221 84.629 237.028 84.3753 236.022C84.161 235.168 83.6579 234.481 83.1724 233.777C82.2582 233.085 81.3002 232.398 80.1061 232.468C78.8857 232.542 77.884 232.249 77.0879 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask2_76_7496"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="390"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="446.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask2_76_7496)">
            <rect x="39" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 464.267C64.6033 464.704 64.0477 464.593 63.2821 463.776C63.0783 463.554 62.8993 463.302 62.7264 463.057C62.2325 462.368 61.9732 462.239 61.2632 462.694C59.5901 463.782 58.1145 463.69 56.7007 462.239C56.0092 461.533 55.0707 461.262 54.1014 461.145C53.0827 461.029 52.1443 460.789 51.4467 459.947C51.033 459.455 50.4156 459.511 49.8599 459.621C49.0079 459.793 48.2053 459.707 47.4521 459.296C46.4952 458.767 45.6185 458.896 44.7665 459.511C44.6059 459.627 44.4454 459.732 44.2787 459.836C43.0378 460.629 42.3339 460.488 41.4819 459.271C40.8275 458.331 39.9446 458.06 39 458.435V454.41C40.0619 454.766 41.1671 454.908 42.2845 454.859C44.0132 454.779 45.495 455.233 46.7545 456.438C47.4089 457.065 48.193 457.268 49.1067 457.016C50.1563 456.727 51.0268 457.01 51.6874 457.913C52.1752 458.57 52.8296 458.988 53.6878 458.951C55.398 458.878 56.8365 459.492 58.0528 460.654C58.6208 461.195 59.269 461.588 60.0902 461.526C61.9176 461.385 63.4364 461.901 64.486 463.475C64.7823 463.886 65.2948 463.985 65.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0567 470.696L80.0506 471.961C79.5258 472.134 79.0442 472.441 78.4824 472.49C76.4573 472.343 74.494 471.863 72.5431 471.359C66.1962 469.718 59.6827 470.696 53.2001 470.696C49.4896 470.358 45.2172 470.542 41.4943 470.696C41.4943 470.696 40.0558 470.622 39.0124 470.696V458.441C39.957 458.067 40.8399 458.337 41.4943 459.277C42.3463 460.494 43.044 460.635 44.2911 459.843C44.4578 459.738 44.6184 459.634 44.7789 459.517C45.6309 458.902 46.5076 458.773 47.4645 459.302C48.2178 459.714 49.0204 459.793 49.8724 459.628C50.428 459.517 51.0454 459.462 51.4591 459.953C52.1567 460.795 53.0952 461.035 54.1139 461.152C55.0832 461.262 56.0216 461.533 56.7131 462.245C58.1269 463.696 59.6025 463.788 61.2756 462.7C61.9795 462.239 62.2449 462.375 62.7388 463.063C62.9179 463.309 63.0908 463.561 63.2945 463.782C64.0601 464.605 64.6157 464.71 65.6838 464.273C66.0913 464.31 66.4926 464.353 66.9124 464.335C67.7891 464.31 68.3139 464.876 68.6103 465.613C68.8819 466.302 69.3141 466.64 70.0735 466.646C70.8205 466.652 71.4503 467.027 71.9936 467.506C72.9011 468.299 73.9631 468.668 75.1546 468.686C76.1054 468.698 76.9327 469.012 77.6736 469.595C78.3712 470.149 79.1306 470.609 80.0567 470.696Z"
              fill="#66646F"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V464.205C51.2464 465.968 73.1866 468.991 96.359 462.902C118.48 457.084 137.779 453.413 152 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V468.817C51.2464 470.58 75.0945 482.139 96.359 471.208C118.913 459.606 137.779 453.413 152 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 465.312C134.64 464.619 134.644 463.93 134.644 463.237C134.659 462.728 134.674 462.216 134.689 461.708L134.659 461.678C135.495 461.915 136.339 461.862 137.182 461.723C137.197 462.559 137.212 463.391 137.228 464.228C137.216 465.467 137.209 466.702 137.186 467.942C137.171 468.781 136.986 469.565 135.947 469.576C134.907 469.588 134.704 468.812 134.681 467.968C134.659 467.079 134.655 466.198 134.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 461.708C130.79 460.849 128.105 457.93 128.278 454.174C128.538 448.63 130.067 443.402 133.077 438.671C134.975 439.533 136.018 441.168 136.881 442.946C138.192 445.65 139.02 448.513 139.559 451.462C139.819 452.89 140.166 454.321 139.898 455.794C139.348 458.819 137.499 460.691 134.655 461.678L134.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 461.674C137.502 460.687 139.352 458.815 139.905 455.79C140.173 454.321 139.826 452.886 139.566 451.459C139.028 448.513 138.199 445.647 136.888 442.942C136.026 441.164 134.986 439.53 133.084 438.667C134.884 436.192 136.636 436.057 138.523 438.283C138.979 438.821 139.397 439.413 139.728 440.038C141.423 443.232 142.493 446.649 143.194 450.182C143.375 451.086 143.506 452.001 143.661 452.912C143.774 455.327 143.326 457.553 141.589 459.384C140.873 460.141 140 460.664 139.145 461.222C138.493 461.387 137.841 461.553 137.186 461.719C136.342 461.858 135.498 461.911 134.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 475.595C109.439 474.325 109.446 473.061 109.446 471.79C109.474 470.858 109.501 469.918 109.529 468.986L109.474 468.931C111.007 469.366 112.554 469.269 114.101 469.014C114.129 470.547 114.156 472.073 114.184 473.606C114.163 475.878 114.149 478.144 114.108 480.416C114.08 481.956 113.742 483.392 111.836 483.413C109.93 483.434 109.557 482.011 109.515 480.464C109.474 478.834 109.467 477.218 109.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 468.986C102.381 467.411 97.4568 462.059 97.7745 455.174C98.2511 445.008 101.055 435.422 106.573 426.748C110.054 428.329 111.967 431.327 113.549 434.586C115.952 439.545 117.471 444.794 118.459 450.201C118.936 452.819 119.571 455.443 119.081 458.143C118.072 463.689 114.681 467.121 109.467 468.931L109.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 468.924C114.688 467.114 118.079 463.682 119.094 458.136C119.585 455.443 118.949 452.812 118.473 450.194C117.485 444.794 115.966 439.538 113.563 434.579C111.981 431.32 110.075 428.322 106.587 426.741C109.888 422.203 113.1 421.955 116.56 426.036C117.396 427.024 118.162 428.108 118.77 429.255C121.878 435.111 123.839 441.375 125.124 447.853C125.455 449.511 125.697 451.189 125.98 452.86C126.187 457.287 125.365 461.368 122.182 464.725C120.869 466.113 119.267 467.073 117.699 468.095C116.505 468.399 115.31 468.703 114.108 469.007C112.561 469.262 111.014 469.359 109.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 422.469C55.6849 423.108 54.622 423.729 53.8696 424.753C53.5765 425.152 53.1522 425.06 52.7367 425.012C50.4971 424.736 48.4587 426.084 47.8026 428.268C47.5051 429.261 47.6364 429.458 48.6687 429.462C53.7865 429.467 58.9043 429.462 64.0222 429.471C64.4946 429.471 64.967 429.524 65.435 429.55C67.3028 429.524 69.1662 429.48 71.034 429.489C71.7601 429.493 71.9876 429.266 71.8214 428.526C71.3184 426.285 69.1662 424.723 66.8829 425.007C66.4017 425.068 65.9949 425.121 65.6013 424.675C63.3179 422.062 60.4922 421.344 57.1984 422.359C57.0497 422.399 56.8966 422.429 56.7391 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 422.355C88.4171 422.359 95.534 422.364 102.655 422.381C103.39 422.381 103.499 422.075 103.355 421.436C102.918 419.488 101.22 418.14 99.1164 418.232C98.3553 418.267 97.8435 418.075 97.3055 417.514C94.5541 414.652 90.1668 414.665 87.3848 417.528C86.8774 418.053 86.3962 418.35 85.657 418.223C84.7821 418.075 83.986 418.482 83.1549 418.661C82.1532 419.698 81.1122 420.718 81.2959 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 405.317C74.7127 404.096 72.32 403.886 69.9317 405.264C68.0026 406.38 66.9091 408.079 66.5285 410.271C66.4061 410.985 66.6116 411.247 67.3465 411.238C70.1329 411.217 72.9193 411.23 75.7013 411.23C78.2952 411.23 80.8934 411.234 83.4873 411.225C84.5065 411.221 84.629 411.028 84.3753 410.022C84.161 409.168 83.6579 408.481 83.1724 407.777C82.2582 407.085 81.3002 406.398 80.1061 406.468C78.8857 406.542 77.884 406.249 77.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask3_76_7496"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="564"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="620.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask3_76_7496)">
            <rect x="39" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 638.267C64.6033 638.704 64.0477 638.593 63.2821 637.776C63.0783 637.554 62.8993 637.303 62.7264 637.057C62.2325 636.368 61.9732 636.239 61.2632 636.694C59.5901 637.782 58.1145 637.69 56.7007 636.239C56.0092 635.533 55.0707 635.262 54.1014 635.145C53.0827 635.029 52.1443 634.789 51.4467 633.947C51.033 633.455 50.4156 633.511 49.8599 633.621C49.0079 633.793 48.2053 633.707 47.4521 633.296C46.4952 632.767 45.6185 632.896 44.7665 633.511C44.6059 633.628 44.4454 633.732 44.2787 633.836C43.0378 634.629 42.3339 634.488 41.4819 633.271C40.8275 632.331 39.9446 632.06 39 632.435V628.41C40.0619 628.766 41.1671 628.908 42.2845 628.859C44.0132 628.779 45.495 629.234 46.7545 630.438C47.4089 631.065 48.193 631.268 49.1067 631.016C50.1563 630.727 51.0268 631.01 51.6874 631.913C52.1752 632.57 52.8296 632.988 53.6878 632.952C55.398 632.878 56.8365 633.492 58.0528 634.654C58.6208 635.195 59.269 635.588 60.0902 635.526C61.9176 635.385 63.4364 635.901 64.486 637.475C64.7823 637.886 65.2948 637.985 65.6714 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0567 644.696L80.0506 645.961C79.5258 646.134 79.0442 646.441 78.4824 646.49C76.4573 646.343 74.494 645.863 72.5431 645.359C66.1962 643.718 59.6827 644.696 53.2001 644.696C49.4896 644.358 45.2172 644.542 41.4943 644.696C41.4943 644.696 40.0558 644.622 39.0124 644.696V632.441C39.957 632.067 40.8399 632.337 41.4943 633.277C42.3463 634.494 43.044 634.635 44.2911 633.843C44.4578 633.738 44.6184 633.634 44.7789 633.517C45.6309 632.902 46.5076 632.773 47.4645 633.302C48.2178 633.714 49.0204 633.793 49.8724 633.628C50.428 633.517 51.0454 633.462 51.4591 633.953C52.1567 634.795 53.0952 635.035 54.1139 635.152C55.0832 635.262 56.0216 635.533 56.7131 636.245C58.1269 637.696 59.6025 637.788 61.2756 636.7C61.9795 636.239 62.2449 636.375 62.7388 637.063C62.9179 637.309 63.0908 637.561 63.2945 637.782C64.0601 638.605 64.6157 638.71 65.6838 638.273C66.0913 638.31 66.4926 638.353 66.9124 638.335C67.7891 638.31 68.3139 638.876 68.6103 639.613C68.8819 640.302 69.3141 640.64 70.0735 640.646C70.8205 640.652 71.4503 641.027 71.9936 641.506C72.9011 642.299 73.9631 642.668 75.1546 642.686C76.1054 642.698 76.9327 643.012 77.6736 643.595C78.3712 644.149 79.1306 644.609 80.0567 644.696Z"
              fill="#66646F"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V638.205C51.2464 639.968 73.1866 642.991 96.359 636.902C118.48 631.084 137.779 627.413 152 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V642.817C51.2464 644.58 75.0945 656.139 96.359 645.208C118.913 633.606 137.779 627.413 152 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 639.312C134.64 638.619 134.644 637.93 134.644 637.237C134.659 636.728 134.674 636.216 134.689 635.708L134.659 635.678C135.495 635.915 136.339 635.862 137.182 635.723C137.197 636.559 137.212 637.391 137.228 638.228C137.216 639.467 137.209 640.702 137.186 641.942C137.171 642.782 136.986 643.565 135.947 643.576C134.907 643.588 134.704 642.812 134.681 641.968C134.659 641.079 134.655 640.198 134.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 635.708C130.79 634.849 128.105 631.93 128.278 628.174C128.538 622.63 130.067 617.402 133.077 612.671C134.975 613.533 136.018 615.168 136.881 616.946C138.192 619.65 139.02 622.513 139.559 625.462C139.819 626.89 140.166 628.321 139.898 629.794C139.348 632.819 137.499 634.691 134.655 635.678L134.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 635.674C137.502 634.687 139.352 632.815 139.905 629.79C140.173 628.321 139.826 626.886 139.566 625.459C139.028 622.513 138.199 619.647 136.888 616.942C136.026 615.164 134.986 613.53 133.084 612.667C134.884 610.192 136.636 610.057 138.523 612.283C138.979 612.821 139.397 613.413 139.728 614.038C141.423 617.232 142.493 620.649 143.194 624.182C143.375 625.086 143.506 626.001 143.661 626.912C143.774 629.327 143.326 631.553 141.589 633.384C140.873 634.141 140 634.664 139.145 635.222C138.493 635.387 137.841 635.553 137.186 635.719C136.342 635.858 135.498 635.911 134.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 649.595C109.439 648.325 109.446 647.061 109.446 645.79C109.474 644.858 109.501 643.918 109.529 642.986L109.474 642.931C111.007 643.366 112.554 643.269 114.101 643.014C114.129 644.547 114.156 646.073 114.184 647.606C114.163 649.878 114.149 652.144 114.108 654.416C114.08 655.956 113.742 657.392 111.836 657.413C109.93 657.434 109.557 656.011 109.515 654.464C109.474 652.834 109.467 651.218 109.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 642.986C102.381 641.411 97.4568 636.059 97.7745 629.174C98.2511 619.008 101.055 609.422 106.573 600.748C110.054 602.329 111.967 605.327 113.549 608.586C115.952 613.545 117.471 618.794 118.459 624.201C118.936 626.819 119.571 629.443 119.081 632.143C118.072 637.689 114.681 641.121 109.467 642.931L109.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 642.924C114.688 641.114 118.079 637.682 119.094 632.136C119.585 629.443 118.949 626.812 118.473 624.194C117.485 618.794 115.966 613.538 113.563 608.579C111.981 605.32 110.075 602.322 106.587 600.741C109.888 596.203 113.1 595.955 116.56 600.036C117.396 601.024 118.162 602.108 118.77 603.255C121.878 609.111 123.839 615.375 125.124 621.853C125.455 623.511 125.697 625.189 125.98 626.86C126.187 631.287 125.365 635.368 122.182 638.725C120.869 640.113 119.267 641.073 117.699 642.095C116.505 642.399 115.31 642.703 114.108 643.007C112.561 643.262 111.014 643.359 109.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 596.469C55.6849 597.108 54.622 597.729 53.8696 598.753C53.5765 599.152 53.1522 599.06 52.7367 599.011C50.4971 598.736 48.4587 600.084 47.8026 602.268C47.5051 603.261 47.6364 603.458 48.6687 603.462C53.7865 603.467 58.9043 603.462 64.0222 603.471C64.4946 603.471 64.967 603.524 65.435 603.55C67.3028 603.524 69.1662 603.48 71.034 603.489C71.7601 603.493 71.9876 603.266 71.8214 602.526C71.3184 600.285 69.1662 598.723 66.8829 599.007C66.4017 599.068 65.9949 599.121 65.6013 598.675C63.3179 596.062 60.4922 595.344 57.1984 596.359C57.0497 596.399 56.8966 596.429 56.7391 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 596.355C88.4171 596.359 95.534 596.364 102.655 596.381C103.39 596.381 103.499 596.075 103.355 595.436C102.918 593.488 101.22 592.14 99.1164 592.232C98.3553 592.267 97.8435 592.075 97.3055 591.514C94.5541 588.652 90.1668 588.665 87.3848 591.528C86.8774 592.053 86.3962 592.35 85.657 592.223C84.7821 592.075 83.986 592.482 83.1549 592.661C82.1532 593.698 81.1122 594.718 81.2959 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 579.317C74.7127 578.096 72.32 577.886 69.9317 579.264C68.0026 580.38 66.9091 582.079 66.5285 584.271C66.4061 584.985 66.6116 585.247 67.3465 585.238C70.1329 585.217 72.9193 585.23 75.7013 585.23C78.2952 585.23 80.8934 585.234 83.4873 585.225C84.5065 585.221 84.629 585.028 84.3753 584.022C84.161 583.168 83.6579 582.481 83.1724 581.777C82.2582 581.085 81.3002 580.398 80.1061 580.468C78.8857 580.542 77.884 580.249 77.0879 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="175" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="260" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="609" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7496"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7496"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7496"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7496"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7496"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7496"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7496"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7496"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7496"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7496"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7496"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7496"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7496">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerRoundedRoundedRight = (props) => {
  return (
    <div className="CollectionContainerRoundedRoundedRight">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7497)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7497)">
            <rect x="17" y="19" width="346" height="159" rx="14" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7497)">
            <rect
              x="17"
              y="193"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter2_d_76_7497)">
            <rect
              x="17"
              y="367"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_76_7497)">
            <rect
              x="17"
              y="541"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <mask
            id="mask0_76_7497"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="42"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="98.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7497)">
            <rect x="226" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 116.267C251.603 116.704 251.048 116.593 250.282 115.776C250.078 115.554 249.899 115.302 249.726 115.057C249.233 114.368 248.973 114.239 248.263 114.694C246.59 115.782 245.114 115.69 243.701 114.239C243.009 113.533 242.071 113.262 241.101 113.145C240.083 113.029 239.144 112.789 238.447 111.947C238.033 111.455 237.416 111.511 236.86 111.621C236.008 111.793 235.205 111.707 234.452 111.296C233.495 110.767 232.618 110.896 231.766 111.511C231.606 111.627 231.445 111.732 231.279 111.836C230.038 112.629 229.334 112.488 228.482 111.271C227.827 110.331 226.945 110.06 226 110.435V106.41C227.062 106.766 228.167 106.908 229.285 106.859C231.013 106.779 232.495 107.233 233.754 108.438C234.409 109.065 235.193 109.268 236.107 109.016C237.156 108.727 238.027 109.01 238.687 109.913C239.175 110.57 239.83 110.988 240.688 110.951C242.398 110.878 243.836 111.492 245.053 112.654C245.621 113.195 246.269 113.588 247.09 113.526C248.918 113.385 250.436 113.901 251.486 115.475C251.782 115.886 252.295 115.985 252.671 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 122.696L267.051 123.961C266.526 124.134 266.044 124.441 265.482 124.49C263.457 124.343 261.494 123.863 259.543 123.359C253.196 121.718 246.683 122.696 240.2 122.696C236.49 122.358 232.217 122.542 228.494 122.696C228.494 122.696 227.056 122.622 226.012 122.696V110.441C226.957 110.067 227.84 110.337 228.494 111.277C229.346 112.494 230.044 112.635 231.291 111.843C231.458 111.738 231.618 111.634 231.779 111.517C232.631 110.902 233.508 110.773 234.465 111.302C235.218 111.714 236.02 111.793 236.872 111.628C237.428 111.517 238.045 111.462 238.459 111.953C239.157 112.795 240.095 113.035 241.114 113.152C242.083 113.262 243.022 113.533 243.713 114.245C245.127 115.696 246.603 115.788 248.276 114.7C248.979 114.239 249.245 114.375 249.739 115.063C249.918 115.309 250.091 115.561 250.295 115.782C251.06 116.605 251.616 116.71 252.684 116.273C253.091 116.31 253.493 116.353 253.912 116.335C254.789 116.31 255.314 116.876 255.61 117.613C255.882 118.302 256.314 118.64 257.074 118.646C257.821 118.652 258.45 119.027 258.994 119.506C259.901 120.299 260.963 120.668 262.155 120.686C263.105 120.698 263.933 121.012 264.674 121.595C265.371 122.149 266.131 122.609 267.057 122.696Z"
              fill="#66646F"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V116.205C238.246 117.968 260.187 120.991 283.359 114.902C305.48 109.084 324.779 105.413 339 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V120.817C238.246 122.58 262.094 134.139 283.359 123.208C305.913 111.606 324.779 105.413 339 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 117.312C321.64 116.619 321.644 115.93 321.644 115.237C321.659 114.728 321.674 114.216 321.689 113.708L321.659 113.678C322.495 113.915 323.339 113.862 324.182 113.723C324.197 114.559 324.213 115.391 324.228 116.228C324.216 117.467 324.209 118.702 324.186 119.942C324.171 120.781 323.987 121.565 322.947 121.576C321.907 121.588 321.704 120.812 321.681 119.968C321.659 119.079 321.655 118.198 321.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 113.708C317.79 112.849 315.105 109.93 315.278 106.174C315.538 100.63 317.067 95.4017 320.077 90.6707C321.975 91.5333 323.018 93.168 323.881 94.9459C325.192 97.6504 326.02 100.513 326.559 103.462C326.819 104.89 327.166 106.321 326.898 107.794C326.348 110.819 324.499 112.691 321.655 113.678L321.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 113.674C324.502 112.687 326.352 110.815 326.905 107.79C327.173 106.321 326.826 104.886 326.566 103.459C326.028 100.513 325.199 97.6466 323.888 94.9421C323.026 93.1643 321.986 91.5295 320.084 90.667C321.885 88.1923 323.636 88.0567 325.523 90.2828C325.979 90.8214 326.397 91.4128 326.728 92.038C328.423 95.2322 329.493 98.6485 330.194 102.182C330.375 103.086 330.506 104.001 330.661 104.912C330.774 107.327 330.326 109.553 328.589 111.384C327.874 112.141 327 112.664 326.145 113.222C325.493 113.387 324.841 113.553 324.186 113.719C323.342 113.858 322.498 113.911 321.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 127.595C296.439 126.325 296.446 125.061 296.446 123.79C296.474 122.858 296.501 121.918 296.529 120.986L296.474 120.931C298.007 121.366 299.554 121.269 301.101 121.014C301.129 122.547 301.156 124.073 301.184 125.606C301.163 127.878 301.149 130.144 301.108 132.416C301.08 133.956 300.742 135.392 298.836 135.413C296.93 135.434 296.557 134.011 296.515 132.464C296.474 130.834 296.467 129.218 296.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 120.986C289.381 119.411 284.457 114.059 284.775 107.174C285.251 97.0078 288.055 87.422 293.573 78.7478C297.054 80.3293 298.967 83.3266 300.549 86.5863C302.952 91.545 304.471 96.7937 305.459 102.201C305.936 104.819 306.571 107.443 306.081 110.143C305.072 115.689 301.681 119.121 296.467 120.931L296.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 120.924C301.688 119.114 305.079 115.682 306.095 110.136C306.585 107.443 305.949 104.812 305.473 102.194C304.485 96.7936 302.966 91.538 300.563 86.5794C298.981 83.3197 297.075 80.3224 293.587 78.7409C296.888 74.2035 300.1 73.9549 303.56 78.0364C304.396 79.024 305.162 80.1083 305.77 81.2547C308.878 87.1112 310.839 93.3751 312.124 99.8531C312.455 101.511 312.697 103.189 312.98 104.86C313.187 109.287 312.365 113.368 309.182 116.725C307.869 118.113 306.267 119.073 304.699 120.095C303.505 120.399 302.31 120.703 301.108 121.007C299.561 121.262 298.014 121.359 296.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 74.4687C242.685 75.1077 241.622 75.7292 240.87 76.7533C240.577 77.1516 240.152 77.0597 239.737 77.0115C237.497 76.7358 235.459 78.0838 234.803 80.2677C234.505 81.2612 234.636 81.4581 235.669 81.4625C240.786 81.4669 245.904 81.4625 251.022 81.4712C251.495 81.4712 251.967 81.5238 252.435 81.55C254.303 81.5238 256.166 81.48 258.034 81.4888C258.76 81.4931 258.988 81.2656 258.821 80.5259C258.318 78.2851 256.166 76.7227 253.883 77.0072C253.402 77.0684 252.995 77.1209 252.601 76.6745C250.318 74.0617 247.492 73.344 244.198 74.3593C244.05 74.3987 243.897 74.4294 243.739 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 74.355C275.417 74.3593 282.534 74.3637 289.655 74.3812C290.39 74.3812 290.499 74.0749 290.355 73.4359C289.918 71.4883 288.22 70.1403 286.116 70.2322C285.355 70.2673 284.844 70.0747 284.305 69.5145C281.554 66.6522 277.167 66.6653 274.385 69.5276C273.877 70.0528 273.396 70.3504 272.657 70.2235C271.782 70.0747 270.986 70.4817 270.155 70.6611C269.153 71.6984 268.112 72.7181 268.296 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 57.317C261.713 56.0959 259.32 55.8858 256.932 57.2645C255.003 58.3805 253.909 60.0786 253.529 62.2712C253.406 62.9846 253.612 63.2472 254.347 63.2385C257.133 63.2166 259.919 63.2297 262.701 63.2297C265.295 63.2297 267.893 63.2341 270.487 63.2253C271.507 63.221 271.629 63.0284 271.375 62.0218C271.161 61.1683 270.658 60.4812 270.172 59.7766C269.258 59.0851 268.3 58.398 267.106 58.468C265.886 58.5424 264.884 58.2492 264.088 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask1_76_7497"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="216"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="272.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask1_76_7497)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.603 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.074 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.213 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.987 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.905 281.79C327.173 280.321 326.826 278.886 326.566 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.885 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.728 266.038C328.423 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.874 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.498 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.095 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.563 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.396 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.182 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.505 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.786 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.844 244.075 284.305 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask2_76_7497"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="390"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="446.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask2_76_7497)">
            <rect x="226" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 464.267C251.603 464.704 251.048 464.593 250.282 463.776C250.078 463.554 249.899 463.302 249.726 463.057C249.233 462.368 248.973 462.239 248.263 462.694C246.59 463.782 245.114 463.69 243.701 462.239C243.009 461.533 242.071 461.262 241.101 461.145C240.083 461.029 239.144 460.789 238.447 459.947C238.033 459.455 237.416 459.511 236.86 459.621C236.008 459.793 235.205 459.707 234.452 459.296C233.495 458.767 232.618 458.896 231.766 459.511C231.606 459.627 231.445 459.732 231.279 459.836C230.038 460.629 229.334 460.488 228.482 459.271C227.827 458.331 226.945 458.06 226 458.435V454.41C227.062 454.766 228.167 454.908 229.285 454.859C231.013 454.779 232.495 455.233 233.754 456.438C234.409 457.065 235.193 457.268 236.107 457.016C237.156 456.727 238.027 457.01 238.687 457.913C239.175 458.57 239.83 458.988 240.688 458.951C242.398 458.878 243.836 459.492 245.053 460.654C245.621 461.195 246.269 461.588 247.09 461.526C248.918 461.385 250.436 461.901 251.486 463.475C251.782 463.886 252.295 463.985 252.671 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 470.696L267.051 471.961C266.526 472.134 266.044 472.441 265.482 472.49C263.457 472.343 261.494 471.863 259.543 471.359C253.196 469.718 246.683 470.696 240.2 470.696C236.49 470.358 232.217 470.542 228.494 470.696C228.494 470.696 227.056 470.622 226.012 470.696V458.441C226.957 458.067 227.84 458.337 228.494 459.277C229.346 460.494 230.044 460.635 231.291 459.843C231.458 459.738 231.618 459.634 231.779 459.517C232.631 458.902 233.508 458.773 234.465 459.302C235.218 459.714 236.02 459.793 236.872 459.628C237.428 459.517 238.045 459.462 238.459 459.953C239.157 460.795 240.095 461.035 241.114 461.152C242.083 461.262 243.022 461.533 243.713 462.245C245.127 463.696 246.603 463.788 248.276 462.7C248.979 462.239 249.245 462.375 249.739 463.063C249.918 463.309 250.091 463.561 250.295 463.782C251.06 464.605 251.616 464.71 252.684 464.273C253.091 464.31 253.493 464.353 253.912 464.335C254.789 464.31 255.314 464.876 255.61 465.613C255.882 466.302 256.314 466.64 257.074 466.646C257.821 466.652 258.45 467.027 258.994 467.506C259.901 468.299 260.963 468.668 262.155 468.686C263.105 468.698 263.933 469.012 264.674 469.595C265.371 470.149 266.131 470.609 267.057 470.696Z"
              fill="#66646F"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V464.205C238.246 465.968 260.187 468.991 283.359 462.902C305.48 457.084 324.779 453.413 339 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V468.817C238.246 470.58 262.094 482.139 283.359 471.208C305.913 459.606 324.779 453.413 339 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 465.312C321.64 464.619 321.644 463.93 321.644 463.237C321.659 462.728 321.674 462.216 321.689 461.708L321.659 461.678C322.495 461.915 323.339 461.862 324.182 461.723C324.197 462.559 324.213 463.391 324.228 464.228C324.216 465.467 324.209 466.702 324.186 467.942C324.171 468.781 323.987 469.565 322.947 469.576C321.907 469.588 321.704 468.812 321.681 467.968C321.659 467.079 321.655 466.198 321.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 461.708C317.79 460.849 315.105 457.93 315.278 454.174C315.538 448.63 317.067 443.402 320.077 438.671C321.975 439.533 323.018 441.168 323.881 442.946C325.192 445.65 326.02 448.513 326.559 451.462C326.819 452.89 327.166 454.321 326.898 455.794C326.348 458.819 324.499 460.691 321.655 461.678L321.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 461.674C324.502 460.687 326.352 458.815 326.905 455.79C327.173 454.321 326.826 452.886 326.566 451.459C326.028 448.513 325.199 445.647 323.888 442.942C323.026 441.164 321.986 439.53 320.084 438.667C321.885 436.192 323.636 436.057 325.523 438.283C325.979 438.821 326.397 439.413 326.728 440.038C328.423 443.232 329.493 446.649 330.194 450.182C330.375 451.086 330.506 452.001 330.661 452.912C330.774 455.327 330.326 457.553 328.589 459.384C327.874 460.141 327 460.664 326.145 461.222C325.493 461.387 324.841 461.553 324.186 461.719C323.342 461.858 322.498 461.911 321.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 475.595C296.439 474.325 296.446 473.061 296.446 471.79C296.474 470.858 296.501 469.918 296.529 468.986L296.474 468.931C298.007 469.366 299.554 469.269 301.101 469.014C301.129 470.547 301.156 472.073 301.184 473.606C301.163 475.878 301.149 478.144 301.108 480.416C301.08 481.956 300.742 483.392 298.836 483.413C296.93 483.434 296.557 482.011 296.515 480.464C296.474 478.834 296.467 477.218 296.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 468.986C289.381 467.411 284.457 462.059 284.775 455.174C285.251 445.008 288.055 435.422 293.573 426.748C297.054 428.329 298.967 431.327 300.549 434.586C302.952 439.545 304.471 444.794 305.459 450.201C305.936 452.819 306.571 455.443 306.081 458.143C305.072 463.689 301.681 467.121 296.467 468.931L296.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 468.924C301.688 467.114 305.079 463.682 306.095 458.136C306.585 455.443 305.949 452.812 305.473 450.194C304.485 444.794 302.966 439.538 300.563 434.579C298.981 431.32 297.075 428.322 293.587 426.741C296.888 422.203 300.1 421.955 303.56 426.036C304.396 427.024 305.162 428.108 305.77 429.255C308.878 435.111 310.839 441.375 312.124 447.853C312.455 449.511 312.697 451.189 312.98 452.86C313.187 457.287 312.365 461.368 309.182 464.725C307.869 466.113 306.267 467.073 304.699 468.095C303.505 468.399 302.31 468.703 301.108 469.007C299.561 469.262 298.014 469.359 296.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 422.469C242.685 423.108 241.622 423.729 240.87 424.753C240.577 425.152 240.152 425.06 239.737 425.012C237.497 424.736 235.459 426.084 234.803 428.268C234.505 429.261 234.636 429.458 235.669 429.462C240.786 429.467 245.904 429.462 251.022 429.471C251.495 429.471 251.967 429.524 252.435 429.55C254.303 429.524 256.166 429.48 258.034 429.489C258.76 429.493 258.988 429.266 258.821 428.526C258.318 426.285 256.166 424.723 253.883 425.007C253.402 425.068 252.995 425.121 252.601 424.675C250.318 422.062 247.492 421.344 244.198 422.359C244.05 422.399 243.897 422.429 243.739 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 422.355C275.417 422.359 282.534 422.364 289.655 422.381C290.39 422.381 290.499 422.075 290.355 421.436C289.918 419.488 288.22 418.14 286.116 418.232C285.355 418.267 284.844 418.075 284.305 417.514C281.554 414.652 277.167 414.665 274.385 417.528C273.877 418.053 273.396 418.35 272.657 418.223C271.782 418.075 270.986 418.482 270.155 418.661C269.153 419.698 268.112 420.718 268.296 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 405.317C261.713 404.096 259.32 403.886 256.932 405.264C255.003 406.38 253.909 408.079 253.529 410.271C253.406 410.985 253.612 411.247 254.347 411.238C257.133 411.217 259.919 411.23 262.701 411.23C265.295 411.23 267.893 411.234 270.487 411.225C271.507 411.221 271.629 411.028 271.375 410.022C271.161 409.168 270.658 408.481 270.172 407.777C269.258 407.085 268.3 406.398 267.106 406.468C265.886 406.542 264.884 406.249 264.088 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask3_76_7497"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="564"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="620.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask3_76_7497)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.961C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.245C245.127 637.696 246.603 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.273C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.074 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.595C265.371 644.149 266.131 644.609 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.213 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.987 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.905 629.79C327.173 628.321 326.826 626.886 326.566 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.885 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.728 614.038C328.423 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.874 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.498 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.095 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.563 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.396 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.182 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.505 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.786 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.844 592.075 284.305 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="41" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7497"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7497"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7497"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7497"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7497"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7497"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7497"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7497"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7497"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7497"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7497"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7497"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7497">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerRoundedRoundedAlt = (props) => {
  return (
    <div className="CollectionContainerRoundedRoundedAlt">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7498)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7498)">
            <rect x="17" y="19" width="346" height="159" rx="14" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7498)">
            <rect
              x="17"
              y="193"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter2_d_76_7498)">
            <rect
              x="17"
              y="367"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_76_7498)">
            <rect
              x="17"
              y="541"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <mask
            id="mask0_76_7498"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="41"
            y="42"
            width="113"
            height="113"
          >
            <circle cx="97.5" cy="98.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7498)">
            <rect x="41" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 116.267C66.6033 116.704 66.0477 116.593 65.2821 115.776C65.0783 115.554 64.8993 115.302 64.7264 115.057C64.2325 114.368 63.9732 114.239 63.2632 114.694C61.5901 115.782 60.1145 115.69 58.7007 114.239C58.0092 113.533 57.0707 113.262 56.1014 113.145C55.0827 113.029 54.1443 112.789 53.4467 111.947C53.033 111.455 52.4156 111.511 51.8599 111.621C51.0079 111.793 50.2053 111.707 49.4521 111.296C48.4952 110.767 47.6185 110.896 46.7665 111.511C46.6059 111.627 46.4454 111.732 46.2787 111.836C45.0378 112.629 44.3339 112.488 43.4819 111.271C42.8275 110.331 41.9446 110.06 41 110.435V106.41C42.0619 106.766 43.1671 106.908 44.2845 106.859C46.0132 106.779 47.495 107.233 48.7545 108.438C49.4089 109.065 50.193 109.268 51.1067 109.016C52.1563 108.727 53.0268 109.01 53.6874 109.913C54.1752 110.57 54.8296 110.988 55.6878 110.951C57.398 110.878 58.8365 111.492 60.0528 112.654C60.6208 113.195 61.269 113.588 62.0902 113.526C63.9176 113.385 65.4364 113.901 66.486 115.475C66.7823 115.886 67.2948 115.985 67.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0568 122.696L82.0506 123.961C81.5258 124.134 81.0442 124.441 80.4824 124.49C78.4574 124.343 76.494 123.863 74.5431 123.359C68.1963 121.718 61.6828 122.696 55.2001 122.696C51.4896 122.358 47.2173 122.542 43.4944 122.696C43.4944 122.696 42.0558 122.622 41.0125 122.696V110.441C41.9571 110.067 42.8399 110.337 43.4944 111.277C44.3464 112.494 45.044 112.635 46.2912 111.843C46.4579 111.738 46.6184 111.634 46.7789 111.517C47.6309 110.902 48.5076 110.773 49.4646 111.302C50.2178 111.714 51.0204 111.793 51.8724 111.628C52.4281 111.517 53.0454 111.462 53.4591 111.953C54.1568 112.795 55.0952 113.035 56.1139 113.152C57.0832 113.262 58.0216 113.533 58.7131 114.245C60.1269 115.696 61.6025 115.788 63.2757 114.7C63.9795 114.239 64.245 114.375 64.7389 115.063C64.9179 115.309 65.0908 115.561 65.2945 115.782C66.0601 116.605 66.6158 116.71 67.6838 116.273C68.0913 116.31 68.4926 116.353 68.9125 116.335C69.7892 116.31 70.3139 116.876 70.6103 117.613C70.8819 118.302 71.3141 118.64 72.0735 118.646C72.8206 118.652 73.4503 119.027 73.9936 119.506C74.9012 120.299 75.9631 120.668 77.1547 120.686C78.1054 120.698 78.9327 121.012 79.6736 121.595C80.3713 122.149 81.1307 122.609 82.0568 122.696Z"
              fill="#66646F"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V116.205C53.2464 117.968 75.1866 120.991 98.359 114.902C120.48 109.084 139.779 105.413 154 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V120.817C53.2464 122.58 77.0945 134.139 98.359 123.208C120.913 111.606 139.779 105.413 154 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 117.312C136.64 116.619 136.644 115.93 136.644 115.237C136.659 114.728 136.674 114.216 136.689 113.708L136.659 113.678C137.495 113.915 138.339 113.862 139.182 113.723C139.197 114.559 139.213 115.391 139.228 116.228C139.216 117.467 139.209 118.702 139.186 119.942C139.171 120.781 138.987 121.565 137.947 121.576C136.907 121.588 136.704 120.812 136.681 119.968C136.659 119.079 136.655 118.198 136.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 113.708C132.79 112.849 130.105 109.93 130.278 106.174C130.538 100.63 132.067 95.4017 135.077 90.6707C136.975 91.5333 138.018 93.168 138.881 94.9459C140.192 97.6504 141.02 100.513 141.559 103.462C141.819 104.89 142.166 106.321 141.898 107.794C141.348 110.819 139.499 112.691 136.655 113.678L136.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 113.674C139.502 112.687 141.352 110.815 141.906 107.79C142.173 106.321 141.826 104.886 141.567 103.459C141.028 100.513 140.199 97.6466 138.888 94.9421C138.026 93.1643 136.986 91.5295 135.084 90.667C136.885 88.1923 138.636 88.0567 140.523 90.2828C140.979 90.8214 141.397 91.4128 141.729 92.038C143.424 95.2322 144.493 98.6485 145.194 102.182C145.375 103.086 145.506 104.001 145.661 104.912C145.774 107.327 145.326 109.553 143.589 111.384C142.874 112.141 142 112.664 141.145 113.222C140.493 113.387 139.841 113.553 139.186 113.719C138.342 113.858 137.499 113.911 136.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 127.595C111.439 126.325 111.446 125.061 111.446 123.79C111.474 122.858 111.501 121.918 111.529 120.986L111.474 120.931C113.007 121.366 114.554 121.269 116.101 121.014C116.129 122.547 116.156 124.073 116.184 125.606C116.163 127.878 116.149 130.144 116.108 132.416C116.08 133.956 115.742 135.392 113.836 135.413C111.93 135.434 111.557 134.011 111.515 132.464C111.474 130.834 111.467 129.218 111.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 120.986C104.381 119.411 99.4568 114.059 99.7745 107.174C100.251 97.0078 103.055 87.422 108.573 78.7478C112.054 80.3293 113.967 83.3266 115.549 86.5863C117.952 91.545 119.471 96.7937 120.459 102.201C120.936 104.819 121.571 107.443 121.081 110.143C120.072 115.689 116.681 119.121 111.467 120.931L111.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 120.924C116.688 119.114 120.079 115.682 121.095 110.136C121.585 107.443 120.949 104.812 120.473 102.194C119.485 96.7936 117.966 91.538 115.563 86.5794C113.981 83.3197 112.075 80.3224 108.587 78.7409C111.888 74.2035 115.1 73.9549 118.56 78.0364C119.396 79.024 120.162 80.1083 120.77 81.2547C123.878 87.1112 125.839 93.3751 127.124 99.8531C127.455 101.511 127.697 103.189 127.98 104.86C128.187 109.287 127.365 113.368 124.182 116.725C122.869 118.113 121.267 119.073 119.699 120.095C118.505 120.399 117.31 120.703 116.108 121.007C114.561 121.262 113.014 121.359 111.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7391 74.4687C57.6849 75.1077 56.622 75.7292 55.8696 76.7533C55.5766 77.1516 55.1523 77.0597 54.7367 77.0115C52.4971 76.7358 50.4587 78.0838 49.8026 80.2677C49.5052 81.2612 49.6364 81.4581 50.6687 81.4625C55.7865 81.4669 60.9044 81.4625 66.0222 81.4712C66.4946 81.4712 66.967 81.5238 67.4351 81.55C69.3029 81.5238 71.1663 81.48 73.0341 81.4888C73.7602 81.4931 73.9876 81.2656 73.8214 80.5259C73.3184 78.2851 71.1663 76.7227 68.8829 77.0072C68.4018 77.0684 67.995 77.1209 67.6013 76.6745C65.3179 74.0617 62.4922 73.344 59.1984 74.3593C59.0497 74.3987 58.8966 74.4294 58.7391 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2959 74.355C90.4171 74.3593 97.534 74.3637 104.655 74.3812C105.39 74.3812 105.499 74.0749 105.355 73.4359C104.918 71.4883 103.22 70.1403 101.116 70.2322C100.355 70.2673 99.8436 70.0747 99.3055 69.5145C96.5542 66.6522 92.1668 66.6653 89.3848 69.5276C88.8774 70.0528 88.3963 70.3504 87.657 70.2235C86.7822 70.0747 85.9861 70.4817 85.155 70.6611C84.1533 71.6984 83.1122 72.7181 83.2959 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 57.317C76.7127 56.0959 74.32 55.8858 71.9317 57.2645C70.0026 58.3805 68.9091 60.0786 68.5285 62.2712C68.4061 62.9846 68.6116 63.2472 69.3465 63.2385C72.1329 63.2166 74.9193 63.2297 77.7013 63.2297C80.2952 63.2297 82.8934 63.2341 85.4873 63.2253C86.5065 63.221 86.629 63.0284 86.3753 62.0218C86.161 61.1683 85.6579 60.4812 85.1724 59.7766C84.2582 59.0851 83.3002 58.398 82.1061 58.468C80.8857 58.5424 79.884 58.2492 79.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask1_76_7498"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="216"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="272.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask1_76_7498)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.603 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.074 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.213 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.987 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.906 281.79C327.173 280.321 326.826 278.886 326.567 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.885 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.729 266.038C328.424 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.874 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.499 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.095 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.563 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.396 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.182 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.505 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.787 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.844 244.075 284.306 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask2_76_7498"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="41"
            y="390"
            width="113"
            height="113"
          >
            <circle cx="97.5" cy="446.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask2_76_7498)">
            <rect x="41" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 464.267C66.6033 464.704 66.0477 464.593 65.2821 463.776C65.0783 463.554 64.8993 463.302 64.7264 463.057C64.2325 462.368 63.9732 462.239 63.2632 462.694C61.5901 463.782 60.1145 463.69 58.7007 462.239C58.0092 461.533 57.0707 461.262 56.1014 461.145C55.0827 461.029 54.1443 460.789 53.4467 459.947C53.033 459.455 52.4156 459.511 51.8599 459.621C51.0079 459.793 50.2053 459.707 49.4521 459.296C48.4952 458.767 47.6185 458.896 46.7665 459.511C46.6059 459.627 46.4454 459.732 46.2787 459.836C45.0378 460.629 44.3339 460.488 43.4819 459.271C42.8275 458.331 41.9446 458.06 41 458.435V454.41C42.0619 454.766 43.1671 454.908 44.2845 454.859C46.0132 454.779 47.495 455.233 48.7545 456.438C49.4089 457.065 50.193 457.268 51.1067 457.016C52.1563 456.727 53.0268 457.01 53.6874 457.913C54.1752 458.57 54.8296 458.988 55.6878 458.951C57.398 458.878 58.8365 459.492 60.0528 460.654C60.6208 461.195 61.269 461.588 62.0902 461.526C63.9176 461.385 65.4364 461.901 66.486 463.475C66.7823 463.886 67.2948 463.985 67.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0568 470.696L82.0506 471.961C81.5258 472.134 81.0442 472.441 80.4824 472.49C78.4574 472.343 76.494 471.863 74.5431 471.359C68.1963 469.718 61.6828 470.696 55.2001 470.696C51.4896 470.358 47.2173 470.542 43.4944 470.696C43.4944 470.696 42.0558 470.622 41.0125 470.696V458.441C41.9571 458.067 42.8399 458.337 43.4944 459.277C44.3464 460.494 45.044 460.635 46.2912 459.843C46.4579 459.738 46.6184 459.634 46.7789 459.517C47.6309 458.902 48.5076 458.773 49.4646 459.302C50.2178 459.714 51.0204 459.793 51.8724 459.628C52.4281 459.517 53.0454 459.462 53.4591 459.953C54.1568 460.795 55.0952 461.035 56.1139 461.152C57.0832 461.262 58.0216 461.533 58.7131 462.245C60.1269 463.696 61.6025 463.788 63.2757 462.7C63.9795 462.239 64.245 462.375 64.7389 463.063C64.9179 463.309 65.0908 463.561 65.2945 463.782C66.0601 464.605 66.6158 464.71 67.6838 464.273C68.0913 464.31 68.4926 464.353 68.9125 464.335C69.7892 464.31 70.3139 464.876 70.6103 465.613C70.8819 466.302 71.3141 466.64 72.0735 466.646C72.8206 466.652 73.4503 467.027 73.9936 467.506C74.9012 468.299 75.9631 468.668 77.1547 468.686C78.1054 468.698 78.9327 469.012 79.6736 469.595C80.3713 470.149 81.1307 470.609 82.0568 470.696Z"
              fill="#66646F"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V464.205C53.2464 465.968 75.1866 468.991 98.359 462.902C120.48 457.084 139.779 453.413 154 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V468.817C53.2464 470.58 77.0945 482.139 98.359 471.208C120.913 459.606 139.779 453.413 154 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 465.312C136.64 464.619 136.644 463.93 136.644 463.237C136.659 462.728 136.674 462.216 136.689 461.708L136.659 461.678C137.495 461.915 138.339 461.862 139.182 461.723C139.197 462.559 139.213 463.391 139.228 464.228C139.216 465.467 139.209 466.702 139.186 467.942C139.171 468.781 138.987 469.565 137.947 469.576C136.907 469.588 136.704 468.812 136.681 467.968C136.659 467.079 136.655 466.198 136.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 461.708C132.79 460.849 130.105 457.93 130.278 454.174C130.538 448.63 132.067 443.402 135.077 438.671C136.975 439.533 138.018 441.168 138.881 442.946C140.192 445.65 141.02 448.513 141.559 451.462C141.819 452.89 142.166 454.321 141.898 455.794C141.348 458.819 139.499 460.691 136.655 461.678L136.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 461.674C139.502 460.687 141.352 458.815 141.906 455.79C142.173 454.321 141.826 452.886 141.567 451.459C141.028 448.513 140.199 445.647 138.888 442.942C138.026 441.164 136.986 439.53 135.084 438.667C136.885 436.192 138.636 436.057 140.523 438.283C140.979 438.821 141.397 439.413 141.729 440.038C143.424 443.232 144.493 446.649 145.194 450.182C145.375 451.086 145.506 452.001 145.661 452.912C145.774 455.327 145.326 457.553 143.589 459.384C142.874 460.141 142 460.664 141.145 461.222C140.493 461.387 139.841 461.553 139.186 461.719C138.342 461.858 137.499 461.911 136.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 475.595C111.439 474.325 111.446 473.061 111.446 471.79C111.474 470.858 111.501 469.918 111.529 468.986L111.474 468.931C113.007 469.366 114.554 469.269 116.101 469.014C116.129 470.547 116.156 472.073 116.184 473.606C116.163 475.878 116.149 478.144 116.108 480.416C116.08 481.956 115.742 483.392 113.836 483.413C111.93 483.434 111.557 482.011 111.515 480.464C111.474 478.834 111.467 477.218 111.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 468.986C104.381 467.411 99.4568 462.059 99.7745 455.174C100.251 445.008 103.055 435.422 108.573 426.748C112.054 428.329 113.967 431.327 115.549 434.586C117.952 439.545 119.471 444.794 120.459 450.201C120.936 452.819 121.571 455.443 121.081 458.143C120.072 463.689 116.681 467.121 111.467 468.931L111.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 468.924C116.688 467.114 120.079 463.682 121.095 458.136C121.585 455.443 120.949 452.812 120.473 450.194C119.485 444.794 117.966 439.538 115.563 434.579C113.981 431.32 112.075 428.322 108.587 426.741C111.888 422.203 115.1 421.955 118.56 426.036C119.396 427.024 120.162 428.108 120.77 429.255C123.878 435.111 125.839 441.375 127.124 447.853C127.455 449.511 127.697 451.189 127.98 452.86C128.187 457.287 127.365 461.368 124.182 464.725C122.869 466.113 121.267 467.073 119.699 468.095C118.505 468.399 117.31 468.703 116.108 469.007C114.561 469.262 113.014 469.359 111.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7391 422.469C57.6849 423.108 56.622 423.729 55.8696 424.753C55.5766 425.152 55.1523 425.06 54.7367 425.012C52.4971 424.736 50.4587 426.084 49.8026 428.268C49.5052 429.261 49.6364 429.458 50.6687 429.462C55.7865 429.467 60.9044 429.462 66.0222 429.471C66.4946 429.471 66.967 429.524 67.4351 429.55C69.3029 429.524 71.1663 429.48 73.0341 429.489C73.7602 429.493 73.9876 429.266 73.8214 428.526C73.3184 426.285 71.1663 424.723 68.8829 425.007C68.4018 425.068 67.995 425.121 67.6013 424.675C65.3179 422.062 62.4922 421.344 59.1984 422.359C59.0497 422.399 58.8966 422.429 58.7391 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2959 422.355C90.4171 422.359 97.534 422.364 104.655 422.381C105.39 422.381 105.499 422.075 105.355 421.436C104.918 419.488 103.22 418.14 101.116 418.232C100.355 418.267 99.8436 418.075 99.3055 417.514C96.5542 414.652 92.1668 414.665 89.3848 417.528C88.8774 418.053 88.3963 418.35 87.657 418.223C86.7822 418.075 85.9861 418.482 85.155 418.661C84.1533 419.698 83.1122 420.718 83.2959 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 405.317C76.7127 404.096 74.32 403.886 71.9317 405.264C70.0026 406.38 68.9091 408.079 68.5285 410.271C68.4061 410.985 68.6116 411.247 69.3465 411.238C72.1329 411.217 74.9193 411.23 77.7013 411.23C80.2952 411.23 82.8934 411.234 85.4873 411.225C86.5065 411.221 86.629 411.028 86.3753 410.022C86.161 409.168 85.6579 408.481 85.1724 407.777C84.2582 407.085 83.3002 406.398 82.1061 406.468C80.8857 406.542 79.884 406.249 79.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask3_76_7498"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="564"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="620.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask3_76_7498)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.961C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.245C245.127 637.696 246.603 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.273C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.074 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.595C265.371 644.149 266.131 644.609 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.213 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.987 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.906 629.79C327.173 628.321 326.826 626.886 326.567 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.885 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.729 614.038C328.424 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.874 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.499 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.095 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.563 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.396 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.182 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.505 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.787 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.844 592.075 284.306 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="176" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="177" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7498"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7498"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7498"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7498"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7498"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7498"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7498"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7498"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7498"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7498"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7498"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7498"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7498">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// Container Rounded / Image Square / Position
const CollectionContainerRoundedSquareLeft = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareLeft">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7490)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7490)">
            <rect x="17" y="19" width="346" height="159" rx="14" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7490)">
            <rect
              x="17"
              y="193"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter2_d_76_7490)">
            <rect
              x="17"
              y="367"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_76_7490)">
            <rect
              x="17"
              y="541"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip1_76_7490)">
            <rect x="39" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 116.267C64.6033 116.704 64.0477 116.593 63.2821 115.776C63.0783 115.554 62.8993 115.302 62.7264 115.057C62.2325 114.368 61.9732 114.239 61.2632 114.694C59.5901 115.782 58.1145 115.69 56.7007 114.239C56.0092 113.533 55.0707 113.262 54.1014 113.145C53.0827 113.029 52.1443 112.789 51.4467 111.947C51.033 111.455 50.4156 111.511 49.8599 111.621C49.0079 111.793 48.2053 111.707 47.4521 111.296C46.4952 110.767 45.6185 110.896 44.7665 111.511C44.6059 111.627 44.4454 111.732 44.2787 111.836C43.0378 112.629 42.3339 112.488 41.4819 111.271C40.8275 110.331 39.9446 110.06 39 110.435V106.41C40.0619 106.766 41.1671 106.908 42.2845 106.859C44.0132 106.779 45.495 107.233 46.7545 108.438C47.4089 109.065 48.193 109.268 49.1067 109.016C50.1563 108.727 51.0268 109.01 51.6874 109.913C52.1752 110.57 52.8296 110.988 53.6878 110.951C55.398 110.878 56.8365 111.492 58.0528 112.654C58.6208 113.195 59.269 113.588 60.0902 113.526C61.9176 113.385 63.4364 113.901 64.486 115.475C64.7823 115.886 65.2948 115.985 65.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 122.696L80.0506 123.961C79.5258 124.134 79.0442 124.441 78.4824 124.49C76.4574 124.343 74.494 123.863 72.5431 123.359C66.1963 121.718 59.6828 122.696 53.2001 122.696C49.4896 122.358 45.2173 122.542 41.4944 122.696C41.4944 122.696 40.0558 122.622 39.0125 122.696V110.441C39.9571 110.067 40.8399 110.337 41.4944 111.277C42.3464 112.494 43.044 112.635 44.2912 111.843C44.4579 111.738 44.6184 111.634 44.7789 111.517C45.6309 110.902 46.5076 110.773 47.4646 111.302C48.2178 111.714 49.0204 111.793 49.8724 111.628C50.4281 111.517 51.0454 111.462 51.4591 111.953C52.1568 112.795 53.0952 113.035 54.1139 113.152C55.0832 113.262 56.0216 113.533 56.7131 114.245C58.1269 115.696 59.6025 115.788 61.2757 114.7C61.9795 114.239 62.245 114.375 62.7389 115.063C62.9179 115.309 63.0908 115.561 63.2945 115.782C64.0601 116.605 64.6158 116.71 65.6838 116.273C66.0913 116.31 66.4926 116.353 66.9125 116.335C67.7892 116.31 68.3139 116.876 68.6103 117.613C68.8819 118.302 69.3141 118.64 70.0735 118.646C70.8206 118.652 71.4503 119.027 71.9936 119.506C72.9012 120.299 73.9631 120.668 75.1547 120.686C76.1054 120.698 76.9327 121.012 77.6736 121.595C78.3713 122.149 79.1307 122.609 80.0568 122.696Z"
              fill="#66646F"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V116.205C51.2464 117.968 73.1866 120.991 96.359 114.902C118.48 109.084 137.779 105.413 152 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V120.817C51.2464 122.58 75.0945 134.139 96.359 123.208C118.913 111.606 137.779 105.413 152 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 117.312C134.64 116.619 134.644 115.93 134.644 115.237C134.659 114.728 134.674 114.216 134.689 113.708L134.659 113.678C135.495 113.915 136.339 113.862 137.182 113.723C137.197 114.559 137.213 115.391 137.228 116.228C137.216 117.467 137.209 118.702 137.186 119.942C137.171 120.781 136.987 121.565 135.947 121.576C134.907 121.588 134.704 120.812 134.681 119.968C134.659 119.079 134.655 118.198 134.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 113.708C130.79 112.849 128.105 109.93 128.278 106.174C128.538 100.63 130.067 95.4017 133.077 90.6707C134.975 91.5333 136.018 93.168 136.881 94.9459C138.192 97.6504 139.02 100.513 139.559 103.462C139.819 104.89 140.166 106.321 139.898 107.794C139.348 110.819 137.499 112.691 134.655 113.678L134.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 113.674C137.502 112.687 139.352 110.815 139.905 107.79C140.173 106.321 139.826 104.886 139.566 103.459C139.028 100.513 138.199 97.6466 136.888 94.9421C136.026 93.1643 134.986 91.5295 133.084 90.667C134.884 88.1923 136.636 88.0567 138.523 90.2828C138.979 90.8214 139.397 91.4128 139.728 92.038C141.423 95.2322 142.493 98.6485 143.194 102.182C143.375 103.086 143.506 104.001 143.661 104.912C143.774 107.327 143.326 109.553 141.589 111.384C140.873 112.141 140 112.664 139.145 113.222C138.493 113.387 137.841 113.553 137.186 113.719C136.342 113.858 135.498 113.911 134.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 127.595C109.439 126.325 109.446 125.061 109.446 123.79C109.474 122.858 109.501 121.918 109.529 120.986L109.474 120.931C111.007 121.366 112.554 121.269 114.101 121.014C114.129 122.547 114.156 124.073 114.184 125.606C114.163 127.878 114.149 130.144 114.108 132.416C114.08 133.956 113.742 135.392 111.836 135.413C109.93 135.434 109.557 134.011 109.515 132.464C109.474 130.834 109.467 129.218 109.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 120.986C102.381 119.411 97.4568 114.059 97.7745 107.174C98.2511 97.0078 101.055 87.422 106.573 78.7478C110.054 80.3293 111.967 83.3266 113.549 86.5863C115.952 91.545 117.471 96.7937 118.459 102.201C118.936 104.819 119.571 107.443 119.081 110.143C118.072 115.689 114.681 119.121 109.467 120.931L109.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 120.924C114.688 119.114 118.079 115.682 119.094 110.136C119.585 107.443 118.949 104.812 118.473 102.194C117.485 96.7936 115.966 91.538 113.562 86.5794C111.981 83.3197 110.075 80.3224 106.587 78.7409C109.888 74.2035 113.1 73.9549 116.56 78.0364C117.395 79.024 118.162 80.1083 118.77 81.2547C121.878 87.1112 123.839 93.3751 125.124 99.8531C125.455 101.511 125.697 103.189 125.98 104.86C126.187 109.287 125.365 113.368 122.181 116.725C120.869 118.113 119.267 119.073 117.699 120.095C116.504 120.399 115.31 120.703 114.108 121.007C112.561 121.262 111.014 121.359 109.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 74.4687C55.6849 75.1077 54.622 75.7292 53.8696 76.7533C53.5765 77.1516 53.1522 77.0597 52.7367 77.0115C50.4971 76.7358 48.4587 78.0838 47.8026 80.2677C47.5051 81.2612 47.6363 81.4581 48.6686 81.4625C53.7865 81.4669 58.9043 81.4625 64.0221 81.4712C64.4946 81.4712 64.967 81.5238 65.435 81.55C67.3028 81.5238 69.1662 81.48 71.034 81.4888C71.7601 81.4931 71.9876 81.2656 71.8214 80.5259C71.3183 78.2851 69.1662 76.7227 66.8829 77.0072C66.4017 77.0684 65.9949 77.1209 65.6012 76.6745C63.3179 74.0617 60.4921 73.344 57.1984 74.3593C57.0496 74.3987 56.8965 74.4294 56.7391 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 74.355C88.417 74.3593 95.5339 74.3637 102.655 74.3812C103.39 74.3812 103.499 74.0749 103.355 73.4359C102.918 71.4883 101.22 70.1403 99.1163 70.2322C98.3552 70.2673 97.8435 70.0747 97.3054 69.5145C94.554 66.6522 90.1667 66.6653 87.3847 69.5276C86.8773 70.0528 86.3961 70.3504 85.6569 70.2235C84.7821 70.0747 83.9859 70.4817 83.1548 70.6611C82.1532 71.6984 81.1121 72.7181 81.2958 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 57.317C74.7127 56.0959 72.32 55.8858 69.9317 57.2645C68.0026 58.3805 66.9091 60.0786 66.5285 62.2712C66.4061 62.9846 66.6116 63.2472 67.3465 63.2385C70.1329 63.2166 72.9193 63.2297 75.7013 63.2297C78.2952 63.2297 80.8934 63.2341 83.4873 63.2253C84.5065 63.221 84.629 63.0284 84.3753 62.0218C84.161 61.1683 83.6579 60.4812 83.1724 59.7766C82.2582 59.0851 81.3002 58.398 80.1061 58.468C78.8857 58.5424 77.884 58.2492 77.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip2_76_7490)">
            <rect x="39" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 290.267C64.6033 290.704 64.0477 290.593 63.2821 289.776C63.0783 289.554 62.8993 289.302 62.7264 289.057C62.2325 288.368 61.9732 288.239 61.2632 288.694C59.5901 289.782 58.1145 289.69 56.7007 288.239C56.0092 287.533 55.0707 287.262 54.1014 287.145C53.0827 287.029 52.1443 286.789 51.4467 285.947C51.033 285.455 50.4156 285.511 49.8599 285.621C49.0079 285.793 48.2053 285.707 47.4521 285.296C46.4952 284.767 45.6185 284.896 44.7665 285.511C44.6059 285.627 44.4454 285.732 44.2787 285.836C43.0378 286.629 42.3339 286.488 41.4819 285.271C40.8275 284.331 39.9446 284.06 39 284.435V280.41C40.0619 280.766 41.1671 280.908 42.2845 280.859C44.0132 280.779 45.495 281.233 46.7545 282.438C47.4089 283.065 48.193 283.268 49.1067 283.016C50.1563 282.727 51.0268 283.01 51.6874 283.913C52.1752 284.57 52.8296 284.988 53.6878 284.951C55.398 284.878 56.8365 285.492 58.0528 286.654C58.6208 287.195 59.269 287.588 60.0902 287.526C61.9176 287.385 63.4364 287.901 64.486 289.475C64.7823 289.886 65.2948 289.985 65.6714 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 296.696L80.0506 297.961C79.5258 298.134 79.0442 298.441 78.4824 298.49C76.4574 298.343 74.494 297.863 72.5431 297.359C66.1963 295.718 59.6828 296.696 53.2001 296.696C49.4896 296.358 45.2173 296.542 41.4944 296.696C41.4944 296.696 40.0558 296.622 39.0125 296.696V284.441C39.9571 284.067 40.8399 284.337 41.4944 285.277C42.3464 286.494 43.044 286.635 44.2912 285.843C44.4579 285.738 44.6184 285.634 44.7789 285.517C45.6309 284.902 46.5076 284.773 47.4646 285.302C48.2178 285.714 49.0204 285.793 49.8724 285.628C50.4281 285.517 51.0454 285.462 51.4591 285.953C52.1568 286.795 53.0952 287.035 54.1139 287.152C55.0832 287.262 56.0216 287.533 56.7131 288.245C58.1269 289.696 59.6025 289.788 61.2757 288.7C61.9795 288.239 62.245 288.375 62.7389 289.063C62.9179 289.309 63.0908 289.561 63.2945 289.782C64.0601 290.605 64.6158 290.71 65.6838 290.273C66.0913 290.31 66.4926 290.353 66.9125 290.335C67.7892 290.31 68.3139 290.876 68.6103 291.613C68.8819 292.302 69.3141 292.64 70.0735 292.646C70.8206 292.652 71.4503 293.027 71.9936 293.506C72.9012 294.299 73.9631 294.668 75.1547 294.686C76.1054 294.698 76.9327 295.012 77.6736 295.595C78.3713 296.149 79.1307 296.609 80.0568 296.696Z"
              fill="#66646F"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V290.205C51.2464 291.968 73.1866 294.991 96.359 288.902C118.48 283.084 137.779 279.413 152 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V294.817C51.2464 296.58 75.0945 308.139 96.359 297.208C118.913 285.606 137.779 279.413 152 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 291.312C134.64 290.619 134.644 289.93 134.644 289.237C134.659 288.728 134.674 288.216 134.689 287.708L134.659 287.678C135.495 287.915 136.339 287.862 137.182 287.723C137.197 288.559 137.213 289.391 137.228 290.228C137.216 291.467 137.209 292.702 137.186 293.942C137.171 294.781 136.987 295.565 135.947 295.576C134.907 295.588 134.704 294.812 134.681 293.968C134.659 293.079 134.655 292.198 134.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 287.708C130.79 286.849 128.105 283.93 128.278 280.174C128.538 274.63 130.067 269.402 133.077 264.671C134.975 265.533 136.018 267.168 136.881 268.946C138.192 271.65 139.02 274.513 139.559 277.462C139.819 278.89 140.166 280.321 139.898 281.794C139.348 284.819 137.499 286.691 134.655 287.678L134.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 287.674C137.502 286.687 139.352 284.815 139.905 281.79C140.173 280.321 139.826 278.886 139.566 277.459C139.028 274.513 138.199 271.647 136.888 268.942C136.026 267.164 134.986 265.53 133.084 264.667C134.884 262.192 136.636 262.057 138.523 264.283C138.979 264.821 139.397 265.413 139.728 266.038C141.423 269.232 142.493 272.649 143.194 276.182C143.375 277.086 143.506 278.001 143.661 278.912C143.774 281.327 143.326 283.553 141.589 285.384C140.873 286.141 140 286.664 139.145 287.222C138.493 287.387 137.841 287.553 137.186 287.719C136.342 287.858 135.498 287.911 134.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 301.595C109.439 300.325 109.446 299.061 109.446 297.79C109.474 296.858 109.501 295.918 109.529 294.986L109.474 294.931C111.007 295.366 112.554 295.269 114.101 295.014C114.129 296.547 114.156 298.073 114.184 299.606C114.163 301.878 114.149 304.144 114.108 306.416C114.08 307.956 113.742 309.392 111.836 309.413C109.93 309.434 109.557 308.011 109.515 306.464C109.474 304.834 109.467 303.218 109.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 294.986C102.381 293.411 97.4568 288.059 97.7745 281.174C98.2511 271.008 101.055 261.422 106.573 252.748C110.054 254.329 111.967 257.327 113.549 260.586C115.952 265.545 117.471 270.794 118.459 276.201C118.936 278.819 119.571 281.443 119.081 284.143C118.072 289.689 114.681 293.121 109.467 294.931L109.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 294.924C114.688 293.114 118.079 289.682 119.094 284.136C119.585 281.443 118.949 278.812 118.473 276.194C117.485 270.794 115.966 265.538 113.562 260.579C111.981 257.32 110.075 254.322 106.587 252.741C109.888 248.203 113.1 247.955 116.56 252.036C117.395 253.024 118.162 254.108 118.77 255.255C121.878 261.111 123.839 267.375 125.124 273.853C125.455 275.511 125.697 277.189 125.98 278.86C126.187 283.287 125.365 287.368 122.181 290.725C120.869 292.113 119.267 293.073 117.699 294.095C116.504 294.399 115.31 294.703 114.108 295.007C112.561 295.262 111.014 295.359 109.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 248.469C55.6849 249.108 54.622 249.729 53.8696 250.753C53.5765 251.152 53.1522 251.06 52.7367 251.012C50.4971 250.736 48.4587 252.084 47.8026 254.268C47.5051 255.261 47.6363 255.458 48.6686 255.462C53.7865 255.467 58.9043 255.462 64.0221 255.471C64.4946 255.471 64.967 255.524 65.435 255.55C67.3028 255.524 69.1662 255.48 71.034 255.489C71.7601 255.493 71.9876 255.266 71.8214 254.526C71.3183 252.285 69.1662 250.723 66.8829 251.007C66.4017 251.068 65.9949 251.121 65.6012 250.675C63.3179 248.062 60.4921 247.344 57.1984 248.359C57.0496 248.399 56.8965 248.429 56.7391 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 248.355C88.417 248.359 95.5339 248.364 102.655 248.381C103.39 248.381 103.499 248.075 103.355 247.436C102.918 245.488 101.22 244.14 99.1163 244.232C98.3552 244.267 97.8435 244.075 97.3054 243.514C94.554 240.652 90.1667 240.665 87.3847 243.528C86.8773 244.053 86.3961 244.35 85.6569 244.223C84.7821 244.075 83.9859 244.482 83.1548 244.661C82.1532 245.698 81.1121 246.718 81.2958 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 231.317C74.7127 230.096 72.32 229.886 69.9317 231.264C68.0026 232.38 66.9091 234.079 66.5285 236.271C66.4061 236.985 66.6116 237.247 67.3465 237.238C70.1329 237.217 72.9193 237.23 75.7013 237.23C78.2952 237.23 80.8934 237.234 83.4873 237.225C84.5065 237.221 84.629 237.028 84.3753 236.022C84.161 235.168 83.6579 234.481 83.1724 233.777C82.2582 233.085 81.3002 232.398 80.1061 232.468C78.8857 232.542 77.884 232.249 77.0879 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip3_76_7490)">
            <rect x="39" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 464.267C64.6033 464.704 64.0477 464.593 63.2821 463.776C63.0783 463.554 62.8993 463.302 62.7264 463.057C62.2325 462.368 61.9732 462.239 61.2632 462.694C59.5901 463.782 58.1145 463.69 56.7007 462.239C56.0092 461.533 55.0707 461.262 54.1014 461.145C53.0827 461.029 52.1443 460.789 51.4467 459.947C51.033 459.455 50.4156 459.511 49.8599 459.621C49.0079 459.793 48.2053 459.707 47.4521 459.296C46.4952 458.767 45.6185 458.896 44.7665 459.511C44.6059 459.627 44.4454 459.732 44.2787 459.836C43.0378 460.629 42.3339 460.488 41.4819 459.271C40.8275 458.331 39.9446 458.06 39 458.435V454.41C40.0619 454.766 41.1671 454.908 42.2845 454.859C44.0132 454.779 45.495 455.233 46.7545 456.438C47.4089 457.065 48.193 457.268 49.1067 457.016C50.1563 456.727 51.0268 457.01 51.6874 457.913C52.1752 458.57 52.8296 458.988 53.6878 458.951C55.398 458.878 56.8365 459.492 58.0528 460.654C58.6208 461.195 59.269 461.588 60.0902 461.526C61.9176 461.385 63.4364 461.901 64.486 463.475C64.7823 463.886 65.2948 463.985 65.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 470.696L80.0506 471.961C79.5258 472.134 79.0442 472.441 78.4824 472.49C76.4574 472.343 74.494 471.863 72.5431 471.359C66.1963 469.718 59.6828 470.696 53.2001 470.696C49.4896 470.358 45.2173 470.542 41.4944 470.696C41.4944 470.696 40.0558 470.622 39.0125 470.696V458.441C39.9571 458.067 40.8399 458.337 41.4944 459.277C42.3464 460.494 43.044 460.635 44.2912 459.843C44.4579 459.738 44.6184 459.634 44.7789 459.517C45.6309 458.902 46.5076 458.773 47.4646 459.302C48.2178 459.714 49.0204 459.793 49.8724 459.628C50.4281 459.517 51.0454 459.462 51.4591 459.953C52.1568 460.795 53.0952 461.035 54.1139 461.152C55.0832 461.262 56.0216 461.533 56.7131 462.245C58.1269 463.696 59.6025 463.788 61.2757 462.7C61.9795 462.239 62.245 462.375 62.7389 463.063C62.9179 463.309 63.0908 463.561 63.2945 463.782C64.0601 464.605 64.6158 464.71 65.6838 464.273C66.0913 464.31 66.4926 464.353 66.9125 464.335C67.7892 464.31 68.3139 464.876 68.6103 465.613C68.8819 466.302 69.3141 466.64 70.0735 466.646C70.8206 466.652 71.4503 467.027 71.9936 467.506C72.9012 468.299 73.9631 468.668 75.1547 468.686C76.1054 468.698 76.9327 469.012 77.6736 469.595C78.3713 470.149 79.1307 470.609 80.0568 470.696Z"
              fill="#66646F"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V464.205C51.2464 465.968 73.1866 468.991 96.359 462.902C118.48 457.084 137.779 453.413 152 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V468.817C51.2464 470.58 75.0945 482.139 96.359 471.208C118.913 459.606 137.779 453.413 152 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 465.312C134.64 464.619 134.644 463.93 134.644 463.237C134.659 462.728 134.674 462.216 134.689 461.708L134.659 461.678C135.495 461.915 136.339 461.862 137.182 461.723C137.197 462.559 137.213 463.391 137.228 464.228C137.216 465.467 137.209 466.702 137.186 467.942C137.171 468.781 136.987 469.565 135.947 469.576C134.907 469.588 134.704 468.812 134.681 467.968C134.659 467.079 134.655 466.198 134.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 461.708C130.79 460.849 128.105 457.93 128.278 454.174C128.538 448.63 130.067 443.402 133.077 438.671C134.975 439.533 136.018 441.168 136.881 442.946C138.192 445.65 139.02 448.513 139.559 451.462C139.819 452.89 140.166 454.321 139.898 455.794C139.348 458.819 137.499 460.691 134.655 461.678L134.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 461.674C137.502 460.687 139.352 458.815 139.905 455.79C140.173 454.321 139.826 452.886 139.566 451.459C139.028 448.513 138.199 445.647 136.888 442.942C136.026 441.164 134.986 439.53 133.084 438.667C134.884 436.192 136.636 436.057 138.523 438.283C138.979 438.821 139.397 439.413 139.728 440.038C141.423 443.232 142.493 446.649 143.194 450.182C143.375 451.086 143.506 452.001 143.661 452.912C143.774 455.327 143.326 457.553 141.589 459.384C140.873 460.141 140 460.664 139.145 461.222C138.493 461.387 137.841 461.553 137.186 461.719C136.342 461.858 135.498 461.911 134.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 475.595C109.439 474.325 109.446 473.061 109.446 471.79C109.474 470.858 109.501 469.918 109.529 468.986L109.474 468.931C111.007 469.366 112.554 469.269 114.101 469.014C114.129 470.547 114.156 472.073 114.184 473.606C114.163 475.878 114.149 478.144 114.108 480.416C114.08 481.956 113.742 483.392 111.836 483.413C109.93 483.434 109.557 482.011 109.515 480.464C109.474 478.834 109.467 477.218 109.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 468.986C102.381 467.411 97.4568 462.059 97.7745 455.174C98.2511 445.008 101.055 435.422 106.573 426.748C110.054 428.329 111.967 431.327 113.549 434.586C115.952 439.545 117.471 444.794 118.459 450.201C118.936 452.819 119.571 455.443 119.081 458.143C118.072 463.689 114.681 467.121 109.467 468.931L109.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 468.924C114.688 467.114 118.079 463.682 119.094 458.136C119.585 455.443 118.949 452.812 118.473 450.194C117.485 444.794 115.966 439.538 113.562 434.579C111.981 431.32 110.075 428.322 106.587 426.741C109.888 422.203 113.1 421.955 116.56 426.036C117.395 427.024 118.162 428.108 118.77 429.255C121.878 435.111 123.839 441.375 125.124 447.853C125.455 449.511 125.697 451.189 125.98 452.86C126.187 457.287 125.365 461.368 122.181 464.725C120.869 466.113 119.267 467.073 117.699 468.095C116.504 468.399 115.31 468.703 114.108 469.007C112.561 469.262 111.014 469.359 109.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 422.469C55.6849 423.108 54.622 423.729 53.8696 424.753C53.5765 425.152 53.1522 425.06 52.7367 425.012C50.4971 424.736 48.4587 426.084 47.8026 428.268C47.5051 429.261 47.6363 429.458 48.6686 429.462C53.7865 429.467 58.9043 429.462 64.0221 429.471C64.4946 429.471 64.967 429.524 65.435 429.55C67.3028 429.524 69.1662 429.48 71.034 429.489C71.7601 429.493 71.9876 429.266 71.8214 428.526C71.3183 426.285 69.1662 424.723 66.8829 425.007C66.4017 425.068 65.9949 425.121 65.6012 424.675C63.3179 422.062 60.4921 421.344 57.1984 422.359C57.0496 422.399 56.8965 422.429 56.7391 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 422.355C88.417 422.359 95.5339 422.364 102.655 422.381C103.39 422.381 103.499 422.075 103.355 421.436C102.918 419.488 101.22 418.14 99.1163 418.232C98.3552 418.267 97.8435 418.075 97.3054 417.514C94.554 414.652 90.1667 414.665 87.3847 417.528C86.8773 418.053 86.3961 418.35 85.6569 418.223C84.7821 418.075 83.9859 418.482 83.1548 418.661C82.1532 419.698 81.1121 420.718 81.2958 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 405.317C74.7127 404.096 72.32 403.886 69.9317 405.264C68.0026 406.38 66.9091 408.079 66.5285 410.271C66.4061 410.985 66.6116 411.247 67.3465 411.238C70.1329 411.217 72.9193 411.23 75.7013 411.23C78.2952 411.23 80.8934 411.234 83.4873 411.225C84.5065 411.221 84.629 411.028 84.3753 410.022C84.161 409.168 83.6579 408.481 83.1724 407.777C82.2582 407.085 81.3002 406.398 80.1061 406.468C78.8857 406.542 77.884 406.249 77.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip4_76_7490)">
            <rect x="39" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 638.267C64.6033 638.704 64.0477 638.593 63.2821 637.776C63.0783 637.554 62.8993 637.303 62.7264 637.057C62.2325 636.368 61.9732 636.239 61.2632 636.694C59.5901 637.782 58.1145 637.69 56.7007 636.239C56.0092 635.533 55.0707 635.262 54.1014 635.145C53.0827 635.029 52.1443 634.789 51.4467 633.947C51.033 633.455 50.4156 633.511 49.8599 633.621C49.0079 633.793 48.2053 633.707 47.4521 633.296C46.4952 632.767 45.6185 632.896 44.7665 633.511C44.6059 633.628 44.4454 633.732 44.2787 633.836C43.0378 634.629 42.3339 634.488 41.4819 633.271C40.8275 632.331 39.9446 632.06 39 632.435V628.41C40.0619 628.766 41.1671 628.908 42.2845 628.859C44.0132 628.779 45.495 629.234 46.7545 630.438C47.4089 631.065 48.193 631.268 49.1067 631.016C50.1563 630.727 51.0268 631.01 51.6874 631.913C52.1752 632.57 52.8296 632.988 53.6878 632.952C55.398 632.878 56.8365 633.492 58.0528 634.654C58.6208 635.195 59.269 635.588 60.0902 635.526C61.9176 635.385 63.4364 635.901 64.486 637.475C64.7823 637.886 65.2948 637.985 65.6714 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 644.696L80.0506 645.962C79.5258 646.134 79.0442 646.441 78.4824 646.49C76.4574 646.343 74.494 645.863 72.5431 645.359C66.1963 643.718 59.6828 644.696 53.2001 644.696C49.4896 644.358 45.2173 644.542 41.4944 644.696C41.4944 644.696 40.0558 644.622 39.0125 644.696V632.441C39.9571 632.067 40.8399 632.337 41.4944 633.277C42.3464 634.494 43.044 634.635 44.2912 633.843C44.4579 633.738 44.6184 633.634 44.7789 633.517C45.6309 632.902 46.5076 632.773 47.4646 633.302C48.2178 633.714 49.0204 633.793 49.8724 633.628C50.4281 633.517 51.0454 633.462 51.4591 633.953C52.1568 634.795 53.0952 635.035 54.1139 635.152C55.0832 635.262 56.0216 635.533 56.7131 636.246C58.1269 637.696 59.6025 637.788 61.2757 636.7C61.9795 636.239 62.245 636.375 62.7389 637.063C62.9179 637.309 63.0908 637.561 63.2945 637.782C64.0601 638.605 64.6158 638.71 65.6838 638.274C66.0913 638.31 66.4926 638.353 66.9125 638.335C67.7892 638.31 68.3139 638.876 68.6103 639.613C68.8819 640.302 69.3141 640.64 70.0735 640.646C70.8206 640.652 71.4503 641.027 71.9936 641.506C72.9012 642.299 73.9631 642.668 75.1547 642.686C76.1054 642.698 76.9327 643.012 77.6736 643.596C78.3713 644.149 79.1307 644.61 80.0568 644.696Z"
              fill="#66646F"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V638.205C51.2464 639.968 73.1866 642.991 96.359 636.902C118.48 631.084 137.779 627.413 152 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V642.817C51.2464 644.58 75.0945 656.139 96.359 645.208C118.913 633.606 137.779 627.413 152 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 639.312C134.64 638.619 134.644 637.93 134.644 637.237C134.659 636.728 134.674 636.216 134.689 635.708L134.659 635.678C135.495 635.915 136.339 635.862 137.182 635.723C137.197 636.559 137.213 637.391 137.228 638.228C137.216 639.467 137.209 640.702 137.186 641.942C137.171 642.782 136.987 643.565 135.947 643.576C134.907 643.588 134.704 642.812 134.681 641.968C134.659 641.079 134.655 640.198 134.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 635.708C130.79 634.849 128.105 631.93 128.278 628.174C128.538 622.63 130.067 617.402 133.077 612.671C134.975 613.533 136.018 615.168 136.881 616.946C138.192 619.65 139.02 622.513 139.559 625.462C139.819 626.89 140.166 628.321 139.898 629.794C139.348 632.819 137.499 634.691 134.655 635.678L134.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 635.674C137.502 634.687 139.352 632.815 139.905 629.79C140.173 628.321 139.826 626.886 139.566 625.459C139.028 622.513 138.199 619.647 136.888 616.942C136.026 615.164 134.986 613.53 133.084 612.667C134.884 610.192 136.636 610.057 138.523 612.283C138.979 612.821 139.397 613.413 139.728 614.038C141.423 617.232 142.493 620.649 143.194 624.182C143.375 625.086 143.506 626.001 143.661 626.912C143.774 629.327 143.326 631.553 141.589 633.384C140.873 634.141 140 634.664 139.145 635.222C138.493 635.387 137.841 635.553 137.186 635.719C136.342 635.858 135.498 635.911 134.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 649.595C109.439 648.325 109.446 647.061 109.446 645.79C109.474 644.858 109.501 643.918 109.529 642.986L109.474 642.931C111.007 643.366 112.554 643.269 114.101 643.014C114.129 644.547 114.156 646.073 114.184 647.606C114.163 649.878 114.149 652.144 114.108 654.416C114.08 655.956 113.742 657.392 111.836 657.413C109.93 657.434 109.557 656.011 109.515 654.464C109.474 652.834 109.467 651.218 109.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 642.986C102.381 641.411 97.4568 636.059 97.7745 629.174C98.2511 619.008 101.055 609.422 106.573 600.748C110.054 602.329 111.967 605.327 113.549 608.586C115.952 613.545 117.471 618.794 118.459 624.201C118.936 626.819 119.571 629.443 119.081 632.143C118.072 637.689 114.681 641.121 109.467 642.931L109.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 642.924C114.688 641.114 118.079 637.682 119.094 632.136C119.585 629.443 118.949 626.812 118.473 624.194C117.485 618.794 115.966 613.538 113.562 608.579C111.981 605.32 110.075 602.322 106.587 600.741C109.888 596.203 113.1 595.955 116.56 600.036C117.395 601.024 118.162 602.108 118.77 603.255C121.878 609.111 123.839 615.375 125.124 621.853C125.455 623.511 125.697 625.189 125.98 626.86C126.187 631.287 125.365 635.368 122.181 638.725C120.869 640.113 119.267 641.073 117.699 642.095C116.504 642.399 115.31 642.703 114.108 643.007C112.561 643.262 111.014 643.359 109.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 596.469C55.6849 597.108 54.622 597.729 53.8696 598.753C53.5765 599.152 53.1522 599.06 52.7367 599.011C50.4971 598.736 48.4587 600.084 47.8026 602.268C47.5051 603.261 47.6363 603.458 48.6686 603.462C53.7865 603.467 58.9043 603.462 64.0221 603.471C64.4946 603.471 64.967 603.524 65.435 603.55C67.3028 603.524 69.1662 603.48 71.034 603.489C71.7601 603.493 71.9876 603.266 71.8214 602.526C71.3183 600.285 69.1662 598.723 66.8829 599.007C66.4017 599.068 65.9949 599.121 65.6012 598.675C63.3179 596.062 60.4921 595.344 57.1984 596.359C57.0496 596.399 56.8965 596.429 56.7391 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 596.355C88.417 596.359 95.5339 596.364 102.655 596.381C103.39 596.381 103.499 596.075 103.355 595.436C102.918 593.488 101.22 592.14 99.1163 592.232C98.3552 592.267 97.8435 592.075 97.3054 591.514C94.554 588.652 90.1667 588.665 87.3847 591.528C86.8773 592.053 86.3961 592.35 85.6569 592.223C84.7821 592.075 83.9859 592.482 83.1548 592.661C82.1532 593.698 81.1121 594.718 81.2958 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 579.317C74.7127 578.096 72.32 577.886 69.9317 579.264C68.0026 580.38 66.9091 582.079 66.5285 584.271C66.4061 584.985 66.6116 585.247 67.3465 585.238C70.1329 585.217 72.9193 585.23 75.7013 585.23C78.2952 585.23 80.8934 585.234 83.4873 585.225C84.5065 585.221 84.629 585.028 84.3753 584.022C84.161 583.168 83.6579 582.481 83.1724 581.777C82.2582 581.085 81.3002 580.398 80.1061 580.468C78.8857 580.542 77.884 580.249 77.0879 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="175" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="260" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="609" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7490"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7490"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7490"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7490"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7490"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7490"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7490"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7490"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7490"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7490"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7490"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7490"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7490">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7490">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 42)"
            />
          </clipPath>
          <clipPath id="clip2_76_7490">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 216)"
            />
          </clipPath>
          <clipPath id="clip3_76_7490">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 390)"
            />
          </clipPath>
          <clipPath id="clip4_76_7490">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 564)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerRoundedSquareRight = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareRight">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7491)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7491)">
            <rect x="17" y="19" width="346" height="159" rx="14" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7491)">
            <rect
              x="17"
              y="193"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter2_d_76_7491)">
            <rect
              x="17"
              y="367"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_76_7491)">
            <rect
              x="17"
              y="541"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip1_76_7491)">
            <rect x="226" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 116.267C251.603 116.704 251.048 116.593 250.282 115.776C250.078 115.554 249.899 115.302 249.726 115.057C249.233 114.368 248.973 114.239 248.263 114.694C246.59 115.782 245.114 115.69 243.701 114.239C243.009 113.533 242.071 113.262 241.101 113.145C240.083 113.029 239.144 112.789 238.447 111.947C238.033 111.455 237.416 111.511 236.86 111.621C236.008 111.793 235.205 111.707 234.452 111.296C233.495 110.767 232.618 110.896 231.766 111.511C231.606 111.627 231.445 111.732 231.279 111.836C230.038 112.629 229.334 112.488 228.482 111.271C227.827 110.331 226.945 110.06 226 110.435V106.41C227.062 106.766 228.167 106.908 229.285 106.859C231.013 106.779 232.495 107.233 233.754 108.438C234.409 109.065 235.193 109.268 236.107 109.016C237.156 108.727 238.027 109.01 238.687 109.913C239.175 110.57 239.83 110.988 240.688 110.951C242.398 110.878 243.836 111.492 245.053 112.654C245.621 113.195 246.269 113.588 247.09 113.526C248.918 113.385 250.436 113.901 251.486 115.475C251.782 115.886 252.295 115.985 252.671 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 122.696L267.051 123.961C266.526 124.134 266.044 124.441 265.482 124.49C263.457 124.343 261.494 123.863 259.543 123.359C253.196 121.718 246.683 122.696 240.2 122.696C236.49 122.358 232.217 122.542 228.494 122.696C228.494 122.696 227.056 122.622 226.012 122.696V110.441C226.957 110.067 227.84 110.337 228.494 111.277C229.346 112.494 230.044 112.635 231.291 111.843C231.458 111.738 231.618 111.634 231.779 111.517C232.631 110.902 233.508 110.773 234.465 111.302C235.218 111.714 236.02 111.793 236.872 111.628C237.428 111.517 238.045 111.462 238.459 111.953C239.157 112.795 240.095 113.035 241.114 113.152C242.083 113.262 243.022 113.533 243.713 114.245C245.127 115.696 246.603 115.788 248.276 114.7C248.979 114.239 249.245 114.375 249.739 115.063C249.918 115.309 250.091 115.561 250.295 115.782C251.06 116.605 251.616 116.71 252.684 116.273C253.091 116.31 253.493 116.353 253.912 116.335C254.789 116.31 255.314 116.876 255.61 117.613C255.882 118.302 256.314 118.64 257.074 118.646C257.821 118.652 258.45 119.027 258.994 119.506C259.901 120.299 260.963 120.668 262.155 120.686C263.105 120.698 263.933 121.012 264.674 121.595C265.371 122.149 266.131 122.609 267.057 122.696Z"
              fill="#66646F"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V116.205C238.246 117.968 260.187 120.991 283.359 114.902C305.48 109.084 324.779 105.413 339 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V120.817C238.246 122.58 262.094 134.139 283.359 123.208C305.913 111.606 324.779 105.413 339 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 117.312C321.64 116.619 321.644 115.93 321.644 115.237C321.659 114.728 321.674 114.216 321.689 113.708L321.659 113.678C322.495 113.915 323.339 113.862 324.182 113.723C324.197 114.559 324.213 115.391 324.228 116.228C324.216 117.467 324.209 118.702 324.186 119.942C324.171 120.781 323.987 121.565 322.947 121.576C321.907 121.588 321.704 120.812 321.681 119.968C321.659 119.079 321.655 118.198 321.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 113.708C317.79 112.849 315.105 109.93 315.278 106.174C315.538 100.63 317.067 95.4017 320.077 90.6707C321.975 91.5333 323.018 93.168 323.881 94.9459C325.192 97.6504 326.02 100.513 326.559 103.462C326.819 104.89 327.166 106.321 326.898 107.794C326.348 110.819 324.499 112.691 321.655 113.678L321.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 113.674C324.502 112.687 326.352 110.815 326.906 107.79C327.173 106.321 326.826 104.886 326.567 103.459C326.028 100.513 325.199 97.6466 323.888 94.9421C323.026 93.1643 321.986 91.5295 320.084 90.667C321.885 88.1923 323.636 88.0567 325.523 90.2828C325.979 90.8214 326.397 91.4128 326.729 92.038C328.424 95.2322 329.493 98.6485 330.194 102.182C330.375 103.086 330.506 104.001 330.661 104.912C330.774 107.327 330.326 109.553 328.589 111.384C327.874 112.141 327 112.664 326.145 113.222C325.493 113.387 324.841 113.553 324.186 113.719C323.342 113.858 322.499 113.911 321.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 127.595C296.439 126.325 296.446 125.061 296.446 123.79C296.474 122.858 296.501 121.918 296.529 120.986L296.474 120.931C298.007 121.366 299.554 121.269 301.101 121.014C301.129 122.547 301.156 124.073 301.184 125.606C301.163 127.878 301.149 130.144 301.108 132.416C301.08 133.956 300.742 135.392 298.836 135.413C296.93 135.434 296.557 134.011 296.515 132.464C296.474 130.834 296.467 129.218 296.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 120.986C289.381 119.411 284.457 114.059 284.775 107.174C285.251 97.0078 288.055 87.422 293.573 78.7478C297.054 80.3293 298.967 83.3266 300.549 86.5863C302.952 91.545 304.471 96.7937 305.459 102.201C305.936 104.819 306.571 107.443 306.081 110.143C305.072 115.689 301.681 119.121 296.467 120.931L296.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 120.924C301.688 119.114 305.079 115.682 306.094 110.136C306.585 107.443 305.949 104.812 305.473 102.194C304.485 96.7936 302.966 91.538 300.562 86.5794C298.981 83.3197 297.075 80.3224 293.587 78.7409C296.888 74.2035 300.1 73.9549 303.56 78.0364C304.395 79.024 305.162 80.1083 305.77 81.2547C308.878 87.1112 310.839 93.3751 312.124 99.8531C312.455 101.511 312.697 103.189 312.98 104.86C313.187 109.287 312.365 113.368 309.181 116.725C307.869 118.113 306.267 119.073 304.699 120.095C303.504 120.399 302.31 120.703 301.108 121.007C299.561 121.262 298.014 121.359 296.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 74.4687C242.685 75.1077 241.622 75.7292 240.87 76.7533C240.577 77.1516 240.152 77.0597 239.737 77.0115C237.497 76.7358 235.459 78.0838 234.803 80.2677C234.505 81.2612 234.636 81.4581 235.669 81.4625C240.787 81.4669 245.904 81.4625 251.022 81.4712C251.495 81.4712 251.967 81.5238 252.435 81.55C254.303 81.5238 256.166 81.48 258.034 81.4888C258.76 81.4931 258.988 81.2656 258.821 80.5259C258.318 78.2851 256.166 76.7227 253.883 77.0072C253.402 77.0684 252.995 77.1209 252.601 76.6745C250.318 74.0617 247.492 73.344 244.198 74.3593C244.05 74.3987 243.897 74.4294 243.739 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 74.355C275.417 74.3593 282.534 74.3637 289.655 74.3812C290.39 74.3812 290.499 74.0749 290.355 73.4359C289.918 71.4883 288.22 70.1403 286.116 70.2322C285.355 70.2673 284.844 70.0747 284.306 69.5145C281.554 66.6522 277.167 66.6653 274.385 69.5276C273.877 70.0528 273.396 70.3504 272.657 70.2235C271.782 70.0747 270.986 70.4817 270.155 70.6611C269.153 71.6984 268.112 72.7181 268.296 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 57.317C261.713 56.0959 259.32 55.8858 256.932 57.2645C255.003 58.3805 253.909 60.0786 253.529 62.2712C253.406 62.9846 253.612 63.2472 254.347 63.2385C257.133 63.2166 259.919 63.2297 262.701 63.2297C265.295 63.2297 267.893 63.2341 270.487 63.2253C271.507 63.221 271.629 63.0284 271.375 62.0218C271.161 61.1683 270.658 60.4812 270.172 59.7766C269.258 59.0851 268.3 58.398 267.106 58.468C265.886 58.5424 264.884 58.2492 264.088 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip2_76_7491)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.603 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.074 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.213 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.987 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.906 281.79C327.173 280.321 326.826 278.886 326.567 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.885 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.729 266.038C328.424 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.874 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.499 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.094 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.562 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.395 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.181 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.504 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.787 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.844 244.075 284.306 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip3_76_7491)">
            <rect x="226" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 464.267C251.603 464.704 251.048 464.593 250.282 463.776C250.078 463.554 249.899 463.302 249.726 463.057C249.233 462.368 248.973 462.239 248.263 462.694C246.59 463.782 245.114 463.69 243.701 462.239C243.009 461.533 242.071 461.262 241.101 461.145C240.083 461.029 239.144 460.789 238.447 459.947C238.033 459.455 237.416 459.511 236.86 459.621C236.008 459.793 235.205 459.707 234.452 459.296C233.495 458.767 232.618 458.896 231.766 459.511C231.606 459.627 231.445 459.732 231.279 459.836C230.038 460.629 229.334 460.488 228.482 459.271C227.827 458.331 226.945 458.06 226 458.435V454.41C227.062 454.766 228.167 454.908 229.285 454.859C231.013 454.779 232.495 455.233 233.754 456.438C234.409 457.065 235.193 457.268 236.107 457.016C237.156 456.727 238.027 457.01 238.687 457.913C239.175 458.57 239.83 458.988 240.688 458.951C242.398 458.878 243.836 459.492 245.053 460.654C245.621 461.195 246.269 461.588 247.09 461.526C248.918 461.385 250.436 461.901 251.486 463.475C251.782 463.886 252.295 463.985 252.671 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 470.696L267.051 471.961C266.526 472.134 266.044 472.441 265.482 472.49C263.457 472.343 261.494 471.863 259.543 471.359C253.196 469.718 246.683 470.696 240.2 470.696C236.49 470.358 232.217 470.542 228.494 470.696C228.494 470.696 227.056 470.622 226.012 470.696V458.441C226.957 458.067 227.84 458.337 228.494 459.277C229.346 460.494 230.044 460.635 231.291 459.843C231.458 459.738 231.618 459.634 231.779 459.517C232.631 458.902 233.508 458.773 234.465 459.302C235.218 459.714 236.02 459.793 236.872 459.628C237.428 459.517 238.045 459.462 238.459 459.953C239.157 460.795 240.095 461.035 241.114 461.152C242.083 461.262 243.022 461.533 243.713 462.245C245.127 463.696 246.603 463.788 248.276 462.7C248.979 462.239 249.245 462.375 249.739 463.063C249.918 463.309 250.091 463.561 250.295 463.782C251.06 464.605 251.616 464.71 252.684 464.273C253.091 464.31 253.493 464.353 253.912 464.335C254.789 464.31 255.314 464.876 255.61 465.613C255.882 466.302 256.314 466.64 257.074 466.646C257.821 466.652 258.45 467.027 258.994 467.506C259.901 468.299 260.963 468.668 262.155 468.686C263.105 468.698 263.933 469.012 264.674 469.595C265.371 470.149 266.131 470.609 267.057 470.696Z"
              fill="#66646F"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V464.205C238.246 465.968 260.187 468.991 283.359 462.902C305.48 457.084 324.779 453.413 339 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V468.817C238.246 470.58 262.094 482.139 283.359 471.208C305.913 459.606 324.779 453.413 339 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 465.312C321.64 464.619 321.644 463.93 321.644 463.237C321.659 462.728 321.674 462.216 321.689 461.708L321.659 461.678C322.495 461.915 323.339 461.862 324.182 461.723C324.197 462.559 324.213 463.391 324.228 464.228C324.216 465.467 324.209 466.702 324.186 467.942C324.171 468.781 323.987 469.565 322.947 469.576C321.907 469.588 321.704 468.812 321.681 467.968C321.659 467.079 321.655 466.198 321.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 461.708C317.79 460.849 315.105 457.93 315.278 454.174C315.538 448.63 317.067 443.402 320.077 438.671C321.975 439.533 323.018 441.168 323.881 442.946C325.192 445.65 326.02 448.513 326.559 451.462C326.819 452.89 327.166 454.321 326.898 455.794C326.348 458.819 324.499 460.691 321.655 461.678L321.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 461.674C324.502 460.687 326.352 458.815 326.906 455.79C327.173 454.321 326.826 452.886 326.567 451.459C326.028 448.513 325.199 445.647 323.888 442.942C323.026 441.164 321.986 439.53 320.084 438.667C321.885 436.192 323.636 436.057 325.523 438.283C325.979 438.821 326.397 439.413 326.729 440.038C328.424 443.232 329.493 446.649 330.194 450.182C330.375 451.086 330.506 452.001 330.661 452.912C330.774 455.327 330.326 457.553 328.589 459.384C327.874 460.141 327 460.664 326.145 461.222C325.493 461.387 324.841 461.553 324.186 461.719C323.342 461.858 322.499 461.911 321.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 475.595C296.439 474.325 296.446 473.061 296.446 471.79C296.474 470.858 296.501 469.918 296.529 468.986L296.474 468.931C298.007 469.366 299.554 469.269 301.101 469.014C301.129 470.547 301.156 472.073 301.184 473.606C301.163 475.878 301.149 478.144 301.108 480.416C301.08 481.956 300.742 483.392 298.836 483.413C296.93 483.434 296.557 482.011 296.515 480.464C296.474 478.834 296.467 477.218 296.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 468.986C289.381 467.411 284.457 462.059 284.775 455.174C285.251 445.008 288.055 435.422 293.573 426.748C297.054 428.329 298.967 431.327 300.549 434.586C302.952 439.545 304.471 444.794 305.459 450.201C305.936 452.819 306.571 455.443 306.081 458.143C305.072 463.689 301.681 467.121 296.467 468.931L296.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 468.924C301.688 467.114 305.079 463.682 306.094 458.136C306.585 455.443 305.949 452.812 305.473 450.194C304.485 444.794 302.966 439.538 300.562 434.579C298.981 431.32 297.075 428.322 293.587 426.741C296.888 422.203 300.1 421.955 303.56 426.036C304.395 427.024 305.162 428.108 305.77 429.255C308.878 435.111 310.839 441.375 312.124 447.853C312.455 449.511 312.697 451.189 312.98 452.86C313.187 457.287 312.365 461.368 309.181 464.725C307.869 466.113 306.267 467.073 304.699 468.095C303.504 468.399 302.31 468.703 301.108 469.007C299.561 469.262 298.014 469.359 296.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 422.469C242.685 423.108 241.622 423.729 240.87 424.753C240.577 425.152 240.152 425.06 239.737 425.012C237.497 424.736 235.459 426.084 234.803 428.268C234.505 429.261 234.636 429.458 235.669 429.462C240.787 429.467 245.904 429.462 251.022 429.471C251.495 429.471 251.967 429.524 252.435 429.55C254.303 429.524 256.166 429.48 258.034 429.489C258.76 429.493 258.988 429.266 258.821 428.526C258.318 426.285 256.166 424.723 253.883 425.007C253.402 425.068 252.995 425.121 252.601 424.675C250.318 422.062 247.492 421.344 244.198 422.359C244.05 422.399 243.897 422.429 243.739 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 422.355C275.417 422.359 282.534 422.364 289.655 422.381C290.39 422.381 290.499 422.075 290.355 421.436C289.918 419.488 288.22 418.14 286.116 418.232C285.355 418.267 284.844 418.075 284.306 417.514C281.554 414.652 277.167 414.665 274.385 417.528C273.877 418.053 273.396 418.35 272.657 418.223C271.782 418.075 270.986 418.482 270.155 418.661C269.153 419.698 268.112 420.718 268.296 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 405.317C261.713 404.096 259.32 403.886 256.932 405.264C255.003 406.38 253.909 408.079 253.529 410.271C253.406 410.985 253.612 411.247 254.347 411.238C257.133 411.217 259.919 411.23 262.701 411.23C265.295 411.23 267.893 411.234 270.487 411.225C271.507 411.221 271.629 411.028 271.375 410.022C271.161 409.168 270.658 408.481 270.172 407.777C269.258 407.085 268.3 406.398 267.106 406.468C265.886 406.542 264.884 406.249 264.088 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip4_76_7491)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.962C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.246C245.127 637.696 246.603 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.274C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.074 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.596C265.371 644.149 266.131 644.61 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.213 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.987 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.906 629.79C327.173 628.321 326.826 626.886 326.567 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.885 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.729 614.038C328.424 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.874 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.499 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.094 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.562 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.395 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.181 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.504 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.787 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.844 592.075 284.306 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="41" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7491"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7491"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7491"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7491"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7491"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7491"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7491"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7491"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7491"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7491"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7491"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7491"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7491">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7491">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 42)"
            />
          </clipPath>
          <clipPath id="clip2_76_7491">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 216)"
            />
          </clipPath>
          <clipPath id="clip3_76_7491">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 390)"
            />
          </clipPath>
          <clipPath id="clip4_76_7491">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 564)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerRoundedSquareAlt = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareAlt">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7492)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7492)">
            <rect x="17" y="19" width="346" height="159" rx="14" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7492)">
            <rect
              x="17"
              y="193"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter2_d_76_7492)">
            <rect
              x="17"
              y="367"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_76_7492)">
            <rect
              x="17"
              y="541"
              width="346"
              height="159"
              rx="14"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip1_76_7492)">
            <rect x="41" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 116.267C66.6033 116.704 66.0477 116.593 65.2821 115.776C65.0783 115.554 64.8993 115.302 64.7264 115.057C64.2325 114.368 63.9732 114.239 63.2632 114.694C61.5901 115.782 60.1145 115.69 58.7007 114.239C58.0092 113.533 57.0707 113.262 56.1014 113.145C55.0827 113.029 54.1443 112.789 53.4467 111.947C53.033 111.455 52.4156 111.511 51.8599 111.621C51.0079 111.793 50.2053 111.707 49.4521 111.296C48.4952 110.767 47.6185 110.896 46.7665 111.511C46.6059 111.627 46.4454 111.732 46.2787 111.836C45.0378 112.629 44.3339 112.488 43.4819 111.271C42.8275 110.331 41.9446 110.06 41 110.435V106.41C42.0619 106.766 43.1671 106.908 44.2845 106.859C46.0132 106.779 47.495 107.233 48.7545 108.438C49.4089 109.065 50.193 109.268 51.1067 109.016C52.1563 108.727 53.0268 109.01 53.6874 109.913C54.1752 110.57 54.8296 110.988 55.6878 110.951C57.398 110.878 58.8365 111.492 60.0528 112.654C60.6208 113.195 61.269 113.588 62.0902 113.526C63.9176 113.385 65.4364 113.901 66.486 115.475C66.7823 115.886 67.2948 115.985 67.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0568 122.696L82.0506 123.961C81.5258 124.134 81.0442 124.441 80.4824 124.49C78.4574 124.343 76.494 123.863 74.5431 123.359C68.1963 121.718 61.6828 122.696 55.2001 122.696C51.4896 122.358 47.2173 122.542 43.4944 122.696C43.4944 122.696 42.0558 122.622 41.0125 122.696V110.441C41.9571 110.067 42.8399 110.337 43.4944 111.277C44.3464 112.494 45.044 112.635 46.2912 111.843C46.4579 111.738 46.6184 111.634 46.7789 111.517C47.6309 110.902 48.5076 110.773 49.4646 111.302C50.2178 111.714 51.0204 111.793 51.8724 111.628C52.4281 111.517 53.0454 111.462 53.4591 111.953C54.1568 112.795 55.0952 113.035 56.1139 113.152C57.0832 113.262 58.0216 113.533 58.7131 114.245C60.1269 115.696 61.6025 115.788 63.2757 114.7C63.9795 114.239 64.245 114.375 64.7389 115.063C64.9179 115.309 65.0908 115.561 65.2945 115.782C66.0601 116.605 66.6158 116.71 67.6838 116.273C68.0913 116.31 68.4926 116.353 68.9125 116.335C69.7892 116.31 70.3139 116.876 70.6103 117.613C70.8819 118.302 71.3141 118.64 72.0735 118.646C72.8206 118.652 73.4503 119.027 73.9936 119.506C74.9012 120.299 75.9631 120.668 77.1547 120.686C78.1054 120.698 78.9327 121.012 79.6736 121.595C80.3713 122.149 81.1307 122.609 82.0568 122.696Z"
              fill="#66646F"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V116.205C53.2464 117.968 75.1866 120.991 98.359 114.902C120.48 109.084 139.779 105.413 154 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V120.817C53.2464 122.58 77.0945 134.139 98.359 123.208C120.913 111.606 139.779 105.413 154 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 117.312C136.64 116.619 136.644 115.93 136.644 115.237C136.659 114.728 136.674 114.216 136.689 113.708L136.659 113.678C137.495 113.915 138.339 113.862 139.182 113.723C139.197 114.559 139.213 115.391 139.228 116.228C139.216 117.467 139.209 118.702 139.186 119.942C139.171 120.781 138.987 121.565 137.947 121.576C136.907 121.588 136.704 120.812 136.681 119.968C136.659 119.079 136.655 118.198 136.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 113.708C132.79 112.849 130.105 109.93 130.278 106.174C130.538 100.63 132.067 95.4017 135.077 90.6707C136.975 91.5333 138.018 93.168 138.881 94.9459C140.192 97.6504 141.02 100.513 141.559 103.462C141.819 104.89 142.166 106.321 141.898 107.794C141.348 110.819 139.499 112.691 136.655 113.678L136.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 113.674C139.502 112.687 141.352 110.815 141.906 107.79C142.173 106.321 141.826 104.886 141.567 103.459C141.028 100.513 140.199 97.6466 138.888 94.9421C138.026 93.1643 136.986 91.5295 135.084 90.667C136.885 88.1923 138.636 88.0567 140.523 90.2828C140.979 90.8214 141.397 91.4128 141.729 92.038C143.424 95.2322 144.493 98.6485 145.194 102.182C145.375 103.086 145.506 104.001 145.661 104.912C145.774 107.327 145.326 109.553 143.589 111.384C142.874 112.141 142 112.664 141.145 113.222C140.493 113.387 139.841 113.553 139.186 113.719C138.342 113.858 137.499 113.911 136.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 127.595C111.439 126.325 111.446 125.061 111.446 123.79C111.474 122.858 111.501 121.918 111.529 120.986L111.474 120.931C113.007 121.366 114.554 121.269 116.101 121.014C116.129 122.547 116.156 124.073 116.184 125.606C116.163 127.878 116.149 130.144 116.108 132.416C116.08 133.956 115.742 135.392 113.836 135.413C111.93 135.434 111.557 134.011 111.515 132.464C111.474 130.834 111.467 129.218 111.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 120.986C104.381 119.411 99.4568 114.059 99.7745 107.174C100.251 97.0078 103.055 87.422 108.573 78.7478C112.054 80.3293 113.967 83.3266 115.549 86.5863C117.952 91.545 119.471 96.7937 120.459 102.201C120.936 104.819 121.571 107.443 121.081 110.143C120.072 115.689 116.681 119.121 111.467 120.931L111.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 120.924C116.688 119.114 120.079 115.682 121.094 110.136C121.585 107.443 120.949 104.812 120.473 102.194C119.485 96.7936 117.966 91.538 115.562 86.5794C113.981 83.3197 112.075 80.3224 108.587 78.7409C111.888 74.2035 115.1 73.9549 118.56 78.0364C119.395 79.024 120.162 80.1083 120.77 81.2547C123.878 87.1112 125.839 93.3751 127.124 99.8531C127.455 101.511 127.697 103.189 127.98 104.86C128.187 109.287 127.365 113.368 124.181 116.725C122.869 118.113 121.267 119.073 119.699 120.095C118.504 120.399 117.31 120.703 116.108 121.007C114.561 121.262 113.014 121.359 111.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7392 74.4687C57.685 75.1077 56.6221 75.7292 55.8697 76.7533C55.5766 77.1516 55.1523 77.0597 54.7368 77.0115C52.4972 76.7358 50.4588 78.0838 49.8027 80.2677C49.5052 81.2612 49.6365 81.4581 50.6688 81.4625C55.7866 81.4669 60.9044 81.4625 66.0223 81.4712C66.4947 81.4712 66.9671 81.5238 67.4351 81.55C69.3029 81.5238 71.1663 81.48 73.0341 81.4888C73.7602 81.4931 73.9877 81.2656 73.8215 80.5259C73.3184 78.2851 71.1663 76.7227 68.883 77.0072C68.4018 77.0684 67.995 77.1209 67.6013 76.6745C65.318 74.0617 62.4923 73.344 59.1985 74.3593C59.0498 74.3987 58.8967 74.4294 58.7392 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2959 74.355C90.4171 74.3593 97.534 74.3637 104.655 74.3812C105.39 74.3812 105.499 74.0749 105.355 73.4359C104.918 71.4883 103.22 70.1403 101.116 70.2322C100.355 70.2673 99.8436 70.0747 99.3055 69.5145C96.5542 66.6522 92.1668 66.6653 89.3848 69.5276C88.8774 70.0528 88.3963 70.3504 87.657 70.2235C86.7822 70.0747 85.9861 70.4817 85.155 70.6611C84.1533 71.6984 83.1122 72.7181 83.2959 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 57.317C76.7127 56.0959 74.32 55.8858 71.9317 57.2645C70.0026 58.3805 68.9091 60.0786 68.5285 62.2712C68.4061 62.9846 68.6116 63.2472 69.3465 63.2385C72.1329 63.2166 74.9193 63.2297 77.7013 63.2297C80.2952 63.2297 82.8934 63.2341 85.4873 63.2253C86.5065 63.221 86.629 63.0284 86.3753 62.0218C86.161 61.1683 85.6579 60.4812 85.1724 59.7766C84.2582 59.0851 83.3002 58.398 82.1061 58.468C80.8857 58.5424 79.884 58.2492 79.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip2_76_7492)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.603 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.074 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.213 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.987 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.906 281.79C327.173 280.321 326.826 278.886 326.567 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.885 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.729 266.038C328.424 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.874 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.499 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.094 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.562 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.395 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.181 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.504 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.787 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.844 244.075 284.306 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip3_76_7492)">
            <rect x="41" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 464.267C66.6033 464.704 66.0477 464.593 65.2821 463.776C65.0783 463.554 64.8993 463.302 64.7264 463.057C64.2325 462.368 63.9732 462.239 63.2632 462.694C61.5901 463.782 60.1145 463.69 58.7007 462.239C58.0092 461.533 57.0707 461.262 56.1014 461.145C55.0827 461.029 54.1443 460.789 53.4467 459.947C53.033 459.455 52.4156 459.511 51.8599 459.621C51.0079 459.793 50.2053 459.707 49.4521 459.296C48.4952 458.767 47.6185 458.896 46.7665 459.511C46.6059 459.627 46.4454 459.732 46.2787 459.836C45.0378 460.629 44.3339 460.488 43.4819 459.271C42.8275 458.331 41.9446 458.06 41 458.435V454.41C42.0619 454.766 43.1671 454.908 44.2845 454.859C46.0132 454.779 47.495 455.233 48.7545 456.438C49.4089 457.065 50.193 457.268 51.1067 457.016C52.1563 456.727 53.0268 457.01 53.6874 457.913C54.1752 458.57 54.8296 458.988 55.6878 458.951C57.398 458.878 58.8365 459.492 60.0528 460.654C60.6208 461.195 61.269 461.588 62.0902 461.526C63.9176 461.385 65.4364 461.901 66.486 463.475C66.7823 463.886 67.2948 463.985 67.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0568 470.696L82.0506 471.961C81.5258 472.134 81.0442 472.441 80.4824 472.49C78.4574 472.343 76.494 471.863 74.5431 471.359C68.1963 469.718 61.6828 470.696 55.2001 470.696C51.4896 470.358 47.2173 470.542 43.4944 470.696C43.4944 470.696 42.0558 470.622 41.0125 470.696V458.441C41.9571 458.067 42.8399 458.337 43.4944 459.277C44.3464 460.494 45.044 460.635 46.2912 459.843C46.4579 459.738 46.6184 459.634 46.7789 459.517C47.6309 458.902 48.5076 458.773 49.4646 459.302C50.2178 459.714 51.0204 459.793 51.8724 459.628C52.4281 459.517 53.0454 459.462 53.4591 459.953C54.1568 460.795 55.0952 461.035 56.1139 461.152C57.0832 461.262 58.0216 461.533 58.7131 462.245C60.1269 463.696 61.6025 463.788 63.2757 462.7C63.9795 462.239 64.245 462.375 64.7389 463.063C64.9179 463.309 65.0908 463.561 65.2945 463.782C66.0601 464.605 66.6158 464.71 67.6838 464.273C68.0913 464.31 68.4926 464.353 68.9125 464.335C69.7892 464.31 70.3139 464.876 70.6103 465.613C70.8819 466.302 71.3141 466.64 72.0735 466.646C72.8206 466.652 73.4503 467.027 73.9936 467.506C74.9012 468.299 75.9631 468.668 77.1547 468.686C78.1054 468.698 78.9327 469.012 79.6736 469.595C80.3713 470.149 81.1307 470.609 82.0568 470.696Z"
              fill="#66646F"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V464.205C53.2464 465.968 75.1866 468.991 98.359 462.902C120.48 457.084 139.779 453.413 154 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V468.817C53.2464 470.58 77.0945 482.139 98.359 471.208C120.913 459.606 139.779 453.413 154 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 465.312C136.64 464.619 136.644 463.93 136.644 463.237C136.659 462.728 136.674 462.216 136.689 461.708L136.659 461.678C137.495 461.915 138.339 461.862 139.182 461.723C139.197 462.559 139.213 463.391 139.228 464.228C139.216 465.467 139.209 466.702 139.186 467.942C139.171 468.781 138.987 469.565 137.947 469.576C136.907 469.588 136.704 468.812 136.681 467.968C136.659 467.079 136.655 466.198 136.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 461.708C132.79 460.849 130.105 457.93 130.278 454.174C130.538 448.63 132.067 443.402 135.077 438.671C136.975 439.533 138.018 441.168 138.881 442.946C140.192 445.65 141.02 448.513 141.559 451.462C141.819 452.89 142.166 454.321 141.898 455.794C141.348 458.819 139.499 460.691 136.655 461.678L136.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 461.674C139.502 460.687 141.352 458.815 141.906 455.79C142.173 454.321 141.826 452.886 141.567 451.459C141.028 448.513 140.199 445.647 138.888 442.942C138.026 441.164 136.986 439.53 135.084 438.667C136.885 436.192 138.636 436.057 140.523 438.283C140.979 438.821 141.397 439.413 141.729 440.038C143.424 443.232 144.493 446.649 145.194 450.182C145.375 451.086 145.506 452.001 145.661 452.912C145.774 455.327 145.326 457.553 143.589 459.384C142.874 460.141 142 460.664 141.145 461.222C140.493 461.387 139.841 461.553 139.186 461.719C138.342 461.858 137.499 461.911 136.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 475.595C111.439 474.325 111.446 473.061 111.446 471.79C111.474 470.858 111.501 469.918 111.529 468.986L111.474 468.931C113.007 469.366 114.554 469.269 116.101 469.014C116.129 470.547 116.156 472.073 116.184 473.606C116.163 475.878 116.149 478.144 116.108 480.416C116.08 481.956 115.742 483.392 113.836 483.413C111.93 483.434 111.557 482.011 111.515 480.464C111.474 478.834 111.467 477.218 111.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 468.986C104.381 467.411 99.4568 462.059 99.7745 455.174C100.251 445.008 103.055 435.422 108.573 426.748C112.054 428.329 113.967 431.327 115.549 434.586C117.952 439.545 119.471 444.794 120.459 450.201C120.936 452.819 121.571 455.443 121.081 458.143C120.072 463.689 116.681 467.121 111.467 468.931L111.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 468.924C116.688 467.114 120.079 463.682 121.094 458.136C121.585 455.443 120.949 452.812 120.473 450.194C119.485 444.794 117.966 439.538 115.562 434.579C113.981 431.32 112.075 428.322 108.587 426.741C111.888 422.203 115.1 421.955 118.56 426.036C119.395 427.024 120.162 428.108 120.77 429.255C123.878 435.111 125.839 441.375 127.124 447.853C127.455 449.511 127.697 451.189 127.98 452.86C128.187 457.287 127.365 461.368 124.181 464.725C122.869 466.113 121.267 467.073 119.699 468.095C118.504 468.399 117.31 468.703 116.108 469.007C114.561 469.262 113.014 469.359 111.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7392 422.469C57.685 423.108 56.6221 423.729 55.8697 424.753C55.5766 425.152 55.1523 425.06 54.7368 425.012C52.4972 424.736 50.4588 426.084 49.8027 428.268C49.5052 429.261 49.6365 429.458 50.6688 429.462C55.7866 429.467 60.9044 429.462 66.0223 429.471C66.4947 429.471 66.9671 429.524 67.4351 429.55C69.3029 429.524 71.1663 429.48 73.0341 429.489C73.7602 429.493 73.9877 429.266 73.8215 428.526C73.3184 426.285 71.1663 424.723 68.883 425.007C68.4018 425.068 67.995 425.121 67.6013 424.675C65.318 422.062 62.4923 421.344 59.1985 422.359C59.0498 422.399 58.8967 422.429 58.7392 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2959 422.355C90.4171 422.359 97.534 422.364 104.655 422.381C105.39 422.381 105.499 422.075 105.355 421.436C104.918 419.488 103.22 418.14 101.116 418.232C100.355 418.267 99.8436 418.075 99.3055 417.514C96.5542 414.652 92.1668 414.665 89.3848 417.528C88.8774 418.053 88.3963 418.35 87.657 418.223C86.7822 418.075 85.9861 418.482 85.155 418.661C84.1533 419.698 83.1122 420.718 83.2959 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 405.317C76.7127 404.096 74.32 403.886 71.9317 405.264C70.0026 406.38 68.9091 408.079 68.5285 410.271C68.4061 410.985 68.6116 411.247 69.3465 411.238C72.1329 411.217 74.9193 411.23 77.7013 411.23C80.2952 411.23 82.8934 411.234 85.4873 411.225C86.5065 411.221 86.629 411.028 86.3753 410.022C86.161 409.168 85.6579 408.481 85.1724 407.777C84.2582 407.085 83.3002 406.398 82.1061 406.468C80.8857 406.542 79.884 406.249 79.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip4_76_7492)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.962C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.246C245.127 637.696 246.603 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.274C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.074 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.596C265.371 644.149 266.131 644.61 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.213 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.987 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.906 629.79C327.173 628.321 326.826 626.886 326.567 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.885 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.729 614.038C328.424 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.874 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.499 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.094 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.562 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.395 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.181 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.504 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.787 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.844 592.075 284.306 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="176" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="177" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7492"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7492"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7492"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7492"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7492"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7492"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7492"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7492"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7492">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7492">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(41 42)"
            />
          </clipPath>
          <clipPath id="clip2_76_7492">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 216)"
            />
          </clipPath>
          <clipPath id="clip3_76_7492">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(41 390)"
            />
          </clipPath>
          <clipPath id="clip4_76_7492">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 564)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// Container Square / Image Rounded / Position
const CollectionContainerSquareRoundedLeft = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareLeft">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7493)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7493)">
            <rect x="17" y="19" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7493)">
            <rect x="17" y="193" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter2_d_76_7493)">
            <rect x="17" y="367" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter3_d_76_7493)">
            <rect x="17" y="541" width="346" height="159" fill="white" />
          </g>
          <mask
            id="mask0_76_7493"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="42"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="98.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7493)">
            <rect x="39" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 116.267C64.6033 116.704 64.0477 116.593 63.2821 115.776C63.0783 115.554 62.8993 115.302 62.7264 115.057C62.2325 114.368 61.9732 114.239 61.2632 114.694C59.5901 115.782 58.1145 115.69 56.7007 114.239C56.0092 113.533 55.0707 113.262 54.1014 113.145C53.0827 113.029 52.1443 112.789 51.4467 111.947C51.033 111.455 50.4156 111.511 49.8599 111.621C49.0079 111.793 48.2053 111.707 47.4521 111.296C46.4952 110.767 45.6185 110.896 44.7665 111.511C44.6059 111.627 44.4454 111.732 44.2787 111.836C43.0378 112.629 42.3339 112.488 41.4819 111.271C40.8275 110.331 39.9446 110.06 39 110.435V106.41C40.0619 106.766 41.1671 106.908 42.2845 106.859C44.0132 106.779 45.495 107.233 46.7545 108.438C47.4089 109.065 48.193 109.268 49.1067 109.016C50.1563 108.727 51.0268 109.01 51.6874 109.913C52.1752 110.57 52.8296 110.988 53.6878 110.951C55.398 110.878 56.8365 111.492 58.0528 112.654C58.6208 113.195 59.269 113.588 60.0902 113.526C61.9176 113.385 63.4364 113.901 64.486 115.475C64.7823 115.886 65.2948 115.985 65.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 122.696L80.0506 123.961C79.5258 124.134 79.0442 124.441 78.4824 124.49C76.4574 124.343 74.494 123.863 72.5431 123.359C66.1963 121.718 59.6828 122.696 53.2001 122.696C49.4896 122.358 45.2173 122.542 41.4944 122.696C41.4944 122.696 40.0558 122.622 39.0125 122.696V110.441C39.9571 110.067 40.8399 110.337 41.4944 111.277C42.3464 112.494 43.044 112.635 44.2912 111.843C44.4579 111.738 44.6184 111.634 44.7789 111.517C45.6309 110.902 46.5076 110.773 47.4646 111.302C48.2178 111.714 49.0204 111.793 49.8724 111.628C50.4281 111.517 51.0454 111.462 51.4591 111.953C52.1568 112.795 53.0952 113.035 54.1139 113.152C55.0832 113.262 56.0216 113.533 56.7131 114.245C58.1269 115.696 59.6025 115.788 61.2757 114.7C61.9795 114.239 62.245 114.375 62.7389 115.063C62.9179 115.309 63.0908 115.561 63.2945 115.782C64.0601 116.605 64.6158 116.71 65.6838 116.273C66.0913 116.31 66.4926 116.353 66.9125 116.335C67.7892 116.31 68.3139 116.876 68.6103 117.613C68.8819 118.302 69.3141 118.64 70.0735 118.646C70.8206 118.652 71.4503 119.027 71.9936 119.506C72.9012 120.299 73.9631 120.668 75.1547 120.686C76.1054 120.698 76.9327 121.012 77.6736 121.595C78.3713 122.149 79.1307 122.609 80.0568 122.696Z"
              fill="#66646F"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V116.205C51.2464 117.968 73.1866 120.991 96.359 114.902C118.48 109.084 137.779 105.413 152 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V120.817C51.2464 122.58 75.0945 134.139 96.359 123.208C118.913 111.606 137.779 105.413 152 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 117.312C134.64 116.619 134.644 115.93 134.644 115.237C134.659 114.728 134.674 114.216 134.689 113.708L134.659 113.678C135.495 113.915 136.339 113.862 137.182 113.723C137.197 114.559 137.213 115.391 137.228 116.228C137.216 117.467 137.209 118.702 137.186 119.942C137.171 120.781 136.987 121.565 135.947 121.576C134.907 121.588 134.704 120.812 134.681 119.968C134.659 119.079 134.655 118.198 134.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 113.708C130.79 112.849 128.105 109.93 128.278 106.174C128.538 100.63 130.067 95.4017 133.077 90.6707C134.975 91.5333 136.018 93.168 136.881 94.9459C138.192 97.6504 139.02 100.513 139.559 103.462C139.819 104.89 140.166 106.321 139.898 107.794C139.348 110.819 137.499 112.691 134.655 113.678L134.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 113.674C137.502 112.687 139.352 110.815 139.906 107.79C140.173 106.321 139.826 104.886 139.567 103.459C139.028 100.513 138.199 97.6466 136.888 94.9421C136.026 93.1643 134.986 91.5295 133.084 90.667C134.885 88.1923 136.636 88.0567 138.523 90.2828C138.979 90.8214 139.397 91.4128 139.729 92.038C141.424 95.2322 142.493 98.6485 143.194 102.182C143.375 103.086 143.506 104.001 143.661 104.912C143.774 107.327 143.326 109.553 141.589 111.384C140.874 112.141 140 112.664 139.145 113.222C138.493 113.387 137.841 113.553 137.186 113.719C136.342 113.858 135.499 113.911 134.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 127.595C109.439 126.325 109.446 125.061 109.446 123.79C109.474 122.858 109.501 121.918 109.529 120.986L109.474 120.931C111.007 121.366 112.554 121.269 114.101 121.014C114.129 122.547 114.156 124.073 114.184 125.606C114.163 127.878 114.149 130.144 114.108 132.416C114.08 133.956 113.742 135.392 111.836 135.413C109.93 135.434 109.557 134.011 109.515 132.464C109.474 130.834 109.467 129.218 109.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 120.986C102.381 119.411 97.4568 114.059 97.7745 107.174C98.2511 97.0078 101.055 87.422 106.573 78.7478C110.054 80.3293 111.967 83.3266 113.549 86.5863C115.952 91.545 117.471 96.7937 118.459 102.201C118.936 104.819 119.571 107.443 119.081 110.143C118.072 115.689 114.681 119.121 109.467 120.931L109.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 120.924C114.688 119.114 118.079 115.682 119.095 110.136C119.585 107.443 118.949 104.812 118.473 102.194C117.485 96.7936 115.966 91.538 113.563 86.5794C111.981 83.3197 110.075 80.3224 106.587 78.7409C109.888 74.2035 113.1 73.9549 116.56 78.0364C117.396 79.024 118.162 80.1083 118.77 81.2547C121.878 87.1112 123.839 93.3751 125.124 99.8531C125.455 101.511 125.697 103.189 125.98 104.86C126.187 109.287 125.365 113.368 122.182 116.725C120.869 118.113 119.267 119.073 117.699 120.095C116.505 120.399 115.31 120.703 114.108 121.007C112.561 121.262 111.014 121.359 109.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7392 74.4687C55.685 75.1077 54.6221 75.7292 53.8697 76.7533C53.5766 77.1516 53.1523 77.0597 52.7368 77.0115C50.4972 76.7358 48.4588 78.0838 47.8027 80.2677C47.5052 81.2612 47.6365 81.4581 48.6688 81.4625C53.7866 81.4669 58.9044 81.4625 64.0223 81.4712C64.4947 81.4712 64.9671 81.5238 65.4351 81.55C67.3029 81.5238 69.1663 81.48 71.0341 81.4888C71.7602 81.4931 71.9877 81.2656 71.8215 80.5259C71.3184 78.2851 69.1663 76.7227 66.883 77.0072C66.4018 77.0684 65.995 77.1209 65.6013 76.6745C63.318 74.0617 60.4923 73.344 57.1985 74.3593C57.0498 74.3987 56.8967 74.4294 56.7392 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 74.355C88.4171 74.3593 95.534 74.3637 102.655 74.3812C103.39 74.3812 103.499 74.0749 103.355 73.4359C102.918 71.4883 101.22 70.1403 99.1165 70.2322C98.3554 70.2673 97.8436 70.0747 97.3055 69.5145C94.5542 66.6522 90.1668 66.6653 87.3848 69.5276C86.8774 70.0528 86.3963 70.3504 85.657 70.2235C84.7822 70.0747 83.9861 70.4817 83.155 70.6611C82.1533 71.6984 81.1122 72.7181 81.2959 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 57.317C74.7127 56.0959 72.32 55.8858 69.9317 57.2645C68.0026 58.3805 66.9091 60.0786 66.5285 62.2712C66.4061 62.9846 66.6116 63.2472 67.3465 63.2385C70.1329 63.2166 72.9193 63.2297 75.7013 63.2297C78.2952 63.2297 80.8934 63.2341 83.4873 63.2253C84.5065 63.221 84.629 63.0284 84.3753 62.0218C84.161 61.1683 83.6579 60.4812 83.1724 59.7766C82.2582 59.0851 81.3002 58.398 80.1061 58.468C78.8857 58.5424 77.884 58.2492 77.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask1_76_7493"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="216"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="272.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask1_76_7493)">
            <rect x="39" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 290.267C64.6033 290.704 64.0477 290.593 63.2821 289.776C63.0783 289.554 62.8993 289.302 62.7264 289.057C62.2325 288.368 61.9732 288.239 61.2632 288.694C59.5901 289.782 58.1145 289.69 56.7007 288.239C56.0092 287.533 55.0707 287.262 54.1014 287.145C53.0827 287.029 52.1443 286.789 51.4467 285.947C51.033 285.455 50.4156 285.511 49.8599 285.621C49.0079 285.793 48.2053 285.707 47.4521 285.296C46.4952 284.767 45.6185 284.896 44.7665 285.511C44.6059 285.627 44.4454 285.732 44.2787 285.836C43.0378 286.629 42.3339 286.488 41.4819 285.271C40.8275 284.331 39.9446 284.06 39 284.435V280.41C40.0619 280.766 41.1671 280.908 42.2845 280.859C44.0132 280.779 45.495 281.233 46.7545 282.438C47.4089 283.065 48.193 283.268 49.1067 283.016C50.1563 282.727 51.0268 283.01 51.6874 283.913C52.1752 284.57 52.8296 284.988 53.6878 284.951C55.398 284.878 56.8365 285.492 58.0528 286.654C58.6208 287.195 59.269 287.588 60.0902 287.526C61.9176 287.385 63.4364 287.901 64.486 289.475C64.7823 289.886 65.2948 289.985 65.6714 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 296.696L80.0506 297.961C79.5258 298.134 79.0442 298.441 78.4824 298.49C76.4574 298.343 74.494 297.863 72.5431 297.359C66.1963 295.718 59.6828 296.696 53.2001 296.696C49.4896 296.358 45.2173 296.542 41.4944 296.696C41.4944 296.696 40.0558 296.622 39.0125 296.696V284.441C39.9571 284.067 40.8399 284.337 41.4944 285.277C42.3464 286.494 43.044 286.635 44.2912 285.843C44.4579 285.738 44.6184 285.634 44.7789 285.517C45.6309 284.902 46.5076 284.773 47.4646 285.302C48.2178 285.714 49.0204 285.793 49.8724 285.628C50.4281 285.517 51.0454 285.462 51.4591 285.953C52.1568 286.795 53.0952 287.035 54.1139 287.152C55.0832 287.262 56.0216 287.533 56.7131 288.245C58.1269 289.696 59.6025 289.788 61.2757 288.7C61.9795 288.239 62.245 288.375 62.7389 289.063C62.9179 289.309 63.0908 289.561 63.2945 289.782C64.0601 290.605 64.6158 290.71 65.6838 290.273C66.0913 290.31 66.4926 290.353 66.9125 290.335C67.7892 290.31 68.3139 290.876 68.6103 291.613C68.8819 292.302 69.3141 292.64 70.0735 292.646C70.8206 292.652 71.4503 293.027 71.9936 293.506C72.9012 294.299 73.9631 294.668 75.1547 294.686C76.1054 294.698 76.9327 295.012 77.6736 295.595C78.3713 296.149 79.1307 296.609 80.0568 296.696Z"
              fill="#66646F"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V290.205C51.2464 291.968 73.1866 294.991 96.359 288.902C118.48 283.084 137.779 279.413 152 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V294.817C51.2464 296.58 75.0945 308.139 96.359 297.208C118.913 285.606 137.779 279.413 152 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 291.312C134.64 290.619 134.644 289.93 134.644 289.237C134.659 288.728 134.674 288.216 134.689 287.708L134.659 287.678C135.495 287.915 136.339 287.862 137.182 287.723C137.197 288.559 137.213 289.391 137.228 290.228C137.216 291.467 137.209 292.702 137.186 293.942C137.171 294.781 136.987 295.565 135.947 295.576C134.907 295.588 134.704 294.812 134.681 293.968C134.659 293.079 134.655 292.198 134.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 287.708C130.79 286.849 128.105 283.93 128.278 280.174C128.538 274.63 130.067 269.402 133.077 264.671C134.975 265.533 136.018 267.168 136.881 268.946C138.192 271.65 139.02 274.513 139.559 277.462C139.819 278.89 140.166 280.321 139.898 281.794C139.348 284.819 137.499 286.691 134.655 287.678L134.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 287.674C137.502 286.687 139.352 284.815 139.906 281.79C140.173 280.321 139.826 278.886 139.567 277.459C139.028 274.513 138.199 271.647 136.888 268.942C136.026 267.164 134.986 265.53 133.084 264.667C134.885 262.192 136.636 262.057 138.523 264.283C138.979 264.821 139.397 265.413 139.729 266.038C141.424 269.232 142.493 272.649 143.194 276.182C143.375 277.086 143.506 278.001 143.661 278.912C143.774 281.327 143.326 283.553 141.589 285.384C140.874 286.141 140 286.664 139.145 287.222C138.493 287.387 137.841 287.553 137.186 287.719C136.342 287.858 135.499 287.911 134.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 301.595C109.439 300.325 109.446 299.061 109.446 297.79C109.474 296.858 109.501 295.918 109.529 294.986L109.474 294.931C111.007 295.366 112.554 295.269 114.101 295.014C114.129 296.547 114.156 298.073 114.184 299.606C114.163 301.878 114.149 304.144 114.108 306.416C114.08 307.956 113.742 309.392 111.836 309.413C109.93 309.434 109.557 308.011 109.515 306.464C109.474 304.834 109.467 303.218 109.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 294.986C102.381 293.411 97.4568 288.059 97.7745 281.174C98.2511 271.008 101.055 261.422 106.573 252.748C110.054 254.329 111.967 257.327 113.549 260.586C115.952 265.545 117.471 270.794 118.459 276.201C118.936 278.819 119.571 281.443 119.081 284.143C118.072 289.689 114.681 293.121 109.467 294.931L109.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 294.924C114.688 293.114 118.079 289.682 119.095 284.136C119.585 281.443 118.949 278.812 118.473 276.194C117.485 270.794 115.966 265.538 113.563 260.579C111.981 257.32 110.075 254.322 106.587 252.741C109.888 248.203 113.1 247.955 116.56 252.036C117.396 253.024 118.162 254.108 118.77 255.255C121.878 261.111 123.839 267.375 125.124 273.853C125.455 275.511 125.697 277.189 125.98 278.86C126.187 283.287 125.365 287.368 122.182 290.725C120.869 292.113 119.267 293.073 117.699 294.095C116.505 294.399 115.31 294.703 114.108 295.007C112.561 295.262 111.014 295.359 109.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7392 248.469C55.685 249.108 54.6221 249.729 53.8697 250.753C53.5766 251.152 53.1523 251.06 52.7368 251.012C50.4972 250.736 48.4588 252.084 47.8027 254.268C47.5052 255.261 47.6365 255.458 48.6688 255.462C53.7866 255.467 58.9044 255.462 64.0223 255.471C64.4947 255.471 64.9671 255.524 65.4351 255.55C67.3029 255.524 69.1663 255.48 71.0341 255.489C71.7602 255.493 71.9877 255.266 71.8215 254.526C71.3184 252.285 69.1663 250.723 66.883 251.007C66.4018 251.068 65.995 251.121 65.6013 250.675C63.318 248.062 60.4923 247.344 57.1985 248.359C57.0498 248.399 56.8967 248.429 56.7392 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 248.355C88.4171 248.359 95.534 248.364 102.655 248.381C103.39 248.381 103.499 248.075 103.355 247.436C102.918 245.488 101.22 244.14 99.1165 244.232C98.3554 244.267 97.8436 244.075 97.3055 243.514C94.5542 240.652 90.1668 240.665 87.3848 243.528C86.8774 244.053 86.3963 244.35 85.657 244.223C84.7822 244.075 83.9861 244.482 83.155 244.661C82.1533 245.698 81.1122 246.718 81.2959 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 231.317C74.7127 230.096 72.32 229.886 69.9317 231.264C68.0026 232.38 66.9091 234.079 66.5285 236.271C66.4061 236.985 66.6116 237.247 67.3465 237.238C70.1329 237.217 72.9193 237.23 75.7013 237.23C78.2952 237.23 80.8934 237.234 83.4873 237.225C84.5065 237.221 84.629 237.028 84.3753 236.022C84.161 235.168 83.6579 234.481 83.1724 233.777C82.2582 233.085 81.3002 232.398 80.1061 232.468C78.8857 232.542 77.884 232.249 77.0879 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask2_76_7493"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="390"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="446.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask2_76_7493)">
            <rect x="39" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 464.267C64.6033 464.704 64.0477 464.593 63.2821 463.776C63.0783 463.554 62.8993 463.302 62.7264 463.057C62.2325 462.368 61.9732 462.239 61.2632 462.694C59.5901 463.782 58.1145 463.69 56.7007 462.239C56.0092 461.533 55.0707 461.262 54.1014 461.145C53.0827 461.029 52.1443 460.789 51.4467 459.947C51.033 459.455 50.4156 459.511 49.8599 459.621C49.0079 459.793 48.2053 459.707 47.4521 459.296C46.4952 458.767 45.6185 458.896 44.7665 459.511C44.6059 459.627 44.4454 459.732 44.2787 459.836C43.0378 460.629 42.3339 460.488 41.4819 459.271C40.8275 458.331 39.9446 458.06 39 458.435V454.41C40.0619 454.766 41.1671 454.908 42.2845 454.859C44.0132 454.779 45.495 455.233 46.7545 456.438C47.4089 457.065 48.193 457.268 49.1067 457.016C50.1563 456.727 51.0268 457.01 51.6874 457.913C52.1752 458.57 52.8296 458.988 53.6878 458.951C55.398 458.878 56.8365 459.492 58.0528 460.654C58.6208 461.195 59.269 461.588 60.0902 461.526C61.9176 461.385 63.4364 461.901 64.486 463.475C64.7823 463.886 65.2948 463.985 65.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 470.696L80.0506 471.961C79.5258 472.134 79.0442 472.441 78.4824 472.49C76.4574 472.343 74.494 471.863 72.5431 471.359C66.1963 469.718 59.6828 470.696 53.2001 470.696C49.4896 470.358 45.2173 470.542 41.4944 470.696C41.4944 470.696 40.0558 470.622 39.0125 470.696V458.441C39.9571 458.067 40.8399 458.337 41.4944 459.277C42.3464 460.494 43.044 460.635 44.2912 459.843C44.4579 459.738 44.6184 459.634 44.7789 459.517C45.6309 458.902 46.5076 458.773 47.4646 459.302C48.2178 459.714 49.0204 459.793 49.8724 459.628C50.4281 459.517 51.0454 459.462 51.4591 459.953C52.1568 460.795 53.0952 461.035 54.1139 461.152C55.0832 461.262 56.0216 461.533 56.7131 462.245C58.1269 463.696 59.6025 463.788 61.2757 462.7C61.9795 462.239 62.245 462.375 62.7389 463.063C62.9179 463.309 63.0908 463.561 63.2945 463.782C64.0601 464.605 64.6158 464.71 65.6838 464.273C66.0913 464.31 66.4926 464.353 66.9125 464.335C67.7892 464.31 68.3139 464.876 68.6103 465.613C68.8819 466.302 69.3141 466.64 70.0735 466.646C70.8206 466.652 71.4503 467.027 71.9936 467.506C72.9012 468.299 73.9631 468.668 75.1547 468.686C76.1054 468.698 76.9327 469.012 77.6736 469.595C78.3713 470.149 79.1307 470.609 80.0568 470.696Z"
              fill="#66646F"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V464.205C51.2464 465.968 73.1866 468.991 96.359 462.902C118.48 457.084 137.779 453.413 152 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V468.817C51.2464 470.58 75.0945 482.139 96.359 471.208C118.913 459.606 137.779 453.413 152 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 465.312C134.64 464.619 134.644 463.93 134.644 463.237C134.659 462.728 134.674 462.216 134.689 461.708L134.659 461.678C135.495 461.915 136.339 461.862 137.182 461.723C137.197 462.559 137.213 463.391 137.228 464.228C137.216 465.467 137.209 466.702 137.186 467.942C137.171 468.781 136.987 469.565 135.947 469.576C134.907 469.588 134.704 468.812 134.681 467.968C134.659 467.079 134.655 466.198 134.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 461.708C130.79 460.849 128.105 457.93 128.278 454.174C128.538 448.63 130.067 443.402 133.077 438.671C134.975 439.533 136.018 441.168 136.881 442.946C138.192 445.65 139.02 448.513 139.559 451.462C139.819 452.89 140.166 454.321 139.898 455.794C139.348 458.819 137.499 460.691 134.655 461.678L134.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 461.674C137.502 460.687 139.352 458.815 139.906 455.79C140.173 454.321 139.826 452.886 139.567 451.459C139.028 448.513 138.199 445.647 136.888 442.942C136.026 441.164 134.986 439.53 133.084 438.667C134.885 436.192 136.636 436.057 138.523 438.283C138.979 438.821 139.397 439.413 139.729 440.038C141.424 443.232 142.493 446.649 143.194 450.182C143.375 451.086 143.506 452.001 143.661 452.912C143.774 455.327 143.326 457.553 141.589 459.384C140.874 460.141 140 460.664 139.145 461.222C138.493 461.387 137.841 461.553 137.186 461.719C136.342 461.858 135.499 461.911 134.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 475.595C109.439 474.325 109.446 473.061 109.446 471.79C109.474 470.858 109.501 469.918 109.529 468.986L109.474 468.931C111.007 469.366 112.554 469.269 114.101 469.014C114.129 470.547 114.156 472.073 114.184 473.606C114.163 475.878 114.149 478.144 114.108 480.416C114.08 481.956 113.742 483.392 111.836 483.413C109.93 483.434 109.557 482.011 109.515 480.464C109.474 478.834 109.467 477.218 109.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 468.986C102.381 467.411 97.4568 462.059 97.7745 455.174C98.2511 445.008 101.055 435.422 106.573 426.748C110.054 428.329 111.967 431.327 113.549 434.586C115.952 439.545 117.471 444.794 118.459 450.201C118.936 452.819 119.571 455.443 119.081 458.143C118.072 463.689 114.681 467.121 109.467 468.931L109.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 468.924C114.688 467.114 118.079 463.682 119.095 458.136C119.585 455.443 118.949 452.812 118.473 450.194C117.485 444.794 115.966 439.538 113.563 434.579C111.981 431.32 110.075 428.322 106.587 426.741C109.888 422.203 113.1 421.955 116.56 426.036C117.396 427.024 118.162 428.108 118.77 429.255C121.878 435.111 123.839 441.375 125.124 447.853C125.455 449.511 125.697 451.189 125.98 452.86C126.187 457.287 125.365 461.368 122.182 464.725C120.869 466.113 119.267 467.073 117.699 468.095C116.505 468.399 115.31 468.703 114.108 469.007C112.561 469.262 111.014 469.359 109.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7392 422.469C55.685 423.108 54.6221 423.729 53.8697 424.753C53.5766 425.152 53.1523 425.06 52.7368 425.012C50.4972 424.736 48.4588 426.084 47.8027 428.268C47.5052 429.261 47.6365 429.458 48.6688 429.462C53.7866 429.467 58.9044 429.462 64.0223 429.471C64.4947 429.471 64.9671 429.524 65.4351 429.55C67.3029 429.524 69.1663 429.48 71.0341 429.489C71.7602 429.493 71.9877 429.266 71.8215 428.526C71.3184 426.285 69.1663 424.723 66.883 425.007C66.4018 425.068 65.995 425.121 65.6013 424.675C63.318 422.062 60.4923 421.344 57.1985 422.359C57.0498 422.399 56.8967 422.429 56.7392 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 422.355C88.4171 422.359 95.534 422.364 102.655 422.381C103.39 422.381 103.499 422.075 103.355 421.436C102.918 419.488 101.22 418.14 99.1165 418.232C98.3554 418.267 97.8436 418.075 97.3055 417.514C94.5542 414.652 90.1668 414.665 87.3848 417.528C86.8774 418.053 86.3963 418.35 85.657 418.223C84.7822 418.075 83.9861 418.482 83.155 418.661C82.1533 419.698 81.1122 420.718 81.2959 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 405.317C74.7127 404.096 72.32 403.886 69.9317 405.264C68.0026 406.38 66.9091 408.079 66.5285 410.271C66.4061 410.985 66.6116 411.247 67.3465 411.238C70.1329 411.217 72.9193 411.23 75.7013 411.23C78.2952 411.23 80.8934 411.234 83.4873 411.225C84.5065 411.221 84.629 411.028 84.3753 410.022C84.161 409.168 83.6579 408.481 83.1724 407.777C82.2582 407.085 81.3002 406.398 80.1061 406.468C78.8857 406.542 77.884 406.249 77.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask3_76_7493"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="39"
            y="564"
            width="113"
            height="113"
          >
            <circle cx="95.5" cy="620.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask3_76_7493)">
            <rect x="39" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 638.267C64.6033 638.704 64.0477 638.593 63.2821 637.776C63.0783 637.554 62.8993 637.303 62.7264 637.057C62.2325 636.368 61.9732 636.239 61.2632 636.694C59.5901 637.782 58.1145 637.69 56.7007 636.239C56.0092 635.533 55.0707 635.262 54.1014 635.145C53.0827 635.029 52.1443 634.789 51.4467 633.947C51.033 633.455 50.4156 633.511 49.8599 633.621C49.0079 633.793 48.2053 633.707 47.4521 633.296C46.4952 632.767 45.6185 632.896 44.7665 633.511C44.6059 633.628 44.4454 633.732 44.2787 633.836C43.0378 634.629 42.3339 634.488 41.4819 633.271C40.8275 632.331 39.9446 632.06 39 632.435V628.41C40.0619 628.766 41.1671 628.908 42.2845 628.859C44.0132 628.779 45.495 629.234 46.7545 630.438C47.4089 631.065 48.193 631.268 49.1067 631.016C50.1563 630.727 51.0268 631.01 51.6874 631.913C52.1752 632.57 52.8296 632.988 53.6878 632.952C55.398 632.878 56.8365 633.492 58.0528 634.654C58.6208 635.195 59.269 635.588 60.0902 635.526C61.9176 635.385 63.4364 635.901 64.486 637.475C64.7823 637.886 65.2948 637.985 65.6714 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 644.696L80.0506 645.961C79.5258 646.134 79.0442 646.441 78.4824 646.49C76.4574 646.343 74.494 645.863 72.5431 645.359C66.1963 643.718 59.6828 644.696 53.2001 644.696C49.4896 644.358 45.2173 644.542 41.4944 644.696C41.4944 644.696 40.0558 644.622 39.0125 644.696V632.441C39.9571 632.067 40.8399 632.337 41.4944 633.277C42.3464 634.494 43.044 634.635 44.2912 633.843C44.4579 633.738 44.6184 633.634 44.7789 633.517C45.6309 632.902 46.5076 632.773 47.4646 633.302C48.2178 633.714 49.0204 633.793 49.8724 633.628C50.4281 633.517 51.0454 633.462 51.4591 633.953C52.1568 634.795 53.0952 635.035 54.1139 635.152C55.0832 635.262 56.0216 635.533 56.7131 636.245C58.1269 637.696 59.6025 637.788 61.2757 636.7C61.9795 636.239 62.245 636.375 62.7389 637.063C62.9179 637.309 63.0908 637.561 63.2945 637.782C64.0601 638.605 64.6158 638.71 65.6838 638.273C66.0913 638.31 66.4926 638.353 66.9125 638.335C67.7892 638.31 68.3139 638.876 68.6103 639.613C68.8819 640.302 69.3141 640.64 70.0735 640.646C70.8206 640.652 71.4503 641.027 71.9936 641.506C72.9012 642.299 73.9631 642.668 75.1547 642.686C76.1054 642.698 76.9327 643.012 77.6736 643.595C78.3713 644.149 79.1307 644.609 80.0568 644.696Z"
              fill="#66646F"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V638.205C51.2464 639.968 73.1866 642.991 96.359 636.902C118.48 631.084 137.779 627.413 152 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V642.817C51.2464 644.58 75.0945 656.139 96.359 645.208C118.913 633.606 137.779 627.413 152 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 639.312C134.64 638.619 134.644 637.93 134.644 637.237C134.659 636.728 134.674 636.216 134.689 635.708L134.659 635.678C135.495 635.915 136.339 635.862 137.182 635.723C137.197 636.559 137.213 637.391 137.228 638.228C137.216 639.467 137.209 640.702 137.186 641.942C137.171 642.782 136.987 643.565 135.947 643.576C134.907 643.588 134.704 642.812 134.681 641.968C134.659 641.079 134.655 640.198 134.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 635.708C130.79 634.849 128.105 631.93 128.278 628.174C128.538 622.63 130.067 617.402 133.077 612.671C134.975 613.533 136.018 615.168 136.881 616.946C138.192 619.65 139.02 622.513 139.559 625.462C139.819 626.89 140.166 628.321 139.898 629.794C139.348 632.819 137.499 634.691 134.655 635.678L134.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 635.674C137.502 634.687 139.352 632.815 139.906 629.79C140.173 628.321 139.826 626.886 139.567 625.459C139.028 622.513 138.199 619.647 136.888 616.942C136.026 615.164 134.986 613.53 133.084 612.667C134.885 610.192 136.636 610.057 138.523 612.283C138.979 612.821 139.397 613.413 139.729 614.038C141.424 617.232 142.493 620.649 143.194 624.182C143.375 625.086 143.506 626.001 143.661 626.912C143.774 629.327 143.326 631.553 141.589 633.384C140.874 634.141 140 634.664 139.145 635.222C138.493 635.387 137.841 635.553 137.186 635.719C136.342 635.858 135.499 635.911 134.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 649.595C109.439 648.325 109.446 647.061 109.446 645.79C109.474 644.858 109.501 643.918 109.529 642.986L109.474 642.931C111.007 643.366 112.554 643.269 114.101 643.014C114.129 644.547 114.156 646.073 114.184 647.606C114.163 649.878 114.149 652.144 114.108 654.416C114.08 655.956 113.742 657.392 111.836 657.413C109.93 657.434 109.557 656.011 109.515 654.464C109.474 652.834 109.467 651.218 109.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 642.986C102.381 641.411 97.4568 636.059 97.7745 629.174C98.2511 619.008 101.055 609.422 106.573 600.748C110.054 602.329 111.967 605.327 113.549 608.586C115.952 613.545 117.471 618.794 118.459 624.201C118.936 626.819 119.571 629.443 119.081 632.143C118.072 637.689 114.681 641.121 109.467 642.931L109.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 642.924C114.688 641.114 118.079 637.682 119.095 632.136C119.585 629.443 118.949 626.812 118.473 624.194C117.485 618.794 115.966 613.538 113.563 608.579C111.981 605.32 110.075 602.322 106.587 600.741C109.888 596.203 113.1 595.955 116.56 600.036C117.396 601.024 118.162 602.108 118.77 603.255C121.878 609.111 123.839 615.375 125.124 621.853C125.455 623.511 125.697 625.189 125.98 626.86C126.187 631.287 125.365 635.368 122.182 638.725C120.869 640.113 119.267 641.073 117.699 642.095C116.505 642.399 115.31 642.703 114.108 643.007C112.561 643.262 111.014 643.359 109.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7392 596.469C55.685 597.108 54.6221 597.729 53.8697 598.753C53.5766 599.152 53.1523 599.06 52.7368 599.011C50.4972 598.736 48.4588 600.084 47.8027 602.268C47.5052 603.261 47.6365 603.458 48.6688 603.462C53.7866 603.467 58.9044 603.462 64.0223 603.471C64.4947 603.471 64.9671 603.524 65.4351 603.55C67.3029 603.524 69.1663 603.48 71.0341 603.489C71.7602 603.493 71.9877 603.266 71.8215 602.526C71.3184 600.285 69.1663 598.723 66.883 599.007C66.4018 599.068 65.995 599.121 65.6013 598.675C63.318 596.062 60.4923 595.344 57.1985 596.359C57.0498 596.399 56.8967 596.429 56.7392 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2959 596.355C88.4171 596.359 95.534 596.364 102.655 596.381C103.39 596.381 103.499 596.075 103.355 595.436C102.918 593.488 101.22 592.14 99.1165 592.232C98.3554 592.267 97.8436 592.075 97.3055 591.514C94.5542 588.652 90.1668 588.665 87.3848 591.528C86.8774 592.053 86.3963 592.35 85.657 592.223C84.7822 592.075 83.9861 592.482 83.155 592.661C82.1533 593.698 81.1122 594.718 81.2959 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 579.317C74.7127 578.096 72.32 577.886 69.9317 579.264C68.0026 580.38 66.9091 582.079 66.5285 584.271C66.4061 584.985 66.6116 585.247 67.3465 585.238C70.1329 585.217 72.9193 585.23 75.7013 585.23C78.2952 585.23 80.8934 585.234 83.4873 585.225C84.5065 585.221 84.629 585.028 84.3753 584.022C84.161 583.168 83.6579 582.481 83.1724 581.777C82.2582 581.085 81.3002 580.398 80.1061 580.468C78.8857 580.542 77.884 580.249 77.0879 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="175" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="260" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="609" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7493"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7493"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7493"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7493"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7493"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7493"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7493"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7493"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7493"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7493"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7493"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7493"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7493">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerSquareRoundedRight = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareRight">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7494)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7494)">
            <rect x="17" y="19" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7494)">
            <rect x="17" y="193" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter2_d_76_7494)">
            <rect x="17" y="367" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter3_d_76_7494)">
            <rect x="17" y="541" width="346" height="159" fill="white" />
          </g>
          <mask
            id="mask0_76_7494"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="42"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="98.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7494)">
            <rect x="226" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 116.267C251.603 116.704 251.048 116.593 250.282 115.776C250.078 115.554 249.899 115.302 249.726 115.057C249.233 114.368 248.973 114.239 248.263 114.694C246.59 115.782 245.114 115.69 243.701 114.239C243.009 113.533 242.071 113.262 241.101 113.145C240.083 113.029 239.144 112.789 238.447 111.947C238.033 111.455 237.416 111.511 236.86 111.621C236.008 111.793 235.205 111.707 234.452 111.296C233.495 110.767 232.618 110.896 231.766 111.511C231.606 111.627 231.445 111.732 231.279 111.836C230.038 112.629 229.334 112.488 228.482 111.271C227.827 110.331 226.945 110.06 226 110.435V106.41C227.062 106.766 228.167 106.908 229.285 106.859C231.013 106.779 232.495 107.233 233.754 108.438C234.409 109.065 235.193 109.268 236.107 109.016C237.156 108.727 238.027 109.01 238.687 109.913C239.175 110.57 239.83 110.988 240.688 110.951C242.398 110.878 243.836 111.492 245.053 112.654C245.621 113.195 246.269 113.588 247.09 113.526C248.918 113.385 250.436 113.901 251.486 115.475C251.782 115.886 252.295 115.985 252.671 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 122.696L267.051 123.961C266.526 124.134 266.044 124.441 265.482 124.49C263.457 124.343 261.494 123.863 259.543 123.359C253.196 121.718 246.683 122.696 240.2 122.696C236.49 122.358 232.217 122.542 228.494 122.696C228.494 122.696 227.056 122.622 226.012 122.696V110.441C226.957 110.067 227.84 110.337 228.494 111.277C229.346 112.494 230.044 112.635 231.291 111.843C231.458 111.738 231.618 111.634 231.779 111.517C232.631 110.902 233.508 110.773 234.465 111.302C235.218 111.714 236.02 111.793 236.872 111.628C237.428 111.517 238.045 111.462 238.459 111.953C239.157 112.795 240.095 113.035 241.114 113.152C242.083 113.262 243.022 113.533 243.713 114.245C245.127 115.696 246.603 115.788 248.276 114.7C248.979 114.239 249.245 114.375 249.739 115.063C249.918 115.309 250.091 115.561 250.295 115.782C251.06 116.605 251.616 116.71 252.684 116.273C253.091 116.31 253.493 116.353 253.912 116.335C254.789 116.31 255.314 116.876 255.61 117.613C255.882 118.302 256.314 118.64 257.074 118.646C257.821 118.652 258.45 119.027 258.994 119.506C259.901 120.299 260.963 120.668 262.155 120.686C263.105 120.698 263.933 121.012 264.674 121.595C265.371 122.149 266.131 122.609 267.057 122.696Z"
              fill="#66646F"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V116.205C238.246 117.968 260.187 120.991 283.359 114.902C305.48 109.084 324.779 105.413 339 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V120.817C238.246 122.58 262.094 134.139 283.359 123.208C305.913 111.606 324.779 105.413 339 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 117.312C321.64 116.619 321.644 115.93 321.644 115.237C321.659 114.728 321.674 114.216 321.689 113.708L321.659 113.678C322.495 113.915 323.339 113.862 324.182 113.723C324.197 114.559 324.213 115.391 324.228 116.228C324.216 117.467 324.209 118.702 324.186 119.942C324.171 120.781 323.987 121.565 322.947 121.576C321.907 121.588 321.704 120.812 321.681 119.968C321.659 119.079 321.655 118.198 321.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 113.708C317.79 112.849 315.105 109.93 315.278 106.174C315.538 100.63 317.067 95.4017 320.077 90.6707C321.975 91.5333 323.018 93.168 323.881 94.9459C325.192 97.6504 326.02 100.513 326.559 103.462C326.819 104.89 327.166 106.321 326.898 107.794C326.348 110.819 324.499 112.691 321.655 113.678L321.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 113.674C324.502 112.687 326.352 110.815 326.905 107.79C327.173 106.321 326.826 104.886 326.566 103.459C326.028 100.513 325.199 97.6466 323.888 94.9421C323.026 93.1643 321.986 91.5295 320.084 90.667C321.885 88.1923 323.636 88.0567 325.523 90.2828C325.979 90.8214 326.397 91.4128 326.728 92.038C328.423 95.2322 329.493 98.6485 330.194 102.182C330.375 103.086 330.506 104.001 330.661 104.912C330.774 107.327 330.326 109.553 328.589 111.384C327.874 112.141 327 112.664 326.145 113.222C325.493 113.387 324.841 113.553 324.186 113.719C323.342 113.858 322.498 113.911 321.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 127.595C296.439 126.325 296.446 125.061 296.446 123.79C296.474 122.858 296.501 121.918 296.529 120.986L296.474 120.931C298.007 121.366 299.554 121.269 301.101 121.014C301.129 122.547 301.156 124.073 301.184 125.606C301.163 127.878 301.149 130.144 301.108 132.416C301.08 133.956 300.742 135.392 298.836 135.413C296.93 135.434 296.557 134.011 296.515 132.464C296.474 130.834 296.467 129.218 296.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 120.986C289.381 119.411 284.457 114.059 284.775 107.174C285.251 97.0078 288.055 87.422 293.573 78.7478C297.054 80.3293 298.967 83.3266 300.549 86.5863C302.952 91.545 304.471 96.7937 305.459 102.201C305.936 104.819 306.571 107.443 306.081 110.143C305.072 115.689 301.681 119.121 296.467 120.931L296.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 120.924C301.688 119.114 305.079 115.682 306.095 110.136C306.585 107.443 305.949 104.812 305.473 102.194C304.485 96.7936 302.966 91.538 300.563 86.5794C298.981 83.3197 297.075 80.3224 293.587 78.7409C296.888 74.2035 300.1 73.9549 303.56 78.0364C304.396 79.024 305.162 80.1083 305.77 81.2547C308.878 87.1112 310.839 93.3751 312.124 99.8531C312.455 101.511 312.697 103.189 312.98 104.86C313.187 109.287 312.365 113.368 309.182 116.725C307.869 118.113 306.267 119.073 304.699 120.095C303.505 120.399 302.31 120.703 301.108 121.007C299.561 121.262 298.014 121.359 296.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 74.4687C242.685 75.1077 241.622 75.7292 240.87 76.7533C240.577 77.1516 240.152 77.0597 239.737 77.0115C237.497 76.7358 235.459 78.0838 234.803 80.2677C234.505 81.2612 234.636 81.4581 235.669 81.4625C240.786 81.4669 245.904 81.4625 251.022 81.4712C251.495 81.4712 251.967 81.5238 252.435 81.55C254.303 81.5238 256.166 81.48 258.034 81.4888C258.76 81.4931 258.988 81.2656 258.821 80.5259C258.318 78.2851 256.166 76.7227 253.883 77.0072C253.402 77.0684 252.995 77.1209 252.601 76.6745C250.318 74.0617 247.492 73.344 244.198 74.3593C244.05 74.3987 243.897 74.4294 243.739 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 74.355C275.417 74.3593 282.534 74.3637 289.655 74.3812C290.39 74.3812 290.499 74.0749 290.355 73.4359C289.918 71.4883 288.22 70.1403 286.116 70.2322C285.355 70.2673 284.844 70.0747 284.305 69.5145C281.554 66.6522 277.167 66.6653 274.385 69.5276C273.877 70.0528 273.396 70.3504 272.657 70.2235C271.782 70.0747 270.986 70.4817 270.155 70.6611C269.153 71.6984 268.112 72.7181 268.296 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 57.317C261.713 56.0959 259.32 55.8858 256.932 57.2645C255.003 58.3805 253.909 60.0786 253.529 62.2712C253.406 62.9846 253.612 63.2472 254.347 63.2385C257.133 63.2166 259.919 63.2297 262.701 63.2297C265.295 63.2297 267.893 63.2341 270.487 63.2253C271.507 63.221 271.629 63.0284 271.375 62.0218C271.161 61.1683 270.658 60.4812 270.172 59.7766C269.258 59.0851 268.3 58.398 267.106 58.468C265.886 58.5424 264.884 58.2492 264.088 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask1_76_7494"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="216"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="272.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask1_76_7494)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.603 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.074 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.213 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.987 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.905 281.79C327.173 280.321 326.826 278.886 326.566 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.885 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.728 266.038C328.423 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.874 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.498 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.095 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.563 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.396 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.182 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.505 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.786 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.844 244.075 284.305 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask2_76_7494"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="390"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="446.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask2_76_7494)">
            <rect x="226" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 464.267C251.603 464.704 251.048 464.593 250.282 463.776C250.078 463.554 249.899 463.302 249.726 463.057C249.233 462.368 248.973 462.239 248.263 462.694C246.59 463.782 245.114 463.69 243.701 462.239C243.009 461.533 242.071 461.262 241.101 461.145C240.083 461.029 239.144 460.789 238.447 459.947C238.033 459.455 237.416 459.511 236.86 459.621C236.008 459.793 235.205 459.707 234.452 459.296C233.495 458.767 232.618 458.896 231.766 459.511C231.606 459.627 231.445 459.732 231.279 459.836C230.038 460.629 229.334 460.488 228.482 459.271C227.827 458.331 226.945 458.06 226 458.435V454.41C227.062 454.766 228.167 454.908 229.285 454.859C231.013 454.779 232.495 455.233 233.754 456.438C234.409 457.065 235.193 457.268 236.107 457.016C237.156 456.727 238.027 457.01 238.687 457.913C239.175 458.57 239.83 458.988 240.688 458.951C242.398 458.878 243.836 459.492 245.053 460.654C245.621 461.195 246.269 461.588 247.09 461.526C248.918 461.385 250.436 461.901 251.486 463.475C251.782 463.886 252.295 463.985 252.671 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 470.696L267.051 471.961C266.526 472.134 266.044 472.441 265.482 472.49C263.457 472.343 261.494 471.863 259.543 471.359C253.196 469.718 246.683 470.696 240.2 470.696C236.49 470.358 232.217 470.542 228.494 470.696C228.494 470.696 227.056 470.622 226.012 470.696V458.441C226.957 458.067 227.84 458.337 228.494 459.277C229.346 460.494 230.044 460.635 231.291 459.843C231.458 459.738 231.618 459.634 231.779 459.517C232.631 458.902 233.508 458.773 234.465 459.302C235.218 459.714 236.02 459.793 236.872 459.628C237.428 459.517 238.045 459.462 238.459 459.953C239.157 460.795 240.095 461.035 241.114 461.152C242.083 461.262 243.022 461.533 243.713 462.245C245.127 463.696 246.603 463.788 248.276 462.7C248.979 462.239 249.245 462.375 249.739 463.063C249.918 463.309 250.091 463.561 250.295 463.782C251.06 464.605 251.616 464.71 252.684 464.273C253.091 464.31 253.493 464.353 253.912 464.335C254.789 464.31 255.314 464.876 255.61 465.613C255.882 466.302 256.314 466.64 257.074 466.646C257.821 466.652 258.45 467.027 258.994 467.506C259.901 468.299 260.963 468.668 262.155 468.686C263.105 468.698 263.933 469.012 264.674 469.595C265.371 470.149 266.131 470.609 267.057 470.696Z"
              fill="#66646F"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V464.205C238.246 465.968 260.187 468.991 283.359 462.902C305.48 457.084 324.779 453.413 339 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V468.817C238.246 470.58 262.094 482.139 283.359 471.208C305.913 459.606 324.779 453.413 339 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 465.312C321.64 464.619 321.644 463.93 321.644 463.237C321.659 462.728 321.674 462.216 321.689 461.708L321.659 461.678C322.495 461.915 323.339 461.862 324.182 461.723C324.197 462.559 324.213 463.391 324.228 464.228C324.216 465.467 324.209 466.702 324.186 467.942C324.171 468.781 323.987 469.565 322.947 469.576C321.907 469.588 321.704 468.812 321.681 467.968C321.659 467.079 321.655 466.198 321.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 461.708C317.79 460.849 315.105 457.93 315.278 454.174C315.538 448.63 317.067 443.402 320.077 438.671C321.975 439.533 323.018 441.168 323.881 442.946C325.192 445.65 326.02 448.513 326.559 451.462C326.819 452.89 327.166 454.321 326.898 455.794C326.348 458.819 324.499 460.691 321.655 461.678L321.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 461.674C324.502 460.687 326.352 458.815 326.905 455.79C327.173 454.321 326.826 452.886 326.566 451.459C326.028 448.513 325.199 445.647 323.888 442.942C323.026 441.164 321.986 439.53 320.084 438.667C321.885 436.192 323.636 436.057 325.523 438.283C325.979 438.821 326.397 439.413 326.728 440.038C328.423 443.232 329.493 446.649 330.194 450.182C330.375 451.086 330.506 452.001 330.661 452.912C330.774 455.327 330.326 457.553 328.589 459.384C327.874 460.141 327 460.664 326.145 461.222C325.493 461.387 324.841 461.553 324.186 461.719C323.342 461.858 322.498 461.911 321.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 475.595C296.439 474.325 296.446 473.061 296.446 471.79C296.474 470.858 296.501 469.918 296.529 468.986L296.474 468.931C298.007 469.366 299.554 469.269 301.101 469.014C301.129 470.547 301.156 472.073 301.184 473.606C301.163 475.878 301.149 478.144 301.108 480.416C301.08 481.956 300.742 483.392 298.836 483.413C296.93 483.434 296.557 482.011 296.515 480.464C296.474 478.834 296.467 477.218 296.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 468.986C289.381 467.411 284.457 462.059 284.775 455.174C285.251 445.008 288.055 435.422 293.573 426.748C297.054 428.329 298.967 431.327 300.549 434.586C302.952 439.545 304.471 444.794 305.459 450.201C305.936 452.819 306.571 455.443 306.081 458.143C305.072 463.689 301.681 467.121 296.467 468.931L296.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 468.924C301.688 467.114 305.079 463.682 306.095 458.136C306.585 455.443 305.949 452.812 305.473 450.194C304.485 444.794 302.966 439.538 300.563 434.579C298.981 431.32 297.075 428.322 293.587 426.741C296.888 422.203 300.1 421.955 303.56 426.036C304.396 427.024 305.162 428.108 305.77 429.255C308.878 435.111 310.839 441.375 312.124 447.853C312.455 449.511 312.697 451.189 312.98 452.86C313.187 457.287 312.365 461.368 309.182 464.725C307.869 466.113 306.267 467.073 304.699 468.095C303.505 468.399 302.31 468.703 301.108 469.007C299.561 469.262 298.014 469.359 296.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 422.469C242.685 423.108 241.622 423.729 240.87 424.753C240.577 425.152 240.152 425.06 239.737 425.012C237.497 424.736 235.459 426.084 234.803 428.268C234.505 429.261 234.636 429.458 235.669 429.462C240.786 429.467 245.904 429.462 251.022 429.471C251.495 429.471 251.967 429.524 252.435 429.55C254.303 429.524 256.166 429.48 258.034 429.489C258.76 429.493 258.988 429.266 258.821 428.526C258.318 426.285 256.166 424.723 253.883 425.007C253.402 425.068 252.995 425.121 252.601 424.675C250.318 422.062 247.492 421.344 244.198 422.359C244.05 422.399 243.897 422.429 243.739 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 422.355C275.417 422.359 282.534 422.364 289.655 422.381C290.39 422.381 290.499 422.075 290.355 421.436C289.918 419.488 288.22 418.14 286.116 418.232C285.355 418.267 284.844 418.075 284.305 417.514C281.554 414.652 277.167 414.665 274.385 417.528C273.877 418.053 273.396 418.35 272.657 418.223C271.782 418.075 270.986 418.482 270.155 418.661C269.153 419.698 268.112 420.718 268.296 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 405.317C261.713 404.096 259.32 403.886 256.932 405.264C255.003 406.38 253.909 408.079 253.529 410.271C253.406 410.985 253.612 411.247 254.347 411.238C257.133 411.217 259.919 411.23 262.701 411.23C265.295 411.23 267.893 411.234 270.487 411.225C271.507 411.221 271.629 411.028 271.375 410.022C271.161 409.168 270.658 408.481 270.172 407.777C269.258 407.085 268.3 406.398 267.106 406.468C265.886 406.542 264.884 406.249 264.088 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask3_76_7494"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="564"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="620.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask3_76_7494)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.961C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.245C245.127 637.696 246.603 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.273C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.074 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.595C265.371 644.149 266.131 644.609 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.213 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.987 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.905 629.79C327.173 628.321 326.826 626.886 326.566 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.885 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.728 614.038C328.423 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.874 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.498 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.095 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.563 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.396 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.182 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.505 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.786 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.844 592.075 284.305 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="41" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7494"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7494"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7494"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7494"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7494"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7494"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7494"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7494"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7494"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7494"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7494"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7494"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7494">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerSquareRoundedAlt = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareAlt">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7495)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7495)">
            <rect x="17" y="19" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7495)">
            <rect x="17" y="193" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter2_d_76_7495)">
            <rect x="17" y="367" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter3_d_76_7495)">
            <rect x="17" y="541" width="346" height="159" fill="white" />
          </g>
          <mask
            id="mask0_76_7495"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="41"
            y="42"
            width="113"
            height="113"
          >
            <circle cx="97.5" cy="98.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_76_7495)">
            <rect x="41" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 116.267C66.6033 116.704 66.0477 116.593 65.2821 115.776C65.0783 115.554 64.8993 115.302 64.7264 115.057C64.2325 114.368 63.9732 114.239 63.2632 114.694C61.5901 115.782 60.1145 115.69 58.7007 114.239C58.0092 113.533 57.0707 113.262 56.1014 113.145C55.0827 113.029 54.1443 112.789 53.4467 111.947C53.033 111.455 52.4156 111.511 51.8599 111.621C51.0079 111.793 50.2053 111.707 49.4521 111.296C48.4952 110.767 47.6185 110.896 46.7665 111.511C46.6059 111.627 46.4454 111.732 46.2787 111.836C45.0378 112.629 44.3339 112.488 43.4819 111.271C42.8275 110.331 41.9446 110.06 41 110.435V106.41C42.0619 106.766 43.1671 106.908 44.2845 106.859C46.0132 106.779 47.495 107.233 48.7545 108.438C49.4089 109.065 50.193 109.268 51.1067 109.016C52.1563 108.727 53.0268 109.01 53.6874 109.913C54.1752 110.57 54.8296 110.988 55.6878 110.951C57.398 110.878 58.8365 111.492 60.0528 112.654C60.6208 113.195 61.269 113.588 62.0902 113.526C63.9176 113.385 65.4364 113.901 66.486 115.475C66.7823 115.886 67.2948 115.985 67.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0567 122.696L82.0506 123.961C81.5258 124.134 81.0442 124.441 80.4824 124.49C78.4573 124.343 76.494 123.863 74.5431 123.359C68.1962 121.718 61.6827 122.696 55.2001 122.696C51.4896 122.358 47.2172 122.542 43.4943 122.696C43.4943 122.696 42.0558 122.622 41.0124 122.696V110.441C41.957 110.067 42.8399 110.337 43.4943 111.277C44.3463 112.494 45.044 112.635 46.2911 111.843C46.4578 111.738 46.6184 111.634 46.7789 111.517C47.6309 110.902 48.5076 110.773 49.4645 111.302C50.2178 111.714 51.0204 111.793 51.8724 111.628C52.428 111.517 53.0454 111.462 53.4591 111.953C54.1567 112.795 55.0952 113.035 56.1139 113.152C57.0832 113.262 58.0216 113.533 58.7131 114.245C60.1269 115.696 61.6025 115.788 63.2756 114.7C63.9795 114.239 64.2449 114.375 64.7388 115.063C64.9179 115.309 65.0908 115.561 65.2945 115.782C66.0601 116.605 66.6157 116.71 67.6838 116.273C68.0913 116.31 68.4926 116.353 68.9124 116.335C69.7891 116.31 70.3139 116.876 70.6103 117.613C70.8819 118.302 71.3141 118.64 72.0735 118.646C72.8205 118.652 73.4503 119.027 73.9936 119.506C74.9011 120.299 75.9631 120.668 77.1546 120.686C78.1054 120.698 78.9327 121.012 79.6736 121.595C80.3712 122.149 81.1306 122.609 82.0567 122.696Z"
              fill="#66646F"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V116.205C53.2464 117.968 75.1866 120.991 98.359 114.902C120.48 109.084 139.779 105.413 154 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V120.817C53.2464 122.58 77.0945 134.139 98.359 123.208C120.913 111.606 139.779 105.413 154 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 117.312C136.64 116.619 136.644 115.93 136.644 115.237C136.659 114.728 136.674 114.216 136.689 113.708L136.659 113.678C137.495 113.915 138.339 113.862 139.182 113.723C139.197 114.559 139.213 115.391 139.228 116.228C139.216 117.467 139.209 118.702 139.186 119.942C139.171 120.781 138.987 121.565 137.947 121.576C136.907 121.588 136.704 120.812 136.681 119.968C136.659 119.079 136.655 118.198 136.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 113.708C132.79 112.849 130.105 109.93 130.278 106.174C130.538 100.63 132.067 95.4017 135.077 90.6707C136.975 91.5333 138.018 93.168 138.881 94.9459C140.192 97.6504 141.02 100.513 141.559 103.462C141.819 104.89 142.166 106.321 141.898 107.794C141.348 110.819 139.499 112.691 136.655 113.678L136.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 113.674C139.502 112.687 141.352 110.815 141.905 107.79C142.173 106.321 141.826 104.886 141.566 103.459C141.028 100.513 140.199 97.6466 138.888 94.9421C138.026 93.1643 136.986 91.5295 135.084 90.667C136.885 88.1923 138.636 88.0567 140.523 90.2828C140.979 90.8214 141.397 91.4128 141.728 92.038C143.423 95.2322 144.493 98.6485 145.194 102.182C145.375 103.086 145.506 104.001 145.661 104.912C145.774 107.327 145.326 109.553 143.589 111.384C142.874 112.141 142 112.664 141.145 113.222C140.493 113.387 139.841 113.553 139.186 113.719C138.342 113.858 137.498 113.911 136.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 127.595C111.439 126.325 111.446 125.061 111.446 123.79C111.474 122.858 111.501 121.918 111.529 120.986L111.474 120.931C113.007 121.366 114.554 121.269 116.101 121.014C116.129 122.547 116.156 124.073 116.184 125.606C116.163 127.878 116.149 130.144 116.108 132.416C116.08 133.956 115.742 135.392 113.836 135.413C111.93 135.434 111.557 134.011 111.515 132.464C111.474 130.834 111.467 129.218 111.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 120.986C104.381 119.411 99.4568 114.059 99.7745 107.174C100.251 97.0078 103.055 87.422 108.573 78.7478C112.054 80.3293 113.967 83.3266 115.549 86.5863C117.952 91.545 119.471 96.7937 120.459 102.201C120.936 104.819 121.571 107.443 121.081 110.143C120.072 115.689 116.681 119.121 111.467 120.931L111.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 120.924C116.688 119.114 120.079 115.682 121.094 110.136C121.585 107.443 120.949 104.812 120.473 102.194C119.485 96.7936 117.966 91.538 115.563 86.5794C113.981 83.3197 112.075 80.3224 108.587 78.7409C111.888 74.2035 115.1 73.9549 118.56 78.0364C119.396 79.024 120.162 80.1083 120.77 81.2547C123.878 87.1112 125.839 93.3751 127.124 99.8531C127.455 101.511 127.697 103.189 127.98 104.86C128.187 109.287 127.365 113.368 124.182 116.725C122.869 118.113 121.267 119.073 119.699 120.095C118.505 120.399 117.31 120.703 116.108 121.007C114.561 121.262 113.014 121.359 111.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7391 74.4687C57.6849 75.1077 56.622 75.7292 55.8696 76.7533C55.5765 77.1516 55.1522 77.0597 54.7367 77.0115C52.4971 76.7358 50.4587 78.0838 49.8026 80.2677C49.5051 81.2612 49.6364 81.4581 50.6687 81.4625C55.7865 81.4669 60.9043 81.4625 66.0222 81.4712C66.4946 81.4712 66.967 81.5238 67.435 81.55C69.3028 81.5238 71.1662 81.48 73.034 81.4888C73.7601 81.4931 73.9876 81.2656 73.8214 80.5259C73.3184 78.2851 71.1662 76.7227 68.8829 77.0072C68.4017 77.0684 67.9949 77.1209 67.6013 76.6745C65.3179 74.0617 62.4922 73.344 59.1984 74.3593C59.0497 74.3987 58.8966 74.4294 58.7391 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2959 74.355C90.4171 74.3593 97.534 74.3637 104.655 74.3812C105.39 74.3812 105.499 74.0749 105.355 73.4359C104.918 71.4883 103.22 70.1403 101.116 70.2322C100.355 70.2673 99.8435 70.0747 99.3055 69.5145C96.5541 66.6522 92.1668 66.6653 89.3848 69.5276C88.8774 70.0528 88.3962 70.3504 87.657 70.2235C86.7821 70.0747 85.986 70.4817 85.1549 70.6611C84.1532 71.6984 83.1122 72.7181 83.2959 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 57.317C76.7127 56.0959 74.32 55.8858 71.9317 57.2645C70.0026 58.3805 68.9091 60.0786 68.5285 62.2712C68.4061 62.9846 68.6116 63.2472 69.3465 63.2385C72.1329 63.2166 74.9193 63.2297 77.7013 63.2297C80.2952 63.2297 82.8934 63.2341 85.4873 63.2253C86.5065 63.221 86.629 63.0284 86.3753 62.0218C86.161 61.1683 85.6579 60.4812 85.1724 59.7766C84.2582 59.0851 83.3002 58.398 82.1061 58.468C80.8857 58.5424 79.884 58.2492 79.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask1_76_7495"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="216"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="272.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask1_76_7495)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.602 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.073 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.212 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.986 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.905 281.79C327.173 280.321 326.826 278.886 326.566 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.884 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.728 266.038C328.423 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.873 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.498 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.094 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.563 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.396 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.182 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.505 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.787 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.844 244.075 284.306 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask2_76_7495"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="41"
            y="390"
            width="113"
            height="113"
          >
            <circle cx="97.5" cy="446.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask2_76_7495)">
            <rect x="41" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 464.267C66.6033 464.704 66.0477 464.593 65.2821 463.776C65.0783 463.554 64.8993 463.302 64.7264 463.057C64.2325 462.368 63.9732 462.239 63.2632 462.694C61.5901 463.782 60.1145 463.69 58.7007 462.239C58.0092 461.533 57.0707 461.262 56.1014 461.145C55.0827 461.029 54.1443 460.789 53.4467 459.947C53.033 459.455 52.4156 459.511 51.8599 459.621C51.0079 459.793 50.2053 459.707 49.4521 459.296C48.4952 458.767 47.6185 458.896 46.7665 459.511C46.6059 459.627 46.4454 459.732 46.2787 459.836C45.0378 460.629 44.3339 460.488 43.4819 459.271C42.8275 458.331 41.9446 458.06 41 458.435V454.41C42.0619 454.766 43.1671 454.908 44.2845 454.859C46.0132 454.779 47.495 455.233 48.7545 456.438C49.4089 457.065 50.193 457.268 51.1067 457.016C52.1563 456.727 53.0268 457.01 53.6874 457.913C54.1752 458.57 54.8296 458.988 55.6878 458.951C57.398 458.878 58.8365 459.492 60.0528 460.654C60.6208 461.195 61.269 461.588 62.0902 461.526C63.9176 461.385 65.4364 461.901 66.486 463.475C66.7823 463.886 67.2948 463.985 67.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0567 470.696L82.0506 471.961C81.5258 472.134 81.0442 472.441 80.4824 472.49C78.4573 472.343 76.494 471.863 74.5431 471.359C68.1962 469.718 61.6827 470.696 55.2001 470.696C51.4896 470.358 47.2172 470.542 43.4943 470.696C43.4943 470.696 42.0558 470.622 41.0124 470.696V458.441C41.957 458.067 42.8399 458.337 43.4943 459.277C44.3463 460.494 45.044 460.635 46.2911 459.843C46.4578 459.738 46.6184 459.634 46.7789 459.517C47.6309 458.902 48.5076 458.773 49.4645 459.302C50.2178 459.714 51.0204 459.793 51.8724 459.628C52.428 459.517 53.0454 459.462 53.4591 459.953C54.1567 460.795 55.0952 461.035 56.1139 461.152C57.0832 461.262 58.0216 461.533 58.7131 462.245C60.1269 463.696 61.6025 463.788 63.2756 462.7C63.9795 462.239 64.2449 462.375 64.7388 463.063C64.9179 463.309 65.0908 463.561 65.2945 463.782C66.0601 464.605 66.6157 464.71 67.6838 464.273C68.0913 464.31 68.4926 464.353 68.9124 464.335C69.7891 464.31 70.3139 464.876 70.6103 465.613C70.8819 466.302 71.3141 466.64 72.0735 466.646C72.8205 466.652 73.4503 467.027 73.9936 467.506C74.9011 468.299 75.9631 468.668 77.1546 468.686C78.1054 468.698 78.9327 469.012 79.6736 469.595C80.3712 470.149 81.1306 470.609 82.0567 470.696Z"
              fill="#66646F"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V464.205C53.2464 465.968 75.1866 468.991 98.359 462.902C120.48 457.084 139.779 453.413 154 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V468.817C53.2464 470.58 77.0945 482.139 98.359 471.208C120.913 459.606 139.779 453.413 154 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 465.312C136.64 464.619 136.644 463.93 136.644 463.237C136.659 462.728 136.674 462.216 136.689 461.708L136.659 461.678C137.495 461.915 138.339 461.862 139.182 461.723C139.197 462.559 139.213 463.391 139.228 464.228C139.216 465.467 139.209 466.702 139.186 467.942C139.171 468.781 138.987 469.565 137.947 469.576C136.907 469.588 136.704 468.812 136.681 467.968C136.659 467.079 136.655 466.198 136.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 461.708C132.79 460.849 130.105 457.93 130.278 454.174C130.538 448.63 132.067 443.402 135.077 438.671C136.975 439.533 138.018 441.168 138.881 442.946C140.192 445.65 141.02 448.513 141.559 451.462C141.819 452.89 142.166 454.321 141.898 455.794C141.348 458.819 139.499 460.691 136.655 461.678L136.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 461.674C139.502 460.687 141.352 458.815 141.905 455.79C142.173 454.321 141.826 452.886 141.566 451.459C141.028 448.513 140.199 445.647 138.888 442.942C138.026 441.164 136.986 439.53 135.084 438.667C136.885 436.192 138.636 436.057 140.523 438.283C140.979 438.821 141.397 439.413 141.728 440.038C143.423 443.232 144.493 446.649 145.194 450.182C145.375 451.086 145.506 452.001 145.661 452.912C145.774 455.327 145.326 457.553 143.589 459.384C142.874 460.141 142 460.664 141.145 461.222C140.493 461.387 139.841 461.553 139.186 461.719C138.342 461.858 137.498 461.911 136.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 475.595C111.439 474.325 111.446 473.061 111.446 471.79C111.474 470.858 111.501 469.918 111.529 468.986L111.474 468.931C113.007 469.366 114.554 469.269 116.101 469.014C116.129 470.547 116.156 472.073 116.184 473.606C116.163 475.878 116.149 478.144 116.108 480.416C116.08 481.956 115.742 483.392 113.836 483.413C111.93 483.434 111.557 482.011 111.515 480.464C111.474 478.834 111.467 477.218 111.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 468.986C104.381 467.411 99.4568 462.059 99.7745 455.174C100.251 445.008 103.055 435.422 108.573 426.748C112.054 428.329 113.967 431.327 115.549 434.586C117.952 439.545 119.471 444.794 120.459 450.201C120.936 452.819 121.571 455.443 121.081 458.143C120.072 463.689 116.681 467.121 111.467 468.931L111.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 468.924C116.688 467.114 120.079 463.682 121.094 458.136C121.585 455.443 120.949 452.812 120.473 450.194C119.485 444.794 117.966 439.538 115.563 434.579C113.981 431.32 112.075 428.322 108.587 426.741C111.888 422.203 115.1 421.955 118.56 426.036C119.396 427.024 120.162 428.108 120.77 429.255C123.878 435.111 125.839 441.375 127.124 447.853C127.455 449.511 127.697 451.189 127.98 452.86C128.187 457.287 127.365 461.368 124.182 464.725C122.869 466.113 121.267 467.073 119.699 468.095C118.505 468.399 117.31 468.703 116.108 469.007C114.561 469.262 113.014 469.359 111.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7391 422.469C57.6849 423.108 56.622 423.729 55.8696 424.753C55.5765 425.152 55.1522 425.06 54.7367 425.012C52.4971 424.736 50.4587 426.084 49.8026 428.268C49.5051 429.261 49.6364 429.458 50.6687 429.462C55.7865 429.467 60.9043 429.462 66.0222 429.471C66.4946 429.471 66.967 429.524 67.435 429.55C69.3028 429.524 71.1662 429.48 73.034 429.489C73.7601 429.493 73.9876 429.266 73.8214 428.526C73.3184 426.285 71.1662 424.723 68.8829 425.007C68.4017 425.068 67.9949 425.121 67.6013 424.675C65.3179 422.062 62.4922 421.344 59.1984 422.359C59.0497 422.399 58.8966 422.429 58.7391 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2959 422.355C90.4171 422.359 97.534 422.364 104.655 422.381C105.39 422.381 105.499 422.075 105.355 421.436C104.918 419.488 103.22 418.14 101.116 418.232C100.355 418.267 99.8435 418.075 99.3055 417.514C96.5541 414.652 92.1668 414.665 89.3848 417.528C88.8774 418.053 88.3962 418.35 87.657 418.223C86.7821 418.075 85.986 418.482 85.1549 418.661C84.1532 419.698 83.1122 420.718 83.2959 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 405.317C76.7127 404.096 74.32 403.886 71.9317 405.264C70.0026 406.38 68.9091 408.079 68.5285 410.271C68.4061 410.985 68.6116 411.247 69.3465 411.238C72.1329 411.217 74.9193 411.23 77.7013 411.23C80.2952 411.23 82.8934 411.234 85.4873 411.225C86.5065 411.221 86.629 411.028 86.3753 410.022C86.161 409.168 85.6579 408.481 85.1724 407.777C84.2582 407.085 83.3002 406.398 82.1061 406.468C80.8857 406.542 79.884 406.249 79.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <mask
            id="mask3_76_7495"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="226"
            y="564"
            width="113"
            height="113"
          >
            <circle cx="282.5" cy="620.5" r="56.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask3_76_7495)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.961C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.245C245.127 637.696 246.602 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.273C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.073 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.595C265.371 644.149 266.131 644.609 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.212 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.986 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.905 629.79C327.173 628.321 326.826 626.886 326.566 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.884 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.728 614.038C328.423 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.873 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.498 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.094 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.563 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.396 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.182 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.505 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.787 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.844 592.075 284.306 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="176" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="177" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7495"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7495"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7495"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7495"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7495"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7495"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7495"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7495"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7495"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7495"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7495"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7495"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7495">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// Container Square / Image Square / Position
const CollectionContainerSquareSquareLeft = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareLeft">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7486)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7486)">
            <rect x="17" y="19" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7486)">
            <rect x="17" y="193" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter2_d_76_7486)">
            <rect x="17" y="367" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter3_d_76_7486)">
            <rect x="17" y="541" width="346" height="159" fill="white" />
          </g>
          <g clipPath="url(#clip1_76_7486)">
            <rect x="39" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 116.267C64.6033 116.704 64.0477 116.593 63.2821 115.776C63.0783 115.554 62.8993 115.302 62.7264 115.057C62.2325 114.368 61.9732 114.239 61.2632 114.694C59.5901 115.782 58.1145 115.69 56.7007 114.239C56.0092 113.533 55.0707 113.262 54.1014 113.145C53.0827 113.029 52.1443 112.789 51.4467 111.947C51.033 111.455 50.4156 111.511 49.8599 111.621C49.0079 111.793 48.2053 111.707 47.4521 111.296C46.4952 110.767 45.6185 110.896 44.7665 111.511C44.6059 111.627 44.4454 111.732 44.2787 111.836C43.0378 112.629 42.3339 112.488 41.4819 111.271C40.8275 110.331 39.9446 110.06 39 110.435V106.41C40.0619 106.766 41.1671 106.908 42.2845 106.859C44.0132 106.779 45.495 107.233 46.7545 108.438C47.4089 109.065 48.193 109.268 49.1067 109.016C50.1563 108.727 51.0268 109.01 51.6874 109.913C52.1752 110.57 52.8296 110.988 53.6878 110.951C55.398 110.878 56.8365 111.492 58.0528 112.654C58.6208 113.195 59.269 113.588 60.0902 113.526C61.9176 113.385 63.4364 113.901 64.486 115.475C64.7823 115.886 65.2948 115.985 65.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 122.696L80.0506 123.961C79.5258 124.134 79.0442 124.441 78.4824 124.49C76.4574 124.343 74.494 123.863 72.5431 123.359C66.1963 121.718 59.6828 122.696 53.2001 122.696C49.4896 122.358 45.2173 122.542 41.4944 122.696C41.4944 122.696 40.0558 122.622 39.0125 122.696V110.441C39.9571 110.067 40.8399 110.337 41.4944 111.277C42.3464 112.494 43.044 112.635 44.2912 111.843C44.4579 111.738 44.6184 111.634 44.7789 111.517C45.6309 110.902 46.5076 110.773 47.4646 111.302C48.2178 111.714 49.0204 111.793 49.8724 111.628C50.4281 111.517 51.0454 111.462 51.4591 111.953C52.1568 112.795 53.0952 113.035 54.1139 113.152C55.0832 113.262 56.0216 113.533 56.7131 114.245C58.1269 115.696 59.6025 115.788 61.2757 114.7C61.9795 114.239 62.245 114.375 62.7389 115.063C62.9179 115.309 63.0908 115.561 63.2945 115.782C64.0601 116.605 64.6158 116.71 65.6838 116.273C66.0913 116.31 66.4926 116.353 66.9125 116.335C67.7892 116.31 68.3139 116.876 68.6103 117.613C68.8819 118.302 69.3141 118.64 70.0735 118.646C70.8206 118.652 71.4503 119.027 71.9936 119.506C72.9012 120.299 73.9631 120.668 75.1547 120.686C76.1054 120.698 76.9327 121.012 77.6736 121.595C78.3713 122.149 79.1307 122.609 80.0568 122.696Z"
              fill="#66646F"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V116.205C51.2464 117.968 73.1866 120.991 96.359 114.902C118.48 109.084 137.779 105.413 152 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 106.65V156.267C152 157.647 150.865 158.767 149.469 158.767H41.5305C40.1306 158.767 39 157.647 39 156.267V120.817C51.2464 122.58 75.0945 134.139 96.359 123.208C118.913 111.606 137.779 105.413 152 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 117.312C134.64 116.619 134.644 115.93 134.644 115.237C134.659 114.728 134.674 114.216 134.689 113.708L134.659 113.678C135.495 113.915 136.339 113.862 137.182 113.723C137.197 114.559 137.213 115.391 137.228 116.228C137.216 117.467 137.209 118.702 137.186 119.942C137.171 120.781 136.987 121.565 135.947 121.576C134.907 121.588 134.704 120.812 134.681 119.968C134.659 119.079 134.655 118.198 134.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 113.708C130.79 112.849 128.105 109.93 128.278 106.174C128.538 100.63 130.067 95.4017 133.077 90.6707C134.975 91.5333 136.018 93.168 136.881 94.9459C138.192 97.6504 139.02 100.513 139.559 103.462C139.819 104.89 140.166 106.321 139.898 107.794C139.348 110.819 137.499 112.691 134.655 113.678L134.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 113.674C137.502 112.687 139.352 110.815 139.905 107.79C140.173 106.321 139.826 104.886 139.566 103.459C139.028 100.513 138.199 97.6466 136.888 94.9421C136.026 93.1643 134.986 91.5295 133.084 90.667C134.884 88.1923 136.636 88.0567 138.523 90.2828C138.979 90.8214 139.397 91.4128 139.728 92.038C141.423 95.2322 142.493 98.6485 143.194 102.182C143.375 103.086 143.506 104.001 143.661 104.912C143.774 107.327 143.326 109.553 141.589 111.384C140.873 112.141 140 112.664 139.145 113.222C138.493 113.387 137.841 113.553 137.186 113.719C136.342 113.858 135.498 113.911 134.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 127.595C109.439 126.325 109.446 125.061 109.446 123.79C109.474 122.858 109.501 121.918 109.529 120.986L109.474 120.931C111.007 121.366 112.554 121.269 114.101 121.014C114.129 122.547 114.156 124.073 114.184 125.606C114.163 127.878 114.149 130.144 114.108 132.416C114.08 133.956 113.742 135.392 111.836 135.413C109.93 135.434 109.557 134.011 109.515 132.464C109.474 130.834 109.467 129.218 109.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 120.986C102.381 119.411 97.4568 114.059 97.7745 107.174C98.2511 97.0078 101.055 87.422 106.573 78.7478C110.054 80.3293 111.967 83.3266 113.549 86.5863C115.952 91.545 117.471 96.7937 118.459 102.201C118.936 104.819 119.571 107.443 119.081 110.143C118.072 115.689 114.681 119.121 109.467 120.931L109.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 120.924C114.688 119.114 118.079 115.682 119.094 110.136C119.585 107.443 118.949 104.812 118.473 102.194C117.485 96.7936 115.966 91.538 113.562 86.5794C111.981 83.3197 110.075 80.3224 106.587 78.7409C109.888 74.2035 113.1 73.9549 116.56 78.0364C117.395 79.024 118.162 80.1083 118.77 81.2547C121.878 87.1112 123.839 93.3751 125.124 99.8531C125.455 101.511 125.697 103.189 125.98 104.86C126.187 109.287 125.365 113.368 122.181 116.725C120.869 118.113 119.267 119.073 117.699 120.095C116.504 120.399 115.31 120.703 114.108 121.007C112.561 121.262 111.014 121.359 109.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 74.4687C55.6849 75.1077 54.622 75.7292 53.8696 76.7533C53.5765 77.1516 53.1522 77.0597 52.7367 77.0115C50.4971 76.7358 48.4587 78.0838 47.8026 80.2677C47.5051 81.2612 47.6363 81.4581 48.6686 81.4625C53.7865 81.4669 58.9043 81.4625 64.0221 81.4712C64.4946 81.4712 64.967 81.5238 65.435 81.55C67.3028 81.5238 69.1662 81.48 71.034 81.4888C71.7601 81.4931 71.9876 81.2656 71.8214 80.5259C71.3183 78.2851 69.1662 76.7227 66.8829 77.0072C66.4017 77.0684 65.9949 77.1209 65.6012 76.6745C63.3179 74.0617 60.4921 73.344 57.1984 74.3593C57.0496 74.3987 56.8965 74.4294 56.7391 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 74.355C88.417 74.3593 95.5339 74.3637 102.655 74.3812C103.39 74.3812 103.499 74.0749 103.355 73.4359C102.918 71.4883 101.22 70.1403 99.1163 70.2322C98.3552 70.2673 97.8435 70.0747 97.3054 69.5145C94.554 66.6522 90.1667 66.6653 87.3847 69.5276C86.8773 70.0528 86.3961 70.3504 85.6569 70.2235C84.7821 70.0747 83.9859 70.4817 83.1548 70.6611C82.1532 71.6984 81.1121 72.7181 81.2958 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 57.317C74.7127 56.0959 72.32 55.8858 69.9317 57.2645C68.0026 58.3805 66.9091 60.0786 66.5285 62.2712C66.4061 62.9846 66.6116 63.2472 67.3465 63.2385C70.1329 63.2166 72.9193 63.2297 75.7013 63.2297C78.2952 63.2297 80.8934 63.2341 83.4873 63.2253C84.5065 63.221 84.629 63.0284 84.3753 62.0218C84.161 61.1683 83.6579 60.4812 83.1724 59.7766C82.2582 59.0851 81.3002 58.398 80.1061 58.468C78.8857 58.5424 77.884 58.2492 77.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip2_76_7486)">
            <rect x="39" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 290.267C64.6033 290.704 64.0477 290.593 63.2821 289.776C63.0783 289.554 62.8993 289.302 62.7264 289.057C62.2325 288.368 61.9732 288.239 61.2632 288.694C59.5901 289.782 58.1145 289.69 56.7007 288.239C56.0092 287.533 55.0707 287.262 54.1014 287.145C53.0827 287.029 52.1443 286.789 51.4467 285.947C51.033 285.455 50.4156 285.511 49.8599 285.621C49.0079 285.793 48.2053 285.707 47.4521 285.296C46.4952 284.767 45.6185 284.896 44.7665 285.511C44.6059 285.627 44.4454 285.732 44.2787 285.836C43.0378 286.629 42.3339 286.488 41.4819 285.271C40.8275 284.331 39.9446 284.06 39 284.435V280.41C40.0619 280.766 41.1671 280.908 42.2845 280.859C44.0132 280.779 45.495 281.233 46.7545 282.438C47.4089 283.065 48.193 283.268 49.1067 283.016C50.1563 282.727 51.0268 283.01 51.6874 283.913C52.1752 284.57 52.8296 284.988 53.6878 284.951C55.398 284.878 56.8365 285.492 58.0528 286.654C58.6208 287.195 59.269 287.588 60.0902 287.526C61.9176 287.385 63.4364 287.901 64.486 289.475C64.7823 289.886 65.2948 289.985 65.6714 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 296.696L80.0506 297.961C79.5258 298.134 79.0442 298.441 78.4824 298.49C76.4574 298.343 74.494 297.863 72.5431 297.359C66.1963 295.718 59.6828 296.696 53.2001 296.696C49.4896 296.358 45.2173 296.542 41.4944 296.696C41.4944 296.696 40.0558 296.622 39.0125 296.696V284.441C39.9571 284.067 40.8399 284.337 41.4944 285.277C42.3464 286.494 43.044 286.635 44.2912 285.843C44.4579 285.738 44.6184 285.634 44.7789 285.517C45.6309 284.902 46.5076 284.773 47.4646 285.302C48.2178 285.714 49.0204 285.793 49.8724 285.628C50.4281 285.517 51.0454 285.462 51.4591 285.953C52.1568 286.795 53.0952 287.035 54.1139 287.152C55.0832 287.262 56.0216 287.533 56.7131 288.245C58.1269 289.696 59.6025 289.788 61.2757 288.7C61.9795 288.239 62.245 288.375 62.7389 289.063C62.9179 289.309 63.0908 289.561 63.2945 289.782C64.0601 290.605 64.6158 290.71 65.6838 290.273C66.0913 290.31 66.4926 290.353 66.9125 290.335C67.7892 290.31 68.3139 290.876 68.6103 291.613C68.8819 292.302 69.3141 292.64 70.0735 292.646C70.8206 292.652 71.4503 293.027 71.9936 293.506C72.9012 294.299 73.9631 294.668 75.1547 294.686C76.1054 294.698 76.9327 295.012 77.6736 295.595C78.3713 296.149 79.1307 296.609 80.0568 296.696Z"
              fill="#66646F"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V290.205C51.2464 291.968 73.1866 294.991 96.359 288.902C118.48 283.084 137.779 279.413 152 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 280.65V330.267C152 331.647 150.865 332.767 149.469 332.767H41.5305C40.1306 332.767 39 331.647 39 330.267V294.817C51.2464 296.58 75.0945 308.139 96.359 297.208C118.913 285.606 137.779 279.413 152 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 291.312C134.64 290.619 134.644 289.93 134.644 289.237C134.659 288.728 134.674 288.216 134.689 287.708L134.659 287.678C135.495 287.915 136.339 287.862 137.182 287.723C137.197 288.559 137.213 289.391 137.228 290.228C137.216 291.467 137.209 292.702 137.186 293.942C137.171 294.781 136.987 295.565 135.947 295.576C134.907 295.588 134.704 294.812 134.681 293.968C134.659 293.079 134.655 292.198 134.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 287.708C130.79 286.849 128.105 283.93 128.278 280.174C128.538 274.63 130.067 269.402 133.077 264.671C134.975 265.533 136.018 267.168 136.881 268.946C138.192 271.65 139.02 274.513 139.559 277.462C139.819 278.89 140.166 280.321 139.898 281.794C139.348 284.819 137.499 286.691 134.655 287.678L134.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 287.674C137.502 286.687 139.352 284.815 139.905 281.79C140.173 280.321 139.826 278.886 139.566 277.459C139.028 274.513 138.199 271.647 136.888 268.942C136.026 267.164 134.986 265.53 133.084 264.667C134.884 262.192 136.636 262.057 138.523 264.283C138.979 264.821 139.397 265.413 139.728 266.038C141.423 269.232 142.493 272.649 143.194 276.182C143.375 277.086 143.506 278.001 143.661 278.912C143.774 281.327 143.326 283.553 141.589 285.384C140.873 286.141 140 286.664 139.145 287.222C138.493 287.387 137.841 287.553 137.186 287.719C136.342 287.858 135.498 287.911 134.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 301.595C109.439 300.325 109.446 299.061 109.446 297.79C109.474 296.858 109.501 295.918 109.529 294.986L109.474 294.931C111.007 295.366 112.554 295.269 114.101 295.014C114.129 296.547 114.156 298.073 114.184 299.606C114.163 301.878 114.149 304.144 114.108 306.416C114.08 307.956 113.742 309.392 111.836 309.413C109.93 309.434 109.557 308.011 109.515 306.464C109.474 304.834 109.467 303.218 109.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 294.986C102.381 293.411 97.4568 288.059 97.7745 281.174C98.2511 271.008 101.055 261.422 106.573 252.748C110.054 254.329 111.967 257.327 113.549 260.586C115.952 265.545 117.471 270.794 118.459 276.201C118.936 278.819 119.571 281.443 119.081 284.143C118.072 289.689 114.681 293.121 109.467 294.931L109.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 294.924C114.688 293.114 118.079 289.682 119.094 284.136C119.585 281.443 118.949 278.812 118.473 276.194C117.485 270.794 115.966 265.538 113.562 260.579C111.981 257.32 110.075 254.322 106.587 252.741C109.888 248.203 113.1 247.955 116.56 252.036C117.395 253.024 118.162 254.108 118.77 255.255C121.878 261.111 123.839 267.375 125.124 273.853C125.455 275.511 125.697 277.189 125.98 278.86C126.187 283.287 125.365 287.368 122.181 290.725C120.869 292.113 119.267 293.073 117.699 294.095C116.504 294.399 115.31 294.703 114.108 295.007C112.561 295.262 111.014 295.359 109.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 248.469C55.6849 249.108 54.622 249.729 53.8696 250.753C53.5765 251.152 53.1522 251.06 52.7367 251.012C50.4971 250.736 48.4587 252.084 47.8026 254.268C47.5051 255.261 47.6363 255.458 48.6686 255.462C53.7865 255.467 58.9043 255.462 64.0221 255.471C64.4946 255.471 64.967 255.524 65.435 255.55C67.3028 255.524 69.1662 255.48 71.034 255.489C71.7601 255.493 71.9876 255.266 71.8214 254.526C71.3183 252.285 69.1662 250.723 66.8829 251.007C66.4017 251.068 65.9949 251.121 65.6012 250.675C63.3179 248.062 60.4921 247.344 57.1984 248.359C57.0496 248.399 56.8965 248.429 56.7391 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 248.355C88.417 248.359 95.5339 248.364 102.655 248.381C103.39 248.381 103.499 248.075 103.355 247.436C102.918 245.488 101.22 244.14 99.1163 244.232C98.3552 244.267 97.8435 244.075 97.3054 243.514C94.554 240.652 90.1667 240.665 87.3847 243.528C86.8773 244.053 86.3961 244.35 85.6569 244.223C84.7821 244.075 83.9859 244.482 83.1548 244.661C82.1532 245.698 81.1121 246.718 81.2958 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 231.317C74.7127 230.096 72.32 229.886 69.9317 231.264C68.0026 232.38 66.9091 234.079 66.5285 236.271C66.4061 236.985 66.6116 237.247 67.3465 237.238C70.1329 237.217 72.9193 237.23 75.7013 237.23C78.2952 237.23 80.8934 237.234 83.4873 237.225C84.5065 237.221 84.629 237.028 84.3753 236.022C84.161 235.168 83.6579 234.481 83.1724 233.777C82.2582 233.085 81.3002 232.398 80.1061 232.468C78.8857 232.542 77.884 232.249 77.0879 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip3_76_7486)">
            <rect x="39" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 464.267C64.6033 464.704 64.0477 464.593 63.2821 463.776C63.0783 463.554 62.8993 463.302 62.7264 463.057C62.2325 462.368 61.9732 462.239 61.2632 462.694C59.5901 463.782 58.1145 463.69 56.7007 462.239C56.0092 461.533 55.0707 461.262 54.1014 461.145C53.0827 461.029 52.1443 460.789 51.4467 459.947C51.033 459.455 50.4156 459.511 49.8599 459.621C49.0079 459.793 48.2053 459.707 47.4521 459.296C46.4952 458.767 45.6185 458.896 44.7665 459.511C44.6059 459.627 44.4454 459.732 44.2787 459.836C43.0378 460.629 42.3339 460.488 41.4819 459.271C40.8275 458.331 39.9446 458.06 39 458.435V454.41C40.0619 454.766 41.1671 454.908 42.2845 454.859C44.0132 454.779 45.495 455.233 46.7545 456.438C47.4089 457.065 48.193 457.268 49.1067 457.016C50.1563 456.727 51.0268 457.01 51.6874 457.913C52.1752 458.57 52.8296 458.988 53.6878 458.951C55.398 458.878 56.8365 459.492 58.0528 460.654C58.6208 461.195 59.269 461.588 60.0902 461.526C61.9176 461.385 63.4364 461.901 64.486 463.475C64.7823 463.886 65.2948 463.985 65.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 470.696L80.0506 471.961C79.5258 472.134 79.0442 472.441 78.4824 472.49C76.4574 472.343 74.494 471.863 72.5431 471.359C66.1963 469.718 59.6828 470.696 53.2001 470.696C49.4896 470.358 45.2173 470.542 41.4944 470.696C41.4944 470.696 40.0558 470.622 39.0125 470.696V458.441C39.9571 458.067 40.8399 458.337 41.4944 459.277C42.3464 460.494 43.044 460.635 44.2912 459.843C44.4579 459.738 44.6184 459.634 44.7789 459.517C45.6309 458.902 46.5076 458.773 47.4646 459.302C48.2178 459.714 49.0204 459.793 49.8724 459.628C50.4281 459.517 51.0454 459.462 51.4591 459.953C52.1568 460.795 53.0952 461.035 54.1139 461.152C55.0832 461.262 56.0216 461.533 56.7131 462.245C58.1269 463.696 59.6025 463.788 61.2757 462.7C61.9795 462.239 62.245 462.375 62.7389 463.063C62.9179 463.309 63.0908 463.561 63.2945 463.782C64.0601 464.605 64.6158 464.71 65.6838 464.273C66.0913 464.31 66.4926 464.353 66.9125 464.335C67.7892 464.31 68.3139 464.876 68.6103 465.613C68.8819 466.302 69.3141 466.64 70.0735 466.646C70.8206 466.652 71.4503 467.027 71.9936 467.506C72.9012 468.299 73.9631 468.668 75.1547 468.686C76.1054 468.698 76.9327 469.012 77.6736 469.595C78.3713 470.149 79.1307 470.609 80.0568 470.696Z"
              fill="#66646F"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V464.205C51.2464 465.968 73.1866 468.991 96.359 462.902C118.48 457.084 137.779 453.413 152 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 454.65V504.267C152 505.647 150.865 506.767 149.469 506.767H41.5305C40.1306 506.767 39 505.647 39 504.267V468.817C51.2464 470.58 75.0945 482.139 96.359 471.208C118.913 459.606 137.779 453.413 152 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 465.312C134.64 464.619 134.644 463.93 134.644 463.237C134.659 462.728 134.674 462.216 134.689 461.708L134.659 461.678C135.495 461.915 136.339 461.862 137.182 461.723C137.197 462.559 137.213 463.391 137.228 464.228C137.216 465.467 137.209 466.702 137.186 467.942C137.171 468.781 136.987 469.565 135.947 469.576C134.907 469.588 134.704 468.812 134.681 467.968C134.659 467.079 134.655 466.198 134.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 461.708C130.79 460.849 128.105 457.93 128.278 454.174C128.538 448.63 130.067 443.402 133.077 438.671C134.975 439.533 136.018 441.168 136.881 442.946C138.192 445.65 139.02 448.513 139.559 451.462C139.819 452.89 140.166 454.321 139.898 455.794C139.348 458.819 137.499 460.691 134.655 461.678L134.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 461.674C137.502 460.687 139.352 458.815 139.905 455.79C140.173 454.321 139.826 452.886 139.566 451.459C139.028 448.513 138.199 445.647 136.888 442.942C136.026 441.164 134.986 439.53 133.084 438.667C134.884 436.192 136.636 436.057 138.523 438.283C138.979 438.821 139.397 439.413 139.728 440.038C141.423 443.232 142.493 446.649 143.194 450.182C143.375 451.086 143.506 452.001 143.661 452.912C143.774 455.327 143.326 457.553 141.589 459.384C140.873 460.141 140 460.664 139.145 461.222C138.493 461.387 137.841 461.553 137.186 461.719C136.342 461.858 135.498 461.911 134.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 475.595C109.439 474.325 109.446 473.061 109.446 471.79C109.474 470.858 109.501 469.918 109.529 468.986L109.474 468.931C111.007 469.366 112.554 469.269 114.101 469.014C114.129 470.547 114.156 472.073 114.184 473.606C114.163 475.878 114.149 478.144 114.108 480.416C114.08 481.956 113.742 483.392 111.836 483.413C109.93 483.434 109.557 482.011 109.515 480.464C109.474 478.834 109.467 477.218 109.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 468.986C102.381 467.411 97.4568 462.059 97.7745 455.174C98.2511 445.008 101.055 435.422 106.573 426.748C110.054 428.329 111.967 431.327 113.549 434.586C115.952 439.545 117.471 444.794 118.459 450.201C118.936 452.819 119.571 455.443 119.081 458.143C118.072 463.689 114.681 467.121 109.467 468.931L109.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 468.924C114.688 467.114 118.079 463.682 119.094 458.136C119.585 455.443 118.949 452.812 118.473 450.194C117.485 444.794 115.966 439.538 113.562 434.579C111.981 431.32 110.075 428.322 106.587 426.741C109.888 422.203 113.1 421.955 116.56 426.036C117.395 427.024 118.162 428.108 118.77 429.255C121.878 435.111 123.839 441.375 125.124 447.853C125.455 449.511 125.697 451.189 125.98 452.86C126.187 457.287 125.365 461.368 122.181 464.725C120.869 466.113 119.267 467.073 117.699 468.095C116.504 468.399 115.31 468.703 114.108 469.007C112.561 469.262 111.014 469.359 109.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 422.469C55.6849 423.108 54.622 423.729 53.8696 424.753C53.5765 425.152 53.1522 425.06 52.7367 425.012C50.4971 424.736 48.4587 426.084 47.8026 428.268C47.5051 429.261 47.6363 429.458 48.6686 429.462C53.7865 429.467 58.9043 429.462 64.0221 429.471C64.4946 429.471 64.967 429.524 65.435 429.55C67.3028 429.524 69.1662 429.48 71.034 429.489C71.7601 429.493 71.9876 429.266 71.8214 428.526C71.3183 426.285 69.1662 424.723 66.8829 425.007C66.4017 425.068 65.9949 425.121 65.6012 424.675C63.3179 422.062 60.4921 421.344 57.1984 422.359C57.0496 422.399 56.8965 422.429 56.7391 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 422.355C88.417 422.359 95.5339 422.364 102.655 422.381C103.39 422.381 103.499 422.075 103.355 421.436C102.918 419.488 101.22 418.14 99.1163 418.232C98.3552 418.267 97.8435 418.075 97.3054 417.514C94.554 414.652 90.1667 414.665 87.3847 417.528C86.8773 418.053 86.3961 418.35 85.6569 418.223C84.7821 418.075 83.9859 418.482 83.1548 418.661C82.1532 419.698 81.1121 420.718 81.2958 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 405.317C74.7127 404.096 72.32 403.886 69.9317 405.264C68.0026 406.38 66.9091 408.079 66.5285 410.271C66.4061 410.985 66.6116 411.247 67.3465 411.238C70.1329 411.217 72.9193 411.23 75.7013 411.23C78.2952 411.23 80.8934 411.234 83.4873 411.225C84.5065 411.221 84.629 411.028 84.3753 410.022C84.161 409.168 83.6579 408.481 83.1724 407.777C82.2582 407.085 81.3002 406.398 80.1061 406.468C78.8857 406.542 77.884 406.249 77.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip4_76_7486)">
            <rect x="39" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M65.6714 638.267C64.6033 638.704 64.0477 638.593 63.2821 637.776C63.0783 637.554 62.8993 637.303 62.7264 637.057C62.2325 636.368 61.9732 636.239 61.2632 636.694C59.5901 637.782 58.1145 637.69 56.7007 636.239C56.0092 635.533 55.0707 635.262 54.1014 635.145C53.0827 635.029 52.1443 634.789 51.4467 633.947C51.033 633.455 50.4156 633.511 49.8599 633.621C49.0079 633.793 48.2053 633.707 47.4521 633.296C46.4952 632.767 45.6185 632.896 44.7665 633.511C44.6059 633.628 44.4454 633.732 44.2787 633.836C43.0378 634.629 42.3339 634.488 41.4819 633.271C40.8275 632.331 39.9446 632.06 39 632.435V628.41C40.0619 628.766 41.1671 628.908 42.2845 628.859C44.0132 628.779 45.495 629.234 46.7545 630.438C47.4089 631.065 48.193 631.268 49.1067 631.016C50.1563 630.727 51.0268 631.01 51.6874 631.913C52.1752 632.57 52.8296 632.988 53.6878 632.952C55.398 632.878 56.8365 633.492 58.0528 634.654C58.6208 635.195 59.269 635.588 60.0902 635.526C61.9176 635.385 63.4364 635.901 64.486 637.475C64.7823 637.886 65.2948 637.985 65.6714 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M80.0568 644.696L80.0506 645.962C79.5258 646.134 79.0442 646.441 78.4824 646.49C76.4574 646.343 74.494 645.863 72.5431 645.359C66.1963 643.718 59.6828 644.696 53.2001 644.696C49.4896 644.358 45.2173 644.542 41.4944 644.696C41.4944 644.696 40.0558 644.622 39.0125 644.696V632.441C39.9571 632.067 40.8399 632.337 41.4944 633.277C42.3464 634.494 43.044 634.635 44.2912 633.843C44.4579 633.738 44.6184 633.634 44.7789 633.517C45.6309 632.902 46.5076 632.773 47.4646 633.302C48.2178 633.714 49.0204 633.793 49.8724 633.628C50.4281 633.517 51.0454 633.462 51.4591 633.953C52.1568 634.795 53.0952 635.035 54.1139 635.152C55.0832 635.262 56.0216 635.533 56.7131 636.246C58.1269 637.696 59.6025 637.788 61.2757 636.7C61.9795 636.239 62.245 636.375 62.7389 637.063C62.9179 637.309 63.0908 637.561 63.2945 637.782C64.0601 638.605 64.6158 638.71 65.6838 638.274C66.0913 638.31 66.4926 638.353 66.9125 638.335C67.7892 638.31 68.3139 638.876 68.6103 639.613C68.8819 640.302 69.3141 640.64 70.0735 640.646C70.8206 640.652 71.4503 641.027 71.9936 641.506C72.9012 642.299 73.9631 642.668 75.1547 642.686C76.1054 642.698 76.9327 643.012 77.6736 643.596C78.3713 644.149 79.1307 644.61 80.0568 644.696Z"
              fill="#66646F"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V638.205C51.2464 639.968 73.1866 642.991 96.359 636.902C118.48 631.084 137.779 627.413 152 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M152 628.65V678.267C152 679.647 150.865 680.767 149.469 680.767H41.5305C40.1306 680.767 39 679.647 39 678.267V642.817C51.2464 644.58 75.0945 656.139 96.359 645.208C118.913 633.606 137.779 627.413 152 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M134.64 639.312C134.64 638.619 134.644 637.93 134.644 637.237C134.659 636.728 134.674 636.216 134.689 635.708L134.659 635.678C135.495 635.915 136.339 635.862 137.182 635.723C137.197 636.559 137.213 637.391 137.228 638.228C137.216 639.467 137.209 640.702 137.186 641.942C137.171 642.782 136.987 643.565 135.947 643.576C134.907 643.588 134.704 642.812 134.681 641.968C134.659 641.079 134.655 640.198 134.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M134.689 635.708C130.79 634.849 128.105 631.93 128.278 628.174C128.538 622.63 130.067 617.402 133.077 612.671C134.975 613.533 136.018 615.168 136.881 616.946C138.192 619.65 139.02 622.513 139.559 625.462C139.819 626.89 140.166 628.321 139.898 629.794C139.348 632.819 137.499 634.691 134.655 635.678L134.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M134.662 635.674C137.502 634.687 139.352 632.815 139.905 629.79C140.173 628.321 139.826 626.886 139.566 625.459C139.028 622.513 138.199 619.647 136.888 616.942C136.026 615.164 134.986 613.53 133.084 612.667C134.884 610.192 136.636 610.057 138.523 612.283C138.979 612.821 139.397 613.413 139.728 614.038C141.423 617.232 142.493 620.649 143.194 624.182C143.375 625.086 143.506 626.001 143.661 626.912C143.774 629.327 143.326 631.553 141.589 633.384C140.873 634.141 140 634.664 139.145 635.222C138.493 635.387 137.841 635.553 137.186 635.719C136.342 635.858 135.498 635.911 134.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M109.439 649.595C109.439 648.325 109.446 647.061 109.446 645.79C109.474 644.858 109.501 643.918 109.529 642.986L109.474 642.931C111.007 643.366 112.554 643.269 114.101 643.014C114.129 644.547 114.156 646.073 114.184 647.606C114.163 649.878 114.149 652.144 114.108 654.416C114.08 655.956 113.742 657.392 111.836 657.413C109.93 657.434 109.557 656.011 109.515 654.464C109.474 652.834 109.467 651.218 109.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M109.529 642.986C102.381 641.411 97.4568 636.059 97.7745 629.174C98.2511 619.008 101.055 609.422 106.573 600.748C110.054 602.329 111.967 605.327 113.549 608.586C115.952 613.545 117.471 618.794 118.459 624.201C118.936 626.819 119.571 629.443 119.081 632.143C118.072 637.689 114.681 641.121 109.467 642.931L109.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M109.481 642.924C114.688 641.114 118.079 637.682 119.094 632.136C119.585 629.443 118.949 626.812 118.473 624.194C117.485 618.794 115.966 613.538 113.562 608.579C111.981 605.32 110.075 602.322 106.587 600.741C109.888 596.203 113.1 595.955 116.56 600.036C117.395 601.024 118.162 602.108 118.77 603.255C121.878 609.111 123.839 615.375 125.124 621.853C125.455 623.511 125.697 625.189 125.98 626.86C126.187 631.287 125.365 635.368 122.181 638.725C120.869 640.113 119.267 641.073 117.699 642.095C116.504 642.399 115.31 642.703 114.108 643.007C112.561 643.262 111.014 643.359 109.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M56.7391 596.469C55.6849 597.108 54.622 597.729 53.8696 598.753C53.5765 599.152 53.1522 599.06 52.7367 599.011C50.4971 598.736 48.4587 600.084 47.8026 602.268C47.5051 603.261 47.6363 603.458 48.6686 603.462C53.7865 603.467 58.9043 603.462 64.0221 603.471C64.4946 603.471 64.967 603.524 65.435 603.55C67.3028 603.524 69.1662 603.48 71.034 603.489C71.7601 603.493 71.9876 603.266 71.8214 602.526C71.3183 600.285 69.1662 598.723 66.8829 599.007C66.4017 599.068 65.9949 599.121 65.6012 598.675C63.3179 596.062 60.4921 595.344 57.1984 596.359C57.0496 596.399 56.8965 596.429 56.7391 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M81.2958 596.355C88.417 596.359 95.5339 596.364 102.655 596.381C103.39 596.381 103.499 596.075 103.355 595.436C102.918 593.488 101.22 592.14 99.1163 592.232C98.3552 592.267 97.8435 592.075 97.3054 591.514C94.554 588.652 90.1667 588.665 87.3847 591.528C86.8773 592.053 86.3961 592.35 85.6569 592.223C84.7821 592.075 83.9859 592.482 83.1548 592.661C82.1532 593.698 81.1121 594.718 81.2958 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M77.0879 579.317C74.7127 578.096 72.32 577.886 69.9317 579.264C68.0026 580.38 66.9091 582.079 66.5285 584.271C66.4061 584.985 66.6116 585.247 67.3465 585.238C70.1329 585.217 72.9193 585.23 75.7013 585.23C78.2952 585.23 80.8934 585.234 83.4873 585.225C84.5065 585.221 84.629 585.028 84.3753 584.022C84.161 583.168 83.6579 582.481 83.1724 581.777C82.2582 581.085 81.3002 580.398 80.1061 580.468C78.8857 580.542 77.884 580.249 77.0879 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="175" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="260" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="175" y="609" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7486"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7486"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7486"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7486"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7486"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7486"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7486"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7486"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7486"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7486"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7486"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7486"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7486">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7486">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 42)"
            />
          </clipPath>
          <clipPath id="clip2_76_7486">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 216)"
            />
          </clipPath>
          <clipPath id="clip3_76_7486">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 390)"
            />
          </clipPath>
          <clipPath id="clip4_76_7486">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(39 564)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerSquareSquareRight = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareRight">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7488)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7488)">
            <rect x="17" y="19" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7488)">
            <rect x="17" y="193" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter2_d_76_7488)">
            <rect x="17" y="367" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter3_d_76_7488)">
            <rect x="17" y="541" width="346" height="159" fill="white" />
          </g>
          <g clipPath="url(#clip1_76_7488)">
            <rect x="226" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 116.267C251.603 116.704 251.048 116.593 250.282 115.776C250.078 115.554 249.899 115.302 249.726 115.057C249.233 114.368 248.973 114.239 248.263 114.694C246.59 115.782 245.114 115.69 243.701 114.239C243.009 113.533 242.071 113.262 241.101 113.145C240.083 113.029 239.144 112.789 238.447 111.947C238.033 111.455 237.416 111.511 236.86 111.621C236.008 111.793 235.205 111.707 234.452 111.296C233.495 110.767 232.618 110.896 231.766 111.511C231.606 111.627 231.445 111.732 231.279 111.836C230.038 112.629 229.334 112.488 228.482 111.271C227.827 110.331 226.945 110.06 226 110.435V106.41C227.062 106.766 228.167 106.908 229.285 106.859C231.013 106.779 232.495 107.233 233.754 108.438C234.409 109.065 235.193 109.268 236.107 109.016C237.156 108.727 238.027 109.01 238.687 109.913C239.175 110.57 239.83 110.988 240.688 110.951C242.398 110.878 243.836 111.492 245.053 112.654C245.621 113.195 246.269 113.588 247.09 113.526C248.918 113.385 250.436 113.901 251.486 115.475C251.782 115.886 252.295 115.985 252.671 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 122.696L267.051 123.961C266.526 124.134 266.044 124.441 265.482 124.49C263.457 124.343 261.494 123.863 259.543 123.359C253.196 121.718 246.683 122.696 240.2 122.696C236.49 122.358 232.217 122.542 228.494 122.696C228.494 122.696 227.056 122.622 226.012 122.696V110.441C226.957 110.067 227.84 110.337 228.494 111.277C229.346 112.494 230.044 112.635 231.291 111.843C231.458 111.738 231.618 111.634 231.779 111.517C232.631 110.902 233.508 110.773 234.465 111.302C235.218 111.714 236.02 111.793 236.872 111.628C237.428 111.517 238.045 111.462 238.459 111.953C239.157 112.795 240.095 113.035 241.114 113.152C242.083 113.262 243.022 113.533 243.713 114.245C245.127 115.696 246.603 115.788 248.276 114.7C248.979 114.239 249.245 114.375 249.739 115.063C249.918 115.309 250.091 115.561 250.295 115.782C251.06 116.605 251.616 116.71 252.684 116.273C253.091 116.31 253.493 116.353 253.912 116.335C254.789 116.31 255.314 116.876 255.61 117.613C255.882 118.302 256.314 118.64 257.074 118.646C257.821 118.652 258.45 119.027 258.994 119.506C259.901 120.299 260.963 120.668 262.155 120.686C263.105 120.698 263.933 121.012 264.674 121.595C265.371 122.149 266.131 122.609 267.057 122.696Z"
              fill="#66646F"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V116.205C238.246 117.968 260.187 120.991 283.359 114.902C305.48 109.084 324.779 105.413 339 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 106.65V156.267C339 157.647 337.865 158.767 336.469 158.767H228.531C227.131 158.767 226 157.647 226 156.267V120.817C238.246 122.58 262.094 134.139 283.359 123.208C305.913 111.606 324.779 105.413 339 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 117.312C321.64 116.619 321.644 115.93 321.644 115.237C321.659 114.728 321.674 114.216 321.689 113.708L321.659 113.678C322.495 113.915 323.339 113.862 324.182 113.723C324.197 114.559 324.213 115.391 324.228 116.228C324.216 117.467 324.209 118.702 324.186 119.942C324.171 120.781 323.987 121.565 322.947 121.576C321.907 121.588 321.704 120.812 321.681 119.968C321.659 119.079 321.655 118.198 321.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 113.708C317.79 112.849 315.105 109.93 315.278 106.174C315.538 100.63 317.067 95.4017 320.077 90.6707C321.975 91.5333 323.018 93.168 323.881 94.9459C325.192 97.6504 326.02 100.513 326.559 103.462C326.819 104.89 327.166 106.321 326.898 107.794C326.348 110.819 324.499 112.691 321.655 113.678L321.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 113.674C324.502 112.687 326.352 110.815 326.905 107.79C327.173 106.321 326.826 104.886 326.566 103.459C326.028 100.513 325.199 97.6466 323.888 94.9421C323.026 93.1643 321.986 91.5295 320.084 90.667C321.884 88.1923 323.636 88.0567 325.523 90.2828C325.979 90.8214 326.397 91.4128 326.728 92.038C328.423 95.2322 329.493 98.6485 330.194 102.182C330.375 103.086 330.506 104.001 330.661 104.912C330.774 107.327 330.326 109.553 328.589 111.384C327.873 112.141 327 112.664 326.145 113.222C325.493 113.387 324.841 113.553 324.186 113.719C323.342 113.858 322.498 113.911 321.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 127.595C296.439 126.325 296.446 125.061 296.446 123.79C296.474 122.858 296.501 121.918 296.529 120.986L296.474 120.931C298.007 121.366 299.554 121.269 301.101 121.014C301.129 122.547 301.156 124.073 301.184 125.606C301.163 127.878 301.149 130.144 301.108 132.416C301.08 133.956 300.742 135.392 298.836 135.413C296.93 135.434 296.557 134.011 296.515 132.464C296.474 130.834 296.467 129.218 296.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 120.986C289.381 119.411 284.457 114.059 284.775 107.174C285.251 97.0078 288.055 87.422 293.573 78.7478C297.054 80.3293 298.967 83.3266 300.549 86.5863C302.952 91.545 304.471 96.7937 305.459 102.201C305.936 104.819 306.571 107.443 306.081 110.143C305.072 115.689 301.681 119.121 296.467 120.931L296.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 120.924C301.688 119.114 305.079 115.682 306.094 110.136C306.585 107.443 305.949 104.812 305.473 102.194C304.485 96.7936 302.966 91.538 300.562 86.5794C298.981 83.3197 297.075 80.3224 293.587 78.7409C296.888 74.2035 300.1 73.9549 303.56 78.0364C304.395 79.024 305.162 80.1083 305.77 81.2547C308.878 87.1112 310.839 93.3751 312.124 99.8531C312.455 101.511 312.697 103.189 312.98 104.86C313.187 109.287 312.365 113.368 309.181 116.725C307.869 118.113 306.267 119.073 304.699 120.095C303.504 120.399 302.31 120.703 301.108 121.007C299.561 121.262 298.014 121.359 296.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 74.4687C242.685 75.1077 241.622 75.7292 240.87 76.7533C240.577 77.1516 240.152 77.0597 239.737 77.0115C237.497 76.7358 235.459 78.0838 234.803 80.2677C234.505 81.2612 234.636 81.4581 235.669 81.4625C240.786 81.4669 245.904 81.4625 251.022 81.4712C251.495 81.4712 251.967 81.5238 252.435 81.55C254.303 81.5238 256.166 81.48 258.034 81.4888C258.76 81.4931 258.988 81.2656 258.821 80.5259C258.318 78.2851 256.166 76.7227 253.883 77.0072C253.402 77.0684 252.995 77.1209 252.601 76.6745C250.318 74.0617 247.492 73.344 244.198 74.3593C244.05 74.3987 243.897 74.4294 243.739 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 74.355C275.417 74.3593 282.534 74.3637 289.655 74.3812C290.39 74.3812 290.499 74.0749 290.355 73.4359C289.918 71.4883 288.22 70.1403 286.116 70.2322C285.355 70.2673 284.843 70.0747 284.305 69.5145C281.554 66.6522 277.167 66.6653 274.385 69.5276C273.877 70.0528 273.396 70.3504 272.657 70.2235C271.782 70.0747 270.986 70.4817 270.155 70.6611C269.153 71.6984 268.112 72.7181 268.296 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 57.317C261.713 56.0959 259.32 55.8858 256.932 57.2645C255.003 58.3805 253.909 60.0786 253.529 62.2712C253.406 62.9846 253.612 63.2472 254.347 63.2385C257.133 63.2166 259.919 63.2297 262.701 63.2297C265.295 63.2297 267.893 63.2341 270.487 63.2253C271.507 63.221 271.629 63.0284 271.375 62.0218C271.161 61.1683 270.658 60.4812 270.172 59.7766C269.258 59.0851 268.3 58.398 267.106 58.468C265.886 58.5424 264.884 58.2492 264.088 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip2_76_7488)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.603 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.074 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.213 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.987 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.905 281.79C327.173 280.321 326.826 278.886 326.566 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.884 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.728 266.038C328.423 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.873 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.498 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.094 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.562 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.395 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.181 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.504 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.786 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.843 244.075 284.305 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip3_76_7488)">
            <rect x="226" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 464.267C251.603 464.704 251.048 464.593 250.282 463.776C250.078 463.554 249.899 463.302 249.726 463.057C249.233 462.368 248.973 462.239 248.263 462.694C246.59 463.782 245.114 463.69 243.701 462.239C243.009 461.533 242.071 461.262 241.101 461.145C240.083 461.029 239.144 460.789 238.447 459.947C238.033 459.455 237.416 459.511 236.86 459.621C236.008 459.793 235.205 459.707 234.452 459.296C233.495 458.767 232.618 458.896 231.766 459.511C231.606 459.627 231.445 459.732 231.279 459.836C230.038 460.629 229.334 460.488 228.482 459.271C227.827 458.331 226.945 458.06 226 458.435V454.41C227.062 454.766 228.167 454.908 229.285 454.859C231.013 454.779 232.495 455.233 233.754 456.438C234.409 457.065 235.193 457.268 236.107 457.016C237.156 456.727 238.027 457.01 238.687 457.913C239.175 458.57 239.83 458.988 240.688 458.951C242.398 458.878 243.836 459.492 245.053 460.654C245.621 461.195 246.269 461.588 247.09 461.526C248.918 461.385 250.436 461.901 251.486 463.475C251.782 463.886 252.295 463.985 252.671 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 470.696L267.051 471.961C266.526 472.134 266.044 472.441 265.482 472.49C263.457 472.343 261.494 471.863 259.543 471.359C253.196 469.718 246.683 470.696 240.2 470.696C236.49 470.358 232.217 470.542 228.494 470.696C228.494 470.696 227.056 470.622 226.012 470.696V458.441C226.957 458.067 227.84 458.337 228.494 459.277C229.346 460.494 230.044 460.635 231.291 459.843C231.458 459.738 231.618 459.634 231.779 459.517C232.631 458.902 233.508 458.773 234.465 459.302C235.218 459.714 236.02 459.793 236.872 459.628C237.428 459.517 238.045 459.462 238.459 459.953C239.157 460.795 240.095 461.035 241.114 461.152C242.083 461.262 243.022 461.533 243.713 462.245C245.127 463.696 246.603 463.788 248.276 462.7C248.979 462.239 249.245 462.375 249.739 463.063C249.918 463.309 250.091 463.561 250.295 463.782C251.06 464.605 251.616 464.71 252.684 464.273C253.091 464.31 253.493 464.353 253.912 464.335C254.789 464.31 255.314 464.876 255.61 465.613C255.882 466.302 256.314 466.64 257.074 466.646C257.821 466.652 258.45 467.027 258.994 467.506C259.901 468.299 260.963 468.668 262.155 468.686C263.105 468.698 263.933 469.012 264.674 469.595C265.371 470.149 266.131 470.609 267.057 470.696Z"
              fill="#66646F"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V464.205C238.246 465.968 260.187 468.991 283.359 462.902C305.48 457.084 324.779 453.413 339 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 454.65V504.267C339 505.647 337.865 506.767 336.469 506.767H228.531C227.131 506.767 226 505.647 226 504.267V468.817C238.246 470.58 262.094 482.139 283.359 471.208C305.913 459.606 324.779 453.413 339 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 465.312C321.64 464.619 321.644 463.93 321.644 463.237C321.659 462.728 321.674 462.216 321.689 461.708L321.659 461.678C322.495 461.915 323.339 461.862 324.182 461.723C324.197 462.559 324.213 463.391 324.228 464.228C324.216 465.467 324.209 466.702 324.186 467.942C324.171 468.781 323.987 469.565 322.947 469.576C321.907 469.588 321.704 468.812 321.681 467.968C321.659 467.079 321.655 466.198 321.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 461.708C317.79 460.849 315.105 457.93 315.278 454.174C315.538 448.63 317.067 443.402 320.077 438.671C321.975 439.533 323.018 441.168 323.881 442.946C325.192 445.65 326.02 448.513 326.559 451.462C326.819 452.89 327.166 454.321 326.898 455.794C326.348 458.819 324.499 460.691 321.655 461.678L321.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 461.674C324.502 460.687 326.352 458.815 326.905 455.79C327.173 454.321 326.826 452.886 326.566 451.459C326.028 448.513 325.199 445.647 323.888 442.942C323.026 441.164 321.986 439.53 320.084 438.667C321.884 436.192 323.636 436.057 325.523 438.283C325.979 438.821 326.397 439.413 326.728 440.038C328.423 443.232 329.493 446.649 330.194 450.182C330.375 451.086 330.506 452.001 330.661 452.912C330.774 455.327 330.326 457.553 328.589 459.384C327.873 460.141 327 460.664 326.145 461.222C325.493 461.387 324.841 461.553 324.186 461.719C323.342 461.858 322.498 461.911 321.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 475.595C296.439 474.325 296.446 473.061 296.446 471.79C296.474 470.858 296.501 469.918 296.529 468.986L296.474 468.931C298.007 469.366 299.554 469.269 301.101 469.014C301.129 470.547 301.156 472.073 301.184 473.606C301.163 475.878 301.149 478.144 301.108 480.416C301.08 481.956 300.742 483.392 298.836 483.413C296.93 483.434 296.557 482.011 296.515 480.464C296.474 478.834 296.467 477.218 296.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 468.986C289.381 467.411 284.457 462.059 284.775 455.174C285.251 445.008 288.055 435.422 293.573 426.748C297.054 428.329 298.967 431.327 300.549 434.586C302.952 439.545 304.471 444.794 305.459 450.201C305.936 452.819 306.571 455.443 306.081 458.143C305.072 463.689 301.681 467.121 296.467 468.931L296.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 468.924C301.688 467.114 305.079 463.682 306.094 458.136C306.585 455.443 305.949 452.812 305.473 450.194C304.485 444.794 302.966 439.538 300.562 434.579C298.981 431.32 297.075 428.322 293.587 426.741C296.888 422.203 300.1 421.955 303.56 426.036C304.395 427.024 305.162 428.108 305.77 429.255C308.878 435.111 310.839 441.375 312.124 447.853C312.455 449.511 312.697 451.189 312.98 452.86C313.187 457.287 312.365 461.368 309.181 464.725C307.869 466.113 306.267 467.073 304.699 468.095C303.504 468.399 302.31 468.703 301.108 469.007C299.561 469.262 298.014 469.359 296.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 422.469C242.685 423.108 241.622 423.729 240.87 424.753C240.577 425.152 240.152 425.06 239.737 425.012C237.497 424.736 235.459 426.084 234.803 428.268C234.505 429.261 234.636 429.458 235.669 429.462C240.786 429.467 245.904 429.462 251.022 429.471C251.495 429.471 251.967 429.524 252.435 429.55C254.303 429.524 256.166 429.48 258.034 429.489C258.76 429.493 258.988 429.266 258.821 428.526C258.318 426.285 256.166 424.723 253.883 425.007C253.402 425.068 252.995 425.121 252.601 424.675C250.318 422.062 247.492 421.344 244.198 422.359C244.05 422.399 243.897 422.429 243.739 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 422.355C275.417 422.359 282.534 422.364 289.655 422.381C290.39 422.381 290.499 422.075 290.355 421.436C289.918 419.488 288.22 418.14 286.116 418.232C285.355 418.267 284.843 418.075 284.305 417.514C281.554 414.652 277.167 414.665 274.385 417.528C273.877 418.053 273.396 418.35 272.657 418.223C271.782 418.075 270.986 418.482 270.155 418.661C269.153 419.698 268.112 420.718 268.296 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 405.317C261.713 404.096 259.32 403.886 256.932 405.264C255.003 406.38 253.909 408.079 253.529 410.271C253.406 410.985 253.612 411.247 254.347 411.238C257.133 411.217 259.919 411.23 262.701 411.23C265.295 411.23 267.893 411.234 270.487 411.225C271.507 411.221 271.629 411.028 271.375 410.022C271.161 409.168 270.658 408.481 270.172 407.777C269.258 407.085 268.3 406.398 267.106 406.468C265.886 406.542 264.884 406.249 264.088 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip4_76_7488)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.962C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.246C245.127 637.696 246.603 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.274C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.074 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.596C265.371 644.149 266.131 644.61 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.213 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.987 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.905 629.79C327.173 628.321 326.826 626.886 326.566 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.884 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.728 614.038C328.423 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.873 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.498 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.094 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.562 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.395 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.181 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.504 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.786 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.843 592.075 284.305 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="41" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7488"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7488"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7488"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7488"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7488"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7488"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7488"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7488"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7488"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7488"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7488"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7488"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7488">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7488">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 42)"
            />
          </clipPath>
          <clipPath id="clip2_76_7488">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 216)"
            />
          </clipPath>
          <clipPath id="clip3_76_7488">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 390)"
            />
          </clipPath>
          <clipPath id="clip4_76_7488">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 564)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionContainerSquareSquareAlt = (props) => {
  return (
    <div className="CollectionContainerRoundedSquareAlt">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_76_7489)">
          <rect width="379" height="664" fill="white" />
          <g filter="url(#filter0_d_76_7489)">
            <rect x="17" y="19" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter1_d_76_7489)">
            <rect x="17" y="193" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter2_d_76_7489)">
            <rect x="17" y="367" width="346" height="159" fill="white" />
          </g>
          <g filter="url(#filter3_d_76_7489)">
            <rect x="17" y="541" width="346" height="159" fill="white" />
          </g>
          <g clipPath="url(#clip1_76_7489)">
            <rect x="41" y="42" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 116.267C66.6033 116.704 66.0477 116.593 65.2821 115.776C65.0783 115.554 64.8993 115.302 64.7264 115.057C64.2325 114.368 63.9732 114.239 63.2632 114.694C61.5901 115.782 60.1145 115.69 58.7007 114.239C58.0092 113.533 57.0707 113.262 56.1014 113.145C55.0827 113.029 54.1443 112.789 53.4467 111.947C53.033 111.455 52.4156 111.511 51.8599 111.621C51.0079 111.793 50.2053 111.707 49.4521 111.296C48.4952 110.767 47.6185 110.896 46.7665 111.511C46.6059 111.627 46.4454 111.732 46.2787 111.836C45.0378 112.629 44.3339 112.488 43.4819 111.271C42.8275 110.331 41.9446 110.06 41 110.435V106.41C42.0619 106.766 43.1671 106.908 44.2845 106.859C46.0132 106.779 47.495 107.233 48.7545 108.438C49.4089 109.065 50.193 109.268 51.1067 109.016C52.1563 108.727 53.0268 109.01 53.6874 109.913C54.1752 110.57 54.8296 110.988 55.6878 110.951C57.398 110.878 58.8365 111.492 60.0528 112.654C60.6208 113.195 61.269 113.588 62.0902 113.526C63.9176 113.385 65.4364 113.901 66.486 115.475C66.7823 115.886 67.2948 115.985 67.6714 116.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0568 122.696L82.0506 123.961C81.5258 124.134 81.0442 124.441 80.4824 124.49C78.4574 124.343 76.494 123.863 74.5431 123.359C68.1963 121.718 61.6828 122.696 55.2001 122.696C51.4896 122.358 47.2173 122.542 43.4944 122.696C43.4944 122.696 42.0558 122.622 41.0125 122.696V110.441C41.9571 110.067 42.8399 110.337 43.4944 111.277C44.3464 112.494 45.044 112.635 46.2912 111.843C46.4579 111.738 46.6184 111.634 46.7789 111.517C47.6309 110.902 48.5076 110.773 49.4646 111.302C50.2178 111.714 51.0204 111.793 51.8724 111.628C52.4281 111.517 53.0454 111.462 53.4591 111.953C54.1568 112.795 55.0952 113.035 56.1139 113.152C57.0832 113.262 58.0216 113.533 58.7131 114.245C60.1269 115.696 61.6025 115.788 63.2757 114.7C63.9795 114.239 64.245 114.375 64.7389 115.063C64.9179 115.309 65.0908 115.561 65.2945 115.782C66.0601 116.605 66.6158 116.71 67.6838 116.273C68.0913 116.31 68.4926 116.353 68.9125 116.335C69.7892 116.31 70.3139 116.876 70.6103 117.613C70.8819 118.302 71.3141 118.64 72.0735 118.646C72.8206 118.652 73.4503 119.027 73.9936 119.506C74.9012 120.299 75.9631 120.668 77.1547 120.686C78.1054 120.698 78.9327 121.012 79.6736 121.595C80.3713 122.149 81.1307 122.609 82.0568 122.696Z"
              fill="#66646F"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V116.205C53.2464 117.968 75.1866 120.991 98.359 114.902C120.48 109.084 139.779 105.413 154 106.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 106.65V156.267C154 157.647 152.865 158.767 151.469 158.767H43.5305C42.1306 158.767 41 157.647 41 156.267V120.817C53.2464 122.58 77.0945 134.139 98.359 123.208C120.913 111.606 139.779 105.413 154 106.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 117.312C136.64 116.619 136.644 115.93 136.644 115.237C136.659 114.728 136.674 114.216 136.689 113.708L136.659 113.678C137.495 113.915 138.339 113.862 139.182 113.723C139.197 114.559 139.213 115.391 139.228 116.228C139.216 117.467 139.209 118.702 139.186 119.942C139.171 120.781 138.987 121.565 137.947 121.576C136.907 121.588 136.704 120.812 136.681 119.968C136.659 119.079 136.655 118.198 136.64 117.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 113.708C132.79 112.849 130.105 109.93 130.278 106.174C130.538 100.63 132.067 95.4017 135.077 90.6707C136.975 91.5333 138.018 93.168 138.881 94.9459C140.192 97.6504 141.02 100.513 141.559 103.462C141.819 104.89 142.166 106.321 141.898 107.794C141.348 110.819 139.499 112.691 136.655 113.678L136.689 113.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 113.674C139.502 112.687 141.352 110.815 141.905 107.79C142.173 106.321 141.826 104.886 141.566 103.459C141.028 100.513 140.199 97.6466 138.888 94.9421C138.026 93.1643 136.986 91.5295 135.084 90.667C136.884 88.1923 138.636 88.0567 140.523 90.2828C140.979 90.8214 141.397 91.4128 141.728 92.038C143.423 95.2322 144.493 98.6485 145.194 102.182C145.375 103.086 145.506 104.001 145.661 104.912C145.774 107.327 145.326 109.553 143.589 111.384C142.873 112.141 142 112.664 141.145 113.222C140.493 113.387 139.841 113.553 139.186 113.719C138.342 113.858 137.498 113.911 136.662 113.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 127.595C111.439 126.325 111.446 125.061 111.446 123.79C111.474 122.858 111.501 121.918 111.529 120.986L111.474 120.931C113.007 121.366 114.554 121.269 116.101 121.014C116.129 122.547 116.156 124.073 116.184 125.606C116.163 127.878 116.149 130.144 116.108 132.416C116.08 133.956 115.742 135.392 113.836 135.413C111.93 135.434 111.557 134.011 111.515 132.464C111.474 130.834 111.467 129.218 111.439 127.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 120.986C104.381 119.411 99.4568 114.059 99.7745 107.174C100.251 97.0078 103.055 87.422 108.573 78.7478C112.054 80.3293 113.967 83.3266 115.549 86.5863C117.952 91.545 119.471 96.7937 120.459 102.201C120.936 104.819 121.571 107.443 121.081 110.143C120.072 115.689 116.681 119.121 111.467 120.931L111.529 120.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 120.924C116.688 119.114 120.079 115.682 121.094 110.136C121.585 107.443 120.949 104.812 120.473 102.194C119.485 96.7936 117.966 91.538 115.562 86.5794C113.981 83.3197 112.075 80.3224 108.587 78.7409C111.888 74.2035 115.1 73.9549 118.56 78.0364C119.395 79.024 120.162 80.1083 120.77 81.2547C123.878 87.1112 125.839 93.3751 127.124 99.8531C127.455 101.511 127.697 103.189 127.98 104.86C128.187 109.287 127.365 113.368 124.181 116.725C122.869 118.113 121.267 119.073 119.699 120.095C118.504 120.399 117.31 120.703 116.108 121.007C114.561 121.262 113.014 121.359 111.481 120.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7391 74.4687C57.6849 75.1077 56.622 75.7292 55.8696 76.7533C55.5765 77.1516 55.1522 77.0597 54.7367 77.0115C52.4971 76.7358 50.4587 78.0838 49.8026 80.2677C49.5051 81.2612 49.6363 81.4581 50.6686 81.4625C55.7865 81.4669 60.9043 81.4625 66.0221 81.4712C66.4946 81.4712 66.967 81.5238 67.435 81.55C69.3028 81.5238 71.1662 81.48 73.034 81.4888C73.7601 81.4931 73.9876 81.2656 73.8214 80.5259C73.3183 78.2851 71.1662 76.7227 68.8829 77.0072C68.4017 77.0684 67.9949 77.1209 67.6012 76.6745C65.3179 74.0617 62.4921 73.344 59.1984 74.3593C59.0496 74.3987 58.8965 74.4294 58.7391 74.4687Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2958 74.355C90.417 74.3593 97.5339 74.3637 104.655 74.3812C105.39 74.3812 105.499 74.0749 105.355 73.4359C104.918 71.4883 103.22 70.1403 101.116 70.2322C100.355 70.2673 99.8435 70.0747 99.3054 69.5145C96.554 66.6522 92.1667 66.6653 89.3847 69.5276C88.8773 70.0528 88.3961 70.3504 87.6569 70.2235C86.7821 70.0747 85.9859 70.4817 85.1548 70.6611C84.1532 71.6984 83.1121 72.7181 83.2958 74.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 57.317C76.7127 56.0959 74.32 55.8858 71.9317 57.2645C70.0026 58.3805 68.9091 60.0786 68.5285 62.2712C68.4061 62.9846 68.6116 63.2472 69.3465 63.2385C72.1329 63.2166 74.9193 63.2297 77.7013 63.2297C80.2952 63.2297 82.8934 63.2341 85.4873 63.2253C86.5065 63.221 86.629 63.0284 86.3753 62.0218C86.161 61.1683 85.6579 60.4812 85.1724 59.7766C84.2582 59.0851 83.3002 58.398 82.1061 58.468C80.8857 58.5424 79.884 58.2492 79.0879 57.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip2_76_7489)">
            <rect x="226" y="216" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 290.267C251.603 290.704 251.048 290.593 250.282 289.776C250.078 289.554 249.899 289.302 249.726 289.057C249.233 288.368 248.973 288.239 248.263 288.694C246.59 289.782 245.114 289.69 243.701 288.239C243.009 287.533 242.071 287.262 241.101 287.145C240.083 287.029 239.144 286.789 238.447 285.947C238.033 285.455 237.416 285.511 236.86 285.621C236.008 285.793 235.205 285.707 234.452 285.296C233.495 284.767 232.618 284.896 231.766 285.511C231.606 285.627 231.445 285.732 231.279 285.836C230.038 286.629 229.334 286.488 228.482 285.271C227.827 284.331 226.945 284.06 226 284.435V280.41C227.062 280.766 228.167 280.908 229.285 280.859C231.013 280.779 232.495 281.233 233.754 282.438C234.409 283.065 235.193 283.268 236.107 283.016C237.156 282.727 238.027 283.01 238.687 283.913C239.175 284.57 239.83 284.988 240.688 284.951C242.398 284.878 243.836 285.492 245.053 286.654C245.621 287.195 246.269 287.588 247.09 287.526C248.918 287.385 250.436 287.901 251.486 289.475C251.782 289.886 252.295 289.985 252.671 290.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 296.696L267.051 297.961C266.526 298.134 266.044 298.441 265.482 298.49C263.457 298.343 261.494 297.863 259.543 297.359C253.196 295.718 246.683 296.696 240.2 296.696C236.49 296.358 232.217 296.542 228.494 296.696C228.494 296.696 227.056 296.622 226.012 296.696V284.441C226.957 284.067 227.84 284.337 228.494 285.277C229.346 286.494 230.044 286.635 231.291 285.843C231.458 285.738 231.618 285.634 231.779 285.517C232.631 284.902 233.508 284.773 234.465 285.302C235.218 285.714 236.02 285.793 236.872 285.628C237.428 285.517 238.045 285.462 238.459 285.953C239.157 286.795 240.095 287.035 241.114 287.152C242.083 287.262 243.022 287.533 243.713 288.245C245.127 289.696 246.603 289.788 248.276 288.7C248.979 288.239 249.245 288.375 249.739 289.063C249.918 289.309 250.091 289.561 250.295 289.782C251.06 290.605 251.616 290.71 252.684 290.273C253.091 290.31 253.493 290.353 253.912 290.335C254.789 290.31 255.314 290.876 255.61 291.613C255.882 292.302 256.314 292.64 257.074 292.646C257.821 292.652 258.45 293.027 258.994 293.506C259.901 294.299 260.963 294.668 262.155 294.686C263.105 294.698 263.933 295.012 264.674 295.595C265.371 296.149 266.131 296.609 267.057 296.696Z"
              fill="#66646F"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V290.205C238.246 291.968 260.187 294.991 283.359 288.902C305.48 283.084 324.779 279.413 339 280.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 280.65V330.267C339 331.647 337.865 332.767 336.469 332.767H228.531C227.131 332.767 226 331.647 226 330.267V294.817C238.246 296.58 262.094 308.139 283.359 297.208C305.913 285.606 324.779 279.413 339 280.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 291.312C321.64 290.619 321.644 289.93 321.644 289.237C321.659 288.728 321.674 288.216 321.689 287.708L321.659 287.678C322.495 287.915 323.339 287.862 324.182 287.723C324.197 288.559 324.213 289.391 324.228 290.228C324.216 291.467 324.209 292.702 324.186 293.942C324.171 294.781 323.987 295.565 322.947 295.576C321.907 295.588 321.704 294.812 321.681 293.968C321.659 293.079 321.655 292.198 321.64 291.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 287.708C317.79 286.849 315.105 283.93 315.278 280.174C315.538 274.63 317.067 269.402 320.077 264.671C321.975 265.533 323.018 267.168 323.881 268.946C325.192 271.65 326.02 274.513 326.559 277.462C326.819 278.89 327.166 280.321 326.898 281.794C326.348 284.819 324.499 286.691 321.655 287.678L321.689 287.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 287.674C324.502 286.687 326.352 284.815 326.905 281.79C327.173 280.321 326.826 278.886 326.566 277.459C326.028 274.513 325.199 271.647 323.888 268.942C323.026 267.164 321.986 265.53 320.084 264.667C321.884 262.192 323.636 262.057 325.523 264.283C325.979 264.821 326.397 265.413 326.728 266.038C328.423 269.232 329.493 272.649 330.194 276.182C330.375 277.086 330.506 278.001 330.661 278.912C330.774 281.327 330.326 283.553 328.589 285.384C327.873 286.141 327 286.664 326.145 287.222C325.493 287.387 324.841 287.553 324.186 287.719C323.342 287.858 322.498 287.911 321.662 287.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 301.595C296.439 300.325 296.446 299.061 296.446 297.79C296.474 296.858 296.501 295.918 296.529 294.986L296.474 294.931C298.007 295.366 299.554 295.269 301.101 295.014C301.129 296.547 301.156 298.073 301.184 299.606C301.163 301.878 301.149 304.144 301.108 306.416C301.08 307.956 300.742 309.392 298.836 309.413C296.93 309.434 296.557 308.011 296.515 306.464C296.474 304.834 296.467 303.218 296.439 301.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 294.986C289.381 293.411 284.457 288.059 284.775 281.174C285.251 271.008 288.055 261.422 293.573 252.748C297.054 254.329 298.967 257.327 300.549 260.586C302.952 265.545 304.471 270.794 305.459 276.201C305.936 278.819 306.571 281.443 306.081 284.143C305.072 289.689 301.681 293.121 296.467 294.931L296.529 294.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 294.924C301.688 293.114 305.079 289.682 306.094 284.136C306.585 281.443 305.949 278.812 305.473 276.194C304.485 270.794 302.966 265.538 300.562 260.579C298.981 257.32 297.075 254.322 293.587 252.741C296.888 248.203 300.1 247.955 303.56 252.036C304.395 253.024 305.162 254.108 305.77 255.255C308.878 261.111 310.839 267.375 312.124 273.853C312.455 275.511 312.697 277.189 312.98 278.86C313.187 283.287 312.365 287.368 309.181 290.725C307.869 292.113 306.267 293.073 304.699 294.095C303.504 294.399 302.31 294.703 301.108 295.007C299.561 295.262 298.014 295.359 296.481 294.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 248.469C242.685 249.108 241.622 249.729 240.87 250.753C240.577 251.152 240.152 251.06 239.737 251.012C237.497 250.736 235.459 252.084 234.803 254.268C234.505 255.261 234.636 255.458 235.669 255.462C240.786 255.467 245.904 255.462 251.022 255.471C251.495 255.471 251.967 255.524 252.435 255.55C254.303 255.524 256.166 255.48 258.034 255.489C258.76 255.493 258.988 255.266 258.821 254.526C258.318 252.285 256.166 250.723 253.883 251.007C253.402 251.068 252.995 251.121 252.601 250.675C250.318 248.062 247.492 247.344 244.198 248.359C244.05 248.399 243.897 248.429 243.739 248.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 248.355C275.417 248.359 282.534 248.364 289.655 248.381C290.39 248.381 290.499 248.075 290.355 247.436C289.918 245.488 288.22 244.14 286.116 244.232C285.355 244.267 284.843 244.075 284.305 243.514C281.554 240.652 277.167 240.665 274.385 243.528C273.877 244.053 273.396 244.35 272.657 244.223C271.782 244.075 270.986 244.482 270.155 244.661C269.153 245.698 268.112 246.718 268.296 248.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 231.317C261.713 230.096 259.32 229.886 256.932 231.264C255.003 232.38 253.909 234.079 253.529 236.271C253.406 236.985 253.612 237.247 254.347 237.238C257.133 237.217 259.919 237.23 262.701 237.23C265.295 237.23 267.893 237.234 270.487 237.225C271.507 237.221 271.629 237.028 271.375 236.022C271.161 235.168 270.658 234.481 270.172 233.777C269.258 233.085 268.3 232.398 267.106 232.468C265.886 232.542 264.884 232.249 264.088 231.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip3_76_7489)">
            <rect x="41" y="390" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M67.6714 464.267C66.6033 464.704 66.0477 464.593 65.2821 463.776C65.0783 463.554 64.8993 463.302 64.7264 463.057C64.2325 462.368 63.9732 462.239 63.2632 462.694C61.5901 463.782 60.1145 463.69 58.7007 462.239C58.0092 461.533 57.0707 461.262 56.1014 461.145C55.0827 461.029 54.1443 460.789 53.4467 459.947C53.033 459.455 52.4156 459.511 51.8599 459.621C51.0079 459.793 50.2053 459.707 49.4521 459.296C48.4952 458.767 47.6185 458.896 46.7665 459.511C46.6059 459.627 46.4454 459.732 46.2787 459.836C45.0378 460.629 44.3339 460.488 43.4819 459.271C42.8275 458.331 41.9446 458.06 41 458.435V454.41C42.0619 454.766 43.1671 454.908 44.2845 454.859C46.0132 454.779 47.495 455.233 48.7545 456.438C49.4089 457.065 50.193 457.268 51.1067 457.016C52.1563 456.727 53.0268 457.01 53.6874 457.913C54.1752 458.57 54.8296 458.988 55.6878 458.951C57.398 458.878 58.8365 459.492 60.0528 460.654C60.6208 461.195 61.269 461.588 62.0902 461.526C63.9176 461.385 65.4364 461.901 66.486 463.475C66.7823 463.886 67.2948 463.985 67.6714 464.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M82.0568 470.696L82.0506 471.961C81.5258 472.134 81.0442 472.441 80.4824 472.49C78.4574 472.343 76.494 471.863 74.5431 471.359C68.1963 469.718 61.6828 470.696 55.2001 470.696C51.4896 470.358 47.2173 470.542 43.4944 470.696C43.4944 470.696 42.0558 470.622 41.0125 470.696V458.441C41.9571 458.067 42.8399 458.337 43.4944 459.277C44.3464 460.494 45.044 460.635 46.2912 459.843C46.4579 459.738 46.6184 459.634 46.7789 459.517C47.6309 458.902 48.5076 458.773 49.4646 459.302C50.2178 459.714 51.0204 459.793 51.8724 459.628C52.4281 459.517 53.0454 459.462 53.4591 459.953C54.1568 460.795 55.0952 461.035 56.1139 461.152C57.0832 461.262 58.0216 461.533 58.7131 462.245C60.1269 463.696 61.6025 463.788 63.2757 462.7C63.9795 462.239 64.245 462.375 64.7389 463.063C64.9179 463.309 65.0908 463.561 65.2945 463.782C66.0601 464.605 66.6158 464.71 67.6838 464.273C68.0913 464.31 68.4926 464.353 68.9125 464.335C69.7892 464.31 70.3139 464.876 70.6103 465.613C70.8819 466.302 71.3141 466.64 72.0735 466.646C72.8206 466.652 73.4503 467.027 73.9936 467.506C74.9012 468.299 75.9631 468.668 77.1547 468.686C78.1054 468.698 78.9327 469.012 79.6736 469.595C80.3713 470.149 81.1307 470.609 82.0568 470.696Z"
              fill="#66646F"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V464.205C53.2464 465.968 75.1866 468.991 98.359 462.902C120.48 457.084 139.779 453.413 154 454.65Z"
              fill="#9694A0"
            />
            <path
              d="M154 454.65V504.267C154 505.647 152.865 506.767 151.469 506.767H43.5305C42.1306 506.767 41 505.647 41 504.267V468.817C53.2464 470.58 77.0945 482.139 98.359 471.208C120.913 459.606 139.779 453.413 154 454.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M136.64 465.312C136.64 464.619 136.644 463.93 136.644 463.237C136.659 462.728 136.674 462.216 136.689 461.708L136.659 461.678C137.495 461.915 138.339 461.862 139.182 461.723C139.197 462.559 139.213 463.391 139.228 464.228C139.216 465.467 139.209 466.702 139.186 467.942C139.171 468.781 138.987 469.565 137.947 469.576C136.907 469.588 136.704 468.812 136.681 467.968C136.659 467.079 136.655 466.198 136.64 465.312Z"
              fill="#66646F"
            />
            <path
              d="M136.689 461.708C132.79 460.849 130.105 457.93 130.278 454.174C130.538 448.63 132.067 443.402 135.077 438.671C136.975 439.533 138.018 441.168 138.881 442.946C140.192 445.65 141.02 448.513 141.559 451.462C141.819 452.89 142.166 454.321 141.898 455.794C141.348 458.819 139.499 460.691 136.655 461.678L136.689 461.708Z"
              fill="#66646F"
            />
            <path
              d="M136.662 461.674C139.502 460.687 141.352 458.815 141.905 455.79C142.173 454.321 141.826 452.886 141.566 451.459C141.028 448.513 140.199 445.647 138.888 442.942C138.026 441.164 136.986 439.53 135.084 438.667C136.884 436.192 138.636 436.057 140.523 438.283C140.979 438.821 141.397 439.413 141.728 440.038C143.423 443.232 144.493 446.649 145.194 450.182C145.375 451.086 145.506 452.001 145.661 452.912C145.774 455.327 145.326 457.553 143.589 459.384C142.873 460.141 142 460.664 141.145 461.222C140.493 461.387 139.841 461.553 139.186 461.719C138.342 461.858 137.498 461.911 136.662 461.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M111.439 475.595C111.439 474.325 111.446 473.061 111.446 471.79C111.474 470.858 111.501 469.918 111.529 468.986L111.474 468.931C113.007 469.366 114.554 469.269 116.101 469.014C116.129 470.547 116.156 472.073 116.184 473.606C116.163 475.878 116.149 478.144 116.108 480.416C116.08 481.956 115.742 483.392 113.836 483.413C111.93 483.434 111.557 482.011 111.515 480.464C111.474 478.834 111.467 477.218 111.439 475.595Z"
              fill="#66646F"
            />
            <path
              d="M111.529 468.986C104.381 467.411 99.4568 462.059 99.7745 455.174C100.251 445.008 103.055 435.422 108.573 426.748C112.054 428.329 113.967 431.327 115.549 434.586C117.952 439.545 119.471 444.794 120.459 450.201C120.936 452.819 121.571 455.443 121.081 458.143C120.072 463.689 116.681 467.121 111.467 468.931L111.529 468.986Z"
              fill="#66646F"
            />
            <path
              d="M111.481 468.924C116.688 467.114 120.079 463.682 121.094 458.136C121.585 455.443 120.949 452.812 120.473 450.194C119.485 444.794 117.966 439.538 115.562 434.579C113.981 431.32 112.075 428.322 108.587 426.741C111.888 422.203 115.1 421.955 118.56 426.036C119.395 427.024 120.162 428.108 120.77 429.255C123.878 435.111 125.839 441.375 127.124 447.853C127.455 449.511 127.697 451.189 127.98 452.86C128.187 457.287 127.365 461.368 124.181 464.725C122.869 466.113 121.267 467.073 119.699 468.095C118.504 468.399 117.31 468.703 116.108 469.007C114.561 469.262 113.014 469.359 111.481 468.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M58.7391 422.469C57.6849 423.108 56.622 423.729 55.8696 424.753C55.5765 425.152 55.1522 425.06 54.7367 425.012C52.4971 424.736 50.4587 426.084 49.8026 428.268C49.5051 429.261 49.6363 429.458 50.6686 429.462C55.7865 429.467 60.9043 429.462 66.0221 429.471C66.4946 429.471 66.967 429.524 67.435 429.55C69.3028 429.524 71.1662 429.48 73.034 429.489C73.7601 429.493 73.9876 429.266 73.8214 428.526C73.3183 426.285 71.1662 424.723 68.8829 425.007C68.4017 425.068 67.9949 425.121 67.6012 424.675C65.3179 422.062 62.4921 421.344 59.1984 422.359C59.0496 422.399 58.8965 422.429 58.7391 422.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M83.2958 422.355C90.417 422.359 97.5339 422.364 104.655 422.381C105.39 422.381 105.499 422.075 105.355 421.436C104.918 419.488 103.22 418.14 101.116 418.232C100.355 418.267 99.8435 418.075 99.3054 417.514C96.554 414.652 92.1667 414.665 89.3847 417.528C88.8773 418.053 88.3961 418.35 87.6569 418.223C86.7821 418.075 85.9859 418.482 85.1548 418.661C84.1532 419.698 83.1121 420.718 83.2958 422.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M79.0879 405.317C76.7127 404.096 74.32 403.886 71.9317 405.264C70.0026 406.38 68.9091 408.079 68.5285 410.271C68.4061 410.985 68.6116 411.247 69.3465 411.238C72.1329 411.217 74.9193 411.23 77.7013 411.23C80.2952 411.23 82.8934 411.234 85.4873 411.225C86.5065 411.221 86.629 411.028 86.3753 410.022C86.161 409.168 85.6579 408.481 85.1724 407.777C84.2582 407.085 83.3002 406.398 82.1061 406.468C80.8857 406.542 79.884 406.249 79.0879 405.317Z"
              fill="#FBFBFC"
            />
          </g>
          <g clipPath="url(#clip4_76_7489)">
            <rect x="226" y="564" width="113" height="113" fill="#EAE8EE" />
            <path
              d="M252.671 638.267C251.603 638.704 251.048 638.593 250.282 637.776C250.078 637.554 249.899 637.303 249.726 637.057C249.233 636.368 248.973 636.239 248.263 636.694C246.59 637.782 245.114 637.69 243.701 636.239C243.009 635.533 242.071 635.262 241.101 635.145C240.083 635.029 239.144 634.789 238.447 633.947C238.033 633.455 237.416 633.511 236.86 633.621C236.008 633.793 235.205 633.707 234.452 633.296C233.495 632.767 232.618 632.896 231.766 633.511C231.606 633.628 231.445 633.732 231.279 633.836C230.038 634.629 229.334 634.488 228.482 633.271C227.827 632.331 226.945 632.06 226 632.435V628.41C227.062 628.766 228.167 628.908 229.285 628.859C231.013 628.779 232.495 629.234 233.754 630.438C234.409 631.065 235.193 631.268 236.107 631.016C237.156 630.727 238.027 631.01 238.687 631.913C239.175 632.57 239.83 632.988 240.688 632.952C242.398 632.878 243.836 633.492 245.053 634.654C245.621 635.195 246.269 635.588 247.09 635.526C248.918 635.385 250.436 635.901 251.486 637.475C251.782 637.886 252.295 637.985 252.671 638.267Z"
              fill="#C0BFCA"
            />
            <path
              d="M267.057 644.696L267.051 645.962C266.526 646.134 266.044 646.441 265.482 646.49C263.457 646.343 261.494 645.863 259.543 645.359C253.196 643.718 246.683 644.696 240.2 644.696C236.49 644.358 232.217 644.542 228.494 644.696C228.494 644.696 227.056 644.622 226.012 644.696V632.441C226.957 632.067 227.84 632.337 228.494 633.277C229.346 634.494 230.044 634.635 231.291 633.843C231.458 633.738 231.618 633.634 231.779 633.517C232.631 632.902 233.508 632.773 234.465 633.302C235.218 633.714 236.02 633.793 236.872 633.628C237.428 633.517 238.045 633.462 238.459 633.953C239.157 634.795 240.095 635.035 241.114 635.152C242.083 635.262 243.022 635.533 243.713 636.246C245.127 637.696 246.603 637.788 248.276 636.7C248.979 636.239 249.245 636.375 249.739 637.063C249.918 637.309 250.091 637.561 250.295 637.782C251.06 638.605 251.616 638.71 252.684 638.274C253.091 638.31 253.493 638.353 253.912 638.335C254.789 638.31 255.314 638.876 255.61 639.613C255.882 640.302 256.314 640.64 257.074 640.646C257.821 640.652 258.45 641.027 258.994 641.506C259.901 642.299 260.963 642.668 262.155 642.686C263.105 642.698 263.933 643.012 264.674 643.596C265.371 644.149 266.131 644.61 267.057 644.696Z"
              fill="#66646F"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V638.205C238.246 639.968 260.187 642.991 283.359 636.902C305.48 631.084 324.779 627.413 339 628.65Z"
              fill="#9694A0"
            />
            <path
              d="M339 628.65V678.267C339 679.647 337.865 680.767 336.469 680.767H228.531C227.131 680.767 226 679.647 226 678.267V642.817C238.246 644.58 262.094 656.139 283.359 645.208C305.913 633.606 324.779 627.413 339 628.65Z"
              fill="#C0BFCA"
            />
            <path
              d="M321.64 639.312C321.64 638.619 321.644 637.93 321.644 637.237C321.659 636.728 321.674 636.216 321.689 635.708L321.659 635.678C322.495 635.915 323.339 635.862 324.182 635.723C324.197 636.559 324.213 637.391 324.228 638.228C324.216 639.467 324.209 640.702 324.186 641.942C324.171 642.782 323.987 643.565 322.947 643.576C321.907 643.588 321.704 642.812 321.681 641.968C321.659 641.079 321.655 640.198 321.64 639.312Z"
              fill="#66646F"
            />
            <path
              d="M321.689 635.708C317.79 634.849 315.105 631.93 315.278 628.174C315.538 622.63 317.067 617.402 320.077 612.671C321.975 613.533 323.018 615.168 323.881 616.946C325.192 619.65 326.02 622.513 326.559 625.462C326.819 626.89 327.166 628.321 326.898 629.794C326.348 632.819 324.499 634.691 321.655 635.678L321.689 635.708Z"
              fill="#66646F"
            />
            <path
              d="M321.662 635.674C324.502 634.687 326.352 632.815 326.905 629.79C327.173 628.321 326.826 626.886 326.566 625.459C326.028 622.513 325.199 619.647 323.888 616.942C323.026 615.164 321.986 613.53 320.084 612.667C321.884 610.192 323.636 610.057 325.523 612.283C325.979 612.821 326.397 613.413 326.728 614.038C328.423 617.232 329.493 620.649 330.194 624.182C330.375 625.086 330.506 626.001 330.661 626.912C330.774 629.327 330.326 631.553 328.589 633.384C327.873 634.141 327 634.664 326.145 635.222C325.493 635.387 324.841 635.553 324.186 635.719C323.342 635.858 322.498 635.911 321.662 635.674Z"
              fill="#C0BFCA"
            />
            <path
              d="M296.439 649.595C296.439 648.325 296.446 647.061 296.446 645.79C296.474 644.858 296.501 643.918 296.529 642.986L296.474 642.931C298.007 643.366 299.554 643.269 301.101 643.014C301.129 644.547 301.156 646.073 301.184 647.606C301.163 649.878 301.149 652.144 301.108 654.416C301.08 655.956 300.742 657.392 298.836 657.413C296.93 657.434 296.557 656.011 296.515 654.464C296.474 652.834 296.467 651.218 296.439 649.595Z"
              fill="#66646F"
            />
            <path
              d="M296.529 642.986C289.381 641.411 284.457 636.059 284.775 629.174C285.251 619.008 288.055 609.422 293.573 600.748C297.054 602.329 298.967 605.327 300.549 608.586C302.952 613.545 304.471 618.794 305.459 624.201C305.936 626.819 306.571 629.443 306.081 632.143C305.072 637.689 301.681 641.121 296.467 642.931L296.529 642.986Z"
              fill="#66646F"
            />
            <path
              d="M296.481 642.924C301.688 641.114 305.079 637.682 306.094 632.136C306.585 629.443 305.949 626.812 305.473 624.194C304.485 618.794 302.966 613.538 300.562 608.579C298.981 605.32 297.075 602.322 293.587 600.741C296.888 596.203 300.1 595.955 303.56 600.036C304.395 601.024 305.162 602.108 305.77 603.255C308.878 609.111 310.839 615.375 312.124 621.853C312.455 623.511 312.697 625.189 312.98 626.86C313.187 631.287 312.365 635.368 309.181 638.725C307.869 640.113 306.267 641.073 304.699 642.095C303.504 642.399 302.31 642.703 301.108 643.007C299.561 643.262 298.014 643.359 296.481 642.924Z"
              fill="#C0BFCA"
            />
            <path
              d="M243.739 596.469C242.685 597.108 241.622 597.729 240.87 598.753C240.577 599.152 240.152 599.06 239.737 599.011C237.497 598.736 235.459 600.084 234.803 602.268C234.505 603.261 234.636 603.458 235.669 603.462C240.786 603.467 245.904 603.462 251.022 603.471C251.495 603.471 251.967 603.524 252.435 603.55C254.303 603.524 256.166 603.48 258.034 603.489C258.76 603.493 258.988 603.266 258.821 602.526C258.318 600.285 256.166 598.723 253.883 599.007C253.402 599.068 252.995 599.121 252.601 598.675C250.318 596.062 247.492 595.344 244.198 596.359C244.05 596.399 243.897 596.429 243.739 596.469Z"
              fill="#FBFBFC"
            />
            <path
              d="M268.296 596.355C275.417 596.359 282.534 596.364 289.655 596.381C290.39 596.381 290.499 596.075 290.355 595.436C289.918 593.488 288.22 592.14 286.116 592.232C285.355 592.267 284.843 592.075 284.305 591.514C281.554 588.652 277.167 588.665 274.385 591.528C273.877 592.053 273.396 592.35 272.657 592.223C271.782 592.075 270.986 592.482 270.155 592.661C269.153 593.698 268.112 594.718 268.296 596.355Z"
              fill="#FBFBFC"
            />
            <path
              d="M264.088 579.317C261.713 578.096 259.32 577.886 256.932 579.264C255.003 580.38 253.909 582.079 253.529 584.271C253.406 584.985 253.612 585.247 254.347 585.238C257.133 585.217 259.919 585.23 262.701 585.23C265.295 585.23 267.893 585.234 270.487 585.225C271.507 585.221 271.629 585.028 271.375 584.022C271.161 583.168 270.658 582.481 270.172 581.777C269.258 581.085 268.3 580.398 267.106 580.468C265.886 580.542 264.884 580.249 264.088 579.317Z"
              fill="#FBFBFC"
            />
          </g>
          <rect x="176" y="85" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="259" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="177" y="433" width="161" height="26" rx="4" fill="#9694A0" />
          <rect x="41" y="607" width="161" height="26" rx="4" fill="#9694A0" />
        </g>
        <defs>
          <filter
            id="filter0_d_76_7489"
            x="-2"
            y="0"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7489"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7489"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7489"
            x="-2"
            y="174"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7489"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7489"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7489"
            x="-2"
            y="348"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7489"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7489"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_76_7489"
            x="-2"
            y="522"
            width="384"
            height="197"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7489"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7489"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7489">
            <rect width="379" height="664" fill="white" />
          </clipPath>
          <clipPath id="clip1_76_7489">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(41 42)"
            />
          </clipPath>
          <clipPath id="clip2_76_7489">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 216)"
            />
          </clipPath>
          <clipPath id="clip3_76_7489">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(41 390)"
            />
          </clipPath>
          <clipPath id="clip4_76_7489">
            <rect
              width="113"
              height="113"
              fill="white"
              transform="translate(226 564)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CollectionContainer;
