import React from "react";

const CollectionBigImage = (props) => {
  switch (props.theme) {
    case "rounded":
      return <CollectionBigImageRounded color={props.color} />;
    case "square":
      return <CollectionBigImageSquare color={props.color} />;
    default:
      return <CollectionBigImageRounded color={props.color} />;
  }
};

const CollectionBigImageRounded = (props) => {
  return (
    <div className="CollectionBigImageRounded">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="preview 14 collection list big image w title rd"
          clipPath="url(#clip0_76_7530)"
        >
          <rect width="379" height="664" fill="white" />
          <g id="Frame 67">
            <g id="Rectangle 2">
              <g id="Rectangle 1" filter="url(#filter0_d_76_7530)">
                <rect x="17" y="593" width="346" height="274" fill="white" />
              </g>
            </g>
            <mask
              id="mask0_76_7530"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="16"
              y="592"
              width="347"
              height="227"
            >
              <rect
                id="Rectangle 39"
                x="16"
                y="592"
                width="347"
                height="227"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_76_7530)">
              <g id="prev comp elem img 4:3">
                <g id="comp elem img sky">
                  <rect
                    id="Rectangle 16"
                    x="7"
                    y="593"
                    width="365"
                    height="252"
                    rx="8"
                    fill="#EAE8EE"
                  />
                </g>
                <g id="comp elem img clouds">
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M179.628 664.629C182.826 666.584 186.051 668.485 188.333 671.619C189.222 672.837 190.509 672.556 191.77 672.409C198.564 671.565 204.748 675.689 206.739 682.37C207.641 685.41 207.243 686.012 204.111 686.026C188.585 686.039 173.059 686.026 157.533 686.053C156.1 686.053 154.667 686.213 153.247 686.294C147.58 686.213 141.927 686.079 136.261 686.106C134.058 686.12 133.368 685.423 133.872 683.16C135.398 676.305 141.927 671.525 148.854 672.395C150.314 672.583 151.548 672.743 152.743 671.378C159.67 663.384 168.242 661.188 178.235 664.295C178.686 664.415 179.15 664.509 179.628 664.629Z"
                      fill="#FBFBFC"
                    />
                    <path
                      id="Vector_2"
                      d="M105.129 664.281C83.5254 664.295 61.9348 664.308 40.331 664.361C38.1016 664.361 37.7698 663.424 38.2077 661.469C39.5347 655.511 44.6836 651.387 51.0665 651.668C53.3755 651.775 54.9281 651.186 56.5604 649.472C64.9073 640.715 78.2173 640.756 86.6571 649.512C88.1965 651.119 89.6562 652.03 91.8988 651.641C94.5529 651.186 96.9681 652.431 99.4894 652.98C102.528 656.154 105.687 659.273 105.129 664.281Z"
                      fill="#FBFBFC"
                    />
                    <path
                      id="Vector_3"
                      d="M117.895 612.156C125.101 608.42 132.36 607.777 139.605 611.995C145.457 615.409 148.775 620.604 149.929 627.313C150.301 629.495 149.677 630.298 147.448 630.272C138.995 630.205 130.542 630.245 122.102 630.245C114.233 630.245 106.35 630.258 98.4809 630.231C95.389 630.218 95.0174 629.629 95.7871 626.549C96.4373 623.938 97.9634 621.836 99.4364 619.68C102.21 617.565 105.116 615.463 108.739 615.677C112.441 615.905 115.48 615.008 117.895 612.156Z"
                      fill="#FBFBFC"
                    />
                  </g>
                </g>
                <g id="comp elem img tree">
                  <path
                    id="Vector_4"
                    d="M322.76 702.171C313.01 700.042 306.293 692.804 306.727 683.493C307.377 669.745 311.201 656.783 318.728 645.053C323.476 647.191 326.086 651.245 328.243 655.653C331.521 662.358 333.594 669.456 334.941 676.768C335.591 680.308 336.457 683.857 335.789 687.509C334.413 695.008 329.788 699.649 322.675 702.096L322.76 702.171Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_5"
                    d="M322.694 702.087C329.797 699.64 334.423 694.998 335.807 687.499C336.476 683.857 335.61 680.299 334.96 676.759C333.612 669.456 331.54 662.349 328.262 655.643C326.104 651.235 323.504 647.182 318.747 645.043C323.25 638.908 327.631 638.571 332.35 644.091C333.49 645.426 334.536 646.893 335.365 648.443C339.604 656.362 342.279 664.833 344.031 673.593C344.484 675.835 344.813 678.104 345.2 680.364C345.482 686.35 344.361 691.87 340.018 696.409C338.228 698.286 336.043 699.584 333.905 700.966C332.275 701.377 330.645 701.788 329.006 702.199C326.896 702.544 324.786 702.675 322.694 702.087Z"
                    fill="#C0BFCA"
                  />
                </g>
                <g id="comp elem img tree_2">
                  <path
                    id="Vector_6"
                    d="M262.996 720.388C247.287 716.97 236.466 705.351 237.164 690.404C238.211 668.336 244.373 647.528 256.5 628.698C264.149 632.131 268.353 638.638 271.829 645.714C277.11 656.478 280.449 667.872 282.62 679.61C283.667 685.292 285.063 690.989 283.986 696.851C281.77 708.889 274.318 716.34 262.859 720.268L262.996 720.388Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_7"
                    d="M262.889 720.253C274.333 716.325 281.785 708.874 284.016 696.836C285.094 690.989 283.697 685.277 282.65 679.595C280.48 667.872 277.141 656.463 271.859 645.699C268.384 638.623 264.195 632.116 256.53 628.683C263.785 618.833 270.842 618.294 278.446 627.154C280.283 629.298 281.967 631.651 283.303 634.14C290.133 646.853 294.443 660.451 297.266 674.513C297.995 678.111 298.526 681.754 299.148 685.382C299.603 694.992 297.797 703.852 290.8 711.138C287.917 714.151 284.396 716.235 280.95 718.454C278.325 719.114 275.699 719.773 273.058 720.433C269.658 720.988 266.259 721.197 262.889 720.253Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Frame 66">
            <g id="Rectangle 2_2">
              <g id="Rectangle 1_2" filter="url(#filter1_d_76_7530)">
                <rect x="17" y="305" width="346" height="274" fill="white" />
              </g>
            </g>
            <path
              id="themecolor1"
              d="M16 531H363V564C363 572.284 356.284 579 348 579H31C22.7157 579 16 572.284 16 564V531Z"
              fill={props.color}
            />
            <g id="Rectangle 38">
              <rect
                id="Rectangle 35"
                x="109"
                y="544"
                width="161"
                height="20"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <mask
              id="mask1_76_7530"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="16"
              y="304"
              width="347"
              height="227"
            >
              <rect
                id="Rectangle 39_2"
                x="16"
                y="304"
                width="347"
                height="227"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask1_76_7530)">
              <g id="prev comp elem img 4:3_2">
                <g id="comp elem img sky_2">
                  <rect
                    id="Rectangle 16_2"
                    x="7"
                    y="305"
                    width="365"
                    height="252"
                    rx="8"
                    fill="#EAE8EE"
                  />
                </g>
                <g id="comp elem img clouds_2">
                  <g id="Group_2">
                    <path
                      id="Vector_8"
                      d="M179.628 376.629C182.826 378.584 186.051 380.485 188.333 383.619C189.222 384.837 190.509 384.556 191.77 384.409C198.564 383.565 204.748 387.689 206.739 394.37C207.641 397.41 207.243 398.012 204.111 398.026C188.585 398.039 173.059 398.026 157.533 398.053C156.1 398.053 154.667 398.213 153.247 398.294C147.58 398.213 141.927 398.079 136.261 398.106C134.058 398.12 133.368 397.423 133.872 395.16C135.398 388.305 141.927 383.525 148.854 384.395C150.314 384.583 151.548 384.743 152.743 383.378C159.67 375.384 168.242 373.188 178.235 376.295C178.686 376.415 179.15 376.509 179.628 376.629Z"
                      fill="#FBFBFC"
                    />
                    <path
                      id="Vector_9"
                      d="M105.129 376.281C83.5254 376.295 61.9348 376.308 40.331 376.362C38.1016 376.362 37.7698 375.424 38.2077 373.469C39.5347 367.511 44.6836 363.387 51.0665 363.668C53.3755 363.775 54.9281 363.186 56.5604 361.472C64.9073 352.716 78.2173 352.756 86.6571 361.512C88.1965 363.119 89.6562 364.03 91.8988 363.641C94.5529 363.186 96.9681 364.431 99.4894 364.98C102.528 368.154 105.687 371.273 105.129 376.281Z"
                      fill="#FBFBFC"
                    />
                    <path
                      id="Vector_10"
                      d="M117.895 324.156C125.101 320.42 132.36 319.777 139.605 323.995C145.457 327.409 148.775 332.604 149.929 339.313C150.301 341.495 149.677 342.298 147.448 342.272C138.995 342.205 130.542 342.245 122.102 342.245C114.233 342.245 106.35 342.258 98.4809 342.231C95.389 342.218 95.0174 341.629 95.7871 338.549C96.4373 335.938 97.9634 333.836 99.4364 331.68C102.21 329.565 105.116 327.463 108.739 327.677C112.441 327.905 115.48 327.008 117.895 324.156Z"
                      fill="#FBFBFC"
                    />
                  </g>
                </g>
                <g id="comp elem img bush">
                  <path
                    id="Vector_11"
                    d="M70.2764 428.114C67.7424 429.149 66.4242 428.886 64.6079 426.947C64.1245 426.422 63.6998 425.825 63.2896 425.241C62.1179 423.608 61.5027 423.302 59.8182 424.381C55.8488 426.962 52.3481 426.743 48.9939 423.302C47.3534 421.626 45.127 420.984 42.8273 420.707C40.4105 420.43 38.1841 419.861 36.529 417.864C35.5476 416.698 34.0829 416.829 32.7646 417.091C30.7433 417.5 28.8391 417.295 27.0522 416.319C24.7818 415.065 22.7019 415.371 20.6806 416.829C20.2998 417.106 19.9189 417.354 19.5235 417.602C16.5793 419.482 14.9096 419.147 12.8882 416.26C11.3356 414.03 9.24104 413.388 7 414.277V404.728C9.51934 405.573 12.1412 405.909 14.7924 405.792C18.8936 405.602 22.409 406.681 25.397 409.539C26.9496 411.026 28.8099 411.507 30.9777 410.909C33.4677 410.224 35.533 410.895 37.1002 413.038C38.2574 414.598 39.81 415.59 41.846 415.502C45.9033 415.327 49.3161 416.785 52.2016 419.541C53.5492 420.824 55.0871 421.757 57.0352 421.611C61.3708 421.276 64.9741 422.5 67.4641 426.233C68.1672 427.21 69.3829 427.443 70.2764 428.114Z"
                    fill="#C0BFCA"
                  />
                  <path
                    id="Vector_12"
                    d="M104.405 443.364L104.39 446.367C103.145 446.776 102.002 447.505 100.67 447.621C95.8653 447.271 91.2074 446.134 86.5789 444.939C71.5214 441.046 56.0685 443.364 40.6888 443.364C31.8858 442.562 21.7498 442.999 12.9175 443.364C12.9175 443.364 9.50469 443.189 7.0293 443.364V414.292C9.27034 413.403 11.3649 414.044 12.9175 416.275C14.9388 419.162 16.594 419.497 19.5528 417.616C19.9482 417.368 20.3291 417.12 20.7099 416.843C22.7312 415.385 24.8111 415.079 27.0815 416.333C28.8684 417.31 30.7726 417.5 32.7939 417.106C34.1122 416.843 35.5769 416.712 36.5583 417.879C38.2134 419.876 40.4398 420.445 42.8566 420.722C45.1563 420.984 47.3827 421.626 49.0232 423.317C52.3774 426.758 55.8781 426.976 59.8475 424.396C61.5173 423.302 62.1471 423.623 63.3189 425.256C63.7437 425.839 64.1538 426.437 64.6372 426.962C66.4535 428.915 67.7717 429.163 70.3057 428.128C71.2724 428.216 72.2245 428.318 73.2205 428.274C75.3004 428.216 76.5455 429.557 77.2485 431.307C77.893 432.939 78.9183 433.741 80.7199 433.756C82.4923 433.771 83.9863 434.66 85.2753 435.797C87.4284 437.678 89.9478 438.553 92.7747 438.596C95.0304 438.626 96.9931 439.369 98.7508 440.754C100.406 442.066 102.208 443.16 104.405 443.364Z"
                    fill="#66646F"
                  />
                </g>
                <g id="comp elem img hills">
                  <path
                    id="Vector_13"
                    d="M372 408.627V549.883C372 553.814 368.334 557 363.826 557H15.1739C10.6519 557 7 553.814 7 549.883V435.829C46.557 440.851 117.426 449.455 192.275 432.122C263.728 415.558 326.067 405.106 372 408.627Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_14"
                    d="M372 408.627V549.883C372 553.814 368.334 557 363.826 557H15.1739C10.6519 557 7 553.814 7 549.883V448.959C46.557 453.981 123.588 486.886 192.275 455.766C265.126 422.736 326.067 405.106 372 408.627Z"
                    fill="#C0BFCA"
                  />
                </g>
                <g id="comp elem img tree_3">
                  <path
                    id="Vector_15"
                    d="M322.638 423.109C322.638 421.39 322.647 419.681 322.647 417.963C322.685 416.702 322.722 415.432 322.76 414.171L322.685 414.096C324.776 414.685 326.886 414.554 328.996 414.208C329.034 416.282 329.072 418.346 329.11 420.419C329.081 423.492 329.062 426.555 329.006 429.627C328.968 431.71 328.507 433.653 325.907 433.681C323.307 433.709 322.798 431.785 322.741 429.693C322.685 427.489 322.675 425.303 322.638 423.109Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_16"
                    d="M322.76 414.171C313.01 412.042 306.293 404.804 306.727 395.493C307.377 381.745 311.201 368.783 318.728 357.053C323.476 359.191 326.086 363.245 328.243 367.653C331.521 374.358 333.594 381.456 334.941 388.768C335.591 392.308 336.457 395.857 335.789 399.509C334.413 407.008 329.788 411.649 322.675 414.096L322.76 414.171Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_17"
                    d="M322.694 414.087C329.797 411.64 334.423 406.998 335.807 399.499C336.476 395.857 335.61 392.299 334.96 388.759C333.612 381.456 331.54 374.349 328.262 367.643C326.104 363.235 323.504 359.182 318.747 357.043C323.25 350.908 327.631 350.571 332.35 356.091C333.49 357.426 334.536 358.893 335.365 360.443C339.604 368.362 342.279 376.833 344.031 385.593C344.484 387.835 344.813 390.104 345.2 392.364C345.482 398.35 344.361 403.87 340.018 408.409C338.228 410.286 336.043 411.584 333.905 412.966C332.275 413.377 330.645 413.788 329.006 414.199C326.896 414.544 324.786 414.675 322.694 414.087Z"
                    fill="#C0BFCA"
                  />
                </g>
                <g id="comp elem img tree_4">
                  <path
                    id="Vector_18"
                    d="M262.798 446.735C262.798 443.977 262.814 441.233 262.814 438.475C262.874 436.451 262.935 434.412 262.996 432.388L262.874 432.268C266.244 433.213 269.643 433.003 273.043 432.448C273.104 435.776 273.164 439.089 273.225 442.418C273.18 447.35 273.149 452.267 273.058 457.199C272.997 460.543 272.254 463.661 268.065 463.706C263.876 463.751 263.056 460.663 262.965 457.304C262.874 453.766 262.859 450.258 262.798 446.735Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_19"
                    d="M262.996 432.388C247.287 428.97 236.466 417.351 237.164 402.404C238.211 380.336 244.373 359.528 256.5 340.698C264.149 344.131 268.353 350.638 271.829 357.714C277.11 368.478 280.449 379.872 282.62 391.61C283.667 397.292 285.063 402.989 283.986 408.851C281.77 420.889 274.318 428.34 262.859 432.268L262.996 432.388Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_20"
                    d="M262.889 432.253C274.333 428.325 281.785 420.874 284.016 408.836C285.094 402.989 283.697 397.277 282.65 391.595C280.48 379.872 277.141 368.463 271.859 357.699C268.384 350.623 264.195 344.116 256.53 340.683C263.785 330.833 270.842 330.294 278.446 339.154C280.283 341.298 281.967 343.651 283.303 346.14C290.133 358.853 294.443 372.451 297.266 386.513C297.995 390.111 298.526 393.754 299.148 397.382C299.603 406.992 297.797 415.852 290.8 423.138C287.917 426.151 284.396 428.235 280.95 430.454C278.325 431.114 275.699 431.773 273.058 432.433C269.658 432.988 266.259 433.197 262.889 432.253Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="comp prev img tb">
            <g id="Rectangle 2_3">
              <g id="Rectangle 1_3" filter="url(#filter2_d_76_7530)">
                <rect x="17" y="17" width="346" height="274" fill="white" />
              </g>
            </g>
            <path
              id="themecolor1_2"
              d="M16 243H363V276C363 284.284 356.284 291 348 291H31C22.7157 291 16 284.284 16 276V243Z"
              fill={props.color}
            />
            <g id="Rectangle 38_2">
              <rect
                id="Rectangle 35_2"
                x="109"
                y="256"
                width="161"
                height="20"
                rx="4"
                fill="#FBFBFC"
              />
            </g>
            <mask
              id="mask2_76_7530"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="16"
              y="16"
              width="347"
              height="227"
            >
              <rect
                id="Rectangle 39_3"
                x="16"
                y="16"
                width="347"
                height="227"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask2_76_7530)">
              <g id="prev comp elem img 4:3_3">
                <g id="comp elem img sky_3">
                  <rect
                    id="Rectangle 16_3"
                    x="7"
                    y="17"
                    width="365"
                    height="252"
                    rx="8"
                    fill="#EAE8EE"
                  />
                </g>
                <g id="comp elem img clouds_3">
                  <g id="Group_3">
                    <path
                      id="Vector_21"
                      d="M179.628 88.6293C182.826 90.5842 186.051 92.4855 188.333 95.6186C189.222 96.8371 190.509 96.5559 191.77 96.4086C198.564 95.5651 204.748 99.6891 206.739 106.37C207.641 109.41 207.243 110.012 204.111 110.026C188.585 110.039 173.059 110.026 157.533 110.053C156.1 110.053 154.667 110.213 153.247 110.294C147.58 110.213 141.927 110.079 136.261 110.106C134.058 110.12 133.368 109.423 133.872 107.16C135.398 100.305 141.927 95.5249 148.854 96.3952C150.314 96.5827 151.548 96.7434 152.743 95.3776C159.67 87.3841 168.242 85.1882 178.235 88.2946C178.686 88.4151 179.15 88.5088 179.628 88.6293Z"
                      fill="#FBFBFC"
                    />
                    <path
                      id="Vector_22"
                      d="M105.129 88.2812C83.5254 88.2946 61.9348 88.308 40.331 88.3615C38.1016 88.3615 37.7698 87.4242 38.2077 85.4694C39.5347 79.511 44.6836 75.387 51.0665 75.6682C53.3755 75.7753 54.9281 75.1862 56.5604 73.4723C64.9073 64.7155 78.2173 64.7557 86.6571 73.5125C88.1965 75.1192 89.6562 76.0297 91.8988 75.6414C94.5529 75.1862 96.9681 76.4314 99.4894 76.9804C102.528 80.1537 105.687 83.2735 105.129 88.2812Z"
                      fill="#FBFBFC"
                    />
                    <path
                      id="Vector_23"
                      d="M117.895 36.1555C125.101 32.4199 132.36 31.7772 139.605 35.9949C145.457 39.4092 148.775 44.6044 149.929 51.3125C150.301 53.495 149.677 54.2984 147.448 54.2716C138.995 54.2047 130.542 54.2449 122.102 54.2449C114.233 54.2449 106.35 54.2582 98.4809 54.2315C95.389 54.2181 95.0174 53.6289 95.7871 50.5493C96.4373 47.9384 97.9634 45.8362 99.4364 43.6805C102.21 41.5649 105.116 39.4628 108.739 39.677C112.441 39.9046 115.48 39.0075 117.895 36.1555Z"
                      fill="#FBFBFC"
                    />
                  </g>
                </g>
                <g id="comp elem img bush_2">
                  <path
                    id="Vector_24"
                    d="M70.2764 140.114C67.7424 141.149 66.4242 140.886 64.6079 138.947C64.1245 138.422 63.6998 137.825 63.2896 137.241C62.1179 135.608 61.5027 135.302 59.8182 136.381C55.8488 138.962 52.3481 138.743 48.9939 135.302C47.3534 133.626 45.127 132.984 42.8273 132.707C40.4105 132.43 38.1841 131.861 36.529 129.864C35.5476 128.698 34.0829 128.829 32.7646 129.091C30.7433 129.5 28.8391 129.295 27.0522 128.319C24.7818 127.065 22.7019 127.371 20.6806 128.829C20.2998 129.106 19.9189 129.354 19.5235 129.602C16.5793 131.482 14.9096 131.147 12.8882 128.26C11.3356 126.03 9.24104 125.388 7 126.277V116.728C9.51934 117.573 12.1412 117.909 14.7924 117.792C18.8936 117.602 22.409 118.681 25.397 121.539C26.9496 123.026 28.8099 123.507 30.9777 122.909C33.4677 122.224 35.533 122.895 37.1002 125.038C38.2574 126.598 39.81 127.59 41.846 127.502C45.9033 127.327 49.3161 128.785 52.2016 131.541C53.5492 132.824 55.0871 133.757 57.0352 133.611C61.3708 133.276 64.9741 134.5 67.4641 138.233C68.1672 139.21 69.3829 139.443 70.2764 140.114Z"
                    fill="#C0BFCA"
                  />
                  <path
                    id="Vector_25"
                    d="M104.405 155.364L104.39 158.367C103.145 158.776 102.002 159.505 100.67 159.621C95.8653 159.271 91.2074 158.134 86.5789 156.939C71.5214 153.046 56.0685 155.364 40.6888 155.364C31.8858 154.562 21.7498 154.999 12.9175 155.364C12.9175 155.364 9.50469 155.189 7.0293 155.364V126.292C9.27034 125.403 11.3649 126.044 12.9175 128.275C14.9388 131.162 16.594 131.497 19.5528 129.616C19.9482 129.368 20.3291 129.12 20.7099 128.843C22.7312 127.385 24.8111 127.079 27.0815 128.333C28.8684 129.31 30.7726 129.5 32.7939 129.106C34.1122 128.843 35.5769 128.712 36.5583 129.879C38.2134 131.876 40.4398 132.445 42.8566 132.722C45.1563 132.984 47.3827 133.626 49.0232 135.317C52.3774 138.758 55.8781 138.976 59.8475 136.396C61.5173 135.302 62.1471 135.623 63.3189 137.256C63.7437 137.839 64.1538 138.437 64.6372 138.962C66.4535 140.915 67.7717 141.163 70.3057 140.128C71.2724 140.216 72.2245 140.318 73.2205 140.274C75.3004 140.216 76.5455 141.557 77.2485 143.307C77.893 144.939 78.9183 145.741 80.7199 145.756C82.4923 145.771 83.9863 146.66 85.2753 147.797C87.4284 149.678 89.9478 150.553 92.7747 150.596C95.0304 150.626 96.9931 151.369 98.7508 152.754C100.406 154.066 102.208 155.16 104.405 155.364Z"
                    fill="#66646F"
                  />
                </g>
                <g id="comp elem img hills_2">
                  <path
                    id="Vector_26"
                    d="M372 120.627V261.883C372 265.814 368.334 269 363.826 269H15.1739C10.6519 269 7 265.814 7 261.883V147.829C46.557 152.851 117.426 161.455 192.275 144.122C263.728 127.558 326.067 117.106 372 120.627Z"
                    fill="#9694A0"
                  />
                  <path
                    id="Vector_27"
                    d="M372 120.627V261.883C372 265.814 368.334 269 363.826 269H15.1739C10.6519 269 7 265.814 7 261.883V160.959C46.557 165.981 123.588 198.886 192.275 167.766C265.126 134.736 326.067 117.106 372 120.627Z"
                    fill="#C0BFCA"
                  />
                </g>
                <g id="comp elem img tree_5">
                  <path
                    id="Vector_28"
                    d="M322.638 135.109C322.638 133.39 322.647 131.681 322.647 129.963C322.685 128.702 322.722 127.432 322.76 126.171L322.685 126.096C324.776 126.685 326.886 126.554 328.996 126.208C329.034 128.282 329.072 130.346 329.11 132.419C329.081 135.492 329.062 138.555 329.006 141.627C328.968 143.71 328.507 145.653 325.907 145.681C323.307 145.709 322.798 143.785 322.741 141.693C322.685 139.489 322.675 137.303 322.638 135.109Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_29"
                    d="M322.76 126.171C313.01 124.042 306.293 116.804 306.727 107.493C307.377 93.7455 311.201 80.7827 318.728 69.0527C323.476 71.1914 326.086 75.2446 328.243 79.6527C331.521 86.3582 333.594 93.4559 334.941 100.768C335.591 104.308 336.457 107.857 335.789 111.509C334.413 119.008 329.788 123.649 322.675 126.096L322.76 126.171Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_30"
                    d="M322.694 126.087C329.797 123.64 334.423 118.998 335.807 111.499C336.476 107.857 335.61 104.299 334.96 100.759C333.612 93.4559 331.54 86.3488 328.262 79.6433C326.104 75.2352 323.504 71.182 318.747 69.0434C323.25 62.9075 327.631 62.5713 332.35 68.0908C333.49 69.4263 334.536 70.8925 335.365 72.4428C339.604 80.3624 342.279 88.833 344.031 97.5931C344.484 99.8345 344.813 102.104 345.2 104.364C345.482 110.35 344.361 115.87 340.018 120.409C338.228 122.286 336.043 123.584 333.905 124.966C332.275 125.377 330.645 125.788 329.006 126.199C326.896 126.544 324.786 126.675 322.694 126.087Z"
                    fill="#C0BFCA"
                  />
                </g>
                <g id="comp elem img tree_6">
                  <path
                    id="Vector_31"
                    d="M262.798 158.735C262.798 155.977 262.814 153.233 262.814 150.475C262.874 148.451 262.935 146.412 262.996 144.388L262.874 144.268C266.244 145.213 269.643 145.003 273.043 144.448C273.104 147.776 273.164 151.089 273.225 154.418C273.18 159.35 273.149 164.267 273.058 169.199C272.997 172.543 272.254 175.661 268.065 175.706C263.876 175.751 263.056 172.663 262.965 169.304C262.874 165.766 262.859 162.258 262.798 158.735Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_32"
                    d="M262.996 144.388C247.287 140.97 236.466 129.351 237.164 114.404C238.211 92.3364 244.373 71.5278 256.5 52.6981C264.149 56.1312 268.353 62.6377 271.829 69.7138C277.11 80.4779 280.449 91.8717 282.62 103.61C283.667 109.292 285.063 114.989 283.986 120.851C281.77 132.889 274.318 140.34 262.859 144.268L262.996 144.388Z"
                    fill="#66646F"
                  />
                  <path
                    id="Vector_33"
                    d="M262.889 144.253C274.333 140.325 281.785 132.874 284.016 120.836C285.094 114.989 283.697 109.277 282.65 103.595C280.48 91.8716 277.141 80.4628 271.859 69.6987C268.384 62.6226 264.195 56.1162 256.53 52.683C263.785 42.8334 270.842 42.2937 278.446 51.1539C280.283 53.2977 281.967 55.6514 283.303 58.14C290.133 70.8531 294.443 84.4506 297.266 98.513C297.995 102.111 298.526 105.754 299.148 109.382C299.603 118.992 297.797 127.852 290.8 135.138C287.917 138.151 284.396 140.235 280.95 142.454C278.325 143.114 275.699 143.773 273.058 144.433C269.658 144.988 266.259 145.197 262.889 144.253Z"
                    fill="#C0BFCA"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_76_7530"
            x="-2"
            y="574"
            width="384"
            height="312"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7530"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7530"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_76_7530"
            x="-2"
            y="286"
            width="384"
            height="312"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7530"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7530"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_76_7530"
            x="-2"
            y="-2"
            width="384"
            height="312"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="9.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_76_7530"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_76_7530"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_76_7530">
            <rect width="379" height="664" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CollectionBigImageSquare = (props) => {
  return (
    <div className="CollectionBigImageSquare">
      <svg
        width="300"
        height="664"
        viewBox="0 0 379 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
  <g id="preview 13 collection list big image w title" clip-path="url(#clip0_76_7501)">
    <rect width="379" height="664" fill="white"/>
    <g id="Frame 67">
      <g id="Rectangle 2">
        <g id="Rectangle 1" filter="url(#filter0_d_76_7501)">
          <rect x="17" y="593" width="346" height="274" fill="white"/>
        </g>
      </g>
      <mask id="mask0_76_7501" mask-type="alpha" maskUnits="userSpaceOnUse" x="16" y="592" width="347" height="227">
        <rect id="Rectangle 39" x="16" y="592" width="347" height="227" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_76_7501)">
        <g id="prev comp elem img 4:3">
          <g id="comp elem img sky">
            <rect id="Rectangle 16" x="7" y="593" width="365" height="252" rx="8" fill="#EAE8EE"/>
          </g>
          <g id="comp elem img clouds">
            <g id="Group">
              <path id="Vector" d="M179.628 664.629C182.826 666.584 186.051 668.485 188.333 671.619C189.222 672.837 190.509 672.556 191.77 672.409C198.564 671.565 204.748 675.689 206.739 682.37C207.641 685.41 207.243 686.012 204.111 686.026C188.585 686.039 173.059 686.026 157.533 686.053C156.1 686.053 154.667 686.213 153.247 686.294C147.58 686.213 141.927 686.079 136.261 686.106C134.058 686.12 133.368 685.423 133.872 683.16C135.398 676.305 141.927 671.525 148.854 672.395C150.314 672.583 151.548 672.743 152.743 671.378C159.67 663.384 168.242 661.188 178.235 664.295C178.686 664.415 179.15 664.509 179.628 664.629Z" fill="#FBFBFC"/>
              <path id="Vector_2" d="M105.129 664.281C83.5254 664.295 61.9348 664.308 40.331 664.361C38.1016 664.361 37.7698 663.424 38.2077 661.469C39.5347 655.511 44.6836 651.387 51.0665 651.668C53.3755 651.775 54.9281 651.186 56.5604 649.472C64.9073 640.715 78.2173 640.756 86.6571 649.512C88.1965 651.119 89.6562 652.03 91.8988 651.641C94.5529 651.186 96.9681 652.431 99.4894 652.98C102.528 656.154 105.687 659.273 105.129 664.281Z" fill="#FBFBFC"/>
              <path id="Vector_3" d="M117.895 612.156C125.101 608.42 132.36 607.777 139.605 611.995C145.457 615.409 148.775 620.604 149.929 627.313C150.301 629.495 149.677 630.298 147.448 630.272C138.995 630.205 130.542 630.245 122.102 630.245C114.233 630.245 106.35 630.258 98.4809 630.231C95.389 630.218 95.0174 629.629 95.7871 626.549C96.4373 623.938 97.9634 621.836 99.4364 619.68C102.21 617.565 105.116 615.463 108.739 615.677C112.441 615.905 115.48 615.008 117.895 612.156Z" fill="#FBFBFC"/>
            </g>
          </g>
          <g id="comp elem img tree">
            <path id="Vector_4" d="M322.76 702.171C313.01 700.042 306.293 692.804 306.727 683.493C307.377 669.745 311.201 656.783 318.728 645.053C323.476 647.191 326.086 651.245 328.243 655.653C331.521 662.358 333.594 669.456 334.941 676.768C335.591 680.308 336.457 683.857 335.789 687.509C334.413 695.008 329.788 699.649 322.675 702.096L322.76 702.171Z" fill="#66646F"/>
            <path id="Vector_5" d="M322.694 702.087C329.797 699.64 334.423 694.998 335.807 687.499C336.476 683.857 335.61 680.299 334.96 676.759C333.612 669.456 331.54 662.349 328.262 655.643C326.104 651.235 323.504 647.182 318.747 645.043C323.25 638.908 327.631 638.571 332.35 644.091C333.49 645.426 334.536 646.893 335.365 648.443C339.604 656.362 342.279 664.833 344.031 673.593C344.484 675.835 344.813 678.104 345.2 680.364C345.482 686.35 344.361 691.87 340.018 696.409C338.228 698.286 336.043 699.584 333.905 700.966C332.275 701.377 330.645 701.788 329.006 702.199C326.896 702.544 324.786 702.675 322.694 702.087Z" fill="#C0BFCA"/>
          </g>
          <g id="comp elem img tree_2">
            <path id="Vector_6" d="M262.996 720.388C247.287 716.97 236.466 705.351 237.164 690.404C238.211 668.336 244.373 647.528 256.5 628.698C264.149 632.131 268.353 638.638 271.829 645.714C277.11 656.478 280.449 667.872 282.62 679.61C283.667 685.292 285.063 690.989 283.986 696.851C281.77 708.889 274.318 716.34 262.859 720.268L262.996 720.388Z" fill="#66646F"/>
            <path id="Vector_7" d="M262.889 720.253C274.333 716.325 281.785 708.874 284.016 696.836C285.094 690.989 283.697 685.277 282.65 679.595C280.48 667.872 277.141 656.463 271.859 645.699C268.384 638.623 264.195 632.116 256.53 628.683C263.785 618.833 270.842 618.294 278.446 627.154C280.283 629.298 281.967 631.651 283.303 634.14C290.133 646.853 294.443 660.451 297.266 674.513C297.995 678.111 298.526 681.754 299.148 685.382C299.603 694.992 297.797 703.852 290.8 711.138C287.917 714.151 284.396 716.235 280.95 718.454C278.325 719.114 275.699 719.773 273.058 720.433C269.658 720.988 266.259 721.197 262.889 720.253Z" fill="#C0BFCA"/>
          </g>
        </g>
      </g>
    </g>
    <g id="Frame 66">
      <g id="Rectangle 2_2">
        <g id="Rectangle 1_2" filter="url(#filter1_d_76_7501)">
          <rect x="17" y="305" width="346" height="274" fill="white"/>
        </g>
      </g>
      <rect id="themecolor1" x="16" y="531" width="347" height="48" fill={props.color}/>
      <g id="Rectangle 38">
        <rect id="Rectangle 35" x="109" y="544" width="161" height="20" rx="4" fill="#FBFBFC"/>
      </g>
      <mask id="mask1_76_7501" mask-type="alpha" maskUnits="userSpaceOnUse" x="16" y="304" width="347" height="227">
        <rect id="Rectangle 39_2" x="16" y="304" width="347" height="227" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask1_76_7501)">
        <g id="prev comp elem img 4:3_2">
          <g id="comp elem img sky_2">
            <rect id="Rectangle 16_2" x="7" y="305" width="365" height="252" rx="8" fill="#EAE8EE"/>
          </g>
          <g id="comp elem img clouds_2">
            <g id="Group_2">
              <path id="Vector_8" d="M179.628 376.629C182.826 378.584 186.051 380.485 188.333 383.619C189.222 384.837 190.509 384.556 191.77 384.409C198.564 383.565 204.748 387.689 206.739 394.37C207.641 397.41 207.243 398.012 204.111 398.026C188.585 398.039 173.059 398.026 157.533 398.053C156.1 398.053 154.667 398.213 153.247 398.294C147.58 398.213 141.927 398.079 136.261 398.106C134.058 398.12 133.368 397.423 133.872 395.16C135.398 388.305 141.927 383.525 148.854 384.395C150.314 384.583 151.548 384.743 152.743 383.378C159.67 375.384 168.242 373.188 178.235 376.295C178.686 376.415 179.15 376.509 179.628 376.629Z" fill="#FBFBFC"/>
              <path id="Vector_9" d="M105.129 376.281C83.5254 376.295 61.9348 376.308 40.331 376.362C38.1016 376.362 37.7698 375.424 38.2077 373.469C39.5347 367.511 44.6836 363.387 51.0665 363.668C53.3755 363.775 54.9281 363.186 56.5604 361.472C64.9073 352.716 78.2173 352.756 86.6571 361.512C88.1965 363.119 89.6562 364.03 91.8988 363.641C94.5529 363.186 96.9681 364.431 99.4894 364.98C102.528 368.154 105.687 371.273 105.129 376.281Z" fill="#FBFBFC"/>
              <path id="Vector_10" d="M117.895 324.156C125.101 320.42 132.36 319.777 139.605 323.995C145.457 327.409 148.775 332.604 149.929 339.313C150.301 341.495 149.677 342.298 147.448 342.272C138.995 342.205 130.542 342.245 122.102 342.245C114.233 342.245 106.35 342.258 98.4809 342.231C95.389 342.218 95.0174 341.629 95.7871 338.549C96.4373 335.938 97.9634 333.836 99.4364 331.68C102.21 329.565 105.116 327.463 108.739 327.677C112.441 327.905 115.48 327.008 117.895 324.156Z" fill="#FBFBFC"/>
            </g>
          </g>
          <g id="comp elem img bush">
            <path id="Vector_11" d="M70.2764 428.114C67.7424 429.149 66.4242 428.886 64.6079 426.947C64.1245 426.422 63.6998 425.825 63.2896 425.241C62.1179 423.608 61.5027 423.302 59.8182 424.381C55.8488 426.962 52.3481 426.743 48.9939 423.302C47.3534 421.626 45.127 420.984 42.8273 420.707C40.4105 420.43 38.1841 419.861 36.529 417.864C35.5476 416.698 34.0829 416.829 32.7646 417.091C30.7433 417.5 28.8391 417.295 27.0522 416.319C24.7818 415.065 22.7019 415.371 20.6806 416.829C20.2998 417.106 19.9189 417.354 19.5235 417.602C16.5793 419.482 14.9096 419.147 12.8882 416.26C11.3356 414.03 9.24104 413.388 7 414.277V404.728C9.51934 405.573 12.1412 405.909 14.7924 405.792C18.8936 405.602 22.409 406.681 25.397 409.539C26.9496 411.026 28.8099 411.507 30.9777 410.909C33.4677 410.224 35.533 410.895 37.1002 413.038C38.2574 414.598 39.81 415.59 41.846 415.502C45.9033 415.327 49.3161 416.785 52.2016 419.541C53.5492 420.824 55.0871 421.757 57.0352 421.611C61.3708 421.276 64.9741 422.5 67.4641 426.233C68.1672 427.21 69.3829 427.443 70.2764 428.114Z" fill="#C0BFCA"/>
            <path id="Vector_12" d="M104.405 443.364L104.39 446.367C103.145 446.776 102.002 447.505 100.67 447.621C95.8653 447.271 91.2074 446.134 86.5789 444.939C71.5214 441.046 56.0685 443.364 40.6888 443.364C31.8858 442.562 21.7498 442.999 12.9175 443.364C12.9175 443.364 9.50469 443.189 7.0293 443.364V414.292C9.27034 413.403 11.3649 414.044 12.9175 416.275C14.9388 419.162 16.594 419.497 19.5528 417.616C19.9482 417.368 20.3291 417.12 20.7099 416.843C22.7312 415.385 24.8111 415.079 27.0815 416.333C28.8684 417.31 30.7726 417.5 32.7939 417.106C34.1122 416.843 35.5769 416.712 36.5583 417.879C38.2134 419.876 40.4398 420.445 42.8566 420.722C45.1563 420.984 47.3827 421.626 49.0232 423.317C52.3774 426.758 55.8781 426.976 59.8475 424.396C61.5173 423.302 62.1471 423.623 63.3189 425.256C63.7437 425.839 64.1538 426.437 64.6372 426.962C66.4535 428.915 67.7717 429.163 70.3057 428.128C71.2724 428.216 72.2245 428.318 73.2205 428.274C75.3004 428.216 76.5455 429.557 77.2485 431.307C77.893 432.939 78.9183 433.741 80.7199 433.756C82.4923 433.771 83.9863 434.66 85.2753 435.797C87.4284 437.678 89.9478 438.553 92.7747 438.596C95.0304 438.626 96.9931 439.369 98.7508 440.754C100.406 442.066 102.208 443.16 104.405 443.364Z" fill="#66646F"/>
          </g>
          <g id="comp elem img hills">
            <path id="Vector_13" d="M372 408.627V549.883C372 553.814 368.334 557 363.826 557H15.1739C10.6519 557 7 553.814 7 549.883V435.829C46.557 440.851 117.426 449.455 192.275 432.122C263.728 415.558 326.067 405.106 372 408.627Z" fill="#9694A0"/>
            <path id="Vector_14" d="M372 408.627V549.883C372 553.814 368.334 557 363.826 557H15.1739C10.6519 557 7 553.814 7 549.883V448.959C46.557 453.981 123.588 486.886 192.275 455.766C265.126 422.736 326.067 405.106 372 408.627Z" fill="#C0BFCA"/>
          </g>
          <g id="comp elem img tree_3">
            <path id="Vector_15" d="M322.638 423.109C322.638 421.39 322.647 419.681 322.647 417.963C322.685 416.702 322.722 415.432 322.76 414.171L322.685 414.096C324.776 414.685 326.886 414.554 328.996 414.208C329.034 416.282 329.072 418.346 329.11 420.419C329.081 423.492 329.062 426.555 329.006 429.627C328.968 431.71 328.507 433.653 325.907 433.681C323.307 433.709 322.798 431.785 322.741 429.693C322.685 427.489 322.675 425.303 322.638 423.109Z" fill="#66646F"/>
            <path id="Vector_16" d="M322.76 414.171C313.01 412.042 306.293 404.804 306.727 395.493C307.377 381.745 311.201 368.783 318.728 357.053C323.476 359.191 326.086 363.245 328.243 367.653C331.521 374.358 333.594 381.456 334.941 388.768C335.591 392.308 336.457 395.857 335.789 399.509C334.413 407.008 329.788 411.649 322.675 414.096L322.76 414.171Z" fill="#66646F"/>
            <path id="Vector_17" d="M322.694 414.087C329.797 411.64 334.423 406.998 335.807 399.499C336.476 395.857 335.61 392.299 334.96 388.759C333.612 381.456 331.54 374.349 328.262 367.643C326.104 363.235 323.504 359.182 318.747 357.043C323.25 350.908 327.631 350.571 332.35 356.091C333.49 357.426 334.536 358.893 335.365 360.443C339.604 368.362 342.279 376.833 344.031 385.593C344.484 387.835 344.813 390.104 345.2 392.364C345.482 398.35 344.361 403.87 340.018 408.409C338.228 410.286 336.043 411.584 333.905 412.966C332.275 413.377 330.645 413.788 329.006 414.199C326.896 414.544 324.786 414.675 322.694 414.087Z" fill="#C0BFCA"/>
          </g>
          <g id="comp elem img tree_4">
            <path id="Vector_18" d="M262.798 446.735C262.798 443.977 262.814 441.233 262.814 438.475C262.874 436.451 262.935 434.412 262.996 432.388L262.874 432.268C266.244 433.213 269.643 433.003 273.043 432.448C273.104 435.776 273.164 439.089 273.225 442.418C273.18 447.35 273.149 452.267 273.058 457.199C272.997 460.543 272.254 463.661 268.065 463.706C263.876 463.751 263.056 460.663 262.965 457.304C262.874 453.766 262.859 450.258 262.798 446.735Z" fill="#66646F"/>
            <path id="Vector_19" d="M262.996 432.388C247.287 428.97 236.466 417.351 237.164 402.404C238.211 380.336 244.373 359.528 256.5 340.698C264.149 344.131 268.353 350.638 271.829 357.714C277.11 368.478 280.449 379.872 282.62 391.61C283.667 397.292 285.063 402.989 283.986 408.851C281.77 420.889 274.318 428.34 262.859 432.268L262.996 432.388Z" fill="#66646F"/>
            <path id="Vector_20" d="M262.889 432.253C274.333 428.325 281.785 420.874 284.016 408.836C285.094 402.989 283.697 397.277 282.65 391.595C280.48 379.872 277.141 368.463 271.859 357.699C268.384 350.623 264.195 344.116 256.53 340.683C263.785 330.833 270.842 330.294 278.446 339.154C280.283 341.298 281.967 343.651 283.303 346.14C290.133 358.853 294.443 372.451 297.266 386.513C297.995 390.111 298.526 393.754 299.148 397.382C299.603 406.992 297.797 415.852 290.8 423.138C287.917 426.151 284.396 428.235 280.95 430.454C278.325 431.114 275.699 431.773 273.058 432.433C269.658 432.988 266.259 433.197 262.889 432.253Z" fill="#C0BFCA"/>
          </g>
        </g>
      </g>
    </g>
    <g id="comp prev img tb">
      <g id="Rectangle 2_3">
        <g id="Rectangle 1_3" filter="url(#filter2_d_76_7501)">
          <rect x="17" y="17" width="346" height="274" fill="white"/>
        </g>
      </g>
      <rect id="themecolor1_2" x="16" y="243" width="347" height="48" fill={props.color}/>
      <g id="Rectangle 38_2">
        <rect id="Rectangle 35_2" x="109" y="256" width="161" height="20" rx="4" fill="#FBFBFC"/>
      </g>
      <mask id="mask2_76_7501" mask-type="alpha" maskUnits="userSpaceOnUse" x="16" y="16" width="347" height="227">
        <rect id="Rectangle 39_3" x="16" y="16" width="347" height="227" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask2_76_7501)">
        <g id="prev comp elem img 4:3_3">
          <g id="comp elem img sky_3">
            <rect id="Rectangle 16_3" x="7" y="17" width="365" height="252" rx="8" fill="#EAE8EE"/>
          </g>
          <g id="comp elem img clouds_3">
            <g id="Group_3">
              <path id="Vector_21" d="M179.628 88.6293C182.826 90.5842 186.051 92.4855 188.333 95.6186C189.222 96.8371 190.509 96.5559 191.77 96.4086C198.564 95.5651 204.748 99.6891 206.739 106.37C207.641 109.41 207.243 110.012 204.111 110.026C188.585 110.039 173.059 110.026 157.533 110.053C156.1 110.053 154.667 110.213 153.247 110.294C147.58 110.213 141.927 110.079 136.261 110.106C134.058 110.12 133.368 109.423 133.872 107.16C135.398 100.305 141.927 95.5249 148.854 96.3952C150.314 96.5827 151.548 96.7434 152.743 95.3776C159.67 87.3841 168.242 85.1882 178.235 88.2946C178.686 88.4151 179.15 88.5088 179.628 88.6293Z" fill="#FBFBFC"/>
              <path id="Vector_22" d="M105.129 88.2812C83.5254 88.2946 61.9348 88.308 40.331 88.3615C38.1016 88.3615 37.7698 87.4242 38.2077 85.4694C39.5347 79.511 44.6836 75.387 51.0665 75.6682C53.3755 75.7753 54.9281 75.1862 56.5604 73.4723C64.9073 64.7155 78.2173 64.7557 86.6571 73.5125C88.1965 75.1192 89.6562 76.0297 91.8988 75.6414C94.5529 75.1862 96.9681 76.4314 99.4894 76.9804C102.528 80.1537 105.687 83.2735 105.129 88.2812Z" fill="#FBFBFC"/>
              <path id="Vector_23" d="M117.895 36.1555C125.101 32.4199 132.36 31.7772 139.605 35.9949C145.457 39.4092 148.775 44.6044 149.929 51.3125C150.301 53.495 149.677 54.2984 147.448 54.2716C138.995 54.2047 130.542 54.2449 122.102 54.2449C114.233 54.2449 106.35 54.2582 98.4809 54.2315C95.389 54.2181 95.0174 53.6289 95.7871 50.5493C96.4373 47.9384 97.9634 45.8362 99.4364 43.6805C102.21 41.5649 105.116 39.4628 108.739 39.677C112.441 39.9046 115.48 39.0075 117.895 36.1555Z" fill="#FBFBFC"/>
            </g>
          </g>
          <g id="comp elem img bush_2">
            <path id="Vector_24" d="M70.2764 140.114C67.7424 141.149 66.4242 140.886 64.6079 138.947C64.1245 138.422 63.6998 137.825 63.2896 137.241C62.1179 135.608 61.5027 135.302 59.8182 136.381C55.8488 138.962 52.3481 138.743 48.9939 135.302C47.3534 133.626 45.127 132.984 42.8273 132.707C40.4105 132.43 38.1841 131.861 36.529 129.864C35.5476 128.698 34.0829 128.829 32.7646 129.091C30.7433 129.5 28.8391 129.295 27.0522 128.319C24.7818 127.065 22.7019 127.371 20.6806 128.829C20.2998 129.106 19.9189 129.354 19.5235 129.602C16.5793 131.482 14.9096 131.147 12.8882 128.26C11.3356 126.03 9.24104 125.388 7 126.277V116.728C9.51934 117.573 12.1412 117.909 14.7924 117.792C18.8936 117.602 22.409 118.681 25.397 121.539C26.9496 123.026 28.8099 123.507 30.9777 122.909C33.4677 122.224 35.533 122.895 37.1002 125.038C38.2574 126.598 39.81 127.59 41.846 127.502C45.9033 127.327 49.3161 128.785 52.2016 131.541C53.5492 132.824 55.0871 133.757 57.0352 133.611C61.3708 133.276 64.9741 134.5 67.4641 138.233C68.1672 139.21 69.3829 139.443 70.2764 140.114Z" fill="#C0BFCA"/>
            <path id="Vector_25" d="M104.405 155.364L104.39 158.367C103.145 158.776 102.002 159.505 100.67 159.621C95.8653 159.271 91.2074 158.134 86.5789 156.939C71.5214 153.046 56.0685 155.364 40.6888 155.364C31.8858 154.562 21.7498 154.999 12.9175 155.364C12.9175 155.364 9.50469 155.189 7.0293 155.364V126.292C9.27034 125.403 11.3649 126.044 12.9175 128.275C14.9388 131.162 16.594 131.497 19.5528 129.616C19.9482 129.368 20.3291 129.12 20.7099 128.843C22.7312 127.385 24.8111 127.079 27.0815 128.333C28.8684 129.31 30.7726 129.5 32.7939 129.106C34.1122 128.843 35.5769 128.712 36.5583 129.879C38.2134 131.876 40.4398 132.445 42.8566 132.722C45.1563 132.984 47.3827 133.626 49.0232 135.317C52.3774 138.758 55.8781 138.976 59.8475 136.396C61.5173 135.302 62.1471 135.623 63.3189 137.256C63.7437 137.839 64.1538 138.437 64.6372 138.962C66.4535 140.915 67.7717 141.163 70.3057 140.128C71.2724 140.216 72.2245 140.318 73.2205 140.274C75.3004 140.216 76.5455 141.557 77.2485 143.307C77.893 144.939 78.9183 145.741 80.7199 145.756C82.4923 145.771 83.9863 146.66 85.2753 147.797C87.4284 149.678 89.9478 150.553 92.7747 150.596C95.0304 150.626 96.9931 151.369 98.7508 152.754C100.406 154.066 102.208 155.16 104.405 155.364Z" fill="#66646F"/>
          </g>
          <g id="comp elem img hills_2">
            <path id="Vector_26" d="M372 120.627V261.883C372 265.814 368.334 269 363.826 269H15.1739C10.6519 269 7 265.814 7 261.883V147.829C46.557 152.851 117.426 161.455 192.275 144.122C263.728 127.558 326.067 117.106 372 120.627Z" fill="#9694A0"/>
            <path id="Vector_27" d="M372 120.627V261.883C372 265.814 368.334 269 363.826 269H15.1739C10.6519 269 7 265.814 7 261.883V160.959C46.557 165.981 123.588 198.886 192.275 167.766C265.126 134.736 326.067 117.106 372 120.627Z" fill="#C0BFCA"/>
          </g>
          <g id="comp elem img tree_5">
            <path id="Vector_28" d="M322.638 135.109C322.638 133.39 322.647 131.681 322.647 129.963C322.685 128.702 322.722 127.432 322.76 126.171L322.685 126.096C324.776 126.685 326.886 126.554 328.996 126.208C329.034 128.282 329.072 130.346 329.11 132.419C329.081 135.492 329.062 138.555 329.006 141.627C328.968 143.71 328.507 145.653 325.907 145.681C323.307 145.709 322.798 143.785 322.741 141.693C322.685 139.489 322.675 137.303 322.638 135.109Z" fill="#66646F"/>
            <path id="Vector_29" d="M322.76 126.171C313.01 124.042 306.293 116.804 306.727 107.493C307.377 93.7455 311.201 80.7827 318.728 69.0527C323.476 71.1914 326.086 75.2446 328.243 79.6527C331.521 86.3582 333.594 93.4559 334.941 100.768C335.591 104.308 336.457 107.857 335.789 111.509C334.413 119.008 329.788 123.649 322.675 126.096L322.76 126.171Z" fill="#66646F"/>
            <path id="Vector_30" d="M322.694 126.087C329.797 123.64 334.423 118.998 335.807 111.499C336.476 107.857 335.61 104.299 334.96 100.759C333.612 93.4559 331.54 86.3488 328.262 79.6433C326.104 75.2352 323.504 71.182 318.747 69.0434C323.25 62.9075 327.631 62.5713 332.35 68.0908C333.49 69.4263 334.536 70.8925 335.365 72.4428C339.604 80.3624 342.279 88.833 344.031 97.5931C344.484 99.8345 344.813 102.104 345.2 104.364C345.482 110.35 344.361 115.87 340.018 120.409C338.228 122.286 336.043 123.584 333.905 124.966C332.275 125.377 330.645 125.788 329.006 126.199C326.896 126.544 324.786 126.675 322.694 126.087Z" fill="#C0BFCA"/>
          </g>
          <g id="comp elem img tree_6">
            <path id="Vector_31" d="M262.798 158.735C262.798 155.977 262.814 153.233 262.814 150.475C262.874 148.451 262.935 146.412 262.996 144.388L262.874 144.268C266.244 145.213 269.643 145.003 273.043 144.448C273.104 147.776 273.164 151.089 273.225 154.418C273.18 159.35 273.149 164.267 273.058 169.199C272.997 172.543 272.254 175.661 268.065 175.706C263.876 175.751 263.056 172.663 262.965 169.304C262.874 165.766 262.859 162.258 262.798 158.735Z" fill="#66646F"/>
            <path id="Vector_32" d="M262.996 144.388C247.287 140.97 236.466 129.351 237.164 114.404C238.211 92.3364 244.373 71.5278 256.5 52.6981C264.149 56.1312 268.353 62.6377 271.829 69.7138C277.11 80.4779 280.449 91.8717 282.62 103.61C283.667 109.292 285.063 114.989 283.986 120.851C281.77 132.889 274.318 140.34 262.859 144.268L262.996 144.388Z" fill="#66646F"/>
            <path id="Vector_33" d="M262.889 144.253C274.333 140.325 281.785 132.874 284.016 120.836C285.094 114.989 283.697 109.277 282.65 103.595C280.48 91.8716 277.141 80.4628 271.859 69.6987C268.384 62.6226 264.195 56.1162 256.53 52.683C263.785 42.8334 270.842 42.2937 278.446 51.1539C280.283 53.2977 281.967 55.6514 283.303 58.14C290.133 70.8531 294.443 84.4506 297.266 98.513C297.995 102.111 298.526 105.754 299.148 109.382C299.603 118.992 297.797 127.852 290.8 135.138C287.917 138.151 284.396 140.235 280.95 142.454C278.325 143.114 275.699 143.773 273.058 144.433C269.658 144.988 266.259 145.197 262.889 144.253Z" fill="#C0BFCA"/>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <filter id="filter0_d_76_7501" x="-2" y="574" width="384" height="312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="9.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_7501"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_7501" result="shape"/>
    </filter>
    <filter id="filter1_d_76_7501" x="-2" y="286" width="384" height="312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="9.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_7501"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_7501" result="shape"/>
    </filter>
    <filter id="filter2_d_76_7501" x="-2" y="-2" width="384" height="312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="9.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_7501"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_7501" result="shape"/>
    </filter>
    <clipPath id="clip0_76_7501">
      <rect width="379" height="664" fill="white"/>
    </clipPath>
  </defs>
</svg>
    </div>
  );
};

export default CollectionBigImage;
