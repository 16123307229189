import React from "react";
import classnames from "classnames";
import { Draggable } from "react-beautiful-dnd";

import { CardComponentLayout, CardComponentLayoutNoOver } from "../card-layout";

export const MarketDraggableItem = ({
  componentId,
  title,
  subtitle,
  idx,
  text,
  image,
  mockSize,
  isDraggable,
  isLeftPanel = false
}) => {
  //console.log(`Component ID: ${componentId}, isDraggable: ${isDraggable}`);

  // Function to render the content of the draggable item
  const renderDraggableContent = (provided, snapshot) => (
    <>
      <div
        {...(isDraggable ? provided.draggableProps : {})}
        {...(isDraggable ? provided.dragHandleProps : {})}
        ref={isDraggable ? provided.innerRef : null}
        className={classnames("market-item", {
          dragging: isDraggable && snapshot.isDragging,
        })}
        style={provided.draggableProps ? provided.draggableProps.style : {}}
      >
        <CardComponentLayout
          title={title}
          subtitle={subtitle}
          text={text}
          image={image}
          mockSize={mockSize}
          isDragging={isDraggable && snapshot.isDragging}
          isDraggable={isDraggable}
          isLeftPanel={isLeftPanel}
        />
      </div>
      {isDraggable && snapshot.isDragging && (
        <div className="hide-me">
          <CardComponentLayoutNoOver
            title={title}
            subtitle={subtitle}
            text={text}
            image={image}
            mockSize={mockSize}
          />
        </div>
      )}
    </>
  );

  return isDraggable ? (
    <Draggable draggableId={componentId} index={idx}>
      {(provided, snapshot) => renderDraggableContent(provided, snapshot)}
    </Draggable>
  ) : (
    renderDraggableContent({}, {})
  );
};
